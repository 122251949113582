import { apiClient } from "../../Services/Networking/apiClient"
import { CuttingResultsListItem, CuttingDataInfo, ConsolidatedCuttingDataInfo } from "../types"


export const getCuttingItemCodeResultsBasedOnOCNumber = async (ocNumber: string): Promise<CuttingResultsListItem[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/cutting-register`,
    params: { ocNum: ocNumber }
  })
  return response as unknown as CuttingResultsListItem[]
}

export const getCuttingDataBasedOnItemCode = async (ocNumber: string, itemCode: string, location: string, fitType: string): Promise<CuttingDataInfo> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/cutting-register/oc/${ocNumber}/item/${itemCode}`,
    params: { location: location, fitType: fitType }
  })
  return response as unknown as CuttingDataInfo
}

export const getConsolidatedCuttingDataBasedOnOCNumber = async (ocNumber: string): Promise<ConsolidatedCuttingDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/cutting-register/ocNumber/${ocNumber}`
  })
  return response as unknown as ConsolidatedCuttingDataInfo[]
}