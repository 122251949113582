import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { convertNumberToLocaleString, roundNumberToSignificantDigits } from '../../utils'
import { DefectData, DHUDataDetails, HourlyDefectData } from '../types'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface DHUDateRangeTableProps {
  data: DHUDataDetails[]
}

type DataTypePerBatch = {
  totalQuantity: number,
  totalRejectedQuantity: number,
  [defect: string]: number
}

type GroupedDataType = {
  [batchNumber: string]: DataTypePerBatch
}

type GroupedDataTypeWithTotalDefects = {
  [batchNumber: string]: DataTypePerBatch & {
    totalDefects: number
  }
}

type GroupedDataTypeWithTotalDefectsAndDHU = {
  [batchNumber: string]: DataTypePerBatch & {
    totalDefects: number,
    DHU: number
  }
}

const DHUDateRangeTable = (props: DHUDateRangeTableProps) => {
  const { data } = props
  const [defects, setDefects] = useState<string[]>([])
  const [groupedData, setGroupedData] = useState<GroupedDataTypeWithTotalDefectsAndDHU>({})

  useEffect(() => {
    const groupedData = groupData(data)
    const groupedDataWithTotalDefects = calculateTotalDefectsForEachDate(groupedData)
    const groupedDataWithTotalDefectsAndDHU = calculateDHUForEachDate(groupedDataWithTotalDefects)
    setGroupedData(groupedDataWithTotalDefectsAndDHU)
    const uniqueListOfDefects = getUniqueListOfDefects(data)
    setDefects(uniqueListOfDefects)
  }, [data])

  const groupData = (data: DHUDataDetails[]) => {
    const groupedObject: GroupedDataType = {}

    data.forEach((each: DHUDataDetails) => {
      const { date, totalPassedQuantity, totalRejectedQuantity, totalReworkedQuantity, hourlyDetails } = each
      const totalQuantity = totalPassedQuantity + totalRejectedQuantity + totalReworkedQuantity
      groupedObject[date] = groupedObject[date] || { totalQuantity: 0, totalRejectedQuantity: 0 }
      groupedObject[date].totalQuantity += totalQuantity
      groupedObject[date].totalRejectedQuantity += totalRejectedQuantity

      hourlyDetails.forEach((item: HourlyDefectData) => {
        const { defectDetails } = item
        defectDetails.forEach((defectObject: DefectData) => {
          const { defect, quantity } = defectObject
          groupedObject[date][defect] = groupedObject[date][defect] || 0
          groupedObject[date][defect] += quantity
        })
      })
    })
    return groupedObject
  }

  const calculateTotalDefectsForEachDate = (data: GroupedDataType): GroupedDataTypeWithTotalDefects => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const { totalQuantity, totalRejectedQuantity, ...defectsObject } = value
      const totalNumberOfDefects = Object.entries(defectsObject).reduce((acc, [defect, value]) => {
        return acc + value
      }, 0)
      return Object.assign(
        {},
        { ...acc },
        {
          [key]: {
            ...value,
            totalDefects: totalNumberOfDefects
          }
        }
      )
    }, {})
  }

  const calculateDHUForEachDate = (data: GroupedDataTypeWithTotalDefects): GroupedDataTypeWithTotalDefectsAndDHU => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const { totalQuantity, totalDefects } = value
      const DHU = totalDefects * 100 / totalQuantity
      return Object.assign(
        {},
        { ...acc },
        {
          [key]: {
            ...value,
            DHU
          }
        }
      )
    }, {})
  }

  const getUniqueListOfDefects = (data: DHUDataDetails[]) => {
    const listOfDefects = data.map(({ hourlyDetails }) => {
      return hourlyDetails.map((item: HourlyDefectData) => {
        const { defectDetails } = item
        return defectDetails.map((defectObject: DefectData) => {
          const { defect } = defectObject
          return defect
        })
      }).flat()
    }).flat()
    return [...new Set(listOfDefects)]  //  this line will remove all duplicates because a Set by default will remove duplicates
  }

  const renderTableRows = (data: GroupedDataTypeWithTotalDefectsAndDHU) => {
    return Object.keys(data).map((date) => {
      const { totalQuantity, totalRejectedQuantity, totalDefects, DHU, ...defectsObject } = groupedData[date]
      const correspondingDefectQuantities = defects.map(defect => {
        const quantity = defectsObject[defect] || 0
        return quantity
      })
      return (
        <TableRow key={date}>
          <TableCell align='center'>{date}</TableCell>
          {
            correspondingDefectQuantities.map((qty, index) => {
              return <TableCell align='center'>{qty}</TableCell>
            })
          }
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>{totalDefects}</TableCell>
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>{totalRejectedQuantity}</TableCell>
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>{roundNumberToSignificantDigits(DHU, 2)} %</TableCell>
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>{convertNumberToLocaleString(totalQuantity)}</TableCell>
        </TableRow>
      )
    })
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []

    Object.keys(groupedData).map((date) => {
      const { totalQuantity, totalRejectedQuantity, totalDefects, DHU, ...defectsObject } = groupedData[date]
      const correspondingDefectQuantities = defects.map(defect => {
        const quantity = defectsObject[defect] || 0
        return quantity
      })

      const pushObject: any = {date}

      correspondingDefectQuantities.map((qty, index) => {
        pushObject[defects[index]] = qty
        return null
      })

      pushObject['totalDefects'] = totalDefects
      pushObject['totalRejected'] = totalRejectedQuantity
      pushObject['DHU%'] = roundNumberToSignificantDigits(DHU, 2)
      pushObject['totalInspected'] = convertNumberToLocaleString(totalQuantity)

      jsonData.push(pushObject)
      return null
    })


    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'DHU-Date-Range')
    XLSX.writeFile(workBook, 'DHU-Date-Range.xlsx')
  }

  
  return (
    <TableContainer component={Paper}>

      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}>
        <Typography variant='h5' padding={2}>Batch DHU Table-Date Range</Typography>
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>
      
      
      <Table sx={{
        overflowX: 'auto',
        maxWidth: '100%',
        whiteSpace: 'nowrap'
      }}>
        <TableHead>
          <TableRow sx={{
            backgroundColor: 'alpha.lightest'
          }}>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>DATE</TableCell>
            {
              defects.map((defect: string) => {
                return <TableCell sx={{
                  fontWeight: 'bold'
                }} align='center' key={defect}>{defect}</TableCell>
              })
            }
            <TableCell sx={{
              fontWeight: 'bold'
            }} align='center'>TOTAL DEFECTS</TableCell>
            <TableCell sx={{
              fontWeight: 'bold'
            }} align='center'>TOTAL REJECTED</TableCell>
            <TableCell sx={{
              fontWeight: 'bold'
            }} align='center'>DHU %</TableCell>
            <TableCell sx={{
              fontWeight: 'bold'
            }} align='center'>TOTAL INSPECTED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows(groupedData)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DHUDateRangeTable