import React, { useState } from "react"
import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import SnackBarMessage from "../../UI/Components/SnackBarMessage"
import StringValues from "../../Providers/StringValues"
import { SearchBar } from "../../UI/Components"
import { getPoNumberDetails, postPodelete } from "../api/apiFunctions"
import { barcodePoNumberType } from "../types"

const Mir = () => {
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [barcodeSearchTerm, setBarcodeSearchTerm] = useState<string>("")
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [partDetailsData, setPartDetailsData] = useState<barcodePoNumberType[]>([])
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<'single' | 'multiple' | null>(null)

  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleSearchBarButtonClick = () => {
    fetchPartBarcodeDetails()
  }

  const fetchPartBarcodeDetails = async () => {
    setIsLoading(true)
    setIsError(false)

    try {
      const data = await getPoNumberDetails(searchTerm)
      if (Array.isArray(data)) {
        setPartDetailsData(data)
        console.log("Fetched data:", data)
      } else {
        setIsError(true)
        setErrorMessage("No rolls found in the response")
        setPartDetailsData([])
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        setIsError(true)
        setErrorMessage("An unknown error occurred")
      }
      setSnackBarOpen(true)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSnackBarClose = () => {
    setSnackBarOpen(false)
  }

  const handleCheckboxChange = (index: number) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index]
    )
  }

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectAllChecked(event.target.checked)
    if (event.target.checked && partDetailsData) {
      setSelectedRows(partDetailsData.map((_, index) => index))
    } else {
      setSelectedRows([])
    }
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenDeleteDialog = () => {
    if (selectedRows.length > 0) {
      setOpenDeleteDialog('multiple')
    }
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(null)
  }

  const handleDelete = async () => {
    if (openDeleteDialog) {
      try {
        // Map selected rows to their actual indices in partDetailsData
        const actualIndices = selectedRows.map(
          (index) => partDetailsData.indexOf(filteredPartDetails[index])
        )

        const barcodesToDelete = actualIndices.map(
          (index) => partDetailsData[index].barcodes
        )
        const poNumbersToDelete = actualIndices.map(
          (index) => partDetailsData[index].poNumber
        )

        const results = await Promise.all(
          barcodesToDelete.map((barcode, idx) =>
            postPodelete(barcode, poNumbersToDelete[idx])
          )
        )

        const successfulDeletions = results.filter((res) => res).length
        const totalDeletions = results.length

        // Check the results of the deletion
        if (successfulDeletions === totalDeletions && totalDeletions > 0) {
          setErrorMessage("Failed to delete all selected rows.")
          setIsError(true)
        } else if (successfulDeletions > 0) {
          setErrorMessage(
            `Partially deleted rows. ${successfulDeletions} out of ${totalDeletions} rows were deleted.`
          )
          setIsError(false) // Partial error
        } else {
          setErrorMessage("Successfully deleted selected PO barcode details.")
          setIsError(false)
        }

        // Update partDetailsData by removing the deleted items
        const updatedData = partDetailsData.filter(
          (_, index) => !actualIndices.includes(index)
        )

        setPartDetailsData(updatedData)

        // Clear selections and close dialog after refresh
        setSelectedRows([])
        setOpenDeleteDialog(null)
      } catch (error) {
        setErrorMessage("An error occurred during deletion.")
        setIsError(true)
      } finally {
        setSnackBarOpen(true) // Show the snackbar regardless
      }
    }
  }
  // New barcode search handler
  const handleBarcodeSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcodeSearchTerm(event.target.value)
  }

  // Filter part details by barcode
  const filteredPartDetails = partDetailsData.filter((row) => {
    // Split the barcode search term by commas and trim whitespace
    const searchTerms = barcodeSearchTerm.split(",").map(term => term.trim())

    // Check if any of the search terms match the row's barcode
    return searchTerms.some(term => row.barcodes.toLowerCase().includes(term.toLowerCase()))
  })

  return (
    <React.Fragment>
      <SnackBarMessage
        message={errorMessage}
        successStatus={!isError}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
      />
      <SearchBar
        value={searchTerm}
        onChange={handleSearchBarChange}
        onButtonClick={handleSearchBarButtonClick}
        error={isError}
        errorMessage={errorMessage}
        inputLabel={StringValues.poLabel}
        width={"20%"}
        marginLeft={2}
      />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        {isLoading ? null : (
          filteredPartDetails.length > 0 && (
            <>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={filteredPartDetails.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
              {/* Barcode Search Input */}
              <TextField
                label="Search Barcode"
                variant="outlined"
                value={barcodeSearchTerm}
                onChange={handleBarcodeSearchChange}
                sx={{ marginRight: 8, marginBottom: 2, '& .MuiInputBase-root': { height: '36px' } }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                variant="contained"
                onClick={handleOpenDeleteDialog}
                disabled={selectedRows.length === 0}
                sx={{
                  backgroundColor: "alpha.light",
                  width: '100px',
                  marginRight: 10,
                }}
              >
                Delete
              </Button>
            </>
          )
        )}
      </Box>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 9999,
          }}
        >
          <LinearProgress sx={{ width: "50%" }} />
        </Box>
      ) : (
        filteredPartDetails.length > 0 && (
          <Box sx={{ width: "100%", bgcolor: "primary.pane", marginTop: 2 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={selectAllChecked}
                        onChange={handleSelectAllChange}
                      />
                    </TableCell>
                    <TableCell>OC Number</TableCell>
                    <TableCell>Barcodes</TableCell>
                    <TableCell>Invoice Number</TableCell>
                    <TableCell>Item Code</TableCell>
                    <TableCell>MIR Date</TableCell>
                    <TableCell>MIR Location</TableCell>
                    <TableCell>MIR Number</TableCell>
                    <TableCell>PO Number</TableCell>
                    <TableCell>Roll Length</TableCell>
                    <TableCell>Roll Utilized</TableCell>
                    <TableCell>Supplier Roll Number</TableCell>
                    <TableCell>User</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredPartDetails
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={row.barcodes}>
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.includes(index)}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </TableCell>
                        <TableCell>{row.OCNumber}</TableCell>
                        <TableCell>{row.barcodes}</TableCell>
                        <TableCell>{row.invoiceNumber}</TableCell>
                        <TableCell>{row.itemCode}</TableCell>
                        <TableCell>{row.mirDate}</TableCell>
                        <TableCell>{row.mirLocation}</TableCell>
                        <TableCell>{row.mirNumber}</TableCell>
                        <TableCell>{row.poNumber}</TableCell>
                        <TableCell>{row.rollLength}</TableCell>
                        <TableCell>{row.rollUtilized}</TableCell>
                        <TableCell>{row.supplierRollNumber}</TableCell>
                        <TableCell>{row.user}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )
      )}

      <Dialog open={openDeleteDialog !== null} onClose={handleCloseDeleteDialog}>
        <DialogTitle>
          {openDeleteDialog === 'multiple' ? "Confirm Deletion" : "Delete Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {openDeleteDialog === 'multiple'
              ? "Are you sure you want to delete the selected PO barcode details?"
              : "Are you sure you want to delete this item?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default Mir
