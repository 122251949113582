import React, { useState, useEffect } from 'react'
import {
  Typography, Box, TableContainer, Paper, Table, TableCell, TableHead,
  TableBody, TableRow, FormGroup, Checkbox, Stack
} from '@mui/material'
import { FlatButton, SubmitDialog } from '../../UI/Components'
import StringValues from '../../Providers/StringValues'
import { getRackDetailsType } from '../types'
const ZebraBrowserPrintWrapper = require('zebra-browser-print-wrapper')

interface RackBarcodeRetreiveProps {
  rackBarcodeDetails: getRackDetailsType[]
  handleSnackBarMessage: (message: string) => void
  rackBarcodeLocation: string
  handleLoading: (loadingStatus: boolean) => void
}

function RackBarcodeRetreive(props: RackBarcodeRetreiveProps) {
  const { rackBarcodeDetails, handleSnackBarMessage, rackBarcodeLocation, handleLoading } = props
  const [selectedRackDetails, setSelectedRackDetails] = useState<getRackDetailsType[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState<string>('')
  console.log("rackBarcodeDetails", rackBarcodeDetails)
  useEffect(() => {
    const rackDetailsSelect = rackBarcodeDetails.map(obj => ({ ...obj, isSelected: false }))
    console.log("rackDetailsSelect", rackDetailsSelect)
    setSelectedRackDetails(rackDetailsSelect)
  }, [rackBarcodeDetails])

  const handleSubmitDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true) {
      printBarcode()
    }
  }

  const printBarcodeConfirmation = () => {
    setDialogMessage('Are you sure you want to print selected barcodes?')
    setDialogOpen(true)
  }

  const printBarcode = async () => {
    const selectedBarcodes = selectedRackDetails.filter(barcode => barcode.isSelected)
    console.log("selectedBarcodes", selectedBarcodes)
    if (selectedBarcodes.length === 0) {
      handleSnackBarMessage("Please select at least one barcode to print.")
      return
    }

    handleLoading(true)
    const selectRackDetailslist: getRackDetailsType[] = []
    selectedRackDetails.forEach(async rackDetail => {
      const { rackBarcode, rackLocation } = rackDetail
      try {
        const browserPrint = new ZebraBrowserPrintWrapper.default()
        const defaultPrinter = await browserPrint.getAvailablePrinters()

        if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0) {
          handleLoading(false)
          handleSnackBarMessage(StringValues.zebraPrinterNotFoundMessage)
          return
        }

        if (rackDetail.isSelected === true) {
          selectRackDetailslist.push(rackDetail)

          browserPrint.setPrinter(defaultPrinter[0])
          const printerStatus = await browserPrint.checkPrinterStatus()

          if (printerStatus.isReadyToPrint) {
            const zplCode = `^XA
           ^MMT
           ^PW812
           ^LL424
           ^LS0
           ^FT355,10^A0N,3,5^FH\^CI28^FDRACK^FS^CI27
           ^FT385,114^A0N,62,61^FH\^CI28^FD${rackLocation}^FS^CI27
           ^FT378,278^A0N,135,134^FH\^CI28^FD${rackBarcode}^FS^CI27
           ^FT45,350^BQN,2,7
           ^FH\^FDLA,location - ${rackLocation} Rack - ${rackBarcode}^FS
           ^PQ1,0,1,Y
           ^XZ`
            browserPrint.print(zplCode)
            handleLoading(false)
          } else {
            handleLoading(false)
            handleSnackBarMessage(`Printer Error: ${printerStatus.errors}`)
            return
          }
        }
        return null
      } catch (error) {
        handleLoading(false)
        handleSnackBarMessage(`${error}`)
        return
      }
    })
  }

  const barcodeCheckBoxChange = (rackBarcode: string) => {
    const updatedRackDetails = selectedRackDetails.map(barcode => {
      if (barcode.rackBarcode === rackBarcode) {
        return { ...barcode, isSelected: !barcode.isSelected }
      }
      return barcode
    })

    setSelectedRackDetails(updatedRackDetails)
  }

  const handleSelectAll = () => {
    const allSelected = selectedRackDetails.every(barcode => barcode.isSelected)
    const updatedRackDetails = selectedRackDetails.map(barcode => ({
      ...barcode,
      isSelected: !allSelected
    }))
    setSelectedRackDetails(updatedRackDetails)
  }

  return (
    <React.Fragment>
      <SubmitDialog
        onClose={handleSubmitDialogClose}
        open={dialogOpen}
        submitStatus={false}
        dialogMessage={dialogMessage} />
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Stack direction={'row'} justifyContent='space-between'>
          <FlatButton label='PRINT' onClick={printBarcodeConfirmation} disableControlsStatus={false} />
        </Stack>
      </Box>
      <Box sx={{ paddingTop: 2 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormGroup sx={{ paddingLeft: 0 }}>
                    <Checkbox
                      checked={selectedRackDetails.length > 0 && selectedRackDetails.every(barcode => barcode.isSelected)}
                      onChange={handleSelectAll} />
                  </FormGroup></TableCell>
                {/* <TableCell>  <Typography variant='subtitle1'>SELECT</Typography></TableCell> */}
                <TableCell><Typography variant='subtitle1'>RACK NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>LOCATION</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ACTION</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                selectedRackDetails.map(barcodeObject => (
                  <TableRow key={barcodeObject.rackBarcode}>
                    <TableCell>
                      <Checkbox
                        checked={barcodeObject.isSelected}
                        onChange={() => barcodeCheckBoxChange(barcodeObject.rackBarcode)} />
                    </TableCell>
                    <TableCell>{barcodeObject.rackBarcode}</TableCell>
                    <TableCell>{barcodeObject.rackLocation}</TableCell>
                    <TableCell>{barcodeObject.rackBarcodeCreated}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </React.Fragment>
  )
}

export default RackBarcodeRetreive