import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DefectData, DHUDataDetails, HourlyDefectData } from '../types'
import { convertNumberToLocaleString, roundNumberToSignificantDigits } from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface DHUSingleDateTableProps {
  data: DHUDataDetails[]
}

type DataTypePerBatch = {
  totalQuantity: number,
  totalRejectedQuantity: number,
  [defect: string]: number
}

type GroupedDataType = {
  [batchNumberAndProduct: string]: DataTypePerBatch
}

type GroupedDataTypeWithTotalDefects = {
  [batchNumberAndProduct: string]: DataTypePerBatch & {
    totalDefects: number
  }
}

type GroupedDataTypeWithTotalDefectsAndDHU = {
  [batchNumberAndProduct: string]: DataTypePerBatch & {
    totalDefects: number,
    DHU: number
  }
}

const DHUSingleDateTable = (props: DHUSingleDateTableProps) => {
  const { data } = props

  const [defects, setDefects] = useState<string[]>([])
  const [groupedData, setGroupedData] = useState<GroupedDataTypeWithTotalDefectsAndDHU>({})
  const [defectsQuantityTotal, setDefectsQuantityTotal] = useState<DefectData[]>([])

  useEffect(() => {
    const groupedData = groupData(data)
    const groupedDataWithTotalDefects = calculateTotalDefectsForEachBatch(groupedData)
    const groupedDataWithTotalDefectsAndDHU = calculateDHUForEachBatch(groupedDataWithTotalDefects)
    
    setGroupedData(groupedDataWithTotalDefectsAndDHU)
    const uniqueListOfDefects = createUniqueListOfDefects(data)
    setDefects(uniqueListOfDefects)

    const defectWiseTotalQuantity = createDefectTotalQuantityList(uniqueListOfDefects, data)
    setDefectsQuantityTotal(defectWiseTotalQuantity)
  }, [data])

  const groupData = (data: DHUDataDetails[]) => {
    const groupedObject: GroupedDataType = {}

    data.forEach((each: DHUDataDetails) => {
      const { batchNumber, productCode, totalPassedQuantity, totalRejectedQuantity, totalReworkedQuantity, hourlyDetails } = each
      const totalQuantity = totalPassedQuantity + totalRejectedQuantity + totalReworkedQuantity
      const batchNumberAndProduct = `${batchNumber}|${productCode}`
      groupedObject[batchNumberAndProduct] = groupedObject[batchNumberAndProduct] || { totalQuantity: 0, totalRejectedQuantity: 0 }
      groupedObject[batchNumberAndProduct].totalQuantity += totalQuantity
      groupedObject[batchNumberAndProduct].totalRejectedQuantity += totalRejectedQuantity

      hourlyDetails.forEach((item: HourlyDefectData) => {
        const { defectDetails } = item
        defectDetails.forEach((defectObject: DefectData) => {
          const { defect, quantity } = defectObject
          groupedObject[batchNumberAndProduct][defect] = groupedObject[batchNumberAndProduct][defect] || 0
          groupedObject[batchNumberAndProduct][defect] += quantity  
        })
      })
    })
    return groupedObject
  }

  const calculateTotalDefectsForEachBatch = (data: GroupedDataType): GroupedDataTypeWithTotalDefects => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const { totalQuantity, totalRejectedQuantity, ...defectsObject } = value
      const totalNumberOfDefects = Object.entries(defectsObject).reduce((acc, [defect, value]) => {
        return acc + value
      }, 0)
      return Object.assign(
        {},
        { ...acc },
        {
          [key]: {
            ...value,
            totalDefects: totalNumberOfDefects
          }
        }
      )
    }, {})
  }

  const calculateDHUForEachBatch = (data: GroupedDataTypeWithTotalDefects): GroupedDataTypeWithTotalDefectsAndDHU => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const { totalQuantity, totalDefects } = value
      const DHU = totalDefects * 100 / totalQuantity
      return Object.assign(
        {},
        { ...acc },
        {
          [key]: {
            ...value,
            DHU
          }
        }
      )
    }, {})
  }

  const createUniqueListOfDefects = (data: DHUDataDetails[]) => {
    const uniqueListOfDefects: string[] = []
    data.forEach((each: DHUDataDetails) => {
      const { hourlyDetails } = each
      hourlyDetails.forEach((hourDetail: HourlyDefectData) => {
        const { defectDetails } = hourDetail
        defectDetails.forEach((defectObject: DefectData) => {
          const { defect } = defectObject
          if (uniqueListOfDefects.indexOf(defect) < 0) {
            uniqueListOfDefects.push(defect)
          }
        })
      })
    })
    return uniqueListOfDefects
  }

  const createDefectTotalQuantityList = (defectList: string[], data: DHUDataDetails[]) => {
    const defectTotalList: DefectData[] = []

    defectList.forEach((element : string, index)=>{
      data.forEach((each: DHUDataDetails) => {
        const { hourlyDetails } = each

        hourlyDetails.forEach((hourDetail: HourlyDefectData) => {
          const { defectDetails } = hourDetail
          defectDetails.forEach((defectObject: DefectData) => {
            const { defect, quantity } = defectObject
            if (element === defect){
              const index = defectTotalList.findIndex(obj => obj.defect === defect)
              if (index !== -1) {
                defectTotalList[index].quantity += quantity
              } else {
                defectTotalList.push({...defectObject})
              }
            } 
          })
        })
      })
    })
    return defectTotalList
  }

  const renderTableRows = (data: GroupedDataTypeWithTotalDefectsAndDHU) => {
    
    return Object.keys(data).map((batchNumberAndProduct) => {
      const { totalQuantity, totalRejectedQuantity, totalDefects, DHU, ...defectsObject } = groupedData[batchNumberAndProduct]
      const splitBatchAndProductList = batchNumberAndProduct.split('|')

      const DHUObject = props.data.find(({ batchNumber, productCode }) => {
        return batchNumber === splitBatchAndProductList[0] && productCode === splitBatchAndProductList[1]
      })

      const correspondingDefectQuantities = defects.map(defect => {
        const quantity = defectsObject[defect] || 0
        return quantity
      })
      return (
        <TableRow key={batchNumberAndProduct}>
          <TableCell align='center'>{splitBatchAndProductList[0]}</TableCell>
          <TableCell align='center'>{splitBatchAndProductList[1]}</TableCell>
          <TableCell align='center'>{DHUObject?.productDescription}</TableCell>
          <TableCell align='center'>{DHUObject?.buyerName}</TableCell>
          {
            correspondingDefectQuantities.map((qty, index) => {
              return <TableCell align='center'>{qty}</TableCell>
            })
          }
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>{totalDefects}</TableCell>
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>{totalRejectedQuantity}</TableCell>
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>{roundNumberToSignificantDigits(DHU, 2)}</TableCell>
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>{convertNumberToLocaleString(totalQuantity)}</TableCell>
        </TableRow>
      )
    })
  }

  const renderTableTotal = (data: GroupedDataTypeWithTotalDefectsAndDHU) => {
    let totalDefects = 0
    let totalRejectedQuantity = 0
    let totalInspectedQuantity = 0
    let totalAverageDHU = 0

    Object.entries(data).map(([key, value], index) =>{
      totalDefects += value.totalDefects
      totalRejectedQuantity += value.totalRejectedQuantity
      totalInspectedQuantity += value.totalQuantity
      return null
    })

    if (totalInspectedQuantity > 0){
      totalAverageDHU = roundNumberToSignificantDigits((totalDefects * 100 / totalInspectedQuantity), 2)
    }

    return (
      <TableRow key={'defectTotal'}>
        <TableCell align='center' sx={{ fontWeight: 'bold' }}>{'TOTAL'}</TableCell>
        <TableCell/>
        <TableCell/>
        <TableCell/>
        {
          defectsQuantityTotal.map((defectObject)=> {
            return (
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>{defectObject.quantity}</TableCell>
            )
          })
        }
        <TableCell align='center' sx={{ fontWeight: 'bold' }}>{totalDefects}</TableCell>
        <TableCell align='center' sx={{ fontWeight: 'bold' }}>{totalRejectedQuantity}</TableCell>
        <TableCell align='center' sx={{ fontWeight: 'bold' }}>{totalAverageDHU}</TableCell>
        <TableCell align='center' sx={{ fontWeight: 'bold' }}>{totalInspectedQuantity}</TableCell>
      </TableRow>
    )
  }


  /** Total row calculation */
  const rowTotalsForDownload = () => {
    let totalDefects = 0
    let totalRejectedQuantity = 0
    let totalInspectedQuantity = 0
    let totalAverageDHU = 0

    Object.entries(groupedData).map(([key, value], index) =>{
      totalDefects += value.totalDefects
      totalRejectedQuantity += value.totalRejectedQuantity
      totalInspectedQuantity += value.totalQuantity
      return null
    })

    if (totalInspectedQuantity > 0){
      totalAverageDHU = roundNumberToSignificantDigits((totalDefects * 100 / totalInspectedQuantity), 2)
    }

    const object: any = {
      batch: 'total', 
      product: '',
      productName: '',
      buyerName: ''
    }

    defectsQuantityTotal.map((defectObject)=> {
      const { defect, quantity } = defectObject
      object[defect] = quantity
      return null
    })

    object['totalDefects'] = totalDefects
    object['totalRejected'] = totalRejectedQuantity
    object['DHUInPercentage'] = totalAverageDHU
    object['totalInspected'] = totalInspectedQuantity

    return object
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []

    Object.keys(groupedData).map((batchNumberAndProduct) => {
      const { totalQuantity, totalRejectedQuantity, totalDefects, DHU, ...defectsObject } = groupedData[batchNumberAndProduct]
      const splitBatchAndProductList = batchNumberAndProduct.split('|')

      const DHUObject = props.data.find(({ batchNumber, productCode }) => {
        return batchNumber === splitBatchAndProductList[0] && productCode === splitBatchAndProductList[1]
      })

      const correspondingDefectQuantities = defects.map(defect => {
        const quantity = defectsObject[defect] || 0
        return quantity
      })

      const pushObject: any = {
        batch: splitBatchAndProductList[0], 
        product: splitBatchAndProductList[1],
        productName: DHUObject?.productDescription,
        buyerName: DHUObject?.buyerName
      }

      correspondingDefectQuantities.map((qty, index) => {
        pushObject[defects[index]] = qty
        return null
      })

      pushObject['totalDefects'] = totalDefects
      pushObject['totalRejected'] = totalRejectedQuantity
      pushObject['DHU%'] = roundNumberToSignificantDigits(DHU, 2)
      pushObject['totalInspected'] = convertNumberToLocaleString(totalQuantity)

      jsonData.push(pushObject)
      return null
    })

    const totalRowResult = rowTotalsForDownload()
    jsonData.push(totalRowResult)

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'DHU-Single-Date')
    XLSX.writeFile(workBook, 'DHU-Single-Date.xlsx')
  }

  return (
    <TableContainer component={Paper}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}
      >
        <Typography variant='h5' padding={2}>Batch DHU Table-Single Date</Typography>
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>

      
      <Table sx={{
        overflowX: 'auto',
        maxWidth: '100%',
        whiteSpace: 'nowrap'
      }}>
        <TableHead>
          <TableRow sx={{
            backgroundColor: 'alpha.lightest'
          }}>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>BATCH</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>PRODUCT</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>PRODUCT NAME</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>BUYER NAME</TableCell>
            {
              defects.map((defect: string) => {
                return <TableCell sx={{
                  fontWeight: 'bold'
                }} align='center' key={defect}>{defect}</TableCell>
              })
            }
            <TableCell sx={{fontWeight: 'bold'}} align='center' >TOTAL DEFECTS</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align='center' >TOTAL REJECTED</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align='center'>DHU %</TableCell>
            <TableCell sx={{fontWeight: 'bold'}} align='center'>TOTAL INSPECTED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows(groupedData)}
          {renderTableTotal(groupedData)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DHUSingleDateTable