import React, { useState, useEffect } from 'react'
import { Typography, Box, TableContainer, Paper, Table, TableCell, TableHead, 
  TableBody, TableRow, Card, CardContent, FormGroup, FormControlLabel, Checkbox, Switch, Stack } from '@mui/material'
import { GeneratedBarcodeDetails, SelectedBarcodeList, BarcodeRollData } from '../types'
import { checkboxClasses } from "@mui/material/Checkbox"
import {FlatButton} from '../../UI/Components'

interface BarcodeRetreiveProps {
  barcodeDetails: GeneratedBarcodeDetails
  barcodeRePrintSubmit : (rollQuantitiesDetails : BarcodeRollData[]) => void
  selectedBarcode: BarcodeRollData[]
}

function BarcodeRetreive(props: BarcodeRetreiveProps) {
  const {barcodeDetails, barcodeRePrintSubmit, selectedBarcode} = props

  const [selectedBarcodeList, setSelectedBarcodeList] = useState<SelectedBarcodeList[]>([])
  const [switchDense, setSwitchDense] = useState(false)

  useEffect(()=>{

    if (selectedBarcode.length > 0){
      const selectdBarcodesList = barcodeDetails.rollDetails.map((object)=>{
        const selectedBarcodeObject = selectedBarcode.find(barcodeObject => barcodeObject.roll === object.rollNumber)
        if (selectedBarcodeObject === null || selectedBarcodeObject === undefined) {
          return ({...object, isSelected: false})
        }
        return ({...object, isSelected: true})
      })
      setSelectedBarcodeList(selectdBarcodesList)
      return
    }

    const barcodeList = barcodeDetails.rollDetails.map((object)=>{
      return ({...object, isSelected: false})
    })
    setSelectedBarcodeList(barcodeList)


  }, [barcodeDetails])


  const convertDateTimeToLocalString = (date: string | null) => {
    if (date === null || date === undefined)
    {
      return null
    }
    return new Date(date as string)
      .toLocaleTimeString('en-GB',
        {timeZone: 'UTC', hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}
      ).replace(',', '')
  }

  const barcodeCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const barcodeNumber = event.target.name
    const checkedStatus = event.target.checked

    const barcodeList = selectedBarcodeList.map((barcodeObject)=>{

      if (barcodeNumber === barcodeObject.rollNumber){
        return {...barcodeObject, isSelected: checkedStatus}
      }
      return {...barcodeObject}
    })
    
    setSelectedBarcodeList(barcodeList)
  }


  const selectOrDeselectDataRows = (status:boolean)=>{

    const barcodeList = selectedBarcodeList.map((barcodeObject)=>{
      return {...barcodeObject, isSelected: status}
    })
    setSelectedBarcodeList(barcodeList)
  }

  const handleChangeSwitchDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    const denseStatus = event.target.checked
    setSwitchDense(denseStatus)
    selectOrDeselectDataRows(denseStatus)
  }

  const rePrintSubmit = ()=>{

    const barcodeList = selectedBarcodeList.reduce((acc: BarcodeRollData[], curr) => {
      const { rollNumber, rollLength, supplierRollNumber, fabricWidth, cuttableWidth, isSelected } = curr
      
      if (isSelected === true){
        acc.push({roll: rollNumber, length: rollLength, supplierRollNo: supplierRollNumber, fabricWidth, cuttableWidth, isRollNoRepeated: false})
      }
      return acc
    }, [])
    barcodeRePrintSubmit(barcodeList)
  }

  return (
    <React.Fragment>
     
      <Box sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'row',
        gap: 2
      }}>
        <Card sx={{
          flex: 1
        }}>
          <CardContent>
            <Typography variant='body1'>OC NUMBER</Typography>
          </CardContent>
          <CardContent>
            <Typography variant='h6' color={'alpha.light'}>{barcodeDetails.ocNumber}</Typography>
          </CardContent>
          
        </Card>

        <Card sx={{
          flex: 1
        }}>
          <CardContent>
            <Typography variant='body1'>BUYER</Typography>
          </CardContent>
          <CardContent>
            <Typography variant='h6' color={'alpha.light'}>{barcodeDetails.customer}</Typography>
          </CardContent>
        </Card>

        <Card sx={{
          flex: 1
        }}>
          <CardContent>
            <Typography variant='body1'>STYLE</Typography>
          </CardContent>
          <CardContent>
            <Typography variant='h6' color={'alpha.light'}>{barcodeDetails.styleNumber}</Typography>
          </CardContent>
        </Card>

        <Card sx={{
          flex: 1
        }}>
          <CardContent>
            <Typography variant='body1'>STYLE DESCRIPTION</Typography>
          </CardContent>
          <CardContent>
            <Typography variant='h6' color={'alpha.light'}>{barcodeDetails.styleDescription}</Typography>
          </CardContent>
        </Card>

      </Box>

      <Box sx={{paddingLeft: 2, paddingRight: 2}}>
        <Stack direction={'row'} justifyContent='space-between'>
          <FormControlLabel
            control={<Switch checked={switchDense} onChange={handleChangeSwitchDense} />}
            label="SELECT ALL / DESELECT ALL"
          />

          <FlatButton label='RE-PRINT' onClick={rePrintSubmit} disableControlsStatus={false}/>
        </Stack>
      </Box>
                      
      <Box sx={{
        paddingTop: 2
      }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>BARCODE</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>INVOICE NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ROLL NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ROLL LENGTH</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ROLL WIDTH</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ROLL UTILIZED</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>MIR NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ROLL DATE AND TIME</Typography></TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                selectedBarcodeList.map((barcodeObject)=>{
                  const {rollNumber, supplierRollNumber, invoiceNumber, rollLength, fabricWidth, rollUtilized, mirNumber, rollDate, isSelected} = barcodeObject
                  return (
                    <TableRow key={rollNumber} sx={{ backgroundColor: isSelected === true ? 'alpha.light' : null}}>
                      <TableCell sx={{color: isSelected === true ? 'white' : null}}>
                        <FormGroup sx={{ paddingLeft: 2}}>
                          <FormControlLabel control={<Checkbox sx={{
                            [`&, &.${checkboxClasses.checked}`]: {
                              color: isSelected === true ? 'white' : null,
                            },
                          }} checked={isSelected} onChange={barcodeCheckBoxChange} name={`${rollNumber}`} />} label={rollNumber} />
                        </FormGroup>
                      </TableCell>
                      <TableCell sx={{color: isSelected === true ? 'white' : null}}>{invoiceNumber}</TableCell>
                      <TableCell sx={{color: isSelected === true ? 'white' : null}}>{supplierRollNumber}</TableCell>
                      <TableCell sx={{color: isSelected === true ? 'white' : null}}>{rollLength}</TableCell>
                      <TableCell sx={{color: isSelected === true ? 'white' : null}}>{fabricWidth}</TableCell>
                      <TableCell sx={{color: isSelected === true ? 'white' : null}}>{rollUtilized}</TableCell>
                      <TableCell sx={{color: isSelected === true ? 'white' : null}}>{mirNumber}</TableCell>
                      <TableCell sx={{color: isSelected === true ? 'white' : null}}>{convertDateTimeToLocalString(rollDate)}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      

    </React.Fragment>
  )
}

export default BarcodeRetreive