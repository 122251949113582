import React, { useState, useEffect } from 'react'
import { Box, Typography, Stack, TextField, LinearProgress, Grid} from '@mui/material'
import { getProductLabelDetails } from '../api/apiFunctions'
import useAuthenticationToken from '../../Services/Authentication/useAuthenticationToken'
import { getProductLabelDetailsType, getQrCodeDetailsType } from '../types'
import { AxiosError } from 'axios'
import {FlatButton, SnackBarMessage} from '../../UI/Components'
import {roundNumberToSignificantDigits} from '../../utils'
import {default as ProductBarcodeRetreive } from './ProductBarcodeRetreive'
import StringValues from '../../Providers/StringValues'

const ReprintQrLabel = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [scannedBarcode, setScannedBarcode] = useState<string>('')
  const [productLabelDetails, setProductLabelDetails] = useState<getProductLabelDetailsType[]>([])
  const [selectedProductLabelDetails, setSelectedProductLabelDetails] = useState<getProductLabelDetailsType[]>([])
  const [startNumbering, setStartNumbering] = useState<number>(0)
  const [endNumbering, setEndNumbering] = useState<number>(0)
  const [dataSubmitStatus, setDataSubmitStatus] = useState(false)
  
  useAuthenticationToken()

  useEffect(()=>{
    if (scannedBarcode.length === 10){
      fetchProductLabelDetails()
    }
  }, [scannedBarcode])

  /**Handle API call to fetch product label details associate with bundle barcode
   */
  const fetchProductLabelDetails = async () => {
    if (isLoading) {
      return
    }

    if (scannedBarcode === undefined || scannedBarcode === null || scannedBarcode === ''){
      return
    }
  
    setProductLabelDetails([])
    setSelectedProductLabelDetails([])
    setIsLoading(true)
    
    try {
      const productLabelDetailsResult = await getProductLabelDetails(scannedBarcode)
      setProductLabelDetails(productLabelDetailsResult)

      if (productLabelDetailsResult.length > 0){
        const {qrDetails} = productLabelDetailsResult[0]
        setStartNumbering(qrDetails[0].serialNumber)
        setEndNumbering(qrDetails[qrDetails.length - 1].serialNumber)
      }



      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {       
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }

  /**This function is responsible to update barcode
   * @param {string}colourDesc The colour description
   * @param {number}indexToChange The index number to update value
   */
  const handleScannedBarcodeChange = (barcode: string) => {
    setScannedBarcode(barcode)
  }

  /**This function is responsible convert to number and update start sticker number to generate
   * @param {string}startStickerNumber The start stickers to generate
   */
  const handleStartStickerNumberChange = (startStickerNumber: string) => {
    let startNumber = 0

    if (parseFloat(startStickerNumber) > 0){
      startNumber = roundNumberToSignificantDigits(parseFloat(startStickerNumber), 0)
    }

    setStartNumbering(startNumber)
  }

  /**This function is responsible convert to number and update end sticker number to generate
   * @param {string}endStickerNumber The end stickers to generate
   */
  const handleEndStickerNumberChange = (endStickerNumber: string) => {
    let endNumber = 0

    if (parseFloat(endStickerNumber) > 0){
      endNumber = roundNumberToSignificantDigits(parseFloat(endStickerNumber), 0)
    }

    setEndNumbering(endNumber)
  }

  /**This function is responsible to search productlabel barcodes for API result list
   */
  const filterProductLabelBarcodes = async () => {

    if (startNumbering < 1) {
      handleSnackBarMessage(StringValues.productStartLabelStickerValidationMessage)
      return
    }

    if (endNumbering < 1) {
      handleSnackBarMessage(StringValues.productEndLabelStickerValidationMessage)
      return
    }

    if (endNumbering < startNumbering) {
      handleSnackBarMessage(StringValues.productLabelStickerValidationMessage)
      return
    }


    if (productLabelDetails.length > 0){
      const list: getQrCodeDetailsType[] = []

      productLabelDetails[0].qrDetails.map((qrObject)=>{
        if (qrObject.serialNumber >= startNumbering && qrObject.serialNumber <= endNumbering){
          list.push(qrObject)
        }
        return null
      })
      const actualDetails: getProductLabelDetailsType = {...productLabelDetails[0], qrDetails: list}

      setSelectedProductLabelDetails([actualDetails])

    }

  }

  /**This function is responsible to handle snack bar message
   * @param {string}message The message
   */
  const handleSnackBarMessage = (message: string) => {
    setSnackBarOpen(true)
    setErrorMessage(message)
  }

  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setDataSubmitStatus(false)
    setSnackBarOpen(value)
  }

  const handleLoading = (loadingStatus: boolean)=>{

    if (loadingStatus){
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }
  
  return (
    <React.Fragment>
      <SnackBarMessage message = {errorMessage} successStatus={dataSubmitStatus} open = {snackBarOpen} onClose={handleSnackBarClose}/>

      <Box sx={{ padding: 2}}>

        <Box>
          <Stack spacing={3} direction="row">

            <TextField
              type='text'
              placeholder='Enter Bundle Barcode Number'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  height: "20px",
                  width: "300px"
                },
              }}
              variant="outlined"
              size="small"
              value={scannedBarcode}
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleScannedBarcodeChange(event.target.value)}
            />

            <Box>
              <Stack spacing={1} direction="row">
                <Typography variant='body1' align='center'>Start Sticker Number : </Typography>
                <TextField
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      height: "20px"
                    },
                  }}
                  variant="outlined"
                  size="small"
                  value={startNumbering}
                  onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleStartStickerNumberChange(event.target.value)}
                />
              </Stack>

            </Box>

            <Box>
              <Stack spacing={1} direction="row">
                <Typography variant='body1' align='center'>End Sticker Number : </Typography>
                <TextField
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    style: {
                      height: "20px"
                    },
                  }}
                  variant="outlined"
                  size="small"
                  value={endNumbering}
                  onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleEndStickerNumberChange(event.target.value)}
                />
              </Stack>
            </Box>

            <FlatButton label='SEARCH' onClick={filterProductLabelBarcodes} disableControlsStatus={false}/>
                      
        
          </Stack>
        </Box>
        <Grid container sx={{
          height: '100vh'
        }}>

          <Grid item xs={12}
            sx={{
              paddingTop: 2,
              paddingLeft: 1,
              paddingRight: 1,
              paddingBottom: 1
            }}>
            {
              (isLoading) ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100vh',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                  }}
                >
                  <LinearProgress sx={{ width: '50%' }} />
                </Box>
              ) :
                <Box sx={{paddingTop: 2}}>
                  {
                    selectedProductLabelDetails.length > 0
                      ? <ProductBarcodeRetreive barcodeDetails={selectedProductLabelDetails} handleSnackBarMessage={handleSnackBarMessage} 
                        bundleBarcode={scannedBarcode} handleLoading={handleLoading}/>
                      : null
                  }
          
                </Box>
            }
          </Grid>
        </Grid>
      </Box>
    </React.Fragment >
  )
}

export default ReprintQrLabel