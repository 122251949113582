import React, { useCallback, useState } from 'react'
import { Box, Card, CardContent, Grid, List, ListItem, Paper, LinearProgress, Typography } from '@mui/material'

import { DropdownMenu, DropdownMenuValues, SearchBar } from '../UI/Components'
import { getFabricDataBasedOnItemCode, getFabricItemCodeResultsBasedOnPONumber, getFabricItemCodeResultsBasedOnOCNumber, getFabricStockSummaryBasedOnPONumber, getFabricStockSummaryBasedOnOCNumber } from './api/apiFunctions'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { FabricLocationInfo, FabricResultsListItem, FabricStockSummary } from './types'
import { roundNumberToSignificantDigits, convertNumberToLocaleString } from '../utils'
import { FabricCard, ItemCodeStock, FabricSummary } from './components'
import { AxiosError } from 'axios'
import SnackBarMessage from '../UI/Components/SnackBarMessage'
import StringValues from '../Providers/StringValues'

interface FabricDashboardProps {
  componentHeader : (headerName : string) => void
}

const FabricDashboard = (props: FabricDashboardProps) => {
  useAuthenticationToken()
  props.componentHeader('Fabric')

  const [fabricStockSummaryStatus, setFabricStockSummaryStatus] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [isFetchingItemCodeList, setIsFetchingItemCodeList] = useState(false)
  const [isFetchingItemCodeDetails, setIsFetchingItemCodeDetails] = useState(false)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [dropdownSelection, setDropdownSelection] = useState<DropdownMenuValues>(DropdownMenuValues.poNumber)
  const [fabricSearchResults, setFabricSearchResults] = useState<FabricResultsListItem[]>([])
  const [fabricItemCodeResults, setFabricItemCodeResults] = useState<FabricLocationInfo>()
  const [fabricStockSummaryResults, setFabricStockSummaryResults] = useState<FabricStockSummary>()

  /**API call to fetch list of items based on search term (i.e, PO/OC number) 
   *Also API call to fetch fabric stock summary based on search term (i.e, PO/OC number) 
   */
  const fetchFabricItemCodeList = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsFetchingItemCodeList(true)
    if (dropdownSelection === DropdownMenuValues.poNumber) {
      try {
        const fabricStockSummaryResult = await getFabricStockSummaryBasedOnPONumber(searchTerm)
        const fabricItemCodeResult = await getFabricItemCodeResultsBasedOnPONumber(searchTerm)

        const itemListIncludingSelected = fabricItemCodeResult.map((item, index) => {
          return ({...item, isSelected: false})
        })

        setFabricStockSummaryResults(fabricStockSummaryResult)
        setFabricSearchResults(itemListIncludingSelected)
        setIsError(false)
        setErrorMessage('')
      } catch (err) {
        if (err instanceof AxiosError || err instanceof Error) {
          setSnackBarOpen(true)
          setIsError(true)
          setErrorMessage(err.message)
        } else {
          throw err
        }
      }

      setFabricStockSummaryStatus(true)
      setIsFetchingItemCodeList(false)
      setIsLoading(false)
    } else if (dropdownSelection === DropdownMenuValues.ocNumber) {
      try {
        const fabricStockSummaryResult = await getFabricStockSummaryBasedOnOCNumber(searchTerm)
        const fabricItemCodeResult = await getFabricItemCodeResultsBasedOnOCNumber(searchTerm)

        const itemListIncludingSelected = fabricItemCodeResult.map((item, index) => {
          return ({...item, isSelected: false})
        })
        
        setFabricStockSummaryResults(fabricStockSummaryResult)
        setFabricSearchResults(itemListIncludingSelected)
        setIsError(false)
        setErrorMessage('')
      } catch (err) {
        if (err instanceof AxiosError || err instanceof Error) {
          setSnackBarOpen(true)
          setIsError(true)
          setErrorMessage(err.message)
        } else {
          throw err
        }
      }
      setFabricStockSummaryStatus(true)
      setIsFetchingItemCodeList(false)
      setIsLoading(false)
    }
  }, [searchTerm])

  /**API call to fetch fabric details based on item code
   * @param {string} itemCode The item code
   */
  const fetchFabricDataBasedOnItemCode = async (itemCode: string) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsFetchingItemCodeDetails(true)
    try {
      const data = await getFabricDataBasedOnItemCode(itemCode)
      setFabricStockSummaryStatus(false)
      setIsLoading(false)
      setIsFetchingItemCodeDetails(false)
      setFabricItemCodeResults(data)
    } catch (err) {
      setSnackBarOpen(true)
      setIsLoading(false)
      setIsFetchingItemCodeDetails(false)
      throw err
    }
  }

  /**Handle search bar change */
  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }

  /**Handle search bar finder button click */
  const handleSearchBarButtonClick = () => {
    fetchFabricItemCodeList()
  }

  /**This function is responsible to handle selected item card
   *@param {string} itemCode The item code
   *@param {number} indexToValidate The index value to validate and update card status
   */
  const handleFabricCardClick = (itemCode: string, indexToValidate: number) => {

    const updatedCardItemResults = fabricSearchResults.map((item, index) => {
      const {isSelected} = item
      if (indexToValidate === index){
        if (isSelected === false){
          item.isSelected = true
          setFabricStockSummaryStatus(false)
          fetchFabricDataBasedOnItemCode(itemCode)
        } else {
          item.isSelected = false
          setFabricStockSummaryStatus(true)
        }
        return item
      }
      item.isSelected = false
      return item
    })

    setFabricSearchResults(updatedCardItemResults)
  }

  /**This function is responsible to handle dropDown selection change(i.e, PO/OC number)
   * @param {string} value The value of search term(i.e, PO/OC number)
   */
  const handleDropdownMenuChange = (value: DropdownMenuValues) => {
    setDropdownSelection(value)

    setFabricStockSummaryResults(undefined)
    setFabricSearchResults([])
    setFabricItemCodeResults(undefined)  
    setSearchTerm('')
  }

  /**Function to calculate total received fabric quantity 
   *@return {number} The total fabric received quantity 
   */
  const calculateTotalReceivedFabric = () => {
    let totalReceivedFabric = 0

    if (fabricStockSummaryStatus === true) {
      if (fabricStockSummaryResults !== null && fabricStockSummaryResults !== undefined) {
        totalReceivedFabric = fabricStockSummaryResults?.totalReceivedQuantity as number
      }
    }

    if (fabricStockSummaryStatus === false) {
      if (fabricItemCodeResults !== null && fabricItemCodeResults !== undefined) {
        //  Add up quantity property in each object of mirInfo array
        totalReceivedFabric = fabricItemCodeResults?.mirInfo.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0) as number
      }
    }

    return totalReceivedFabric
  }

  /**Function to calculate pending fabric quantity 
   *@return {number} The total fabric pending quantity 
   */
  const calculatePendingFabric = () => {
    let totalPendingQuantity = 0

    if (fabricStockSummaryStatus === true) {
      if (fabricStockSummaryResults !== null && fabricStockSummaryResults !== undefined) {
        totalPendingQuantity = fabricStockSummaryResults?.totalPendingQuantity as number
      }
    }

    if (fabricStockSummaryStatus === false) {
      if (fabricItemCodeResults !== null && fabricItemCodeResults !== undefined) {
        //  explictly setting the type to number because calculatTotalReceivedFabric already has check for null
        const totalReceivedFabric = calculateTotalReceivedFabric() as number
        totalPendingQuantity = fabricItemCodeResults?.ordered.quantityOrdered - totalReceivedFabric
      }
    }
    return totalPendingQuantity
  }

  /**Function to calculate total fabric stock
   *@return {number} The total fabric stock
   */
  const calculateTotalStock = () => {
    let totalStock = 0
    if (fabricStockSummaryStatus === true) {
      if (fabricStockSummaryResults !== null && fabricStockSummaryResults !== undefined) {
        totalStock = fabricStockSummaryResults.locationStock.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0)
      }
    }

    if (fabricStockSummaryStatus === false) {
      if (fabricItemCodeResults !== null && fabricItemCodeResults !== undefined) {
        //  Add up quantity property in each object of locationStock array
        totalStock = fabricItemCodeResults.locationStock.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0)
      }
    }
    return totalStock
  }

  /**Function to handle order quantity
   *@return {number} The total order quantity
   */
  const orderQuantity = () => {
    if (fabricStockSummaryStatus === true) {
      if (fabricStockSummaryResults !== null && fabricStockSummaryResults !== undefined) {
        return fabricStockSummaryResults?.totalOrderQuantity as number
      }
    }

    if (fabricStockSummaryStatus === false) {
      if (fabricItemCodeResults !== null && fabricItemCodeResults !== undefined) {
        return fabricItemCodeResults?.ordered?.quantityOrdered as number
      }
    }
    return 0
  }

  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  return (
    <React.Fragment>
      <SnackBarMessage message = {errorMessage} successStatus={false} open = {snackBarOpen} onClose={handleSnackBarClose}/>

      <DropdownMenu onChange={handleDropdownMenuChange} />
      
      <SearchBar value={searchTerm} onChange={handleSearchBarChange} 
        onButtonClick={handleSearchBarButtonClick} error={isError} errorMessage={errorMessage} 
        inputLabel = {StringValues.poOcLabel} width = {'25%'} marginLeft = {10}/>

      
      <Grid container sx={{
        height: '100vh'
      }}>
        <Grid item xs={3} 
          sx={{
            paddingTop: 2,
            paddingBottom: 2
          }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'surface.dark'
          }}>
            {
              isFetchingItemCodeList ? (
                <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                  <Box sx={{ width: '50%' }}>
                    <LinearProgress />
                  </Box>
                </Grid>
              ) : (
                <Paper elevation={8} sx={{
                  height: '100%'
                }}>
                  <List sx={{
                    bgcolor: 'background.paper'
                  }}>
                    {
                      fabricSearchResults.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <FabricCard key={index} poNumber={item.poNumber} ocNumber={item.ocNumber}
                              itemCode={item.itemCode} itemDesc={item.itemDesc} onClick={handleFabricCardClick} isSelected={item.isSelected} index={index}/>
                          </ListItem>
                        )
                      })
                    }
                  </List>
                </Paper>
              )
            }
          </Box>
        </Grid>
        <Grid item xs={9}
          sx={{
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 2,
            paddingBottom: 2
          }}>
          {
            (isFetchingItemCodeList || isFetchingItemCodeDetails) ? (
              <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                <Box sx={{ width: '50%' }}>
                  <LinearProgress />
                </Box>
              </Grid>
            ) : (
              <Box sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'primary.pane',
                backgroundColor: 'surface.dark'
              }}>
                {
                  fabricStockSummaryResults !== null && fabricStockSummaryResults !== undefined ? (
                    <React.Fragment>
                      <Box sx={{
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2
                      }}>
                        <Card sx={{
                          flex: 1
                        }}>
                          <CardContent>
                            <Typography variant='h6' color={'alpha.light'}>
                              {`${convertNumberToLocaleString(roundNumberToSignificantDigits(orderQuantity(), 2))} Meters`}
                            </Typography>
                          </CardContent>
                          <CardContent>
                            <Typography variant='body1'>
                              FABRIC ORDER QUANTITY
                            </Typography>
                          </CardContent>
                        </Card>
                        <Card sx={{
                          flex: 1
                        }}>
                          <CardContent>
                            <Typography variant='h6' color={'alpha.light'}>
                              {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalReceivedFabric(), 2))} Meters`}
                            </Typography>
                          </CardContent>
                          <CardContent>
                            <Typography variant='body1'>
                              FABRIC RECEIVED
                            </Typography>
                          </CardContent>
                        </Card>
                        
                        {
                          calculatePendingFabric() < 0
                            ? (<Card sx={{
                              flex: 1
                            }}>
                              <CardContent>
                                <Typography variant='h6' color={'font.red'}>
                                  {`+ ${convertNumberToLocaleString(roundNumberToSignificantDigits(Math.abs(calculatePendingFabric()), 2))} Meters`}
                                </Typography>
                              </CardContent>
                              <CardContent>
                                <Typography variant='body1'>
                                FABRIC RECEIVED EXCESS
                                </Typography>
                              </CardContent>
                            </Card>)
                            : (<Card sx={{
                              flex: 1
                            }}>
                              <CardContent>
                                <Typography variant='h6' color={'alpha.light'}>
                                  {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculatePendingFabric(), 2))} Meters`}
                                </Typography>
                              </CardContent>
                              <CardContent>
                                <Typography variant='body1'>
                                FABRIC PENDING
                                </Typography>
                              </CardContent>
                            </Card>)
                        }
                          
                        
                        <Card sx={{
                          flex: 1
                        }}>
                          <CardContent>
                            <Typography variant='h6' color={'alpha.light'}>
                              {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalStock(), 2))} Meters`}
                            </Typography>
                          </CardContent>
                          <CardContent>
                            <Typography variant='body1'>
                              STOCK
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                      <Box sx={{
                        paddingLeft: 2,
                        paddingRight: 2
                      }}>
                        {
                          fabricStockSummaryStatus === true
                            ? <FabricSummary fabricStockSummaryResults={fabricStockSummaryResults}
                              dropdownSelection={dropdownSelection} />
                            : <ItemCodeStock fabricItemCodeResults={fabricItemCodeResults} />
                        }
                      </Box>
                    </React.Fragment>
                  ) : (null)
                }
              </Box>
            )
          }
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default FabricDashboard