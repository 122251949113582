import { apiClient } from "../../Services/Networking/apiClient"
import { ManageOrdersDetails, TechPackProductDetails, OrderDetails, sizeQuantities, PartsDetails, ManagementProgress } from "../types"

export const getManageOrdersBasedOnOcNumber = async (ocNumber: string): Promise<ManageOrdersDetails> => {
  const response = await apiClient({
    method: 'get',
    url: `fabcut/cutting/orders/${ocNumber}`
  })
  return response as unknown as ManageOrdersDetails
}

export const getTechPackProductDetailsBasedOnOcNumber = async (ocNumber: string): Promise<TechPackProductDetails[]> => {
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/ocNumber/${ocNumber}/products`
  })
  return response as unknown as TechPackProductDetails[]
}

export const getOrderDetails = async (location: string, ocNumber: string, itemCode: string, fitType: string): Promise<OrderDetails> => {
  const response = await apiClient({
    method: 'get',
    url: `fabcut/cutting/orders/orderDetails`,
    params: { location, ocNumber, itemCode, fitType}
  })
  return response as unknown as OrderDetails
}

export const postOrderSizeAndQuantities = async (OC: string, location: string, itemCode: string, itemDescription: string, fitType: string, approvedConsumption: number, sizeQuantities: sizeQuantities[]): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabcut/cutting/orders/${OC}`,
    data: {
      location,
      itemCode,
      itemDescription,
      fitType,
      approvedConsumption,
      sizeQuantities
    }
  })
  return response as unknown as Boolean
}

export const getPartsDetailsBasedOnOcAndLocation = async (location: string, ocNumber: string): Promise<PartsDetails> => {
  const response = await apiClient({
    method: 'get',
    url: `fabcut/cutting/parts/${ocNumber}`,
    params: { location }
  })
  return response as unknown as PartsDetails
}

export const postPartsDetails = async (ocNumber: string, location: string, style: string, parts: string[]): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabcut/cutting/parts/${ocNumber}`,
    data: {
      location,
      style,
      parts
    }
  })
  return response as unknown as Boolean
}

export const getTechPackBasedOnOcLocationAndProduct = async (location: string, ocNumber: string, productCode: string): Promise<string> => {
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/defect/product/${productCode}/techPack`,
    params: { location, ocNumber }
  })
  return response as unknown as string
}

export const postTechPack = async (location: string, ocNumber: string, productCode: string, productDescription: string, productTechPack: string): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabstitch/defect/product/${productCode}/techPack`,
    data: {
      ocNumber,
      location,
      productDescription,
      productTechPack
    }
  })
  return response as unknown as Boolean
}

export const getManagementProgressBasedOnOcAndLocation = async (location: string, ocNumber: string): Promise<ManagementProgress> => {
  const response = await apiClient({
    method: 'get',
    url: `fabcut/cutting/orders/location/${location}/oc/${ocNumber}/orderProgressDetails`
  })
  return response as unknown as ManagementProgress
}