import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography } from '@mui/material'
import { FabricStockSummary } from '../types'
import { DropdownMenuValues } from '../../UI/Components'
import {roundNumberToSignificantDigits, convertNumberToLocaleString} from '../../utils'
interface FabricSummaryProps {
  fabricStockSummaryResults: FabricStockSummary | undefined
  dropdownSelection: DropdownMenuValues
}

function FabricSummary(props: FabricSummaryProps) {
  const { fabricStockSummaryResults, dropdownSelection } = props

  return (
    <div>
      <Box sx={{
        bgcolor: 'primary.card'
      }}>
        <Typography variant='h6' paddingLeft={2} paddingTop={1}>
          {dropdownSelection === DropdownMenuValues.poNumber ? 'PO Stock Summary' : 'OC Stock Summary'}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>FACTORIES</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>QUANTITY RECEIVED</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              fabricStockSummaryResults?.locationStock.map((locationStockItem, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{locationStockItem.location}</TableCell>
                    <TableCell>{convertNumberToLocaleString(roundNumberToSignificantDigits(locationStockItem.quantity, 2))} meters</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default FabricSummary