import React, { useState, useCallback, useEffect } from 'react'
import { Grid, Box, Stack, TableContainer, Paper, Table, TableHead, 
  TableRow, TableCell, TableBody, Typography, TextField, 
  LinearProgress} from '@mui/material'
import { SearchBar } from '../UI/Components'
import { getJobWorkProductDetails, getLocationList, getFitTypeList, getJobWorkOrder, postJobWorkOrder, getProductColourList } from './api/apiFunctions'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { JobWorkProductType, JobWorkFitType, colourSizeDetailsType, postJobWorkOrderType, productColourType, locationDetailsType, colourSizeRequestDetails } from './types'
import { JobworkDropdownMenu, ColourSizeDetails } from './components'
import { AxiosError } from 'axios'
import StringValues from '../Providers/StringValues'
import {FlatButton, SnackBarMessage, SubmitDialog} from '../UI/Components'
import {roundNumberToSignificantDigits, sortAlphaNumericArrayList} from '../utils'
import globalEnums from '../UI/enum'

interface JobWorkDashboardProps {
  componentHeader : (headerName : string) => void
}

enum DropdownMenuValues {
  location = 'Select Location',
  productCode = 'Select Product Code',
  fitType = 'Select Fit Type',
  colour = 'Select Colour'
}

const JobWorkDashboard = (props: JobWorkDashboardProps) => {

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState<string>('')

  const [productSearchResults, setProductSearchResults] = useState<JobWorkProductType[]>([])
  const [locationListResults, setLocationListResults] = useState<locationDetailsType[]>([])
  const [fitTypeDetailResults, setFitTypeDetailResults] = useState<JobWorkFitType>()
  const [colourSizeDetail, setColourSizeDetail] = useState<colourSizeDetailsType[]>([])
  const [productColourListhResults, setProductColourListhResults] = useState<productColourType[]>([])

  const [selectedLocationDropDown, setSelectedLocationDropDown] = useState<string>(DropdownMenuValues.location)
  const [selectedProductCodeDropDown, setSelectedProductCodeDropDown] = useState<string>(DropdownMenuValues.productCode)
  const [selectedFitTypeDropDown, setSelectedFitTypeDropDown] = useState<string>(DropdownMenuValues.fitType)

  const [sewingSam, setSewingSam] = useState<number>(0)
  const [globalSam, setGlobalSam] = useState<number>(0)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState<string>('')
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)
  const [colourGroupIndex, setColourGroupIndex] = useState<number>(0)
  const [dataSubmitStatus, setDataSubmitStatus] = useState(false)

  const [totalQuantityValue, setTotalQuantityValue] = useState<number>(0)

  
  useAuthenticationToken()
  props.componentHeader('JOB WORK')

  useEffect(() => {
    setProductSearchResults([])
    setLocationListResults([])
    setFitTypeDetailResults(undefined)
    setColourSizeDetail([])
    setSelectedLocationDropDown(DropdownMenuValues.location)
    setSelectedProductCodeDropDown(DropdownMenuValues.productCode)
    setSelectedFitTypeDropDown(DropdownMenuValues.fitType)
    setSewingSam(0)
    setGlobalSam(0)
  }, [searchTerm])

  useEffect(() => {
    setSewingSam(0)
    setGlobalSam(0)
    setColourSizeDetail([])
  }, [selectedLocationDropDown, selectedProductCodeDropDown, selectedFitTypeDropDown])

  
  /**
   * API call to fetch list of products associated with oc number
   */
  const fetchSearchResults = useCallback(async () => {
    if (isLoading || searchTerm === '' || searchTerm === undefined || searchTerm === null) {
      return
    }
    setIsLoading(true)
    setProductSearchResults([])
    setLocationListResults([])
    setFitTypeDetailResults(undefined)
    setColourSizeDetail([])
    setSelectedLocationDropDown(DropdownMenuValues.location)
    setSelectedProductCodeDropDown(DropdownMenuValues.productCode)
    setSelectedFitTypeDropDown(DropdownMenuValues.fitType)
    setSewingSam(0)
    setGlobalSam(0)

    try {
      const productListResult = await getJobWorkProductDetails(searchTerm)
      setProductSearchResults(productListResult)

      const locationList = await getLocationList()
      setLocationListResults(locationList)

      const fitTypeDetails = await getFitTypeList(searchTerm)
      setFitTypeDetailResults(fitTypeDetails)

      setIsError(false)
      setErrorMessage('')

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }, [searchTerm])

  /**
   * API call to fetch job work order details and colours list
   */
  const fetchJobworkOrderDetailsAndColourList = useCallback(async () => {
    if (isLoading || searchTerm === '' || searchTerm === undefined || searchTerm === null) {
      return
    }
    setIsLoading(true)
    
    try {
      const locationCode = getLocationCodeBasedOnLocationName(selectedLocationDropDown)
      const jobworkOrderResult = await getJobWorkOrder(searchTerm, locationCode, selectedProductCodeDropDown, selectedFitTypeDropDown)
      const productColourList = await getProductColourList(searchTerm, selectedProductCodeDropDown)
      
      setProductColourListhResults([{colourCode: DropdownMenuValues.colour, colourDescription: ''}, ...productColourList])
      setIsError(false)
      setErrorMessage('')

      if (jobworkOrderResult.colourSizeDetails === null || jobworkOrderResult.colourSizeDetails === undefined){
        const data: colourSizeDetailsType[] = [{
          colourCode: DropdownMenuValues.colour,
          colourDescription: '',
          sizeDetails: [{
            size: '',
            quantity: 0
          }],
          sizeQuantityTotal: 0
        }]
        setColourSizeDetail(data)
        
      } else {
        setSewingSam(jobworkOrderResult.machineSam)
        setGlobalSam(jobworkOrderResult.globalSam)
        setColourSizeDetail(jobworkOrderResult.colourSizeDetails)
      } 

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }, [searchTerm, selectedLocationDropDown, selectedProductCodeDropDown, selectedFitTypeDropDown])
  
 
  /** API call to post job work orders */
  const postJobworkOrderDetails = useCallback(async (data : postJobWorkOrderType) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsError(false)
    setErrorMessage('')

    try {
      await postJobWorkOrder(searchTerm, data)

      setDataSubmitStatus(true)
      setIsLoading(false)
      handleSnackBarMessage(StringValues.submitSuccessMessage)

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
 
  }, [searchTerm, selectedLocationDropDown, selectedProductCodeDropDown, selectedFitTypeDropDown, colourSizeDetail, sewingSam, globalSam])


  /** Function responsible to return location based on location name 
   * @param {string} locationName The location name
   * @return {string} The location code
   */
  const getLocationCodeBasedOnLocationName = (locationName: string)=>{
    const locationObject = locationListResults.find(locationObject => locationObject.locationName === locationName)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.location
  }

  /**Handle search bar changes */
  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }

  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setDataSubmitStatus(false)
    setSnackBarOpen(value)
  }

  /**Handle search bar finder button click */
  const handleSearchBarButtonClick = () => {
    fetchSearchResults()
  }

  /**This function is responsible to handle location dropDown value
   * @param {string} value The selected location
   */
  const handleLocationDropdownMenuChange = (value: string) => {
    setSelectedLocationDropDown(value)
  }

  /**
   * This function is responsible to handle dropDown list for location
   */
  const locationDropdownList = () => {
    let locations: string[] = []
   
    if (locationListResults.length > 0) {
      locations = locationListResults.map((locationObject)=>{
        const {locationName, locationType} = locationObject

        if (locationType === globalEnums.production){
          return locationName
        }
        return ''
      }).filter((obj) => { return obj !== '' })
    }

    locations = sortAlphaNumericArrayList(locations)
    locations.unshift(DropdownMenuValues.location)
    return locations
  }

  /**This function is responsible to handle productCode dropDown value
   * @param {string} value The selected product code
   */
  const handleProductCodeDropdownMenuChange = (value: string) => {
    setSelectedProductCodeDropDown(value)
  }

  /**
   * This function is responsible to handle dropDown list for productCode
   */
  const productCodeDropdownList = () => {
    let productCodeList: string[] = []
   
    if (productSearchResults.length > 0) {
      productCodeList =  productSearchResults.map(item => item.productCode)
        .filter((value, index, self) => self.indexOf(value) === index)
    }
    productCodeList.unshift(DropdownMenuValues.productCode)
   
    return productCodeList
  }

  /**This function is responsible to handle fitType dropDown value
   * @param {string} value The selected Fit type
   */
  const handleFitTypeDropdownMenuChange = (value: string) => {
    setSelectedFitTypeDropDown(value)
  }

  /**
   * This function is responsible to handle dropDown list for fitType
   */
  const fitTypeDropdownList = () => {
    let fitList: string[] = []
   
    if (fitTypeDetailResults !== null && fitTypeDetailResults !== undefined) {
      const {fitTypeList} = fitTypeDetailResults
      fitList =  fitTypeList.map(item => item.fitType)
        .filter((value, index, self) => self.indexOf(value) === index)
    }
    fitList.unshift(DropdownMenuValues.fitType)
    return fitList
  }

  /**Handle continue button click */
  const handleContinueButtonClick = () => {
    if (selectedLocationDropDown === DropdownMenuValues.location) {
      handleSnackBarMessage(StringValues.locationMessage)
      return
    }

    if (selectedProductCodeDropDown === DropdownMenuValues.productCode) {
      handleSnackBarMessage(StringValues.techPackProductValidationMessage)
      return
    }

    if (selectedFitTypeDropDown === DropdownMenuValues.fitType) {
      handleSnackBarMessage(StringValues.fitTypeValidationMessage)
      return
    }
    
    fetchJobworkOrderDetailsAndColourList()
  }

  /**This function is responsible convert to number and update sewing sam
   * @param {string}sewingSam The sewing sam to update
   */
  const handleSewingSamChange = (sewingSam: string) => {
    setSewingSam(roundNumberToSignificantDigits(parseFloat(sewingSam), 2))
  }

  /**This function is responsible convert to number and update global sam
   * @param {string}globalSam The global sam to update
   */
  const handleGlobalSamChange = (globalSam: string) => {
    setGlobalSam(roundNumberToSignificantDigits(parseFloat(globalSam), 2))
  }

  /**This function is responsible to handle snack bar message
   * @param {string}message The message
   */
  const handleSnackBarMessage = (message: string) => {
    setSnackBarOpen(true)
    setErrorMessage(message)
  }
  
  /**Function responsible to handle confirmation dialog on close
   * @param {boolean} value The dialog confirmation status
   */
  const handleJobworkOrderSubmitDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true){
      dataPresentationToPostJobWorkOrder()
    }
  }
  
  /**Function responsible to present data to post job work orders
   */
  const dataPresentationToPostJobWorkOrder = () => {
    const locationCode = getLocationCodeBasedOnLocationName(selectedLocationDropDown)
    const colourSizeDetailsRequest : colourSizeRequestDetails[]  = colourSizeDetail.map((colourObj)=>{
      const {colourCode, colourDescription, sizeDetails} = colourObj

      return {colourCode, colourDescription, sizeDetails}

    })
    const jobWorkOrderData : postJobWorkOrderType = {
      location: locationCode, 
      style: productSearchResults[0].styleDescription,
      customerName: fitTypeDetailResults?.customer as string,
      productCode: selectedProductCodeDropDown, 
      productDescription: productSearchResults[0].productDescription, 
      fitType: selectedFitTypeDropDown, 
      machineSam: sewingSam, 
      globalSam: globalSam,
      colourSizeDetails: colourSizeDetailsRequest
    }

    postJobworkOrderDetails(jobWorkOrderData)

  }
 

    
  /**Function to validate colour repeat
   * @param {string} compareToColour Colour to compare
   * @param {colourSizeDetailsType[]} colourListArray The entered colour details
   * @return {boolean}
   */
  const isOrderColourRepeated = (compareToColour: string, colourListArray: colourSizeDetailsType[]): boolean => {
    let colourRepeatCount = 0
    colourListArray.map(colourObject => {
      const { colourCode } = colourObject
      if (colourCode.toUpperCase() === compareToColour.toUpperCase()) {
        colourRepeatCount++
      }
      return null
    })
    if (colourRepeatCount > 1) {
      return true
    }
    return false
  }
    
  /**This function is responsible to validate and submit colour details along with size and quantity*/
  const validateAndSubmitColourAssociatedWithSizeAndQuantity = (data: colourSizeDetailsType[], isSubmitButtonClicked: boolean) => {
    if (searchTerm === '' || searchTerm === undefined || searchTerm === null) {
      handleSnackBarMessage(StringValues.ocNumberLabel)
      return
    }

    if (selectedLocationDropDown === DropdownMenuValues.location) {
      handleSnackBarMessage(StringValues.locationMessage)
      return
    }

    if (selectedProductCodeDropDown === DropdownMenuValues.productCode) {
      handleSnackBarMessage(StringValues.techPackProductValidationMessage)
      return
    }

    if (selectedFitTypeDropDown === DropdownMenuValues.fitType) {
      handleSnackBarMessage(StringValues.fitTypeValidationMessage)
      return
    }

    if (sewingSam <= 0 || sewingSam === undefined || sewingSam === null) {
      handleSnackBarMessage(StringValues.sewingSamValidationMessage)
      return
    }

    if (globalSam <= 0 || globalSam === undefined || globalSam === null) {
      handleSnackBarMessage(StringValues.globalSamValidationMessage)
      return
    }

    const colourValidationResult = validateColourInList(data)
    if (colourValidationResult === true){
      handleSnackBarMessage(StringValues.jobworkColourValidationMessage)
      return
    }

    /* Check for size and colour repeated and validate quantity */
    let isSizeAndQuantityValid = true
    let validatedColourCode = ''
    let isColourRepeated = false

    data.map((object)=>{
      const {colourCode, sizeDetails} = object

      const colourRepeatedResult = isOrderColourRepeated(colourCode, data)

      if (colourRepeatedResult === true){
        isColourRepeated = true
        validatedColourCode = colourCode

      }

      const status = isOrderSizeQuantityListValid(sizeDetails)
      if (status === false && isColourRepeated === false){
        isSizeAndQuantityValid = status
        validatedColourCode = colourCode
      }
      return null
    })

    if (isColourRepeated !== false){
      handleSnackBarMessage(`Please check repeated colour code for ${validatedColourCode}`)
      return
    }
    
    if (isSizeAndQuantityValid !== true){
      handleSnackBarMessage(`Please check the size quantites you have entered for colour code ${validatedColourCode}`)
      return
    }
    
    setColourSizeDetail(data)
    setIsSubmitButtonClicked(isSubmitButtonClicked)
    setDialogMessage(StringValues.jobworkOrdersubmitConfirmationMessage)
    setDialogOpen(true)
  }

  const validateColourInList = (data: colourSizeDetailsType[]) => {
    let status = false
    data.map((object)=>{
      const {colourCode, colourDescription} = object
      if (colourCode.trim() === '' || colourCode === null){
        status = true
      }

      if (colourDescription.trim() === '' || colourDescription === null){
        status = true
      }
      return null
    })

    return status
  }

  /**Function to validate entered size quantities
   * @param {{size: string, quantity: number}[]} sizeQuantityList The entered size quantity details
   * @return {boolean}
   */
  const isOrderSizeQuantityListValid = (sizeQuantityList: {size: string, quantity: number}[]): boolean => {
    return sizeQuantityList.map(sizeQuantityObject => {
      const { size, quantity } = sizeQuantityObject
      
      if (size === "" || size === null || quantity <= 0 || isNaN(quantity)) {
        return false
      }
      if (isOrderSizeRepeated(size, sizeQuantityList)) {
        return false
      }
      return true
    }).reduce((acc, curr) => {
      return acc && curr
    }, true)
  }

  /**Function to validate entered size
   * @param {string} compareToSize Size to compare
   * @param {{size: string, quantity: number}[]} sizeQuantityList The entered size quantity details
   * @return {boolean}
   */
  const isOrderSizeRepeated = (compareToSize: string, sizeListArray: {size: string, quantity: number}[]): boolean => {
    let sizeRepeatCount = 0
    sizeListArray.map(sizeQuantityObject => {
      const { size } = sizeQuantityObject
      if (size.toUpperCase() === compareToSize.toUpperCase()) {
        sizeRepeatCount++
      }
      return null
    })
    if (sizeRepeatCount > 1) {
      return true
    }
    return false
  }

  /** This function responsible to remove colour group for list 
   * @param {colourSizeDetailsType[]} data The colour Size details list
   * @param {number} indexToRemove The index number to remove colour group
   * @param {boolean} isSubmitButtonClicked The submit click status
   */
  const removeColourWithSizeDetails = (data: colourSizeDetailsType[], indexToRemove: number, isSubmitButtonClicked: boolean) => {
    setColourSizeDetail(data)
    setColourGroupIndex(indexToRemove)
    setIsSubmitButtonClicked(isSubmitButtonClicked)
    setDialogMessage(StringValues.jobworkColourDeleteDialogMessage)
    setDialogOpen(true)
  }

  /**Function responsible to handle confirmation dialog on close
   * @param {boolean} value The dialog confirmation status
   */
  const handleColourGroupDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true){

      const createColourListWithChanges = [...colourSizeDetail]
      createColourListWithChanges.splice(colourGroupIndex, 1)
    
      setColourSizeDetail(createColourListWithChanges)
    }
  }


  /**Function responsible to handle total quantity
   * @param {number} totalQuantity The total quantity
   */
  const handleTotalQuantity = (totalQuantity : number)=>{
    setTotalQuantityValue(totalQuantity)
  }


  return (
    <React.Fragment>

      <SnackBarMessage message = {errorMessage} successStatus={dataSubmitStatus} open = {snackBarOpen} onClose={handleSnackBarClose}/>
      
      <SearchBar value={searchTerm} onChange={handleSearchBarChange} 
        onButtonClick={handleSearchBarButtonClick} error={isError} errorMessage={errorMessage} 
        inputLabel = {StringValues.ocNumberLabel} width = {'20%'} marginLeft = {2}/>

      <SubmitDialog 
        onClose={isSubmitButtonClicked === true ? handleJobworkOrderSubmitDialogClose : handleColourGroupDialogClose} 
        open = {dialogOpen} 
        submitStatus = {false} 
        dialogMessage={dialogMessage} />
        
      {
        productSearchResults.length > 0
          ? (
            <Box sx={{paddingTop: 2, paddingBottom: 2}}>
              <Stack spacing={3} direction="row">
                <JobworkDropdownMenu onChange={handleLocationDropdownMenuChange} menuValues={locationDropdownList()} buttonText={selectedLocationDropDown}></JobworkDropdownMenu>
                <JobworkDropdownMenu onChange={handleProductCodeDropdownMenuChange} menuValues={productCodeDropdownList()} buttonText={selectedProductCodeDropDown}></JobworkDropdownMenu>
                <JobworkDropdownMenu onChange={handleFitTypeDropdownMenuChange} menuValues={fitTypeDropdownList()} buttonText={selectedFitTypeDropDown}></JobworkDropdownMenu>
                <FlatButton label='CONTINUE' onClick={handleContinueButtonClick} disableControlsStatus={false}/>
              </Stack>
            </Box>
          )
          : null
      }
      

      <Box sx={{ paddingLeft: 2}}>
        {
          productSearchResults.map((productObject)=>{
            const { productCode, productDescription, styleDescription} = productObject
            if (productCode === selectedProductCodeDropDown){
              return (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead> 
                      <TableRow
                        sx={{
                          backgroundColor: 'alpha.lightest'
                        }}> 
                        <TableCell><Typography variant='subtitle1'>PRODUCT ID</Typography></TableCell>
                        <TableCell><Typography variant='subtitle1'>PRODUCT TYPE</Typography></TableCell>
                        <TableCell><Typography variant='subtitle1'>STYLE NAME</Typography></TableCell>
                        <TableCell><Typography variant='subtitle1'>BUYER NAME</Typography></TableCell>
                        <TableCell><Typography variant='subtitle1'>TOTAL QUANTITY</Typography></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow key={1}>
                        <TableCell>{productCode}</TableCell>
                        <TableCell>{productDescription}</TableCell>
                        <TableCell>{styleDescription}</TableCell>
                        <TableCell>{fitTypeDetailResults !== null && fitTypeDetailResults !== undefined
                          ? fitTypeDetailResults.customer
                          : null}</TableCell>
                        <TableCell>{totalQuantityValue}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            }
            return null
          })
        }
      </Box>
      {
        colourSizeDetail.length > 0 
          ? (
            <Box sx={{backgroundColor: 'alpha.lightest', paddingTop: 2, paddingLeft: 2}}>
              <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>SAM DETAILS</Typography>

              <Box sx={{ paddingTop: 1 }}>
                <Stack spacing={3} direction="row"> 
                  <Typography variant='body1'>SEWING SAM :</Typography>
                  <TextField
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        height: "20px"
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={sewingSam}
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSewingSamChange(event.target.value)}
                  /> 
            
                  <Typography variant='body1'>GLOBAL SAM :</Typography>
                  <TextField
                    type='number'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        height: "20px"
                      },
                    }}
                    variant="outlined"
                    size="small"
                    value={globalSam}
                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleGlobalSamChange(event.target.value)}
                  /> 


                </Stack>
              </Box>
        
            </Box>
          )
          : null
      }
      
      
      <Grid container sx={{
        height: '100vh'
      }}>

        <Grid item xs={12}
          sx={{
            paddingTop: 2,
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1
          }}>
          {
            (isLoading) ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
              >
                <LinearProgress sx={{ width: '50%' }}/>
              </Box>
            ) : (colourSizeDetail.length > 0 
              ? <ColourSizeDetails colourAndSizeDetais={colourSizeDetail} handleSnackBarMessage={handleSnackBarMessage} 
                submitJobworkOrder = {validateAndSubmitColourAssociatedWithSizeAndQuantity} removeColourWithSizeDetails={removeColourWithSizeDetails}
                productColourList = {productColourListhResults} handleTotalQuantity={handleTotalQuantity}/>
              : null)
            
          }
          
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default JobWorkDashboard