import { apiClient } from "../../Services/Networking/apiClient"
import { JobWorkProductType, JobWorkFitType, getJobWorkOrderType, postJobWorkOrderType, productColourType, locationDetailsType } from "../types"


export const getJobWorkProductDetails = async (ocNumber: string): Promise<JobWorkProductType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/ocNumber/${ocNumber}/products`
  })
  return response as unknown as JobWorkProductType[]
}

export const getLocationList = async (): Promise<locationDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/service/locations`
  })
  return response as unknown as locationDetailsType[]
}

export const getFitTypeList = async (ocNumber: string): Promise<JobWorkFitType> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabcut/marker/ratios/${ocNumber}`
  })
  return response as unknown as JobWorkFitType
}

export const getProductColourList = async (ocNumber: string, productCode: string): Promise<productColourType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/ocNumber/${ocNumber}/product/colours`,
    params: { productCode: productCode }
  })
  return response as unknown as productColourType[]
}


export const getJobWorkOrder = async (ocNumber: string, location: string, productCode: string, fitType: string): Promise<getJobWorkOrderType> => {
  const response = await apiClient({
    method: 'get',
    url: `/jobwork/order/ocNumber/${ocNumber}`,
    params: { location: location, productCode: productCode, fitType: fitType }
  })
  return response as unknown as getJobWorkOrderType
}

export const postJobWorkOrder = async (ocNumber: string, data: postJobWorkOrderType): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `/jobwork/order/ocNumber/${ocNumber}`,
    data: data
  })
  return response as unknown as Boolean
}