import React, { useState, useEffect } from 'react'
import { DateRange } from 'react-date-range'
import { Box, TextField, Typography } from '@mui/material'
import { dateRangeType } from '../../types'
import format from 'date-fns/format'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './DatePicker.css'

interface DatePickerProps {
  onChange: (value: dateRangeType) => void
  defaultDateRange: (value: dateRangeType) => void,
  direction : string
}


const DatePicker = (props: DatePickerProps) => {
  const { onChange, defaultDateRange, direction } = props
 
  const [dateRange, setDateRange] = useState<dateRangeType[]>([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  }])
  const [startDate, setStartDate] = useState<string>('')
  const [endDate, setEndDate] = useState<string>('')
  const [dateSelectCount, setDateSelectCount] = useState<number>(0)
  const [openCalendar, setopenCalendar] = useState(false)

  useEffect(()=>{
    if (dateSelectCount === 2){
      setStartDate(`${format(dateRange[0].startDate as Date, "dd/MM/yyyy")}`)
      setEndDate(`${format(dateRange[0].endDate as Date, "dd/MM/yyyy")}`)
      setopenCalendar(false)
      setDateSelectCount(0)
      onChange(dateRange[0])
    }
  }, [dateSelectCount])

  useEffect(()=>{
    setStartDate(`${format(dateRange[0].startDate as Date, "dd/MM/yyyy")}`)
    setEndDate(`${format(dateRange[0].endDate as Date, "dd/MM/yyyy")}`)
    defaultDateRange(dateRange[0])
    document.addEventListener('keydown', hideOnEscape, true)
  }, [])

  const hideOnEscape = (e: any)=>{
    if (e.key === 'Escape'){
      setopenCalendar(false)
    }
  }

  
  const handleDateSelect = (date: dateRangeType)=>{
    if (openCalendar === true){
      setDateRange([date])
      setDateSelectCount(dateSelectCount + 1)
    }
  }

  return (
    <div className='calendarWrap'>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
        justifyContent: 'flex-end'
      }}>
        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          size="small"
          label = "Start Date"
          value={startDate}
          onClick= {()=> setopenCalendar(openCalendar => !openCalendar)}
          style={{ width: '120px' }} // Adjust width as needed

        />

        <Typography variant='subtitle1'> to </Typography>

        <TextField
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          size="small"
          label = "End Date"
          value={endDate}
          onClick= {()=> setopenCalendar(openCalendar => !openCalendar)}
          style={{ width: '120px' }} // Adjust width as needed

        />
      </Box>
      {openCalendar && 
      <DateRange 
        onChange = {item => handleDateSelect(item.selection)}
        editableDateInputs={true}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        months={2}
        direction= {direction === 'vertical' ? 'vertical' : 'horizontal'}
        className= "calendarElement"
      />
      }
      
    </div>
  )
}

export default DatePicker