import { apiClient } from "../../Services/Networking/apiClient"
import { FabricLocationInfo, FabricResultsListItem, FabricStockSummary } from "../types"

export const getFabricItemCodeResultsBasedOnPONumber = async (poNumber: string): Promise<FabricResultsListItem[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/fabric`,
    params: { poNum: poNumber }
  })
  return response as unknown as FabricResultsListItem[]
}

export const getFabricItemCodeResultsBasedOnOCNumber = async (ocNumber: string): Promise<FabricResultsListItem[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/fabric`,
    params: { ocNum: ocNumber }
  })
  return response as unknown as FabricResultsListItem[]
}




export const getFabricDataBasedOnItemCode = async (itemCode: string): Promise<FabricLocationInfo> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/fabric/itemCode`,
    params: { itemCode }
  })
  return response as unknown as FabricLocationInfo
}

export const getFabricStockSummaryBasedOnPONumber = async (poNumber: string): Promise<FabricStockSummary> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/fabric/summary`,
    params: { poNumber: poNumber }
  })
  return response as unknown as FabricStockSummary
}

export const getFabricStockSummaryBasedOnOCNumber = async (ocNumber: string): Promise<FabricStockSummary> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/fabric/summary`,
    params: { ocNumber: ocNumber }
  })
  return response as unknown as FabricStockSummary
}