import React, { useState, useCallback } from 'react'
import { Typography, Box, Stack, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, LinearProgress } from '@mui/material'
import { FlatButton, SearchBar, SnackBarMessage } from '../UI/Components'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { AxiosError } from 'axios'
import { OcDetailsData, SizeDetail } from './types' // Assuming SizeDetail is defined in types
import StringValues from '../Providers/StringValues'
import { roundNumberToSignificantDigits } from '../utils'
import { getOcDetailsFromSage } from './api/apiFuntions'
import * as XLSX from 'xlsx'


interface OcDetailsProps {
  componentHeader: (headerName: string) => void
}

const OcDetails = (props: OcDetailsProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [ocDetailsData, setOcDetailsData] = useState<OcDetailsData[]>([]) // State to hold fetched OC details

  useAuthenticationToken()
  props.componentHeader('OC Grid Quantity')

  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleSearchBarButtonClick = () => {
    fetchOcDetails()
  }

  const fetchOcDetails = useCallback(async () => {
    if (isLoading || !searchTerm) {
      return
    }
    setIsLoading(true)
    setOcDetailsData([]) // Clear previous data

    try {
      const ocDetails = await getOcDetailsFromSage(searchTerm)
      console.log('Fetched OC Details:', ocDetails) // Log the fetched data
      setOcDetailsData(ocDetails) // Update state with fetched data (assuming it's an array)
      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }, [isLoading, searchTerm])

  /** Function responsible to create table rows for size wise bom quantity */
  const getDetailsQuantityRow = (data: OcDetailsData) => {
    let rowTotal = 0
    return (
      <TableRow key={'Quantity'}>
        <TableCell sx={{ color: 'alpha.main', fontWeight: 'bold' }}>{'QUANTITY'}</TableCell>
        {data.Size.map((sizeObject: SizeDetail) => {
          let sizeQuantity = 0
          data.Size.map((sizeObj) => {
            if (sizeObj.size === sizeObject.size) {
              sizeQuantity += sizeObj.orderqty
              rowTotal += sizeObj.orderqty
            }
            return null
          })
          return <TableCell key={sizeObject.size}>{roundNumberToSignificantDigits(sizeQuantity, 0)}</TableCell>
        })}
        <TableCell sx={{ color: 'red', fontWeight: 'bold' }}>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }
  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []
    ocDetailsData.map((object, Index) => {
      const { Size } = object
      return Size.map((sizeObject) => {
        const { size, orderqty } = sizeObject
        const rowObject = {
          SlNo: Index + 1,
          poNumber: object.poNumber,
          country: object.Country,
          colour: object.color,
          ColorCode: object.ColorCode,
          size,
          orderqty,
        }
        jsonData.push(rowObject)
        return null
      })
    })

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'bomData')
    XLSX.writeFile(workBook, 'SageOcData.xlsx')
  }

  return (
    <React.Fragment>
      <SnackBarMessage
        message={errorMessage}
        successStatus={false}
        open={snackBarOpen}
        onClose={handleSnackBarClose}
      />
      <SearchBar
        value={searchTerm}
        onChange={handleSearchBarChange}
        onButtonClick={handleSearchBarButtonClick}
        error={isError}
        errorMessage={errorMessage}
        inputLabel={StringValues.ocNumberLabel}
        width={'20%'}
        marginLeft={2}
      />
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
          }}
        >
          <LinearProgress sx={{ width: '50%' }}/>
        </Box>
      ) : (
        ocDetailsData.length > 0 && (
          <Box sx={{ width: '100%', bgcolor: 'primary.pane', backgroundColor: 'surface.light' }}>
            <Box display="flex" justifyContent="space-between">
              <h5><span style={{ backgroundColor: 'yellow' }}>"Note: These details are fetched directly from SAGE"</span></h5>
              <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false} />
            </Box>
            {ocDetailsData.map((OcData) => (
              <Box key={OcData.poNumber} sx={{ paddingTop: 2, paddingBottom: 1, marginTop: 2, marginBottom: 2, backgroundColor: 'white' }}>
                <Stack direction="row" justifyContent="space-between" sx={{ paddingLeft: 2, paddingRight: 2, backgroundColor: 'alpha.lightest' }}>
                  <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                    {`PO: ${OcData.poNumber}`}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                    {`COUNTRY: ${OcData.Country}`}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                    {`COLOURCODE: ${OcData.ColorCode}`}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                    {`COLOUR: ${OcData.color}`}
                  </Typography>
                </Stack>
                <Box sx={{ paddingTop: 2 }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ backgroundColor: 'white' }}>
                          <TableCell>
                            <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                              SIZES
                            </Typography>
                          </TableCell>
                          {OcData.Size.map((size) => (
                            <TableCell key={size.size}>
                              <Typography variant="subtitle1">{size.size}</Typography>
                            </TableCell>
                          ))}
                          <TableCell>
                            <Typography variant="subtitle1">TOTAL</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {getDetailsQuantityRow(OcData)}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            ))}
          </Box>
        )
      )}
    </React.Fragment>
  )
}

export default OcDetails
