import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Grid, Box, FormControl, RadioGroup, FormControlLabel, Radio, 
  FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, 
  LinearProgress} from '@mui/material'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { FlatButton, SearchBar, SnackBarMessage } from '../UI/Components'
import StringValues from '../Providers/StringValues'
import {BomLabelBarcodeDetails, locationDetailsType, BomDetailsType, ScannedBomLabelBarcodeDetails, barcodeDetailsSubmitType, bomProductListDetailsType, bomPoDetailstListType} from './types'
import { getBomDataList, getLabelBarcodeDetails, getLocationList, getPoDetailsListAssociatedWithProductAndOc, getProductDetailsListAssociatedWithOc, getScannedBomDataList, postScannedbarcodes } from './api/apiFunctions'
import { ScanLabelBarcodeData, FinishingDashboardDropdownMenu, LocationDropdownMenu, BomStatusData } from './components'
import { AxiosError } from 'axios'
import {sortAlphaNumericArrayList} from '../utils'
import globalEnums from '../UI/enum'
import { styled } from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'

interface FinishingDashboardProps {
  componentHeader : (headerName : string) => void
}

enum DropdownMenuValues {
  location = 'LOCATION'
}

enum TabValuesEnum {
  scanLabel = 'scanLabel',
  bomReportStatus = 'bomReportStatus'
}

enum DropdownMenuValuesForScanbarcode {
  scanBarcode = 'Scan Barcode',
  enterBarcode = 'Enter Barcode'
}

enum DropdownMenuValuesForBomStatus {
  ocSearch = 'OC Search',
  poSearch = 'Buyer PO Search'
}

enum DropdownMenuValuesForOcDetails {
  enterOcNumber = 'Enter Oc Number',
  product = 'Select Product',
  PO = 'Select PO',
  Country = 'Select Country',
  Colour = 'Select Colour'
}


const FinishingDashboard = (props: FinishingDashboardProps) => {

  useAuthenticationToken()
  props.componentHeader('Finishing')
  const inputRef = useRef(null)

  const [radioButonSelect, setRadioButonSelect] = React.useState<string>(TabValuesEnum.scanLabel)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [ocSearchTerm, setOcSearchTerm] = useState<string>('')
  const [ocIsError, setOcIsError] = useState(false)
  
  const [bomProductsList, setBomProductsList] = useState<string[]>([DropdownMenuValuesForOcDetails.product])
  const [selectedProductDetailDropDown, setSelectedProductDetailDropDown] = useState<string>(DropdownMenuValuesForOcDetails.product)

  const [selectedPoDropDown, setSelectedPoDropDown] = useState<string>(DropdownMenuValuesForOcDetails.PO)
  const [poList, setPoList] = useState<string[]>([DropdownMenuValuesForOcDetails.PO])

  const [bomPoDetailsList, setBomPoDetailsList] = useState<bomPoDetailstListType[]>([])

  const [selectedCountryDropDown, setSelectedCountryDropDown] = useState<string>(DropdownMenuValuesForOcDetails.Country)
  const [countryList, setCountryList] = useState<string[]>([DropdownMenuValuesForOcDetails.Country])

  const [selectedColourDropDown, setSelectedColourDropDown] = useState<string>(DropdownMenuValuesForOcDetails.Colour)
  const [colourList, setColourList] = useState<string[]>([DropdownMenuValuesForOcDetails.Colour])


  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedScanBarcodeDropDown, setSelectedScanBarcodeDropDown] = useState<string>(DropdownMenuValuesForScanbarcode.scanBarcode)
  const [labelBarcodeData, setLabelBarcodeData] = useState<ScannedBomLabelBarcodeDetails[]>([])

  const [location, setLocation] = useState<string>(DropdownMenuValues.location)
  const [locationListResults, setLocationListResults] = useState<locationDetailsType[]>([])

  const [searchTermForBomStatus, setSearchTermForBomStatus] = useState<string>('')
  const [selectedBomStatusDropDown, setSelectedBomStatusDropDown] = useState<string>(DropdownMenuValuesForBomStatus.ocSearch)

  const [dataSubmitStatus, setDataSubmitStatus] = useState(false)
  const [bomDataListResults, setBomDataListResults] = useState<BomDetailsType[]>([])
  

  useEffect(() => {
    handleFetchLocation()
  }, [])

  useEffect(() => {
    if (location !== DropdownMenuValues.location){
      fetchBomDataListForPreviouslyScanned()
    }
    
  }, [location])

  

  const handleFetchLocation = async ()=>{
    await new Promise(r => setTimeout(r, 500))
    fetchLocationList()
  }

  

  /**API call to fetch label barcode details
   */
  const fetchLabelBarcodeDetails = useCallback(async () => {
    if (isLoading) {
      return
    }

    if (searchTerm.length < 10){
      return
    }
    
    if (location === '' || location === null || location === undefined || location === DropdownMenuValues.location){
      setDataSubmitStatus(false)
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.locationMessage)
      setSearchTerm('') 
      // @ts-ignore
      setTimeout(() => inputRef.current.focus(), 0)
      return
    }

    if (ocSearchTerm === '' || ocSearchTerm === null || ocSearchTerm === undefined){
      setDataSubmitStatus(false)
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomStatusOcLabelBarcodeLabel)
      setSearchTerm('') 
      // @ts-ignore
      setTimeout(() => inputRef.current.focus(), 0)
      return
    }

    if (selectedProductDetailDropDown === '' || selectedProductDetailDropDown === null || 
    selectedProductDetailDropDown === undefined || selectedProductDetailDropDown === DropdownMenuValuesForOcDetails.product){
      setDataSubmitStatus(false)
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomProductValidationMessage)
      setSearchTerm('') 
      // @ts-ignore
      setTimeout(() => inputRef.current.focus(), 0)
      return
    }

    if (selectedPoDropDown === '' || selectedPoDropDown === null || 
    selectedPoDropDown === undefined || selectedPoDropDown === DropdownMenuValuesForOcDetails.PO){
      setDataSubmitStatus(false)
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomPOValidationMessage)
      setSearchTerm('') 
      // @ts-ignore
      setTimeout(() => inputRef.current.focus(), 0)
      return
    }

    if (selectedCountryDropDown === '' || selectedCountryDropDown === null || 
    selectedCountryDropDown === undefined || selectedCountryDropDown === DropdownMenuValuesForOcDetails.Country){
      setDataSubmitStatus(false)
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomCountryValidationMessage)
      setSearchTerm('') 
      // @ts-ignore
      setTimeout(() => inputRef.current.focus(), 0)
      return
    }

    if (selectedColourDropDown === '' || selectedColourDropDown === null || 
    selectedColourDropDown === undefined || selectedColourDropDown === DropdownMenuValuesForOcDetails.Colour){
      setDataSubmitStatus(false)
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomColourValidationMessage)
      setSearchTerm('') 
      // @ts-ignore
      setTimeout(() => inputRef.current.focus(), 0)
      return
    }

    

    setIsLoading(true)
    setDataSubmitStatus(false)
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      
      const productCode = handleToReturnProduct(selectedProductDetailDropDown)
      const data = await getLabelBarcodeDetails(locationCode, searchTerm, ocSearchTerm, productCode, selectedPoDropDown, selectedCountryDropDown, selectedColourDropDown)
      setIsLoading(false)
      console.log(data)
     
      if (data.length > 0){
        updateScannedResultToDataList(data[0], searchTerm)
      }
      setSearchTerm('') 
      // @ts-ignore
      setTimeout(() => inputRef.current.focus(), 0)
      

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        if (selectedScanBarcodeDropDown === DropdownMenuValuesForScanbarcode.scanBarcode){
          setSearchTerm('') 
          // @ts-ignore
          setTimeout(() => inputRef.current.focus(), 0)
        }

        setIsLoading(false)
        setSnackBarOpen(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }, [searchTerm, location])
  

  /**API call to fetch product list associated with oc number
   */
  const fetchProductListAssociatedWithOc = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setBomProductsList([DropdownMenuValuesForOcDetails.product])

    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      const productList = await getProductDetailsListAssociatedWithOc(locationCode, ocSearchTerm)
      handleProductList(productList)
      setIsLoading(false)
      

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setIsLoading(false)
        setSnackBarOpen(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }, [ocSearchTerm, location])


  const handleProductList = (data: bomProductListDetailsType[])=>{
    const productList = data.map((productObject)=> `${productObject.productCode} | ${productObject.productDescripton}`)
    productList.unshift(DropdownMenuValuesForOcDetails.product)
    setBomProductsList(productList)
  }

  const updateScannedResultToDataList = (data: BomLabelBarcodeDetails, barcode: string)=>{
   
    const formattedData = {
      ocNumber: data.ocNumber,
      location: data.location,
      productCode: data.productCode,
      productDescription: data.productDescription,
      poNumber: data.poNumber,
      shipTo: data.shipTo,
      colourCode: data.colourCode,
      size: data.size,
      quantity: data.quantity,
      scannedLocation: null,
      scannedQuantity: data.scannedQuantity,
      orderQuantity: data.orderQuantity
    }

    const isBarcodeExistsInScannedList = labelBarcodeData.some(object => object.labelBarcode === barcode)
    if (isBarcodeExistsInScannedList){
      const data = labelBarcodeData.map((object)=>{
        const {labelBarcode} = object
        if (labelBarcode === barcode){
          return {...formattedData, labelBarcode: searchTerm}
        }
        return object

      })
      setLabelBarcodeData(data)
    }

    if (!isBarcodeExistsInScannedList){
      setLabelBarcodeData([...labelBarcodeData, {...formattedData, labelBarcode: searchTerm}])
    }
    
  }


  const fetchLocationList = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      setDataSubmitStatus(false)
      const locationsList = await getLocationList()
      setLocationListResults(locationsList)
      setIsLoading(false)
      
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }

  const submitScannedBarcodes = async (data: barcodeDetailsSubmitType[]) => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const locationCode = getLocationCodeBasedOnLocationName(location)

      await postScannedbarcodes(locationCode, data)
      setIsLoading(false)
      setDataSubmitStatus(true)
      setSnackBarOpen(true)
      
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }

  /**API call to fetch label barcode details
   */
  const fetchBomDataList = useCallback(async () => {
    if (isLoading) {
      return
    }
    
    setIsLoading(true)
    try {
      
      if (selectedBomStatusDropDown === DropdownMenuValuesForBomStatus.ocSearch){
        const data = await getBomDataList(searchTermForBomStatus, undefined)
        setBomDataListResults(data)
      } else {
        const data = await getBomDataList(undefined, searchTermForBomStatus)
        setBomDataListResults(data)
      }
      
      setIsLoading(false)
      setSearchTermForBomStatus('')

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }, [searchTermForBomStatus])

  /**API call to fetch scanned label barcode details for previously scanned
   */
  const fetchBomDataListForPreviouslyScanned = useCallback(async () => {
    if (isLoading) {
      return
    }
    setLabelBarcodeData([])
    setIsLoading(true)
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      const data = await getScannedBomDataList(locationCode)
      setLabelBarcodeData(data)
      setIsLoading(false)

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }, [location])

  /**API call to fetch PO details list associated with oc number and product
   */
  const fetchPoDetailsListAssociatedWithOcAndProduct = useCallback(async () => {
    if (isLoading) {
      return
    }
    
    setIsLoading(true)
    setPoList([DropdownMenuValuesForOcDetails.PO])

    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      const product =  handleToReturnProduct(selectedProductDetailDropDown)
      const poDetailsList = await getPoDetailsListAssociatedWithProductAndOc(locationCode, ocSearchTerm, product)
      
      setBomPoDetailsList(poDetailsList)
      handlePoList(poDetailsList)
      setIsLoading(false)

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setIsLoading(false)
        setSnackBarOpen(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }, [ocSearchTerm, location, selectedProductDetailDropDown])

  useEffect(()=>{
    if (selectedProductDetailDropDown !== undefined && selectedProductDetailDropDown !== DropdownMenuValuesForOcDetails.product){
      fetchPoDetailsListAssociatedWithOcAndProduct()
    }
    
  }, [selectedProductDetailDropDown])

  const handlePoList = (poDetails: bomPoDetailstListType[])=>{

    const poList = [...new Set(poDetails.map((poDetailsObject)=> poDetailsObject.poNumber))]
 
    poList.unshift(DropdownMenuValuesForOcDetails.PO)
    setPoList(poList)
  }


  const handleToReturnProduct = (productDetails: string)=>{
    const productDetailsSplitList = productDetails.split('|')
    const product = productDetailsSplitList[0].trim()
    return product

  }

  /** Function responsible to handle country list
   */
  const handleCountryList = ()=>{
    const countryList: string[] = [DropdownMenuValuesForOcDetails.Country]
    
    bomPoDetailsList.map((poDetailObject)=>{

      if (poDetailObject.poNumber === selectedPoDropDown){
        if (!countryList.includes(poDetailObject.country)){
          countryList.push(poDetailObject.country)
        }
      }
      return null
    })
    setCountryList(countryList)
  }

  useEffect(()=>{
    if (selectedPoDropDown !== null && selectedPoDropDown !== undefined && selectedPoDropDown !== DropdownMenuValuesForOcDetails.PO) {
      handleCountryList()
    }
  }, [selectedPoDropDown])


  /** Function responsible to handle colour list
   */
  const handleColourList = ()=>{
    const colourList: string[] = [DropdownMenuValuesForOcDetails.Colour]
    
    bomPoDetailsList.map((poDetailObject)=>{

      if (poDetailObject.country === selectedCountryDropDown){
        if (!colourList.includes(poDetailObject.colour)){
          colourList.push(poDetailObject.colour)
        }
      }
      return null
    })
    setColourList(colourList)
  }

  useEffect(()=>{
    if (selectedCountryDropDown !== null && selectedCountryDropDown !== undefined && selectedCountryDropDown !== DropdownMenuValuesForOcDetails.Country) {
      handleColourList()
    }
  }, [selectedCountryDropDown])


  /** Function responsible to return location based on location name 
   * @param {string} locationName The location name
   * @return {string} The location code
   */
  const getLocationCodeBasedOnLocationName = (locationName: string)=>{
    const locationObject = locationListResults.find(locationObject => locationObject.locationName === locationName)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.location
  }


  /**
   * This function is responsible to handle radio button change
   */
  const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioButonSelect((event.target as HTMLInputElement).value)

    if (((event.target as HTMLInputElement).value) === TabValuesEnum.scanLabel){
      setSelectedScanBarcodeDropDown(DropdownMenuValuesForScanbarcode.scanBarcode)
    } else {
      setSelectedBomStatusDropDown(DropdownMenuValuesForBomStatus.ocSearch)
    }
  }


  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }


  /**Handle search bar changes */
  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }

  /**Handle oc search bar changes */
  const handleOcSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

    setBomProductsList([DropdownMenuValuesForOcDetails.product])
    setSelectedProductDetailDropDown(DropdownMenuValuesForOcDetails.product)

    setPoList([DropdownMenuValuesForOcDetails.PO])
    setSelectedPoDropDown(DropdownMenuValuesForOcDetails.PO)

    setCountryList([DropdownMenuValuesForOcDetails.Country])
    setSelectedCountryDropDown(DropdownMenuValuesForOcDetails.Country)

    setColourList([DropdownMenuValuesForOcDetails.Colour])
    setSelectedColourDropDown(DropdownMenuValuesForOcDetails.Colour)

    setOcSearchTerm(event.target.value)
  }

  /**Handle bom status search bar changes */
  const handleBomStatusSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTermForBomStatus(event.target.value)
  }


  /**Handle search bar finder button click */
  const handleSearchBarButtonClick = () => {
    fetchLabelBarcodeDetails()
  }

  /**Handle oc number search bar finder button click */
  const handleOcSearchBarButtonClick = () => {

    if (location === '' || location === null || location === undefined || location === DropdownMenuValues.location){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.locationMessage)
      setOcSearchTerm('') 
      return
    }

    fetchProductListAssociatedWithOc()
  }

  /**Handle BOM status search bar finder button click */
  const handleBomStatusSearchBarButtonClick = () => {
    fetchBomDataList()
  }

  /**This function is responsible to handle scan barcode tab dropDown value
   * @param {string} value The selected option
   */
  const handleFinishingScanBarcodeDropdownMenuChange = (value: string) => {
   
    setSearchTerm('')
    setIsError(false)
    setSelectedScanBarcodeDropDown(value)
  }

  /**This function is responsible to handle product detail dropDown value
   * @param {string} value The selected product
   */
  const handleFinishingProductListDropdownMenuChange = (value: string) => {
    
    setPoList([DropdownMenuValuesForOcDetails.PO])
    setSelectedPoDropDown(DropdownMenuValuesForOcDetails.PO)

    setCountryList([DropdownMenuValuesForOcDetails.Country])
    setSelectedCountryDropDown(DropdownMenuValuesForOcDetails.Country)

    setColourList([DropdownMenuValuesForOcDetails.Colour])
    setSelectedColourDropDown(DropdownMenuValuesForOcDetails.Colour)

    setOcIsError(false)
    setSelectedProductDetailDropDown(value)
  }

  /**This function is responsible to handle PO dropDown value
   * @param {string} value The selected PO
   */
  const handleFinishingPOListDropdownMenuChange = (value: string) => {
   
    setCountryList([DropdownMenuValuesForOcDetails.Country])
    setSelectedCountryDropDown(DropdownMenuValuesForOcDetails.Country)

    setColourList([DropdownMenuValuesForOcDetails.Colour])
    setSelectedColourDropDown(DropdownMenuValuesForOcDetails.Colour)

    setSelectedPoDropDown(value)
  }

  /**This function is responsible to handle Country dropDown value
   * @param {string} value The selected Country
   */
  const handleFinishingCountryListDropdownMenuChange = (value: string) => {
    
    setColourList([DropdownMenuValuesForOcDetails.Colour])
    setSelectedColourDropDown(DropdownMenuValuesForOcDetails.Colour)

    setSelectedCountryDropDown(value)
  }

  /**This function is responsible to handle Colour dropDown value
   * @param {string} value The selected Colour
   */
  const handleFinishingColourListDropdownMenuChange = (value: string) => {
    setSelectedColourDropDown(value)
  }
  

  /**This function is responsible to handle BOM status data tab dropDown value
   * @param {string} value The selected option
   */
  const handleFinishingBOMStatusDropdownMenuChange = (value: string) => {

    setSearchTermForBomStatus('')
    setIsError(false)
    setSelectedBomStatusDropDown(value)
    setBomDataListResults([])
  }
  /**
   * This function is responsible to handle dropDown value for scan barcode tab
   */
  const scanBarcodeDropdownList = () => {
    return ([DropdownMenuValuesForScanbarcode.scanBarcode, DropdownMenuValuesForScanbarcode.enterBarcode])
  }

  /**
   * This function is responsible to handle dropDown value for bom status tab
   */
  const bomStatusDropdownList = () => {
    return ([DropdownMenuValuesForBomStatus.ocSearch, DropdownMenuValuesForBomStatus.poSearch])
  }

  /**Submit Scanned label barcode */
  const submitScannedLabelBarcode = ()=>{
   
    if (location === '' || location === null || location === undefined || location === DropdownMenuValues.location){
      setDataSubmitStatus(false)
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.locationMessage)
      return
    }

    if (labelBarcodeData.length === 0){
      setDataSubmitStatus(false)
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.scanLabelBarcodeLabelListValidationMessage)
      return
    }
    
    const barcodeList =  labelBarcodeData.map((item)=>{
      const {ocNumber, productCode, poNumber, shipTo, colourCode} = item
      return {ocNumber, productCode, poNumber, country: shipTo, colourCode, barcode: item.labelBarcode, quantity: item.quantity}
    })
      .filter((value, index, self) => self.indexOf(value) === index)
    submitScannedBarcodes(barcodeList)

  }

  /**Submit Scanned label barcode */
  const clearDataForScannedLabelBarcode = ()=>{
    setLocation(DropdownMenuValues.location)

    setOcSearchTerm('')
    setBomProductsList([DropdownMenuValuesForOcDetails.product])
    setSelectedProductDetailDropDown(DropdownMenuValuesForOcDetails.product)

    setPoList([DropdownMenuValuesForOcDetails.PO])
    setSelectedPoDropDown(DropdownMenuValuesForOcDetails.PO)

    setCountryList([DropdownMenuValuesForOcDetails.Country])
    setSelectedCountryDropDown(DropdownMenuValuesForOcDetails.Country)

    setColourList([DropdownMenuValuesForOcDetails.Colour])
    setSelectedColourDropDown(DropdownMenuValuesForOcDetails.Colour)

    setLabelBarcodeData([])
  }

  /**This function is responsible to handle location dropDown value
   * @param {string} value The selected location
   */
  const handleLocationDropdownMenuChange = (value: string) => {
    setOcSearchTerm('')
    setBomProductsList([DropdownMenuValuesForOcDetails.product])
    setSelectedProductDetailDropDown(DropdownMenuValuesForOcDetails.product)

    setPoList([DropdownMenuValuesForOcDetails.PO])
    setSelectedPoDropDown(DropdownMenuValuesForOcDetails.PO)

    setCountryList([DropdownMenuValuesForOcDetails.Country])
    setSelectedCountryDropDown(DropdownMenuValuesForOcDetails.Country)

    setColourList([DropdownMenuValuesForOcDetails.Colour])
    setSelectedColourDropDown(DropdownMenuValuesForOcDetails.Colour)



    setLocation(value)
  }

  /**
   * This function is responsible to handle dropDown list for location
   */
  const locationDropdownList = () => {
    let locations: string[] = []
   
    if (locationListResults.length > 0) {
      locations = locationListResults.map((locationObject)=>{
        const {locationName, locationType} = locationObject

        if (locationType === globalEnums.production){
          return locationName
        }
        return ''
      }).filter((obj) => { return obj !== '' })
    }
    locations = sortAlphaNumericArrayList(locations)
    locations.unshift(DropdownMenuValues.location)
    return locations
  }

  const StyledInputLabel = styled(InputLabel)({
    top: '-7px'
  })

  /**
   * This function is responsible to reduce quantity
   */
  const handleQuantityDecrement = (barcode: string) => {

    const data = labelBarcodeData.map((object)=>{
      const {labelBarcode, quantity, scannedQuantity} = object

      if (barcode === labelBarcode){
        const calculatedQuantity = quantity - 1
        const calculatedScanQuantity = scannedQuantity - 1
        return {...object, quantity: calculatedQuantity > 0 ? calculatedQuantity : 0, scannedQuantity: quantity > 0 ? calculatedScanQuantity : scannedQuantity}
      }
      return object
    })

    setLabelBarcodeData(data)
  }

  const handleKeyboardEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && selectedScanBarcodeDropDown === DropdownMenuValuesForScanbarcode.scanBarcode){
      fetchLabelBarcodeDetails()
    }
  }

  

  const handleDataInputValidation = ()=>{

    if (location === '' || location === null || location === undefined || location === DropdownMenuValues.location){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.locationMessage)
      return true
    }

    if (ocSearchTerm === '' || ocSearchTerm === null || ocSearchTerm === undefined){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomStatusOcLabelBarcodeLabel)
      return true
    }

    if (selectedProductDetailDropDown === '' || selectedProductDetailDropDown === null || 
    selectedProductDetailDropDown === undefined || selectedProductDetailDropDown === DropdownMenuValuesForOcDetails.product){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomProductValidationMessage)
      return true
    }

    if (selectedPoDropDown === '' || selectedPoDropDown === null || 
    selectedPoDropDown === undefined || selectedPoDropDown === DropdownMenuValuesForOcDetails.PO){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomPOValidationMessage)
      return true
    }

    if (selectedCountryDropDown === '' || selectedCountryDropDown === null || 
    selectedCountryDropDown === undefined || selectedCountryDropDown === DropdownMenuValuesForOcDetails.Country){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomCountryValidationMessage)
      return true
    }

    if (selectedColourDropDown === '' || selectedColourDropDown === null || 
    selectedColourDropDown === undefined || selectedColourDropDown === DropdownMenuValuesForOcDetails.Colour){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.bomColourValidationMessage)
      return true
    }

    return false
  }



  return (
    <React.Fragment>
      {
        dataSubmitStatus === true ? <SnackBarMessage message = {StringValues.submitSuccessMessage} successStatus = {true} 
          open = {snackBarOpen} onClose={handleSnackBarClose}/> 
          : <SnackBarMessage message = {errorMessage} successStatus = {false} open = {snackBarOpen} onClose={handleSnackBarClose}/>
      }
      <Grid container sx={{
        height: '100vh'
      }}> 
        <Grid item xs={12}
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1
          }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingBottom: 1
          }}>

            <FormControl>
              <RadioGroup row value={radioButonSelect} onChange={handleRadioButtonChange}>
                <FormControlLabel value={TabValuesEnum.scanLabel} control={<Radio  size="small"/>} label="Scan Label" />
                <FormControlLabel value={TabValuesEnum.bomReportStatus} control={<Radio size="small"/>} label="BOM Report Status" />
              </RadioGroup>
            </FormControl>

          </Box>
          {
            (isLoading) ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
              >
                <LinearProgress sx={{ width: '50%' }} />
              </Box>
            ) : (
              <Box sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'primary.pane',
                backgroundColor: 'surface.dark'
              }}>
                {
                  radioButonSelect === TabValuesEnum.scanLabel ?
                    (
                      <Box
                        display="flex"
                        flexDirection="column"
                        sx={{
                          bgcolor: 'primary.card',
                          paddingTop: 2,
                          paddingLeft: 2
                        }}
                      >
                        <Box width="40%" display="flex"
                          flexDirection="row"
                          gap={4}
                          sx={{ paddingBottom: 4 }}>
                          <LocationDropdownMenu onChange={handleLocationDropdownMenuChange} menuValues={locationDropdownList()} buttonText={location} />
                          <SearchBar value={ocSearchTerm} onChange={handleOcSearchBarChange}
                            onButtonClick={handleOcSearchBarButtonClick} error={ocIsError} errorMessage={errorMessage}
                            inputLabel={DropdownMenuValuesForOcDetails.enterOcNumber}
                            width={'40%'} marginLeft={2} />

                        </Box>

                        <Box width="90%" display="flex"
                          flexDirection="row"
                          gap={4}
                          sx={{paddingBottom: 4}}>
                          <FinishingDashboardDropdownMenu onChange={handleFinishingProductListDropdownMenuChange} menuValues={bomProductsList} buttonText={selectedProductDetailDropDown} />
                          <FinishingDashboardDropdownMenu onChange={handleFinishingPOListDropdownMenuChange} menuValues={poList} buttonText={selectedPoDropDown} />
                          <FinishingDashboardDropdownMenu onChange={handleFinishingCountryListDropdownMenuChange} menuValues={countryList} buttonText={selectedCountryDropDown} />
                          <FinishingDashboardDropdownMenu onChange={handleFinishingColourListDropdownMenuChange} menuValues={colourList} buttonText={selectedColourDropDown} />
                          
                        </Box>

                        
                        <Box display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                        >

                          <Box width= "50%"
                            display="flex"
                            flexDirection="row"
                            sx={{gap: 2}}
                          >
                            <FinishingDashboardDropdownMenu onChange={handleFinishingScanBarcodeDropdownMenuChange} menuValues={scanBarcodeDropdownList()} buttonText={selectedScanBarcodeDropDown} />
                            {
                              selectedScanBarcodeDropDown === DropdownMenuValuesForScanbarcode.enterBarcode
                                ? <SearchBar value={searchTerm} onChange={handleSearchBarChange} 
                                  onButtonClick={handleSearchBarButtonClick} error={isError} errorMessage={errorMessage} 
                                  inputLabel = {selectedScanBarcodeDropDown === DropdownMenuValuesForScanbarcode.enterBarcode ? StringValues.scanLabelEnterBarcodeLabel : StringValues.scanLabelBarcodeLabel}
                                  width = {'30%'} marginLeft = {2}/>
                                : <FormControl sx={{
                                  marginLeft: 2,
                                  width: '30%'
                                }}>
                                  <StyledInputLabel htmlFor='outlined-adornment-search'> { StringValues.scanLabelBarcodeLabel } </StyledInputLabel>
                                  <OutlinedInput
                                    inputRef={inputRef}
                                    error={isError}
                                    id='outlined-adornment-search'
                                    onKeyUp={handleKeyboardEvent}
                                    label='search'
                                    size='small'
                                    value={searchTerm}
                                    onChange={handleSearchBarChange}
                                    endAdornment={
                                      <InputAdornment position='end'>
                                        <IconButton aria-label='search button' edge='end' onClick={handleSearchBarButtonClick}>
                                          <SearchIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    } />
                                  {!!isError && <FormHelperText error>{errorMessage}</FormHelperText>}
                                </FormControl>
                            }
                          </Box>

                          <Box marginRight={2} display="flex"
                            flexDirection="row" sx={{gap: 2}}>
                            <FlatButton label='SUBMIT' onClick={submitScannedLabelBarcode} disableControlsStatus={false}/>
                            <FlatButton label='CLEAR' onClick={clearDataForScannedLabelBarcode} disableControlsStatus={false}/>
                          </Box>
                          
                        </Box>
                        
                        {
                          labelBarcodeData.length > 0 
                            ? <ScanLabelBarcodeData bomBarcodeDetailsList={labelBarcodeData} handleQuantityDecrement={handleQuantityDecrement}/>
                            : null
                        }
       
                      </Box>
                    )
                    : radioButonSelect === TabValuesEnum.bomReportStatus ?
                      (
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          sx={{
                            bgcolor: 'primary.card',
                            paddingTop: 4,
                            paddingLeft: 2
                          }}
                        >
                        
                          <Box display="flex"
                            flexDirection="row"
                            justifyContent="start"
                            sx={{gap: 2, marginBottom: 2}}
                          >
                           
                            <FinishingDashboardDropdownMenu onChange={handleFinishingBOMStatusDropdownMenuChange} menuValues={bomStatusDropdownList()} buttonText={selectedBomStatusDropDown} />
                        
                            <SearchBar value={searchTermForBomStatus} onChange={handleBomStatusSearchBarChange} 
                              onButtonClick={handleBomStatusSearchBarButtonClick} error={isError} errorMessage={errorMessage} 
                              inputLabel = {selectedBomStatusDropDown === DropdownMenuValuesForBomStatus.ocSearch ? StringValues.bomStatusOcLabelBarcodeLabel : StringValues.bomStatusBuyerPoLabelBarcodeLabel} 
                              width = {'20%'} marginLeft = {2}/>
                          </Box>
                        
                          {
                            bomDataListResults.length > 0 
                              ? <BomStatusData bomDetailsList={bomDataListResults} locationResultList={locationListResults}/>
                              : null
                          }
       
                        </Box>
                      )
                      : null

                }
              </Box>
            )
          }
        </Grid>
      </Grid>
      
    </React.Fragment >
  )
}

export default FinishingDashboard