import React from "react"
import { Box, Tabs, Tab, Typography } from '@mui/material'


const Dhu = () => {
  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          marginLeft: 2,
          marginTop: 4,
        }}
      >
        DHU 
      </Typography>
    </Box>
  )

}

export default Dhu
