import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { Box, Stack, Tab, Tabs, Grid, Button, LinearProgress } from '@mui/material'
import {
  ButtonSelectionValues, ButtonSelector, LocationDropdownMenu,
  SewingSingleDateChart, DHUSingleDateChart, DHUDateRangeChart,
  SewingDateRangeChart, SewingSingleDateTable,
  SewingDateRangeTable, DHUSingleDateTable, TabPanel, DHUPassedSingleDateTable, DHUPassedDateRangeTable
} from './components'
import { getDefectDetails, getEfficiencyDetails, getLocationList, getDispatchScanningDetails, getBundleTrackDetails} from './api/apiFunctions'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { DHUDataDetails, DispatchScanningData, SewingEfficiencyDetails, locationDetailsType, BundleTrackvalue } from './types'
import DHUDateRangeTable from './components/DHUDateRangeTable'
import { AxiosError } from 'axios'
import { DatePicker, SnackBarMessage } from '../UI/Components'
import { dateRangeType } from '../UI/types'
import globalEnums from '../UI/enum'
import { sortAlphaNumericArrayList } from '../utils'
import DispatchScanningReport from './components/DispatchScanningReport'
import BundleTrackData from './components/BundleTrackData'
import NoDataFound from './components/NoDataFound'
import RefreshIcon from '@mui/icons-material/Refresh'

interface SewingDashboardProps {
  componentHeader: (headerName: string) => void
}

enum DropdownMenuValues {
  location = 'LOCATION'
}


const SewingDashboard = (props: SewingDashboardProps) => {
  useAuthenticationToken()
  props.componentHeader('Sewing')

  const [location, setLocation] = useState<string>(DropdownMenuValues.location)
  const [chartOrTableSelection, setChartOrTableSelection] = React.useState(ButtonSelectionValues.Chart)
  const [efficiencyData, setEfficiencyData] = useState<SewingEfficiencyDetails[]>([])
  const [dispatchScanningData, setDispatchScanningData] = useState<DispatchScanningData[]>([])
  const [bundleTrackData, setBundleTrackData] = useState<DispatchScanningData[]>([])
  const [defectData, setDefectData] = useState<DHUDataDetails[]>([])
  const [BundelData, setBundelData] = useState<BundleTrackvalue[]>([])
  const [isSingleDayData, setIsSingleDayData] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [tabState, setTabState] = useState(0)
  const [dateRange, setDateRange] = useState<dateRangeType>()
  const [locationListResults, setLocationListResults] = useState<locationDetailsType[]>([])

  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  useEffect(() => {
    handleFetchLocation()
  }, [])

  const handleFetchLocation = async () => {
    await new Promise(r => setTimeout(r, 500))
    fetchLocationList()
  }

  useEffect(() => {
    const fetchEfficiencyData = async (startDate: string, endDate: string) => {
      if (isLoading === true) {
        return
      }
      setEfficiencyData([])
      if (location === DropdownMenuValues.location) {
        return
      }
      try {
        setIsLoading(true)
        const locationCode = getLocationCodeBasedOnLocationName(location)
        const data = await getEfficiencyDetails(locationCode, startDate, endDate)
        setIsLoading(false)
        setEfficiencyData(data)
      } catch (err) {
        setEfficiencyData([])

        if (err instanceof AxiosError || err instanceof Error) {
          setSnackBarOpen(true)
          setIsLoading(false)
          setErrorMessage(err.message)
        } else {
          throw err
        }


      }
    }
    const fetchDispatchScanningData = async (startDate: string, endDate: string) => {
      if (isLoading === true) {
        return
      }
      setDispatchScanningData([])
      if (location === DropdownMenuValues.location) {
        return
      }
      try {
        setIsLoading(true)
        const locationCode = getLocationCodeBasedOnLocationName(location)
        const data = await getDispatchScanningDetails(locationCode, startDate)
        setIsLoading(false)
        setDispatchScanningData(data)
      } catch (err) {
        setDispatchScanningData([])

        if (err instanceof AxiosError || err instanceof Error) {
          setSnackBarOpen(true)
          setIsLoading(false)
          setErrorMessage(err.message)
        } else {
          throw err
        }


      }
    }

    const fetchDefectData = async (startDate: string, endDate: string) => {
      if (isLoading) {
        return
      }
      setDefectData([])
      if (location === DropdownMenuValues.location) {
        return
      }
      try {
        setIsLoading(true)
        const locationCode = getLocationCodeBasedOnLocationName(location)
        const data = await getDefectDetails(locationCode, startDate, endDate)
        setIsLoading(false)
        setDefectData(data)
      } catch (err) {
        setDefectData([])
        if (err instanceof AxiosError || err instanceof Error) {
          setSnackBarOpen(true)
          setIsLoading(false)
          setErrorMessage(err.message)
        } else {
          throw err
        }
      }
    }
    const fetchBundelData = async () => {
      if (isLoading) {
        return
      }
      setDefectData([])
      if (location === DropdownMenuValues.location) {
        return
      }
      try {
        setIsLoading(true)
        const locationCode = getLocationCodeBasedOnLocationName(location)
        const data = await getBundleTrackDetails(locationCode)
        setIsLoading(false)
        setBundelData(data)
      } catch (err) {
        setBundelData([])
        if (err instanceof AxiosError || err instanceof Error) {
          setSnackBarOpen(true)
          setIsLoading(false)
          setErrorMessage(err.message)
        } else {
          throw err
        }
      }
    }

    if (dateRange?.startDate === null || dateRange?.startDate === undefined || dateRange?.endDate === null || dateRange?.endDate === undefined) {
      return
    }
    const formattedStartDate = format(dateRange?.startDate as Date, "yyyyMMdd")
    const formattedEndDate = format(dateRange?.endDate as Date, "yyyyMMdd")

    fetchEfficiencyData(formattedStartDate, formattedEndDate)
    fetchDefectData(formattedStartDate, formattedEndDate)
    fetchDispatchScanningData(formattedStartDate, formattedEndDate)
    fetchBundelData()
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dateRange])


  /** Function responsible to return location based on location name 
   * @param {string} locationName The location name
   * @return {string} The location code
   */
  const getLocationCodeBasedOnLocationName = (locationName: string) => {
    const locationObject = locationListResults.find(locationObject => locationObject.locationName === locationName)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.location
  }

  const fetchLocationList = async () => {
    if (isLoading) {
      return
    }
    try {
      setIsLoading(true)
      const locationsList = await getLocationList()
      setLocationListResults(locationsList)
      setIsLoading(false)

    } catch (err) {
      setDefectData([])
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }

  const handleChartOrTableSelection = (selectionValue: ButtonSelectionValues) => {
    setChartOrTableSelection(selectionValue)
  }

  const handleDateRangeChange = (value: dateRangeType) => {
    const startDate = format(value?.startDate as Date, "yyyyMMdd")
    const endDate = format(value?.endDate as Date, "yyyyMMdd")

    if (startDate !== endDate) {
      setIsSingleDayData(false)
    } else {
      setIsSingleDayData(true)
    }

    setDateRange(value)
  }

  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  /**This function is responsible to handle location dropDown value
   * @param {string} value The selected location
   */
  const handleLocationDropdownMenuChange = (value: string) => {
    setLocation(value)
  }

  /**
   * This function is responsible to handle dropDown list for location
   */
  const locationDropdownList = () => {
    let locations: string[] = []

    if (locationListResults.length > 0) {
      locations = locationListResults.map((locationObject) => {
        const { locationName, locationType } = locationObject

        if (locationType === globalEnums.production) {
          return locationName
        }
        return ''
      }).filter((obj) => { return obj !== '' })
    }
    locations = sortAlphaNumericArrayList(locations)
    locations.unshift(DropdownMenuValues.location)
    return locations
  }

  const handleRefresh = () => {
    const today = new Date()
    const startDate = format(today, 'yyyyMMdd')
    const endDate = format(today, 'yyyyMMdd')
    setDateRange({ startDate: today, endDate: today })

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const locationCode = getLocationCodeBasedOnLocationName(location)
        const efficiencyPromise = getEfficiencyDetails(locationCode, startDate, endDate)
        const defectPromise = getDefectDetails(locationCode, startDate, endDate)
        const dispatchScanningPromise = getDispatchScanningDetails(locationCode, startDate)

        const [efficiencyData, defectData, dispatchScanningData] = await Promise.all([
          efficiencyPromise,
          defectPromise,
          dispatchScanningPromise,
        ])

        setEfficiencyData(efficiencyData)
        setDefectData(defectData)
        setDispatchScanningData(dispatchScanningData)
        setBundelData(BundelData)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        if (err instanceof AxiosError || err instanceof Error) {
          setSnackBarOpen(true)
          setErrorMessage(err.message)
        } else {
          throw err
        }
      }
    }
    console.log(BundelData)

    fetchData()
  }

  return (
    <React.Fragment>
      <SnackBarMessage message={errorMessage} successStatus={false} open={snackBarOpen} onClose={handleSnackBarClose} />

      <Grid container sx={{
        height: '100vh'
      }}>

        <Grid item xs={12}
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1
          }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}>
            <Button
              onClick={handleRefresh}
              sx={{
                bgcolor: 'alpha.main',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.2rem',
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                marginRight: '9px',
                transition: 'background-color 0.3s, color 0.3s',
                '&:hover': {
                  bgcolor: 'alpha.main',
                  color: 'white',
                },
              }}
            >
              <RefreshIcon />
            </Button>
            <Box sx={{
              display: 'flex',
              width: '50%',
              justifyContent: 'flex-end'
            }}>
              <ButtonSelector onSelect={(currentSelection: ButtonSelectionValues) => handleChartOrTableSelection(currentSelection)} />
            </Box>

            <Box sx={{
              display: 'flex',
              width: '50%',
              flexDirection: 'row',
              gap: 1,
              justifyContent: 'flex-end',
              paddingBottom: 2
            }}>
              <DatePicker onChange={handleDateRangeChange} defaultDateRange={(value: dateRangeType) => setDateRange(value)} direction={'horizontal'} />
              <LocationDropdownMenu onChange={handleLocationDropdownMenuChange} menuValues={locationDropdownList()} buttonText={location} />
            </Box>
          </Box>

          {
            isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
              >
                <LinearProgress sx={{ width: '50%' }}/>
              </Box>
            ) : (
              <Box sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'primary.pane',
                backgroundColor: 'surface.dark'
              }}>

                {
                  chartOrTableSelection === ButtonSelectionValues.Chart
                    ? (isSingleDayData
                      ? (
                        <Box sx={{ width: '100%' }}>
                          <Stack spacing={2}>
                            {efficiencyData.length > 0 ? <SewingSingleDateChart data={efficiencyData} /> : null}
                            {defectData.length > 0 ? <DHUSingleDateChart data={defectData} /> : null}
                          </Stack>
                        </Box>

                      ) : (<Box sx={{ width: '100%' }}>
                        <Stack spacing={2}>
                          {efficiencyData.length > 0 ? <SewingDateRangeChart data={efficiencyData} /> : null}
                          {defectData.length > 0 ? <DHUDateRangeChart data={defectData} /> : null}
                        </Stack>
                      </Box>))
                    : (<Box sx={{
                      bgcolor: 'primary.card'
                    }}>
                      <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
                        setTabState(newValue)
                      }} textColor='secondary' TabIndicatorProps={{
                        style: {
                          backgroundColor: 'alpha.light'
                        }
                      }}>
                        <Tab sx={{
                          color: 'alpha.light'
                        }} label="BATCH EFFICIENCY TABLE" />
                        <Tab sx={{
                          color: 'alpha.light'
                        }} label="END TABLE PASS DETAILS" />
                        <Tab sx={{
                          color: 'alpha.light'
                        }} label="DHU TABLE" />
                        <Tab sx={{
                          color: 'alpha.light'
                        }} label="DISPATCH REPORT" />
                        <Tab sx={{
                          color: 'alpha.light'
                        }} label="BUNDLE TRACK" />
                      </Tabs>


                      <TabPanel value={tabState} index={0}>
                        {
                          efficiencyData.length > 0 ?
                            (isSingleDayData ? <SewingSingleDateTable data={efficiencyData} /> : <SewingDateRangeTable data={efficiencyData} />)
                            : null
                        }
                      </TabPanel>
                      <TabPanel value={tabState} index={1}>
                        {
                          defectData.length > 0 ?
                            (isSingleDayData ? <DHUPassedSingleDateTable data={defectData} /> : <DHUPassedDateRangeTable data={defectData} />)
                            : null
                        }
                      </TabPanel>
                      <TabPanel value={tabState} index={2}>
                        {
                          defectData.length > 0 ?
                            (isSingleDayData ? <DHUSingleDateTable data={defectData} /> : <DHUDateRangeTable data={defectData} />)
                            : null
                        }
                      </TabPanel>
                      <TabPanel value={tabState} index={3}>
                        {
                          dispatchScanningData.length > 0 ?
                            (isSingleDayData ? <DispatchScanningReport data={dispatchScanningData} /> : <NoDataFound />)
                            : null
                        }

                      </TabPanel>
                      <TabPanel value={tabState} index={4}>
                        <BundleTrackData data={BundelData}/>

                      </TabPanel>
                    </Box>

                    )
                }
              </Box>
            )
          }
        </Grid>

      </Grid>
    </React.Fragment>
  )
}

export default SewingDashboard