import React from 'react'
import { Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography, Box } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { FabricDataInfo } from '../types'
import { dateRangeType } from '../../UI/types'
import { format } from 'date-fns'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'
interface FabricDataForTrackerProps {
  fabricDataForWareHouseDateRange: FabricDataInfo[],
  isDataBasedOnWarehouseEntryDate: boolean,
  dateRange: dateRangeType
}

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial'
  }
})

function FabricDataForTracker(props: FabricDataForTrackerProps) {
  const { fabricDataForWareHouseDateRange, isDataBasedOnWarehouseEntryDate, dateRange} = props
  const classes = useStyles()

  const convertDateTimeToLocalString = (date: string | null) => {
    if (date === null || date === undefined)
    {
      return null
    }
    return new Date(date as string)
      .toLocaleTimeString('en-GB',
        {timeZone: 'UTC', hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}
      ).replace(',', '')
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
            
    const jsonData = fabricDataForWareHouseDateRange.map((object)=>{
      return {
        rackNumber: object.rackNumber, 
        fabricBarcode: object.fabricBarcode, 
        itemCode: object.itemCode,
        itemDescription: object.itemDescription,
        fabricLength: object.fabricLength,
        mirNumber: object.mirNumber,
        mirDate: object.mirDate,
        warehouseEntryDateTime: convertDateTimeToLocalString(object.warehouseEntryDateTime),
        rackAssociationDateTime: convertDateTimeToLocalString(object.rackAssociationDateTime),
        rackDissociationDateTime: convertDateTimeToLocalString(object.rackDissociationDateTime),
        warehouseExitDateTime: convertDateTimeToLocalString(object.warehouseExitDateTime)
      }
    })
  
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)

    if (isDataBasedOnWarehouseEntryDate === true){
      XLSX.utils.book_append_sheet(workBook, workSheet, 'fabricForWareHouseEntry')
      XLSX.writeFile(workBook, 'fabricDetailsBasedOnWareHouseEntry.xlsx')
    } else {
      XLSX.utils.book_append_sheet(workBook, workSheet, 'fabricForWareHouseExit')
      XLSX.writeFile(workBook, 'fabricDetailsBasedOnWareHouseExit.xlsx')
    }
  
  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          bgcolor: 'primary.card',
          paddingTop: 1
        }}
      >
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>
      <Box sx={{paddingLeft: 1, paddingBottom: 2}}>
        
        <Typography variant='subtitle1' sx={{ paddingBottom: 1}}>
          {
            isDataBasedOnWarehouseEntryDate === true
              ? `FABRIC INFORMATION FOR WAREHOUSE ENTRY DATE RANGE`
              : `FABRIC INFORMATION FOR WAREHOUSE EXIT DATE RANGE`
          }
        </Typography>
      
        <Typography variant='subtitle1'>
          {`${format(dateRange?.startDate as Date, "dd/MM/yyyy")} - ${format(dateRange?.endDate as Date, "dd/MM/yyyy")}`}
        </Typography>
      </Box>

      

      <TableContainer component={Paper} classes={{ root: classes.customTableContainer }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>SERIAL NO.</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK NUMBER</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>FABRIC BARCODE</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>ITEM CODE</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>FABRIC LENGTH</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>MIR NUMBER</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>MIR DATE</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>WH ENTRY DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK ENTRY DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK EXIT DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>WH EXIT DATE & TIME</Typography></TableCell>
            </TableRow>
          </TableHead>
            

          <TableBody>
            {
              fabricDataForWareHouseDateRange.map((fabricObject, index) =>{
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{fabricObject.rackNumber}</TableCell>
                    <TableCell>{fabricObject.fabricBarcode}</TableCell>
                    <TableCell>{fabricObject.itemCode}</TableCell>
                    <TableCell>{fabricObject.fabricLength} meters</TableCell>
                    <TableCell>{fabricObject.mirNumber}</TableCell>
                    <TableCell>{fabricObject.mirDate}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(fabricObject.warehouseEntryDateTime)}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(fabricObject.rackAssociationDateTime)}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(fabricObject.rackDissociationDateTime)}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(fabricObject.warehouseExitDateTime)}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table> 
      </TableContainer>
    </React.Fragment>
  )
}

export default FabricDataForTracker