import React, { useState, useEffect } from 'react'
import { Box, Typography, Stack, TextField, IconButton } from '@mui/material'
import { colourSizeDetailsType, productColourType } from '../types'
import {roundNumberToSignificantDigits} from '../../utils'
import DeleteIcon from '@mui/icons-material/Delete'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import StringValues from '../../Providers/StringValues'
import {AddButton, FlatButton, DeleteButton} from '../../UI/Components'
import ColourCodeDropdownMenu from './ColourCodeDropdownMenu'

interface ColourSizeDetailsProps {
  colourAndSizeDetais: colourSizeDetailsType[]
  handleSnackBarMessage: (value: string) => void
  submitJobworkOrder: (data: colourSizeDetailsType[], isSubmitButtonClicked: boolean) => void
  removeColourWithSizeDetails: (data: colourSizeDetailsType[], indexToRemove: number, isSubmitButtonClicked: boolean) => void
  productColourList: productColourType[]
  handleTotalQuantity: (totalQuantity: number) => void
}

function ColourSizeDetails(props: ColourSizeDetailsProps) {
  const {colourAndSizeDetais, handleSnackBarMessage, submitJobworkOrder, removeColourWithSizeDetails, productColourList, handleTotalQuantity} = props
  
  const [colourSizeData, setColourSizeData] = useState<colourSizeDetailsType[]>(colourAndSizeDetais)

  useEffect(() => {
    setColourSizeData(colourAndSizeDetais)

  }, [colourAndSizeDetais])

  
  useEffect(() => {
    let totalQuantity = 0

    colourSizeData.map((object)=>{
      const {sizeDetails} = object
      totalQuantity += sizeDetails.reduce((acc, curr) => {
        return acc + (curr.quantity > 0 ? curr.quantity : 0)
      }, 0)

      return null
    })

    handleTotalQuantity(totalQuantity)

  }, [colourSizeData])

  /**This function is responsible to update Colour description
   * @param {string}colourDesc The colour description
   * @param {number}indexToChange The index number to update value
   */
  const handleColourDescriptionChange = (colourDesc: string, indexToChange: number) => {
    const createColourListWithChanges = [...colourSizeData]
    createColourListWithChanges[indexToChange].colourDescription = colourDesc
    
    setColourSizeData(createColourListWithChanges)
  }

  /**This function is responsible to update size
   * @param {string}size The size
   * @param {number}indexToChange The index number to update value
   * @param {number}sizeIndexToChange The size index number to update value
   */
  const handleSizeChange = (size: string, indexToChange: number, sizeIndexToChange: number) => {
    const createColourListWithChanges = [...colourSizeData]
    createColourListWithChanges[indexToChange].sizeDetails[sizeIndexToChange].size = size.toUpperCase()
    
    setColourSizeData(createColourListWithChanges)
  }

  /**This function is responsible to update size-quantity
   * @param {string}quantity The quantity
   * @param {number}indexToChange The index number to update value
   * @param {number}sizeIndexToChange The size index number to update value
   */
  const handleSizeQuantityChange = (quantity: string, indexToChange: number, sizeIndexToChange: number) => {
    const createColourListWithChanges = [...colourSizeData]
    createColourListWithChanges[indexToChange].sizeDetails[sizeIndexToChange].quantity = roundNumberToSignificantDigits(parseFloat(quantity), 0)
    
    
    const totalSizeWiseQuantity = createColourListWithChanges[indexToChange].sizeDetails.reduce((acc, curr) => {
      return acc + (curr.quantity > 0 ? curr.quantity : 0)
    }, 0)

    createColourListWithChanges[indexToChange].sizeQuantityTotal = totalSizeWiseQuantity
    setColourSizeData(createColourListWithChanges)
  }

  /**This function is responsible to delete size Quantity
   * @param {number}indexToChange The index number to update value
   * @param {number}sizeIndexToChange The size index number to update value
   */
  const removeSizeQuantity = (indexToChange: number, sizeIndexToChange: number) => {

    if (colourSizeData[indexToChange].sizeDetails.length === 1){
      handleSnackBarMessage(StringValues.jobworkSizeQuantityDeleteValidationMessage)
      return
    }

    const createColourListWithChanges = [...colourSizeData]
    createColourListWithChanges[indexToChange].sizeDetails.splice(sizeIndexToChange, 1)
    
    setColourSizeData(createColourListWithChanges)
  }

  /**This function is responsible to Add size Quantity
   * @param {number}indexToAdd The index number to Add size Quantity
   */
  const addSizeQuantity = (indexToAdd: number) => {

    const createColourListWithChanges = [...colourSizeData]
    createColourListWithChanges[indexToAdd].sizeDetails.push({size: '', quantity: 0})
    
    setColourSizeData(createColourListWithChanges)
  }

  /**This function is responsible to Add colour section */
  const addColour = () => {
    const createColourListWithChanges = [...colourSizeData]
    createColourListWithChanges.push({colourCode: productColourList[0].colourCode, colourDescription: '', sizeDetails: [{size: '', quantity: 0}], sizeQuantityTotal: 0})
    
    setColourSizeData(createColourListWithChanges)
  }

  /**This function is responsible to validate and remove colour section 
   * @param {number} indexToChange The colour size details list index number
   */
  const removeColourGroup = (indexToChange: number) => {
    if (colourSizeData.length === 1){
      handleSnackBarMessage(StringValues.jobworkColourDeleteValidationMessage)
      return
    }
   
    removeColourWithSizeDetails(colourSizeData, indexToChange, false)
  }

  /**This function is responsible to submit colour size details
   */
  const submitColourDetailsWithSizeAndQuantity = () => {
    submitJobworkOrder(colourSizeData, true)
  }

  /**This function is responsible to handle colour Code dropDown value
   * @param {string} value The selected colourCode
   */
  const handleColourCodeDropdownMenuChange = (value: string, indexToChange: number) => {
    const createColourListWithChanges = [...colourSizeData]
    createColourListWithChanges[indexToChange].colourCode = value

    let colourObject = productColourList.find(object => object.colourCode === value)
    if (colourObject !== null && colourObject !== undefined) {
      createColourListWithChanges[indexToChange].colourDescription = colourObject.colourDescription
    }
    
    
    setColourSizeData(createColourListWithChanges)
  }
  
  /**
   * This function is responsible to handle dropDown list for location
   */
  const colourCodeDropdownList = () => {
    let colourCodeList: string[] = []

    if (productColourList.length > 0) {
      colourCodeList =  productColourList.map(item => item.colourCode)
        .filter((value, index, self) => self.indexOf(value) === index)
    }
    
    return colourCodeList
  }

  return (
    
    <React.Fragment>
      <Box sx={{
        width: '100%',
        bgcolor: 'primary.pane',
        backgroundColor: 'surface.dark',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 1,
        paddingRight: 1
      }}> 
        
        <Typography variant='body1' sx={{color: 'alpha.main'}}>COLOUR & SIZE DETAILS</Typography>
        {
          colourSizeData.map((object, index)=>{
            const {colourCode, colourDescription, sizeDetails} = object
            return (
              <Box sx={{paddingTop: 1, paddingBottom: 1,  backgroundColor: 'surface.dark'}}>
                <Box sx={{paddingTop: 2, paddingBottom: 2, paddingLeft: 2, backgroundColor: 'white'}}>

                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingBottom: 1
                  }}> 
                  
                    <Box sx={{
                      display: 'flex',
                      width: '50%',
                      justifyContent: 'flex-start'
                    }}>
                      <Stack spacing={3} direction="row">
                        
                        <Typography variant='body1'>COLOUR CODE :</Typography>
                        <ColourCodeDropdownMenu onChange={handleColourCodeDropdownMenuChange} menuValues={colourCodeDropdownList()} buttonText={colourCode} buttonTextIndex={index}></ColourCodeDropdownMenu>
                        
                        <Typography variant='body1'>COLOUR DESCRIPTION :</Typography>
                        <TextField
                          type='text'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            style: {
                              height: "20px",
                              width: "300px"
                            },
                          }}
                          variant="outlined"
                          size="small"
                          value={colourDescription}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleColourDescriptionChange(event.target.value, index)}
                        /> 
                      </Stack>

                    </Box>


                    <Box sx={{
                      display: 'flex',
                      width: '50%',
                      flexDirection: 'row',
                      gap: 3,
                      justifyContent: 'flex-end'
                    }}> 
                      <Stack spacing={3} direction="row">
                        <Typography variant='body1'>Colour Quantity Total : </Typography>
                        <Typography variant='body1'>{object.sizeQuantityTotal}</Typography>
                        
                        
                        <DeleteButton label='DELETE COLOUR' onClick={() => {
                          removeColourGroup(index)
                        }}></DeleteButton>
                      </Stack>
                      
                    </Box>
                  
                  </Box>
                  

                  <Box sx={{paddingTop: 2}}>
                    <Stack spacing={1} direction="row">
                      <Box sx={{paddingRight: 2}}>
                        <Typography variant='body1' sx={{paddingBottom: 2}}>SIZE:</Typography>
                        <Typography variant='body1'>QUANTITY:</Typography>
                      </Box>
                      {
                        sizeDetails.map((sizeObject, sizeIndex) => {
                          const {size, quantity} = sizeObject
                          return (
                            <Box>
                              <Stack spacing={1} direction="column">
                              
                                <TextField
                                  type='text'
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    style: {
                                      height: "10px",
                                      width: "50PX"
                                    },
                                  }}
                                  variant="outlined"
                                  size= 'small'
                                  value={size}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSizeChange(event.target.value, index, sizeIndex)}
                                />

                                <TextField
                                  type='number'
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  inputProps={{
                                    style: {
                                      height: "10px",
                                      width: "50PX"
                                    },
                                  }}
                                  variant="outlined"
                                  size="small"
                                  value={quantity}
                                  onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSizeQuantityChange(event.target.value, index, sizeIndex)}
                                />

                                <IconButton aria-label="delete" size="small" onClick={() => removeSizeQuantity(index, sizeIndex)}>
                                  <DeleteIcon />
                                </IconButton>
                              
                              </Stack>
                            </Box>
                          )
                        })
                      }

                      <Box sx={{paddingLeft: 2}}>
                        <IconButton aria-label="delete" size="small" onClick={() => addSizeQuantity(index)}>
                          <AddCircleIcon sx={{color: 'alpha.main'}}/>
                        </IconButton>
                      </Box>

                    </Stack>
                  </Box>
                </Box>
              </Box>
            )
          })
        }
        <Box display="flex"
          justifyContent="left"
          alignItems="center" sx={{ paddingTop: 1 }}>
          <AddButton label='ADD COLOUR' onClick={addColour}/>
        </Box>

        <Box display="flex"
          justifyContent="center"
          alignItems="center" sx={{ paddingTop: 2 }}>
          <FlatButton label='SUBMIT' onClick={submitColourDetailsWithSizeAndQuantity} disableControlsStatus={false}/>
        </Box>
        
      </Box>

      
    </React.Fragment>
  )
}

export default ColourSizeDetails