import * as React from 'react'
import {Dialog, DialogTitle, Stack, Button} from '@mui/material'
import {Done, Close} from '@mui/icons-material'

export interface SubmitDialogProps {
  open: boolean
  submitStatus: boolean
  dialogMessage: string
  onClose: (value: boolean) => void
}

function SubmitDialog(props: SubmitDialogProps) {
  const { onClose, submitStatus, open, dialogMessage } = props

  const handleClose = () => {
    onClose(submitStatus)
  }

  const handleItemClick = (value: boolean) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{dialogMessage}</DialogTitle>
      <Stack spacing={2} direction="row" justifyContent={'flex-end'}>
        <Button variant="text" endIcon={<Close/>} onClick={() => handleItemClick(false)}>CANCEL</Button>
        <Button variant="text" endIcon={<Done/>} onClick={() => handleItemClick(true)}>SUBMIT</Button>
      </Stack>
    </Dialog>
  )
}

export default SubmitDialog