import React, { useState, useEffect } from 'react'
import { TextField, Typography, Box, TableContainer, Paper, Table, TableCell, TableHead, TableBody, TableRow, IconButton } from '@mui/material'
import { BarcodeRollData } from '../types'
import { nanoid } from 'nanoid'
import {AddButton, FlatButton} from '../../UI/Components'
import DeleteIcon from '@mui/icons-material/Delete'
import StringValues from '../../Providers/StringValues'

interface FabricRollEntryProps {
  submitOrder : (supplierInvoiceNumber: string, rollQuantitiesDetails : BarcodeRollData[]) => void
  handleDisplayBarcodeSnackBar: (message: string)=> void
}

function FabricRollEntry(props: FabricRollEntryProps) {
  const {submitOrder, handleDisplayBarcodeSnackBar} = props

  const [rollQuantityList, setRollQuantityList] = useState<BarcodeRollData[]>([])
  const [totalLength, setTotalLength] = useState<number>(0)
  const [supplierInvoiceNumber, setSupplierInvoiceNumber] = useState<string>('')

  useEffect(() => {
    const rollQuantityWithId =  {
      roll: nanoid(10),
      length: 0,
      supplierRollNo: '',
      isRollNoRepeated: false,
      fabricWidth: 0,
      cuttableWidth: 0
    }
    setRollQuantityList([rollQuantityWithId])
    
  }, [])
  
  /**This function is responsible to update supplier roll number
   * @param {string}rollNumber The supplier roll number to update
   * @param {string}indexToChange The index value to effect
   */
  const handleRollChange = (rollNumber: string, indexToChange: string) => {
    const createRollQuantityListWithChanges = [...rollQuantityList]
    const rollQuantityIndex = createRollQuantityListWithChanges.findIndex((obj => obj.roll === indexToChange))
    createRollQuantityListWithChanges[rollQuantityIndex].supplierRollNo = rollNumber
    createRollQuantityListWithChanges[rollQuantityIndex].isRollNoRepeated = false
    setRollQuantityList(createRollQuantityListWithChanges)
  }

  /**This function is responsible convert to number and update roll length 
   * @param {string}rollLength The roll length to update
   * @param {string}indexToChange The index value to effect
   */
  const handleLengthChange = (rollLength: string, indexToChange: string) => {
    const createRollQuantityListWithChanges = [...rollQuantityList]
    const rollQuantityIndex = createRollQuantityListWithChanges.findIndex((obj => obj.roll === indexToChange))
    createRollQuantityListWithChanges[rollQuantityIndex].length = parseFloat(rollLength)
    
    setRollQuantityList(createRollQuantityListWithChanges)

    const totalRollLengthResult = createRollQuantityListWithChanges.reduce((acc, curr) => {
      let length = 0
      if (!isNaN(curr.length)){
        length = curr.length
      }
      return acc + length
    }, 0)
    
    setTotalLength(totalRollLengthResult)
    
  }

  /**This function is responsible convert to number and update fabric width
   * @param {string}fabricWidth The roll fabric width
   * @param {string}indexToChange The index value to effect
   */
  const handleFabricWidthChange = (fabricWidth: string, indexToChange: string) => {
    const createRollQuantityListWithChanges = [...rollQuantityList]
    const rollQuantityIndex = createRollQuantityListWithChanges.findIndex((obj => obj.roll === indexToChange))
    createRollQuantityListWithChanges[rollQuantityIndex].fabricWidth = parseFloat(fabricWidth)
    createRollQuantityListWithChanges[rollQuantityIndex].cuttableWidth = (parseFloat(fabricWidth) - 1)
    
    setRollQuantityList(createRollQuantityListWithChanges)
  }

  /**This function is responsible to remove row from entry
   * @param {string}indexToRemove The index value to remove row
   */
  const removeRollQuantityRow = (indexToRemove: string) => {
    if (rollQuantityList.length === 1) {
      return
    }
    const createRollQuantityListWithChanges = [...rollQuantityList]
    const sizeQuantityIndex = createRollQuantityListWithChanges.findIndex((obj => obj.roll === indexToRemove))
    createRollQuantityListWithChanges.splice(sizeQuantityIndex, 1)

    setRollQuantityList(createRollQuantityListWithChanges)

    const totalRollLengthResult = createRollQuantityListWithChanges.reduce((acc, curr) => {
      let length = 0
      if (!isNaN(curr.length)){
        length = curr.length
      }
      return acc + length
    }, 0)

    setTotalLength(totalRollLengthResult)
    
  }

  /**This function is responsible to add row in entry */
  const addRollQuantityRow = () => {
    setRollQuantityList([...rollQuantityList, { roll: nanoid(10), length: 0, supplierRollNo: '', isRollNoRepeated: false, fabricWidth: 0, cuttableWidth: 0 }])
  }

  const checkForSupplierRollNumberRepeat = (rollQuantity: BarcodeRollData[])=>{
    const arr: string[] = []
    let rollRepeatedCount = 0
   
    const listOfrollQuantity = rollQuantity.map((rollObject)=>{
      const {supplierRollNo} = rollObject
      if (arr.includes(supplierRollNo.toUpperCase())) {
        rollRepeatedCount += 1
        return {...rollObject, isRollNoRepeated: true}
      }
      arr.push(supplierRollNo.toUpperCase())
      return {...rollObject}
    })
    return {listOfrollQuantity, rollRepeatedCount}
  }
  /**This function is responsible to submit roll details entry */
  const submitRollQuantities = () => {
    const result : {listOfrollQuantity: BarcodeRollData[], rollRepeatedCount: number} = checkForSupplierRollNumberRepeat(rollQuantityList)
    
    if (result.rollRepeatedCount > 0){
      setRollQuantityList(result.listOfrollQuantity)
      handleDisplayBarcodeSnackBar(StringValues.barcodeSupplierRollValidationMessage)
      return
    }
    submitOrder(supplierInvoiceNumber, rollQuantityList)
  }

  /**This function is responsible to update supplier invoice number
   * @param {string}supplierInvoiceNumber The supplier invoice number
   */
  const handleSupplierInvoiceChange = (supplierInvoiceNumber: string) => {
    setSupplierInvoiceNumber(supplierInvoiceNumber)
  }

  return (
    <React.Fragment>
     
      <Box sx={{
        paddingTop: 2
      }}>

        <Box>
          <Typography variant='body1'>Supplier Invoice Number</Typography>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: {
                height: "15px"
              },
            }}
            variant="outlined"
            size="small"
            value={supplierInvoiceNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSupplierInvoiceChange(event.target.value)}
          /> 
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell/>
                <TableCell><Typography variant='subtitle1'>Roll Number</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>Length</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>Fabric Width</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>Cuttable Width</Typography></TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rollQuantityList.map((object, index) => {
                  return (
                    <TableRow key={object.roll}>
                      <TableCell><Typography variant='h6'>{index + 1}</Typography></TableCell>
                      <TableCell>
                        {
                          object.isRollNoRepeated === true
                            ? <TextField
                              error
                              label="Roll Number Repeated"
                              type='string'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              size="small"
                              value={object.supplierRollNo}
                              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleRollChange(event.target.value, object.roll)}
                              autoFocus
                            />
                            : <TextField
                              type='string'
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              size="small"
                              value={object.supplierRollNo}
                              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleRollChange(event.target.value, object.roll)}
                              autoFocus
                            />
                        }
                         
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          size="small"
                          value={object.length}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleLengthChange(event.target.value, object.roll)}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          type="number"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          size="small"
                          value={object.fabricWidth}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFabricWidthChange(event.target.value, object.roll)}
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          type="number"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          size="small"
                          value={object.cuttableWidth}
                        />
                      </TableCell>

                      <TableCell>
                        <IconButton aria-label="delete" size="large" onClick={() => removeRollQuantityRow(object.roll)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box 
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ paddingTop: 3 }}>
        <AddButton label='ADD ROW' onClick={addRollQuantityRow}/>
        <TextField 
          type='text' 
          variant='outlined'
          size='small'
          value= {`Total Length: ${totalLength}`}
          inputProps={{ readOnly: true, shrink: true}}
          sx={{backgroundColor: 'alpha.lightest'}}
        />
      </Box>

      <Box display="flex"
        justifyContent="center"
        alignItems="center" sx={{ paddingTop: 4 }}>
        <FlatButton label='SUBMIT' onClick={submitRollQuantities} disableControlsStatus={false}/>
      </Box>
    </React.Fragment>
  )
}

export default FabricRollEntry