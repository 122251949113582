import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, IconButton } from '@mui/material'
import { ScannedBomLabelBarcodeDetails } from '../types'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'
import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone'
import { makeStyles } from "@mui/styles"

interface ScanLabelBarcodeDataProps {
  bomBarcodeDetailsList: ScannedBomLabelBarcodeDetails[]
  handleQuantityDecrement: (labelBarcode: string)=>void
}

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  tableRow: {
    height: 30
  },
  tableCell: {
    padding: "0px 16px"
  }
})

function ScanLabelBarcodeData(props: ScanLabelBarcodeDataProps) {
  const { bomBarcodeDetailsList, handleQuantityDecrement } = props

  const classes = useStyles()
  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData = bomBarcodeDetailsList.map((object, Index)=>{
      return {
        SlNo: Index + 1, 
        ocNumber: object.ocNumber, 
        buyerPo: object.poNumber,
        colour: object.colourCode,
        country: object.shipTo, 
        size: object.size, 
        quantity: object.quantity,
        orderQuantity: object.orderQuantity,
        totalScannedQuantity: object.scannedQuantity,
        labelBarcode: object.labelBarcode,
      }
    })
    
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'scannedLabels')
    XLSX.writeFile(workBook, 'scannedLabels.xlsx')
  }

  const rowTotalRender = ()=>{

    const totalQuantity = bomBarcodeDetailsList.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)

    return (
      <TableRow key={'rowTotal'} >
        <TableCell align='center' sx={{fontWeight: 'bold'}}>TOTAL</TableCell>
        <TableCell align='center' ></TableCell>
        <TableCell align='center'></TableCell>
        <TableCell align='center' ></TableCell>
        <TableCell align='center' ></TableCell>
        <TableCell align='center' ></TableCell>
        <TableCell align='center' sx={{fontWeight: 'bold'}}>{totalQuantity}</TableCell> 
        <TableCell align='center' ></TableCell> 
        <TableCell align='center' ></TableCell> 
        <TableCell align='center' ></TableCell> 
      </TableRow>
    )
  }

  return (
    <Box sx={{paddingTop: 2}}>
      <TableContainer component={Paper}>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            bgcolor: 'primary.card',
            paddingTop: 2,
            paddingRight: 2,
            paddingBottom: 1
          }}
        >
          <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
        </Box>
    
        <Table className={classes.table}> 
          <TableHead> 
            <TableRow sx={{ backgroundColor: 'alpha.lightest'}}>
              <TableCell align='center' sx={{ fontWeight: 'bold', borderBottom: "none", paddingTop: 0.8, paddingBottom: 0 }}>SL NO.</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>OC NUMBER</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>BUYER PO</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>COLOUR</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>COUNTRY</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>SIZE</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>QUANTITY</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>ORDER QUANTITY</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>TOTAL SCANNED QUANTITY</TableCell>
              <TableCell align='center' sx={{ fontWeight: 'bold' }}>LABEL BARCODE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              bomBarcodeDetailsList.map((barcodeData, Index) =>{
                return (
                  <TableRow className={classes.tableRow} key={Index}>
                    <TableCell className={classes.tableCell} align='center' >{Index + 1}</TableCell>
                    <TableCell className={classes.tableCell} align='center' >{barcodeData.ocNumber}</TableCell>
                    <TableCell className={classes.tableCell} align='center'>{barcodeData.poNumber}</TableCell>
                    <TableCell className={classes.tableCell} align='center' >{barcodeData.colourCode}</TableCell>
                    <TableCell className={classes.tableCell} align='center' >{barcodeData.shipTo}</TableCell>
                    <TableCell className={classes.tableCell} align='center' >{barcodeData.size}</TableCell>
                    <TableCell className={classes.tableCell} align='center' >
                      <Box justifyContent="center" 
                        alignItems="center"
                        sx={{
                          padding: 2,
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 2
                        }}>
                        {barcodeData.quantity}
                        <IconButton aria-label="delete" size="small" onClick={()=>{ handleQuantityDecrement(barcodeData.labelBarcode)}}>
                          <RemoveCircleOutlineTwoToneIcon />
                        </IconButton>
                      </Box>
                    </TableCell> 
                    <TableCell className={classes.tableCell} align='center' >{barcodeData.orderQuantity}</TableCell> 
                    <TableCell className={classes.tableCell} align='center' >{barcodeData.scannedQuantity}</TableCell> 
                    <TableCell className={classes.tableCell}align='center' >{barcodeData.labelBarcode}</TableCell> 
                  </TableRow>
                )
              })
            }
            {rowTotalRender()}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ScanLabelBarcodeData
