import React from 'react'
import { Card, CardActionArea, CardContent, Typography } from '@mui/material'

interface FabricCardProps {
  poNumber: string,
  itemCode: string,
  ocNumber: string,
  itemDesc: string,
  onClick: (itemCode: string, index: number) => void,
  isSelected: boolean,
  index: number
}

const FabricCard = (props: FabricCardProps) => {
  return <Card elevation={1} sx={props.isSelected === true
    ? {
      cursor: 'pointer',
      bgcolor: 'primary.card',
      border: '2px solid #20518C'
    }
    : {
      cursor: 'pointer',
      bgcolor: 'primary.card'
    }}>
    <CardActionArea onClick={(e) => props.onClick(props.itemCode, props.index)}>
      <CardContent>
        <Typography>
          PO - {props.poNumber}
        </Typography>
        <Typography>
          OC - {props.ocNumber}
        </Typography>
        <Typography>
          Item Code - {props.itemCode}
        </Typography>
        <Typography>
          Item Description - {props.itemDesc}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card >
}

export default FabricCard