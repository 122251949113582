import React, { useState, useEffect } from 'react'
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography, Stack } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { PalletDataInfo } from '../types'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'
interface PalletsConsolidateDataProps {
  palletsConsolidateResults: PalletDataInfo[]
}

interface PalletsSortedObject {
  key: string,
  value: PalletDataInfo[]
}

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial'
  },
  customTableCell: {
    padding: '5px',
  },
})

function PalletsConsolidateData(props: PalletsConsolidateDataProps) {
  const { palletsConsolidateResults } = props
  const [palletArrowStatus, setPalletArrowStatus] = useState(false)
  const [piecesArrowStatus, setPiecesArrowStatus] = useState(false)
  const [palletConsolidateData, setPalletConsolidateData] = useState<Array<PalletsSortedObject>>([])

  const classes = useStyles()

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const groupedDataByArticleNumber =  groupBy(palletsConsolidateResults, i => i.articleNumber)


  const sortDataByNumberOfPallets = () => {
    if (palletArrowStatus === true){
      setPalletArrowStatus(false)
      setPalletConsolidateData(
        Object.keys(groupedDataByArticleNumber)
          .map(function(k) { return { key: k, value: groupedDataByArticleNumber[k] } })
          .sort(function(a, b) { return a.value.length - b.value.length })
      )

    }
    if (palletArrowStatus === false){
      setPalletArrowStatus(true)
      setPalletConsolidateData( 
        Object.keys(groupedDataByArticleNumber)
          .map(function(k) { return { key: k, value: groupedDataByArticleNumber[k] } })
          .sort(function(a, b) { return b.value.length - a.value.length })
      )
    }
  }

  useEffect(()=>{
    sortDataByNumberOfPallets()
    setPiecesArrowStatus(true)
  }, [])

  const calculateTotalNumberOfPieces = (data: PalletDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.numberOfPieces
    }, 0)
  }

  const sortDataByNumberOfPieces = () => {
    if (piecesArrowStatus === true){
      setPiecesArrowStatus(false)
      setPalletConsolidateData(
        Object.keys(groupedDataByArticleNumber)
          .map(function(k) { return { key: k, value: groupedDataByArticleNumber[k] } })
          .sort(function(a, b) { 
            const aValue = a.value.reduce((acc, curr) => {
              return acc + curr.numberOfPieces
            }, 0)

            const bValue = b.value.reduce((acc, curr) => {
              return acc + curr.numberOfPieces
            }, 0)
            return aValue - bValue
          
          })
      )

    }
    if (piecesArrowStatus === false){
      setPiecesArrowStatus(true)
      setPalletConsolidateData( 
        Object.keys(groupedDataByArticleNumber)
          .map(function(k) { return { key: k, value: groupedDataByArticleNumber[k] } })
          .sort(function(a, b) { 
            const aValue = a.value.reduce((acc, curr) => {
              return acc + curr.numberOfPieces
            }, 0)

            const bValue: number = b.value.reduce((acc, curr) => {
              return acc + curr.numberOfPieces
            }, 0)
            
            return bValue - aValue
          })
      )
    }
  }


  const totalNumberOfPieces = (data: PalletDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.numberOfPieces
    }, 0)
  }

  const convertDateTimeToLocalString = (date: string | null) => {
    if (date === null || date === undefined)
    {
      return null
    }
    return new Date(date as string)
      .toLocaleTimeString('en-GB',
        {timeZone: 'UTC', hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}
      ).replace(',', '')
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    
    const jsonData = palletsConsolidateResults.map((object)=>{
      const { articleNumber, numberOfPieces, dateOfManufacture, rackBarcode, rackAssociationDateTime, rackDissociationDateTime, warehouseEntryDateTime} = object
      return {
        articleNumber,
        rackBarcode,
        numberOfPieces,
        dateOfManufacture,
        warehouseEntryDateTime: convertDateTimeToLocalString(warehouseEntryDateTime),
        rackAssociationDateTime: convertDateTimeToLocalString(rackAssociationDateTime),
        rackDissociationDateTime: convertDateTimeToLocalString(rackDissociationDateTime),
      }
    })
    
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'palletConsolidateData')
    XLSX.writeFile(workBook, 'palletConsolidate.xlsx')
  }

  return (
    <React.Fragment>

      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          bgcolor: 'primary.card',
          paddingTop: 1
        }}
      >
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>

      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}
      >

        
        <Typography variant='subtitle1'>
        PALLET INVENTORY SUMMARY
        </Typography>

        <Stack direction="row" spacing={3}>
          <Typography variant='subtitle1' fontSize="30px" fontWeight="bold" sx={{background: 'white'}}>
        TOTAL PIECES IN STOCK: {totalNumberOfPieces(palletsConsolidateResults)}
          </Typography>

          <Typography variant='subtitle1' fontSize="30px" fontWeight="bold" sx={{background: 'white'}}>
        TOTAL PALLETS IN STOCK: {palletsConsolidateResults.length}
          </Typography>
        </Stack>
   
      </Box>
      
      <TableContainer component={Paper} classes={{ root: classes.customTableContainer }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>SERIAL NO.</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>ARTICLE NUMBER</Typography></TableCell>
              <TableCell
                onClick={() => sortDataByNumberOfPallets()}
              ><Typography variant='subtitle1'>
                NO. OF PALLETS {palletArrowStatus === true ? <ArrowUpwardIcon /> : <ArrowDownwardIcon /> }
                </Typography></TableCell>

              <TableCell
                onClick={() => sortDataByNumberOfPieces()}
              ><Typography variant='subtitle1'>
                NO. OF PIECES {piecesArrowStatus === true ? <ArrowUpwardIcon /> : <ArrowDownwardIcon /> }
                </Typography></TableCell>
            </TableRow>
          </TableHead>
            

          <TableBody>
            { 
              palletConsolidateData.map((object, index)=>{
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.customTableCell}>{index + 1}</TableCell>
                    <TableCell className={classes.customTableCell}>{object.key}</TableCell>
                    <TableCell className={classes.customTableCell}>{object.value.length}</TableCell>
                    <TableCell className={classes.customTableCell}>{calculateTotalNumberOfPieces(object.value)}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table> 
      </TableContainer>
    </React.Fragment>
  )
}

export default PalletsConsolidateData