import React from 'react'
import {
  Box, Paper, Stack, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Typography
} from '@mui/material'
import { FlatButton } from '../../UI/Components'
import { DispatchScanningData } from '../types'
import * as XLSX from 'xlsx'

interface DispatchScanningReportProps {
  data: DispatchScanningData[]
}

const DispatchScanningReport = (props: DispatchScanningReportProps) => {
  const { data } = props

  const handleDownloadButtonClick = () => {
    // Group data by buyer name
    const groupedData = data.reduce<Record<string, DispatchScanningData[]>>((acc, dispatchData) => {
      const buyerName = dispatchData.buyer_name
      if (!acc[buyerName]) {
        acc[buyerName] = []
      }
      acc[buyerName].push(dispatchData)
      return acc
    }, {})
  
    // Create a new workbook
    const workBook = XLSX.utils.book_new()
  
    // Iterate over each buyer group to create separate worksheets
    Object.keys(groupedData).forEach(buyerName => {
      const buyerData = groupedData[buyerName]
  
      const jsonData = buyerData.map(dispatchData => {
        // Create a size mapping object dynamically
        const sizeMapping: Record<string, number> = {}
        dispatchData.Size.forEach((sizeDetail: { size: string | number; output_quantity: number }) => {
          const sizeKey = `Size-${typeof sizeDetail.size === 'number' ? sizeDetail.size : sizeDetail.size.toUpperCase()}`
          sizeMapping[sizeKey] = sizeDetail.output_quantity
        })
  
        // Create the row object with fixed properties first
        const row: Record<string, any> = {
          'Batch #': dispatchData.batch_number,
          'OC Number': dispatchData.oc_number,
          'Buyer Name': dispatchData.buyer_name,
          'Style': dispatchData.style,
          'PO Number': dispatchData.PoNumber,
          'Country': dispatchData.country,
          'Color': dispatchData.color,
        }
  
        // Add the size mapping properties
        Object.assign(row, sizeMapping)
  
        // Add the total quantity
        row['Total Quantity'] = dispatchData.Size.reduce(
          (total: any, sizeDetail: { output_quantity: any }) => total + sizeDetail.output_quantity,
          0
        )
  
        return row
      })
  
      // Create a worksheet for the current buyer
      const workSheet = XLSX.utils.json_to_sheet(jsonData)
      XLSX.utils.book_append_sheet(workBook, workSheet, buyerName)
    })
  
    // Write the workbook to a file
    XLSX.writeFile(workBook, 'DispatchScanningReport.xlsx')
  }
  
  
  
  

  return (
    <TableContainer component={Paper}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ bgcolor: 'primary.card' }}
      >
        <Typography variant="h5" padding={2}>Dispatch Report</Typography>
        <FlatButton label="DOWNLOAD" onClick={handleDownloadButtonClick} disableControlsStatus={false} />
      </Box>
      <Box sx={{ width: '100%', bgcolor: 'primary.pane', backgroundColor: 'surface.light' }}>
        {data.map(dispatchData => (
          <Box key={dispatchData.batch_number} sx={{ paddingTop: 2, paddingBottom: 1, marginTop: 2, marginBottom: 2, backgroundColor: 'white' }}>
            <Stack direction="row" justifyContent="space-between" sx={{ paddingLeft: 2, paddingRight: 2, backgroundColor: 'alpha.lightest' }}>
              <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                {`BATCH: ${dispatchData.batch_number}`}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                {`OC NUMBER: ${dispatchData.oc_number}`}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                {`BUYER NAME: ${dispatchData.buyer_name}`}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                {`STYLE: ${dispatchData.style}`}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                {`PO NUMBER: ${dispatchData.PoNumber}`}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                {`COUNTRY: ${dispatchData.country}`}
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                {`COLOUR: ${dispatchData.color}`}
              </Typography>
            </Stack>
            <Box sx={{ paddingTop: 2 }}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: 'white' }}>
                      <TableCell>
                        <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                          SIZES
                        </Typography>
                      </TableCell>
                      {dispatchData.Size.map((sizeDetail, index) => (
                        <TableCell key={index}>
                          <Typography variant="subtitle1">{sizeDetail.size}</Typography>
                        </TableCell>
                      ))}
                      <TableCell>
                        <Typography variant="subtitle1">TOTAL</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" sx={{ color: 'alpha.main', fontWeight: 'bold' }}>
                          QUANTITY
                        </Typography>
                      </TableCell>
                      {dispatchData.Size.map((sizeDetail, index) => (
                        <TableCell key={index}>
                          <Typography variant="subtitle1">{sizeDetail.output_quantity}</Typography>
                        </TableCell>
                      ))}
                      <TableCell>
                        <Typography variant="subtitle1">
                          {dispatchData.Size.reduce((total, sizeDetail) => total + sizeDetail.output_quantity, 0)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        ))}
      </Box>
    </TableContainer>
  )
}

export default DispatchScanningReport
