import React, { useState, useEffect } from 'react'
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Stack } from '@mui/material'
import { SizeDetailsData, LoadDetailsData } from '../types'
import {roundNumberToSignificantDigits} from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface CuttingSummaryTableProps {
  trackerloadDetailsData: LoadDetailsData[]
}

type TableDataType = {
  [x: string]: LoadDetailsData[]
}

function LoadedSummaryTable(props: CuttingSummaryTableProps) {
  const {trackerloadDetailsData} = props
  
  const [sizeList, setSizeList] = useState<string[]>([])
  const [groupedTableData, setGroupedTableData] = useState<TableDataType>({})

  /** Function responsible to group data */
  useEffect(() => {
    const sizeObjectList: SizeDetailsData[] = [...new Set(trackerloadDetailsData.flatMap(({sizeDetails}) => sizeDetails))]
    const sizeList = [...new Set(sizeObjectList.map(sizeObject => sizeObject.size))]
    setSizeList(sizeList)

    const groupedDataresult: TableDataType = groupBy(trackerloadDetailsData, (i) => `${i.itemCode}-${i.buyer_po_number}-${i.buyer_po_country}`)
    setGroupedTableData(groupedDataresult)

  }, [trackerloadDetailsData])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  

  /** Function responsible to create table rows for size wise order quantity */
  const getItemCodeAndSizeWiseLoadedQuantityRow = (data: LoadDetailsData[])=>{
    let rowTotal = 0
    return (
      <TableRow key={'orderQuantity'}>
        <TableCell>{'LOAD QUANTITY'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeOrderQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeOrderQuantity += sizeObject.loadedQuantity
                  rowTotal += sizeObject.loadedQuantity
                }
                return null  
              })
              return null
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeOrderQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }
  

  /** Function responsible to create table rows for size wise DHU pass quantity */
  const getItemCodeAndSizeWiseDHUPassQuantityRow = (data: LoadDetailsData[])=>{
    let rowTotal = 0
    return (
      <TableRow key={'endTablePass'}>
        <TableCell>{'END TABLE PASS'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeEndTablePassQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeEndTablePassQuantity += sizeObject.DHUPassedQuantity
                  rowTotal += sizeObject.DHUPassedQuantity
                }
                return null  
              })
              return null
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeEndTablePassQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }

  /** Function responsible to create table rows for size wise dispatch production quantity */
  const getItemCodeAndSizeWiseDispatchProductionQuantityRow = (data: LoadDetailsData[])=>{
    let rowTotal = 0
    return (
      <TableRow key={'dispatchProduction'}>
        <TableCell>{'DISPATCH PRODUCTION'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeDispatchProductionQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeDispatchProductionQuantity += sizeObject.dispatchQuantity
                  rowTotal += sizeObject.dispatchQuantity
                }
                return null  
              })
              return null
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeDispatchProductionQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }

  /** Function responsible to create table rows for size wise cut to dispatch difference quantity */
  const getItemCodeAndSizeWiseLoadToDispatchRow = (data: LoadDetailsData[])=>{
    let rowTotalDifference = 0
    return (
      <TableRow key={'loadToDispatchDiff'}>
        <TableCell>{'WIP(load and dispatch diffrence)'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeCutQuantity = 0
            let sizeDispatchQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeCutQuantity += sizeObject.loadedQuantity
                  sizeDispatchQuantity += sizeObject.dispatchQuantity
                  rowTotalDifference += (sizeDispatchQuantity - sizeCutQuantity)
                }
                return null  
              })
              return null
            })
            return (<TableCell sx={sizeDispatchQuantity - sizeCutQuantity < 0 ? {color: 'font.red'} : null}>{roundNumberToSignificantDigits((sizeDispatchQuantity - sizeCutQuantity), 0)}</TableCell>)
          })
        }
        <TableCell sx={rowTotalDifference < 0 ? {color: 'font.red'} : null}>{roundNumberToSignificantDigits(rowTotalDifference, 0)}</TableCell>
      </TableRow>
    )
  }


  /** Function responsible to create table rows for size wise order quantity to download */
  const getItemCodeAndSizeWiseLoadQuantityRowToDownload = (data: LoadDetailsData[]) => {
    let rowTotal = 0
  
    const pushObject: any = {
      size: 'LoadQuantity'
    }
  
    sizeList.map((size) => {
      let sizeOrderQuantity = 0
      data.map((object) => {
        const { sizeDetails } = object
        sizeDetails.map((sizeObject) => {
          if (sizeObject.size === size) {
            sizeOrderQuantity += sizeObject.loadedQuantity
            rowTotal += sizeObject.loadedQuantity
          }
          return null
        })
        return null
      })
  
      pushObject[`size-${size}`] = roundNumberToSignificantDigits(sizeOrderQuantity, 0)
      return null
    })
    pushObject['total'] = roundNumberToSignificantDigits(rowTotal, 0)
    return pushObject
  }
  
  /** Function responsible to create table rows for size wise DHU pass quantity to download in excel */
  const getItemCodeAndSizeWiseDHUPassQuantityRowToDownload = (data: LoadDetailsData[]) => {
    let rowTotal = 0
  
    const pushObject: any = {
      size: 'EndTablePass'
    }
  
    sizeList.map((size) => {
      let sizeEndTablePassQuantity = 0
      data.map((object) => {
        const { sizeDetails } = object
        sizeDetails.map((sizeObject) => {
          if (sizeObject.size === size) {
            sizeEndTablePassQuantity += sizeObject.DHUPassedQuantity
            rowTotal += sizeObject.DHUPassedQuantity
          }
          return null
        })
        return null
      })
      pushObject[`size-${size}`] = roundNumberToSignificantDigits(sizeEndTablePassQuantity, 0)
      return null
    })
  
    pushObject['total'] = roundNumberToSignificantDigits(rowTotal, 0)
    return pushObject
  }
  
  /** Function responsible to create table rows for size wise dispatch production quantity to download in excel */
  const getItemCodeAndSizeWiseDispatchProductionQuantityRowToDownload = (data: LoadDetailsData[]) => {
    let rowTotal = 0
  
    const pushObject: any = {
      size: 'DispatchProduction'
    }
  
    sizeList.map((size) => {
      let sizeDispatchProductionQuantity = 0
      data.map((object) => {
        const { sizeDetails } = object
        sizeDetails.map((sizeObject) => {
          if (sizeObject.size === size) {
            sizeDispatchProductionQuantity += sizeObject.dispatchQuantity
            rowTotal += sizeObject.dispatchQuantity
          }
          return null
        })
        return null
      })
      pushObject[`size-${size}`] = roundNumberToSignificantDigits(sizeDispatchProductionQuantity, 0)
      return null
    })
  
    pushObject['total'] = roundNumberToSignificantDigits(rowTotal, 0)
    return pushObject
  }
  
  /** Function responsible to create table rows for size wise cut to dispatch difference quantity to download in excel */
  const getItemCodeAndSizeWiseLoadToDispatchRowToDownload = (data: LoadDetailsData[]) => {
    let rowTotalDifference = 0
  
    const pushObject: any = {
      size: 'LoadToDispatchDiff'
    }
  
    sizeList.map((size) => {
      let sizeLoadQuantity = 0
      let sizeDispatchQuantity = 0
      data.map((object) => {
        const { sizeDetails } = object
        sizeDetails.map((sizeObject) => {
          if (sizeObject.size === size) {
            sizeLoadQuantity += sizeObject.loadedQuantity
            sizeDispatchQuantity += sizeObject.dispatchQuantity
            rowTotalDifference += (sizeDispatchQuantity - sizeLoadQuantity)
          }
          return null
        })
        return null
      })
  
      pushObject[`size-${size}`] = roundNumberToSignificantDigits((sizeDispatchQuantity - sizeLoadQuantity), 0)
      return null
    })
  
    pushObject['total'] = roundNumberToSignificantDigits(rowTotalDifference, 0)
    return pushObject
  }
  
  /** Handle download button click */
  const handleDownloadButtonClick = () => {
    const workBook = XLSX.utils.book_new()
    let sheetIndex = 1
  
    Object.entries(groupedTableData).map(([key, value]) => {
      const jsonData: any[] = []
  
      // Add a header row with item details
      jsonData.push({
        size: `${value[0].itemCode} - ${value[0].itemDescription} - ${value[0].buyer_po_number} - ${value[0].buyer_po_country}`
      })
  
      jsonData.push(getItemCodeAndSizeWiseLoadQuantityRowToDownload(value))
      jsonData.push(getItemCodeAndSizeWiseDHUPassQuantityRowToDownload(value))
      jsonData.push(getItemCodeAndSizeWiseDispatchProductionQuantityRowToDownload(value))
      jsonData.push(getItemCodeAndSizeWiseLoadToDispatchRowToDownload(value))
  
      const workSheet = XLSX.utils.json_to_sheet(jsonData)
      const sheetName = `${value[0].itemCode}-${sheetIndex}`
      XLSX.utils.book_append_sheet(workBook, workSheet, sheetName)
      sheetIndex += 1
      return null
    })
  
    XLSX.writeFile(workBook, 'loaded-Details.xlsx')
  }
  

  return (
    
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          bgcolor: 'primary.card',
          paddingTop: 1
        }}
      >
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>
      

      {
        Object.entries(groupedTableData).map(([key, value]) =>{
          return (
            <Box sx={{paddingTop: 1}}>
              <Stack direction='row' justifyContent='center'>
                <Typography variant='h6' sx={{color: ''}}>{`${value[0].itemCode} - ${value[0].itemDescription}- ${value[0].buyer_po_number}- ${value[0].buyer_po_country}`}</Typography>
              </Stack>
              
             
              
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: 'alpha.lightest'
                      }}
                    >
                      <TableCell><Typography variant='subtitle1'>SIZES</Typography></TableCell>
                      {
                        sizeList.map((size) => {
                          return (
                            <TableCell><Typography variant='subtitle1'>{size}</Typography></TableCell>
                          )
                        })
                      }
                      <TableCell><Typography variant='subtitle1'>TOTAL</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getItemCodeAndSizeWiseLoadedQuantityRow(value)}
                    {getItemCodeAndSizeWiseDHUPassQuantityRow(value)}
                    {getItemCodeAndSizeWiseDispatchProductionQuantityRow(value)}
                    {getItemCodeAndSizeWiseLoadToDispatchRow(value)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        })
      }
    </React.Fragment>
  )
}

export default LoadedSummaryTable