import React, {useRef} from 'react'
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { styled } from '@mui/system'

interface SearchBarProps {
  value: string,
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>,
  onButtonClick: React.MouseEventHandler<HTMLButtonElement>,
  error: boolean,
  errorMessage: string,
  inputLabel: string,
  width: string,
  marginLeft: number
}

const SearchBar = (props: SearchBarProps) => {
  const { value, onChange, onButtonClick, error, errorMessage, inputLabel, width, marginLeft } = props

  /**
   * Creating this custom style here to make sure the label
   * is properly positioned inside the input field.
   */
  const StyledInputLabel = styled(InputLabel)({
    top: '-7px'
  })

  return (
    <FormControl sx={{
      marginLeft: {marginLeft},
      width: {width}
    }}>
      <StyledInputLabel htmlFor='outlined-adornment-search'> { inputLabel } </StyledInputLabel>
      <OutlinedInput
        error={error}
        id='outlined-adornment-search'
        label='search'
        size='small'
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton aria-label='search button' edge='end' onClick={onButtonClick}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        } />
      {!!error && <FormHelperText error>{errorMessage}</FormHelperText>}
    </FormControl>
  )
}

export default SearchBar