import { apiClient } from "../../Services/Networking/apiClient"
import { PartsbarcodeDetailsType, PostProductLabelType, locationDetailsType, getProductLabelDetailsType, SageQty, QRloading} from "../types"


export const getPartBarcodeDetails = async (partbarcode: string): Promise<PartsbarcodeDetailsType> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/partbarcode/${partbarcode}/detail`
  })
  return response as unknown as PartsbarcodeDetailsType
}

export const postBarcodeLabel = async (batchNumber: string, data: PostProductLabelType): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `/fabstitch/product/label/batchNumber/${batchNumber}`,
    data: data
  })
  return response as unknown as Boolean
}

export const getLocationList = async (): Promise<locationDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/service/locations`
  })
  return response as unknown as locationDetailsType[]
}

export const getBatchesAssociatedWithLocation = async (location: string): Promise<string[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/location/${location}/batches`
  })
  return response as unknown as string[]
}

export const getBuyerPoBasedOnOcNumber = async (ocNumber: string): Promise<string[]> => {
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/product/label/${ocNumber}/buyerPo`
  })
  return response as unknown as string[]
}

export const getBuyerCountryBasedOnPoNumber = async (ocNumber: string, poNumber?: string): Promise<string[]> => {
  // let Data = ocNumber ? {ocNumber} : {PoNumber: poNumber}
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/product/label/${ocNumber}/${poNumber}/countries`
  })
  return response as unknown as string[]
}


export const getProductLabelDetails = async (bundleBarcode: string): Promise<getProductLabelDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/product/bundle/${bundleBarcode}`
  })
  return response as unknown as getProductLabelDetailsType[]
}

export const getQuantitybasedOnSize = async (ocNumber: string, poNumber?: string,  Country?: string,  Color?: string, Size?: String): Promise<SageQty> => {
  // let Data = ocNumber ? {ocNumber} : {PoNumber: poNumber}
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/product/label/${ocNumber}/${poNumber}/${Country}/${Color}/${Size}/Qty`
  })
  return response as unknown as SageQty
}

export const getloadingQR = async (startDate: string, endDate: string) => {
  const response = await apiClient({
    method: 'get',
    url: `fabstitch/loadingqr/${startDate}/${endDate}`,
  })
  return response as unknown as QRloading[]
}



