import react from 'react'
interface CanProps {
  userPermissions: Array<Object>,
  action: Array<String>,
  yes: () => react.ReactElement,
  no: () => react.ReactElement | null,
  isValidateStrict: boolean
}

const check = (userPermissions: Array<Object>, action: Array<String>, isValidateStrict: boolean) => {
  if (!userPermissions || !action) {
    return false
  }
  const actualPermissionsListToMatch = action.filter(permission => userPermissions.includes(permission))
  if (isValidateStrict === true && actualPermissionsListToMatch.length === action.length) {
    return true
  }
  if (isValidateStrict === false && actualPermissionsListToMatch.length > 0) {
    return true
  }

  return false
}

const Can = (props: CanProps) => {
  const { userPermissions, action, yes, no, isValidateStrict } = props
  return check(userPermissions, action, isValidateStrict) ? yes() : no()

}
export default Can
