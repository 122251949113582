import { apiClient } from "../../Services/Networking/apiClient"
import { OverViewData, OverViewTrendData, CuttingOverview, LoadingOverview } from "../types"


export const getOverViewDetails = async (startDate: string, endDate: string, organizationCode: string): Promise<OverViewData[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/sewing/overView`,
    params: { startDate, endDate, organizationCode}
  })
  return response as unknown as OverViewData[]
}

// export const getOverViewTrend = async (): Promise<OverViewTrendData[]> => {
//   const response = await apiClient({
//     method: 'get',
//     url: `/analytics/sewing/overView/trend`
//   })
//   return response as unknown as OverViewTrendData[]
// }


export const getCuttingOverView = async (startDate: string, endDate: string, organizationCode: string): Promise<CuttingOverview[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/sewing/cuttingDetails`,
    params: { startDate, endDate, organizationCode }
  })
  return response as unknown as CuttingOverview[]
}

export const getloadingOverView = async (startDate: string, endDate: string, organizationCode: string): Promise<LoadingOverview[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/sewing/loadingDetails`,
    params: { startDate, endDate, organizationCode }
  })
  return response as unknown as LoadingOverview[]
}
