import { apiClient } from "../../Services/Networking/apiClient"
import { OcDetailsData} from "../types"

export const getOcDetailsFromSage = async (ocNumber: string): Promise<OcDetailsData[]> => {
  const response = await apiClient({
    method: 'get',
    url: `analytics/tracker/ocNumber/${ocNumber}/Details`
  })
  return response as unknown as OcDetailsData[]
}

