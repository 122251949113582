import { apiClient } from "../../Services/Networking/apiClient"
import { barcodePoNumberType } from "../types"

export const getPoNumberDetails = async (po: string): Promise<barcodePoNumberType> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabscan/roll/barcodes/${po}/barcodes`
  })
  // console.log("respone", response)
  return response as unknown as barcodePoNumberType
}

export const postPodelete = async (barcode: string, PO: string): Promise<boolean> => {
  try {
    console.log("es/${barcode}/${PO}", barcode, PO)
    const response = await apiClient({
      method: 'delete',
      url: `/fabscan/roll/barcodes/${barcode}/${PO}`,
      data: {
        barcode, PO
      }
    })

    console.log("Response", response)
    return response.status === 200 // Assuming a successful response returns 200
  } catch (error) {
    console.error("Error deleting item:", error)
    return false // Return false on error
  }
}






