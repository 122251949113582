import React, { useState, useEffect } from 'react'
import {
  Typography, Box, TableContainer, Paper, Table, TableCell, TableHead,
  TableBody, TableRow, FormGroup, FormControlLabel, Checkbox, Switch, Stack
} from '@mui/material'
import { checkboxClasses } from "@mui/material/Checkbox"
import { FlatButton, SubmitDialog } from '../../UI/Components'
import { getProductLabelDetailsType, selectedQrCodeDetailsType } from '../types'
import StringValues from '../../Providers/StringValues'
const ZebraBrowserPrintWrapper = require('zebra-browser-print-wrapper')

interface ProductBarcodeRetreiveProps {
  barcodeDetails: getProductLabelDetailsType[]
  handleSnackBarMessage: (message: string) => void
  bundleBarcode: String
  handleLoading: (loadingStatus: boolean) => void
}

function ProductBarcodeRetreive(props: ProductBarcodeRetreiveProps) {
  const { barcodeDetails, handleSnackBarMessage, bundleBarcode, handleLoading } = props

  // const [selectedBarcodeList, setSelectedBarcodeList] = useState<SelectedBarcodeList[]>([])
  const [switchDense, setSwitchDense] = useState(false)
  const [productLabelDetailsResults, setProductLabelDetailsResults] = useState<getProductLabelDetailsType>()
  const [selectedQrCodeDetails, setSelectedQrCodeDetails] = useState<selectedQrCodeDetailsType[]>([])

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState<string>('')



  useEffect(() => {
    setProductLabelDetailsResults(barcodeDetails[0])

    const selectedQrDetails = barcodeDetails[0].qrDetails.map((obj) => {
      return { ...obj, isSelected: false }
    })

    setSelectedQrCodeDetails(selectedQrDetails)

  }, [barcodeDetails])


  const rePrintQrCodeDetails = async () => {

    const browserPrint = new ZebraBrowserPrintWrapper.default()
    const defaultPrinter = await browserPrint.getAvailablePrinters()
    if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0) {
      handleSnackBarMessage(StringValues.zebraPrinterNotFoundMessage)
      return
    }

    setDialogMessage(StringValues.productLabelReprintConfirmationMessage)
    setDialogOpen(true)



  }

  /**Function responsible to handle confirmation dialog on close
   * @param {boolean} value The dialog confirmation status
   */
  const handleSubmitDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true) {
      printBarcode()
    }
  }

  /**This function is responsible to generate QR code labels for product
   */
  const printBarcode = async () => {
    const { customerName } = barcodeDetails[0]
    const buyerNameInUppercase = customerName.toUpperCase()

    if (buyerNameInUppercase.includes('OLD') && buyerNameInUppercase.includes('NAVY')) {
      barcodePrintForOldNavy()
    } else {
      printQrCode()
    }


  }

  /**This function is responsible to generate QR code labels for Old Navy buyer
   */
  const barcodePrintForOldNavy = async () => {
    handleLoading(true)
    const { style, productSeasonCode, colourDescription, batchNumber, size } = barcodeDetails[0]

    try {
      const browserPrint = new ZebraBrowserPrintWrapper.default()
      const defaultPrinter = await browserPrint.getAvailablePrinters()
      if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0) {
        handleLoading(false)
        handleSnackBarMessage(StringValues.zebraPrinterNotFoundMessage)
        return
      }

      const selectQrCodeList: selectedQrCodeDetailsType[] = []

      selectedQrCodeDetails.map((obj) => {
        if (obj.isSelected === true) {
          selectQrCodeList.push(obj)
        }
        return null
      })

      browserPrint.setPrinter(defaultPrinter[0])
      const printerStatus = await browserPrint.checkPrinterStatus()

      if (printerStatus.isReadyToPrint) {
        for (var i = 0; i <= selectQrCodeList.length; i++) {
          (function (i) {
            setTimeout(() => {
              browserPrint.print(`^XA
              ^MMT
              ^PW160
              ^LL559
              ^LS0
              ^FT50,525^BQN,2,5
              ^FDLA,${selectQrCodeList[i].qrCode}^FS
              ^FT67,398^A0B,23,23^FH\^CI28^FD${style}-${productSeasonCode}^FS^CI27
              ^FT96,398^A0B,18,18^CI28^FD${colourDescription}^FS^CI27
              ^FT125,398^A0B,23,23^FH\^CI28^FDLine ${batchNumber}^FS^CI27
              ^FT154,398^A0B,18,18^FH\^CI28^FD${selectQrCodeList[i].qrCode} / ${bundleBarcode}^FS^CI27
              ^FT80,0^GB40,0,3^FS
              ^FT125,310^A0B,23,23^FH\^CI28^FD${size}(${selectQrCodeList[i].serialNumber})^FS^CI27
              ^FT140,190^A0B,23,5^FH\^CI28^FD^GB0,10,8,B^FS^CI27
              ^PQ1,0,1,Y
              ^XZ`)

            }, 100 * i)

          })(i)
        }

        handleLoading(false)
      } else {
        handleLoading(false)
        handleSnackBarMessage(`Printer Error: ${printerStatus.errors}`)
        return
      }
    } catch (error) {
      handleLoading(false)
      handleSnackBarMessage(`${error}`)
      return

    }
  }

  const printQrCode = async () => {
    handleLoading(true)
    const { ocNumber, productSeasonCode, customerName, style, fitType, colourDescription, size, cutNumber, batchNumber } = barcodeDetails[0]

    try {
      const browserPrint = new ZebraBrowserPrintWrapper.default()
      const defaultPrinter = await browserPrint.getAvailablePrinters()
      if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0) {
        handleLoading(false)
        handleSnackBarMessage(StringValues.zebraPrinterNotFoundMessage)
        return
      }

      const selectQrCodeList: selectedQrCodeDetailsType[] = []

      selectedQrCodeDetails.map((obj) => {
        if (obj.isSelected === true) {
          selectQrCodeList.push(obj)
        }
        return null
      })

      browserPrint.setPrinter(defaultPrinter[0])
      const printerStatus = await browserPrint.checkPrinterStatus()

      if (printerStatus.isReadyToPrint) {
        for (var i = 0; i <= selectQrCodeList.length; i++) {
          (function (i) {
            // setTimeout(() => {
            //   browserPrint.print(`^XA
            // ^MMT
            // ^PW203
            // ^LL559
            // ^LS0
            // ^FT50,525^BQN,2,5
            // ^FDLA,${selectQrCodeList[i].qrCode}^FS
            // ^FT67,398^A0B,17,18^FH\^CI28^FD${customerName}^FS^CI27
            // ^FT86,398^A0B,17,18^CI28^FDCUT NO / BUNDLE NO: ${cutNumber} / ${bundleBarcode}^FS^CI27
            // ^FT105,398^A0B,17,18^FH\^CI28^FDFIT: ${fitType}^FS^CI27
            // ^FT124,398^A0B,17,18^FH\^CI28^FD${colourDescription}^FS^CI27
            // ^FT144,398^A0B,17,18^FH\^CI28^FDSIZE: ${size}^FS^CI27
            // ^FT169,398^A0B,23,23^FH\^CI28^FDSL NO: ${selectQrCodeList[i].serialNumber} / ${selectQrCodeList[i].qrCode}^FS^CI27
            // ^FT47,398^A0B,17,18^FH\^CI28^FDOC / STYLE: ${ocNumber}/${style}^FS^CI27
            // ^FO23,0^GB159,0,8^FS
            // ^FT144,281^A0B,17,18^FH\^CI28^FDBATCH:${batchNumber}^FS^CI27
            // ^PQ1,0,1,Y
            // ^XZ`)

            // }, 100 * i)
            // ---------------

            setTimeout(() => {
              browserPrint.print(`^XA
            ^MMT
            ^PW160
            ^LL360
            ^LS0
            ^FT40,230^A0B,17,18^FH\^CI28^FD${style}-${productSeasonCode}^FS^CI27
            ^FT61,230^A0B,17,18^FH\^CI28^FD${colourDescription}^FS^CI27
            ^FT126,230^A0B,17,18^FH\^CI28^FD${bundleBarcode}^FS^CI27
            ^FT104,230^A0B,23,23^FH\^CI28^FD${size} (${selectQrCodeList[i].serialNumber})^FS^CI27
            ^FT82,230^A0B,17,18^FH\^CI28^FDLINE ${batchNumber}^FS^CI27

            ^FO27,225^BQN,2,4.5^FDMA,${selectQrCodeList[i].qrCode}^FS
            ^FO49,359^GB54,0,1^FS
            ^PQ1,0,1,Y
            ^XZ`)

            }, 100 * i)

            


          })(i)
        }

        handleLoading(false)
      } else {
        handleLoading(false)
        handleSnackBarMessage(`Printer Error: ${printerStatus.errors}`)
        return
      }
    } catch (error) {
      handleLoading(false)
      handleSnackBarMessage(`${error}`)
      return

    }
  }

  const selectOrDeselectDataRows = (status: boolean) => {

    const barcodeList = selectedQrCodeDetails.map((barcodeObject) => {
      return { ...barcodeObject, isSelected: status }
    })
    setSelectedQrCodeDetails(barcodeList)
  }

  const handleChangeSwitchDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    const denseStatus = event.target.checked
    setSwitchDense(denseStatus)
    selectOrDeselectDataRows(denseStatus)
  }

  const barcodeCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const qrCode = event.target.name
    const checkedStatus = event.target.checked

    const qrDetailList = selectedQrCodeDetails.map((qrObject) => {

      if (qrCode === qrObject.qrCode) {
        return { ...qrObject, isSelected: checkedStatus }
      }
      return { ...qrObject }
    })

    setSelectedQrCodeDetails(qrDetailList)
  }

  return (
    <React.Fragment>

      <SubmitDialog
        onClose={handleSubmitDialogClose}
        open={dialogOpen}
        submitStatus={false}
        dialogMessage={dialogMessage} />


      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Stack direction={'row'} justifyContent='space-between'>
          <FormControlLabel
            control={<Switch checked={switchDense} onChange={handleChangeSwitchDense} />}
            label="SELECT ALL / DESELECT ALL"
          />

          <FlatButton label='RE-PRINT' onClick={rePrintQrCodeDetails} disableControlsStatus={false} />
        </Stack>
      </Box>

      <Box sx={{
        paddingTop: 2
      }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>QR CODE NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>SERIAL NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>OC NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>PRODUCT</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>BUYER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>CUT/LAY NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>BUYER PO NUMBER</Typography></TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                selectedQrCodeDetails.map((barcodeObject) => {
                  const { qrCode, serialNumber, isSelected } = barcodeObject
                  return (
                    <TableRow key={qrCode} sx={{ backgroundColor: isSelected === true ? 'alpha.light' : null }}>
                      <TableCell sx={{ color: isSelected === true ? 'white' : null }}>
                        <FormGroup sx={{ paddingLeft: 2 }}>
                          <FormControlLabel control={<Checkbox sx={{
                            [`&, &.${checkboxClasses.checked}`]: {
                              color: isSelected === true ? 'white' : null,
                            },
                          }} checked={isSelected} onChange={barcodeCheckBoxChange} name={`${qrCode}`} />} label={qrCode} />
                        </FormGroup>
                      </TableCell>
                      <TableCell sx={{ color: isSelected === true ? 'white' : null }}>{serialNumber}</TableCell>
                      <TableCell sx={{ color: isSelected === true ? 'white' : null }}>{productLabelDetailsResults?.ocNumber}</TableCell>
                      <TableCell sx={{ color: isSelected === true ? 'white' : null }}>{productLabelDetailsResults?.productCode}</TableCell>
                      <TableCell sx={{ color: isSelected === true ? 'white' : null }}>{productLabelDetailsResults?.customerName}</TableCell>
                      <TableCell sx={{ color: isSelected === true ? 'white' : null }}>{productLabelDetailsResults?.cutNumber}</TableCell>
                      <TableCell sx={{ color: isSelected === true ? 'white' : null }}>{productLabelDetailsResults?.buyerPoNumber}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>



    </React.Fragment>
  )
}

export default ProductBarcodeRetreive