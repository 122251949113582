import { apiClient } from "../../Services/Networking/apiClient"
import { BarcodePoItems, BarcodePoQuantity, BarcodePoDetails, PostPoDetails, GeneratedBarcodeDetails } from "../types"

export const validatePoNumber = async (poNumber: string): Promise<boolean> => {
  const response = await apiClient({
    method: 'get',
    url: `fabscan/roll/barcodes/${poNumber}/validate`
  })
  return response as unknown as boolean
}

export const getBarcodePoItemsBasedOnPoNumber = async (poNumber: string): Promise<BarcodePoItems[]> => {
  const response = await apiClient({
    method: 'get',
    url: `fabscan/roll/barcodes/${poNumber}/items`
  })
  return response as unknown as BarcodePoItems[]
}

export const getBarcodePoQuantityBasedOnPoAndItem = async (poNumber: string, itemCode: string): Promise<BarcodePoQuantity> => {
  const response = await apiClient({
    method: 'get',
    url: `fabscan/roll/barcodes/${poNumber}/quantity`,
    params: { ItemNo: itemCode }
  })
  return response as unknown as BarcodePoQuantity
}

export const getBarcodePoDetailsBasedOnPoAndItem = async (poNumber: string, itemCode: string): Promise<BarcodePoDetails> => {
  const response = await apiClient({
    method: 'get',
    url: `fabscan/roll/barcodes/${poNumber}/details`,
    params: { itemNo: itemCode }
  })
  return response as unknown as BarcodePoDetails
}

export const postBarcodePoDetails = async (poNumber: string, poRollDetails: PostPoDetails): Promise<boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `fabscan/roll/barcodes/${poNumber}/details`,
    data: poRollDetails
  })
  return response as unknown as boolean
}

export const getGeneratedBarcodeDetailsBasedOnPoAndItem = async (poNumber: string, itemCode: string): Promise<GeneratedBarcodeDetails> => {
  const response = await apiClient({
    method: 'get',
    url: `fabscan/roll/barcodes/${poNumber}/generated/details`,
    params: { itemNo: itemCode }
  })
  return response as unknown as GeneratedBarcodeDetails
}