import { apiClient } from "../../Services/Networking/apiClient"
import { BomLabelBarcodeDetails, locationDetailsType, BomDetailsType, ScannedBomLabelBarcodeDetails, barcodeDetailsSubmitType, bomProductListDetailsType, bomPoDetailstListType} from "../types"


export const getLabelBarcodeDetails = async (location: string, labelBarcode: string, ocNumber: string, productCode: string, poNumber: string, country: string, colourCode: string): Promise<BomLabelBarcodeDetails[]> => {
  const queryData = {
    location, labelBarcode, ocNumber, productCode, poNumber, country, colourCode
  }
  const response = await apiClient({
    method: 'get',
    url: `/bom/product/label/labelBarcode/details`,
    params: queryData
  })
  return response as unknown as BomLabelBarcodeDetails[]
}

export const getLocationList = async (): Promise<locationDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/service/locations`
  })
  return response as unknown as locationDetailsType[]
}

export const postScannedbarcodes = async (location: string, data: barcodeDetailsSubmitType[]): Promise<Boolean> => {
  const response = await apiClient({
    method: 'post',
    url: `/bom/product/labelBarcode/location/${location}`,
    data: {labelBarcodeList: data}
  })
  return response as unknown as Boolean
}

export const getBomDataList = async (ocNumber?: string, poNumber?: string): Promise<BomDetailsType[]> => {
  let Data = ocNumber ? {ocNumber} : {buyerPoNumber: poNumber}

  const response = await apiClient({
    method: 'get',
    url: `/analytics/bom/product/details`,
    params: Data
  })
  return response as unknown as BomDetailsType[]
}

export const getScannedBomDataList = async (location: string): Promise<ScannedBomLabelBarcodeDetails[]> => {

  const response = await apiClient({
    method: 'get',
    url: `/analytics/bom/location/${location}/details`
  })
  return response as unknown as ScannedBomLabelBarcodeDetails[]
}

export const getProductDetailsListAssociatedWithOc = async (location:string, ocNumber: string): Promise<bomProductListDetailsType[]> => {

  const response = await apiClient({
    method: 'get',
    url: `bom/ocNumber/${ocNumber}/product`,
    params: {location}
  })
  return response as unknown as bomProductListDetailsType[]
}

export const getPoDetailsListAssociatedWithProductAndOc = async (location:string, ocNumber: string, product: string): Promise<bomPoDetailstListType[]> => {

  const response = await apiClient({
    method: 'get',
    url: `bom/ocNumber/${ocNumber}/product/${product}/podetails`,
    params: {location}
  })
  return response as unknown as bomPoDetailstListType[]
}
