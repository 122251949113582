import React from 'react'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    alpha: Palette['primary']
  }
  interface PaletteOptions {
    alpha: PaletteOptions['primary'] & { lightest: string },
    surface: PaletteOptions['primary'] & { lightest: string },
    chart: {
      bar1: string,
      bar2: string,
      bar3: string,
      bar4: string,
      bar5: string,
      bar6: string,
      bar7: string,
      bar8: string,
      bar9: string,
      bar10: string,
    },
    font:{
      green: string,
      red: string
    }
  }
}

const theme = createTheme({
  palette: {
    background: {
      default: '#FBFBFB'
    },
    alpha: {
      lightest: '#F5FAFF',
      light: '#20518C',
      main: '#0F3460',
      contrastText: '#FFFFFF'
    },
    surface: {
      lightest: '#FFFFFF',
      light: '#FAFAFA',
      main: '#FBFBFB',
      dark: '#F0F0F0'
    },
    chart: {
      bar1: '#2390C6',
      bar2: '#5D48A5',
      bar3: '#6B2E2E',
      bar4: '#1668B9',
      bar5: '#5A5FD9',
      bar6: '#4E806C',
      bar7: '#459CF5',
      bar8: '#884E32',
      bar9: '#94408E',
      bar10: '#ECBE65'
    },
    font: {
      green: '#1C721C',
      red: '#EE4B2B'
    }
  }
})

const StyleProvider = (props: { children: React.ReactChild | React.ReactFragment }) => {

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  )
}

export default StyleProvider