import React from 'react'
import { Card, CardActionArea, CardContent, Typography } from '@mui/material'

interface CuttingCardProps {
  itemCode: string,
  location: string,
  fitType: string,
  itemDesc: string,
  onClick: (itemCode: string, location: string, fitType: string, index: number) => void,
  isSelected: boolean,
  index: number
}

const CuttingCard = (props: CuttingCardProps) => {
  return <Card elevation={1} sx={props.isSelected === true
    ? {
      cursor: 'pointer',
      bgcolor: 'primary.card',
      border: '2px solid #20518C'
    }
    : {
      cursor: 'pointer',
      bgcolor: 'primary.card'
    }}>
    <CardActionArea onClick={(e) => props.onClick(props.itemCode, props.location, props.fitType, props.index)}>
      <CardContent>
        <Typography>
         Item Code - {props.itemCode}
        </Typography>
        <Typography>
         Location - {props.location}
        </Typography>
        <Typography>
         Fit Type - {props.fitType}
        </Typography>
        <Typography>
         Item Description - {props.itemDesc}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card >
}

export default CuttingCard