import React, { useState, useCallback, useEffect } from 'react'
import { Box, Grid, Tabs, Tab, Paper, LinearProgress, TextField, FormControl } from '@mui/material'
import { SearchBar, SubmitDialog } from '../UI/Components'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { getManageOrdersBasedOnOcNumber, getTechPackProductDetailsBasedOnOcNumber, 
  getOrderDetails, postOrderSizeAndQuantities, getPartsDetailsBasedOnOcAndLocation, 
  postPartsDetails, getTechPackBasedOnOcLocationAndProduct, postTechPack, getManagementProgressBasedOnOcAndLocation } from './api/apiFunctions'
import { ManageOrdersDetails, TechPackProductDetails, OrderDetails, sizeQuantities, PartsDetails, ManagementProgress } from './types'
import { TabPanel, ManageOrderDropdownMenu, Orders, Parts, TechPack, ManagementOrderProgress } from './components'
import {FlatButton} from '../UI/Components'
import { AxiosError } from 'axios'
import SnackBarMessage from '../UI/Components/SnackBarMessage'
import StringValues from '../Providers/StringValues'
import globalEnums from '../UI/enum'
import {sortAlphaNumericArrayList} from '../utils'

enum orderInitalValue {
  locationValue = 'SELECT LOCATION',
  itemValue = 'SELECT ITEMCODE',
  fitTypeValue = 'SELECT FIT TYPE',
  productValue = 'SELECT PRODUCT'
}

interface ManageOrdersDashboardProps {
  componentHeader : (headerName : string) => void
}

const ManageOrdersDashboard = (props: ManageOrdersDashboardProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [dataSubmitStatus, setDataSubmitStatus] = useState(false)

  const [tabState, setTabState] = useState(0)
  const [orderTabStatus, setOrderTabStatus] = useState(false)
  const [partsTabStatus, setPartsTabStatus] = useState(false)
  const [techPackTabStatus, setTechPackTabStatus] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState<string>('')

  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [manageOrderDetails, setManageOrderDetails] = useState<ManageOrdersDetails>()
  const [productDetails, setProductDetails] = useState<TechPackProductDetails[]>([])
  const [orderDetails, setOrderDetails] = useState<OrderDetails>()
  const [sizeQuantitiesList, setSizeQuantitiesList] = useState<sizeQuantities[]>([])
  const [partsDetails, setPartsDetails] = useState<PartsDetails>()
  const [partsList, setPartsList] = useState<string[]>([])
  const [managementProgress, setManagementProgress] = useState<ManagementProgress>()

  const [itemList, setItemList] = useState<string[]>([orderInitalValue.itemValue])
  const [fitTypeList, setFitTypeList] = useState<string[]>([orderInitalValue.fitTypeValue])
  const [productList, setProductList] = useState<string[]>([orderInitalValue.productValue])

  const [location, setLocation] = useState<string>('')
  const [itemCode, setItemCode] = useState<string>('')
  const [itemDescription, setItemDescription] = useState<string>('')
  const [fitType, setFitType] = useState<string>('')
  const [approvedConsumption, setApprovedConsumption] = useState<number>(0)
  const [partsStyle, setPartsStyle] = useState<string>('')

  const [techPackProduct, setTechPackProduct] = useState<string>('')
  const [techPackProductDescription, setTechPackProductDescription] = useState<string>('')
  const [techPackBase64, setTechPackBase64] = useState<string>('')

  const [isDataLoading, setIsDataLoading] = useState(false)
  useAuthenticationToken()
  props.componentHeader('Manage Orders')

  /** API call to fetch Order details based on searchTerm(i.e, oc number) 
   * Also API call to fetch techPack details based on searchTerm(i.e, oc number) 
   */
  const fetchData = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setIsError(false)
    setErrorMessage('')
    setDataSubmitStatus(false)
    setItemList([orderInitalValue.itemValue])
    setFitTypeList([orderInitalValue.fitTypeValue])
    setProductList([orderInitalValue.productValue])
    setOrderTabStatus(false)
    setPartsTabStatus(false)
    setTechPackTabStatus(false)
    setItemCode('')
    setItemDescription('')
    setFitType('')
    setApprovedConsumption(0)
    setPartsStyle('')
    setTechPackProduct('')
    setTechPackProductDescription('')

    try {
      const manageOrderDetailResult = await getManageOrdersBasedOnOcNumber(searchTerm)
      const techPackProductDetailResult = await getTechPackProductDetailsBasedOnOcNumber(searchTerm)

      setIsLoading(false)
      setIsDataLoading(false)
      setProductDetails(techPackProductDetailResult)
      setManageOrderDetails(manageOrderDetailResult)
      
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsLoading(false)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [searchTerm])

  /** Create new dropDown list for items, fitTypes and product based on oc number */
  useEffect(() => {
    if (manageOrderDetails !== null && manageOrderDetails !== undefined) {
      const items = manageOrderDetails.items.map((element)=>{
        return element
      })
      items.unshift(orderInitalValue.itemValue)
      setItemList(items)

      const fitTypes = manageOrderDetails.fitTypes.map((element)=>{
        return element
      })
      fitTypes.unshift(orderInitalValue.fitTypeValue)
      setFitTypeList(fitTypes)
    }

    if (productDetails.length > 0) {
      const product = productDetails.map(({ productCode, productDescription }) => {
        return `${productCode} | ${productDescription}`
      })
      product.unshift(orderInitalValue.productValue)
      setProductList(product) 
    }
  }, [manageOrderDetails, productDetails])

  /** Fetch progress details onChange of location and oc number*/
  useEffect(() => {
    const fetchManagementProgress = async () => {
      setIsLoading(true)
      setIsDataLoading(true)
      setIsError(false)
      setErrorMessage('')
      setDataSubmitStatus(false)
      setPartsStyle('')
      try {

        const locationCode = getLocationCodeBasedOnLocationName(location)
        const manageProgressDetailResult = await getManagementProgressBasedOnOcAndLocation(locationCode, searchTerm)

        setIsLoading(false)
        setIsDataLoading(false)
        setManagementProgress(manageProgressDetailResult)
      
      } catch (err) {
        if (err instanceof AxiosError || err instanceof Error) {
          setIsLoading(false)
          setIsDataLoading(false)
        } else {
          throw err
        }
      }
      setIsLoading(false)
    }
    if ((location !== null && location !== undefined && location !== '') && (searchTerm !== null && searchTerm !== undefined && searchTerm !== '' && searchTerm.length === 7)){
      fetchManagementProgress()
    }
  }, [location, searchTerm])

  /** API call to fetch order details based on location, oc number, itemCode and fitType */
  const fetchOrderDetails = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setIsError(false)
    setErrorMessage('')
    setDataSubmitStatus(false)
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      const orderDetailResult = await getOrderDetails(locationCode, searchTerm, itemCode, fitType)
      setOrderTabStatus(true)
      if (Object.keys(orderDetailResult).length === 0){
        const orderDetail : OrderDetails = {
          ocNumber: searchTerm,
          location: locationCode,
          itemCode,
          fitType,
          itemDescription,
          approvedConsumption,
          sizeQuantitiesList: [{size: '', quantity: 0}]
        }
        setOrderDetails(orderDetail)
      } else {
        setOrderDetails(orderDetailResult)
      }
      setIsLoading(false)
      setIsDataLoading(false)
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setOrderTabStatus(false)
        setIsError(true)
        setIsLoading(false)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [searchTerm, location, itemCode, fitType])

  /** API call to post orders */
  const postOrderDetails = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setIsError(false)
    setErrorMessage('')
    setDataSubmitStatus(false)
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      await postOrderSizeAndQuantities(searchTerm, locationCode, itemCode, itemDescription, fitType, approvedConsumption, sizeQuantitiesList)
      const orderDetailResult = await getOrderDetails(locationCode, searchTerm, itemCode, fitType)
      const manageProgressDetailResult = await getManagementProgressBasedOnOcAndLocation(locationCode, searchTerm)

      setIsLoading(false)
      setIsDataLoading(false)
      setOrderDetails(orderDetailResult)
      setSnackBarOpen(true)
      setDataSubmitStatus(true)
      setManagementProgress(manageProgressDetailResult)
      
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsLoading(false)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [searchTerm, location, itemCode, fitType, approvedConsumption, sizeQuantitiesList])

  /**API call to Fetch parts in combination of oc number and location */
  const fetchParts = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setIsError(false)
    setErrorMessage('')
    setDataSubmitStatus(false)
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      const partsDetailsResult = await getPartsDetailsBasedOnOcAndLocation(locationCode, searchTerm)
      setPartsTabStatus(true)
      if (partsDetailsResult.parts.length === 0){
        const partsDetails : PartsDetails = {
          ocNo: searchTerm,
          style: '',
          isPartsBarcodeGenerated: false,
          parts: [{partsUnique: 0, part: '', isSelected: 1}]
        }
        setPartsDetails(partsDetails)
      } else {
        setPartsStyle(partsDetailsResult.style)
        setPartsDetails(partsDetailsResult)
      }
      setIsLoading(false)
      setIsDataLoading(false)

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setPartsTabStatus(false)
        setIsError(true)
        setIsLoading(false)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [location, searchTerm])

  /**API call to post parts */
  const postParts = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setIsError(false)
    setErrorMessage('')
    setDataSubmitStatus(false)
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      await postPartsDetails(searchTerm, locationCode, partsStyle, partsList)
      const partsDetailsResult = await getPartsDetailsBasedOnOcAndLocation(locationCode, searchTerm)
      const manageProgressDetailResult = await getManagementProgressBasedOnOcAndLocation(locationCode, searchTerm)


      setIsLoading(false)
      setIsDataLoading(false)
      setPartsStyle(partsDetailsResult.style)
      setPartsDetails(partsDetailsResult)
      setSnackBarOpen(true)
      setDataSubmitStatus(true)
      setManagementProgress(manageProgressDetailResult)
      
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsLoading(false)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [searchTerm, location, partsStyle, partsList])

  /**API call to fetch techPack based on ocNumber, product and location */
  const getTechPack = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setIsError(false)
    setErrorMessage('')
    setDataSubmitStatus(false)
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      const masterTechPack = await getTechPackBasedOnOcLocationAndProduct(locationCode, searchTerm, techPackProduct)
      setTechPackTabStatus(true)
      setIsLoading(false)
      setIsDataLoading(false)
      setTechPackBase64(masterTechPack)
      
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setTechPackTabStatus(false)
        setSnackBarOpen(true)
        setIsError(true)
        setIsLoading(false)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [searchTerm, location, techPackProduct])

  /**API call to post techPack */
  const postTechPackDetails = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setIsError(false)
    setErrorMessage('')
    setDataSubmitStatus(false)
    try {
      const locationCode = getLocationCodeBasedOnLocationName(location)
      await postTechPack(locationCode, searchTerm, techPackProduct, techPackProductDescription, techPackBase64)
      const masterTechPack = await getTechPackBasedOnOcLocationAndProduct(locationCode, searchTerm, techPackProduct)
      const manageProgressDetailResult = await getManagementProgressBasedOnOcAndLocation(locationCode, searchTerm)
      
      setTechPackBase64(masterTechPack)
      setIsLoading(false)
      setIsDataLoading(false)
      setSnackBarOpen(true)
      setDataSubmitStatus(true)
      setManagementProgress(manageProgressDetailResult)
      
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsLoading(false)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [searchTerm, location, techPackProduct, techPackBase64])
  
  /** Function responsible to return location based on location name 
   * @param {string} locationName The location name
   * @return {string} The location code
   */
  const getLocationCodeBasedOnLocationName = (locationName: string)=>{
    const locationObject = manageOrderDetails?.locations.find(locationObject => locationObject.locationName === locationName)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.location
  }

  /**Handle search bar finder button click */
  const handleSearchBarButtonClick = () => {
    fetchData()
  }

  /**Handle orders continue button click */
  const handleOrdersButtonClick = () => {

    if (searchTerm === '' || searchTerm === null || searchTerm === undefined){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.searchTermValidationMessage)
      return
    }

    if (location === '' || location === null || location === undefined || location === orderInitalValue.locationValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.locationMessage)
      return
    }

    if (itemCode === '' || itemCode === null || itemCode === undefined || itemCode === orderInitalValue.itemValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.itemCodeValidationMessage)
      return
    }
    
    if (fitType === '' || fitType === null || fitType === undefined || fitType === orderInitalValue.fitTypeValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.fitTypeValidationMessage)
      return
    }

    fetchOrderDetails()
  }

  /**Handle search bar change */
  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }
  
  /**This function is responsible to handle dropDown location change
   * @param {string} value The location value
   */
  const handleDropdownMenuChangeForLocation = (value: string) => {
    setLocation(value)
    if (value === orderInitalValue.locationValue){
      setLocation('')
      return
    }
  }

  /**This function is responsible to handle dropDown orders itemCode change
   * @param {string} value The itemCode value
   */
  const handleDropdownMenuChangeForItem = (value: string) => {
    setOrderTabStatus(false)
    setApprovedConsumption(0)
    
    if (value === orderInitalValue.itemValue){
      setItemCode('')
      setItemDescription('')
      return
    }

    const result = value.split('|').map(element => element.trim())
    setItemCode(result[0])
    setItemDescription(result[1])

   
  }

  /**This function is responsible to handle dropDown orders fitType change
   * @param {string} value The fitType value
   */
  const handleDropdownMenuChangeForFitType = (value: string) => {
    setOrderTabStatus(false)
    setApprovedConsumption(0)
    
    if (value === orderInitalValue.fitTypeValue){
      setFitType('')
      return
    }

    setFitType(value)
  }

  /**This function is responsible to handle dropDown techPack product change
   * @param {string} value The product value
   */
  const handleDropdownMenuChangeForProduct = (value: string) => {
    setTechPackTabStatus(false)

    if (value === orderInitalValue.productValue){
      setTechPackProduct('')
      setTechPackProductDescription('')
      return
    }

    const result = value.split('|').map(element => element.trim())
    setTechPackProduct(result[0])
    setTechPackProductDescription(result[1])
  }

  /**This function is responsible to format location list 
   *@return {string[]} The list of location 
   */
  const getLocationList = () => {
    let locations: Array<string> = []
    if (manageOrderDetails !== null && manageOrderDetails !== undefined) {
      locations = manageOrderDetails.locations.map((locationObject)=>{
        const {locationName, locationType} = locationObject

        if (locationType === globalEnums.production){
          return locationName
        }
        return ''
      }).filter((obj) => { return obj !== '' })

    }
    locations = sortAlphaNumericArrayList(locations)
    if (locations[0] !== orderInitalValue.locationValue){
      locations.unshift(orderInitalValue.locationValue)
    }
    return locations
  }

  /**Function to validate entered size quantities
   * @param {sizeQuantities[]} sizeQuantityList The entered size quantity details
   * @return {boolean}
   */
  const isOrderSizeQuantityListValid = (sizeQuantityList: sizeQuantities[]): boolean => {
    return sizeQuantityList.map(sizeQuantityObject => {
      const { size, quantity } = sizeQuantityObject
      if (size === "" || size === null || quantity <= 0 || isNaN(quantity)) {
        return false
      }
      if (isOrderSizeRepeated(size, sizeQuantityList)) {
        return false
      }
      return true
    }).reduce((acc, curr) => {
      return acc && curr
    }, true)
  }

  /**Function to validate entered size
   * @param {string} compareToSize Size to compare
   * @param {sizeQuantities[]} sizeQuantityList The entered size quantity details
   * @return {boolean}
   */
  const isOrderSizeRepeated = (compareToSize: string, sizeListArray: sizeQuantities[]): boolean => {
    let sizeRepeatCount = 0
    sizeListArray.map(sizeQuantityObject => {
      const { size } = sizeQuantityObject
      if (size.toUpperCase() === compareToSize.toUpperCase()) {
        sizeRepeatCount++
      }
      return null
    })
    if (sizeRepeatCount > 1) {
      return true
    }
    return false
  }

  /**Function to validate and post entered order quantities  
   *@param {number} approvedConsumption The approved consumption
   *@param {sizeQuantities[]}orderQuantitiesDetails The entered order size quantity details
   */
  const validateAndPostOrderQuantities = async (approvedConsumption: number, orderQuantitiesDetails : sizeQuantities[]) => {
    if (searchTerm === '' || searchTerm === null || searchTerm === undefined){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.searchTermValidationMessage)
      return
    }

    if (location === '' || location === null || location === undefined || location === orderInitalValue.locationValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.locationMessage)
      return
    }

    if (itemCode === '' || itemCode === null || itemCode === undefined || itemCode === orderInitalValue.itemValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.itemCodeValidationMessage)
      return
    }

    if (fitType === '' || fitType === null || fitType === undefined || fitType === orderInitalValue.fitTypeValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.fitTypeValidationMessage)
      return
    }

    if (!(approvedConsumption > 0)){
      setSnackBarOpen(true)
      setErrorMessage(StringValues.approvedConsumptionMessage)
      return
    }
    
    if (isOrderSizeQuantityListValid(orderQuantitiesDetails) === false) {
      setSnackBarOpen(true)
      setErrorMessage(StringValues.sizeQuantitesMessage)
      return
    }
    setApprovedConsumption(approvedConsumption)
    setSizeQuantitiesList(orderQuantitiesDetails)
    setDialogMessage(StringValues.submitConfirmationMessage)
    setDialogOpen(true)
  }

  /**Handle orders dialog close
   * @param {boolean} value The status of dialog
   */
  const handleOrdersDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true){
      postOrderDetails()
    }
  }

  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  /**Handle parts style change */
  const handlePartsStyleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPartsStyle(event.target.value)
  }


  /**Handle parts continue button click */
  const handlePartsButtonClick = () => {
    
    if (searchTerm === '' || searchTerm === null || searchTerm === undefined){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.searchTermValidationMessage)
      return
    }

    if (location === '' || location === null || location === undefined || location === orderInitalValue.locationValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.locationMessage)
      return
    }

    if (partsStyle === '' || partsStyle === null || partsStyle === undefined || partsStyle.trim().length === 0){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.styleValidationMessage)
      return
    }
    
    fetchParts()
  }

  /**Function to validate and post parts 
   *@param{string[]} parts The list of parts
   */
  const validateAndPostParts = async (parts: string[]) => {
    if (partsStyle === null || partsStyle === undefined || partsStyle.trim().length === 0){
      setSnackBarOpen(true)
      setErrorMessage(StringValues.styleValidationMessage)
      return
    }
    
    if (parts.length === 0) {
      setSnackBarOpen(true)
      setErrorMessage(StringValues.partsValidationMessage)
      return
    }

    let isPartEmpty = false
    parts.map((element) => {
      if (element.trim().length === 0){
        isPartEmpty = true
      }
      return null
    })

    if (isPartEmpty){
      setSnackBarOpen(true)
      setErrorMessage(StringValues.partsValidationMessage)
      return
    }

    setPartsList(parts)
    setDialogMessage(StringValues.partsConfirmationMessage)
    setDialogOpen(true)
  }

  /**Handle parts dialog close
   * @param {boolean} value The status of dialog
   */
  const handlePartsDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true){
      postParts()
    }
  }

  /**Handle techpack continue button click */
  const handleTechPackButtonClick = () => {

    if (searchTerm === '' || searchTerm === null || searchTerm === undefined){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.searchTermValidationMessage)
      return
    }

    if (location === '' || location === null || location === undefined || location === orderInitalValue.locationValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.locationMessage)
      return
    }

    if (techPackProduct === '' || techPackProduct === null || techPackProduct === undefined || techPackProduct === orderInitalValue.productValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.techPackProductValidationMessage)
      return
    }

    getTechPack()
  }

  /**Handle techPack dialog close
   * @param {boolean} value The status of dialog
   */
  const handleTechPackDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true){
      postTechPackDetails()
    }
  }

  /**Function to validate and post techPack */
  const validateAndPostTechPack = async () => {
    if (techPackBase64 === null || techPackBase64 === undefined || techPackBase64 === ''){
      setSnackBarOpen(true)
      setErrorMessage(StringValues.techPackValidationMessage)
      return
    }

    setDialogMessage(StringValues.techPackConfirmationMessage)
    setDialogOpen(true)
  }

  const techpackFileValidationMessage = async () => {
    
    setSnackBarOpen(true)
    setErrorMessage(StringValues.techPackFileValidationMessage)
    return
    
  }

  return (
    <React.Fragment>
      {
        dataSubmitStatus === true ? <SnackBarMessage message = {StringValues.submitSuccessMessage} successStatus = {true} 
          open = {snackBarOpen} onClose={handleSnackBarClose}/> 
          : <SnackBarMessage message = {errorMessage} successStatus = {false} open = {snackBarOpen} onClose={handleSnackBarClose}/>
      }

      <SubmitDialog 
        onClose={tabState === 0  ? handleOrdersDialogClose 
          : tabState === 1 ? handlePartsDialogClose : handleTechPackDialogClose
        } 
        open = {dialogOpen} 
        submitStatus = {false} 
        dialogMessage={dialogMessage} />

      <Box
        sx={{paddingRight: 2}}>
        <SearchBar value={searchTerm} onChange={handleSearchBarChange} 
          onButtonClick={handleSearchBarButtonClick} error={isError} errorMessage={errorMessage} 
          inputLabel = {StringValues.ocNumberLabel} width = {'20%'} marginLeft = {2}/>
        <ManageOrderDropdownMenu onChange={handleDropdownMenuChangeForLocation} menuValues = {getLocationList()}/>  
      </Box>   
      <Grid container sx={{
        height: '100vh'
      }}>
        <Grid item xs={3}
          sx={{
            paddingTop: 2,
            paddingBottom: 2
          }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'surface.dark'
          }}>
            <Paper elevation={8} sx={{
              height: '100%'
            }}>
              <Box sx={{
                bgcolor: 'primary.card'
              }}>
                <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
                  setTabState(newValue)
                }} textColor='secondary' TabIndicatorProps={{
                  style: {
                    backgroundColor: 'alpha.light'
                  }
                }}>
                  <Tab sx={{color: 'alpha.light'}} label="ORDERS" />
                  <Tab sx={{color: 'alpha.light'}} label="PARTS" />
                  <Tab sx={{color: 'alpha.light'}} label="TECH PACK" />
                </Tabs>
              </Box>
         
              <Box sx={{
                paddingTop: 2
              }}>
                <TabPanel value={tabState} index={0}>
                  <Box>
                    <ManageOrderDropdownMenu onChange={handleDropdownMenuChangeForItem} menuValues = {itemList}/>  
                  </Box>
                  <Box sx={{ paddingTop: 2}}>
                    <ManageOrderDropdownMenu onChange={handleDropdownMenuChangeForFitType} menuValues = {fitTypeList}/>  
                  </Box>
                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 3 }}>
                    <FlatButton label='CONTINUE' onClick={handleOrdersButtonClick} disableControlsStatus={false}/>
                  </Box>
                </TabPanel>

                <TabPanel value={tabState} index={1}>
                  <FormControl required>
                    <Box sx={{ marginLeft: 1}}> 
                      <TextField
                        type='string'
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        size="small"
                        value={partsStyle}
                        onChange={handlePartsStyleChange}
                        sx={{width: '80%'}}
                        placeholder = {StringValues.styleLabel}
                      />
                    </Box>
                  </FormControl>

                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 3 }}>
                    <FlatButton label='CONTINUE' onClick={handlePartsButtonClick} disableControlsStatus={false}/>
                  </Box>
                </TabPanel>

                <TabPanel value={tabState} index={2}>
                  <ManageOrderDropdownMenu onChange={handleDropdownMenuChangeForProduct} menuValues = {productList}/>  
                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 3 }}>
                    <FlatButton label='CONTINUE' onClick={handleTechPackButtonClick} disableControlsStatus={false}/>
                  </Box>
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={9} sx={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 2,
          paddingBottom: 2
        }}>
          {
            (isDataLoading) ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '100%' }}
              >
                <LinearProgress sx={{ width: '80%', minWidth: '100px' }} />
              </Box>
            ) : (<Box display={'flex'} flexDirection={'row'} 
              sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'primary.pane',
                backgroundColor: 'surface.dark'
              }}>
            
              <Box sx={{
                paddingLeft: 1,
                paddingRight: 1,
                width: '70%'
              }}>
                {
                  tabState === 0
                    ? orderTabStatus === true ? <Orders orderDetails={orderDetails as OrderDetails} submitOrder={validateAndPostOrderQuantities}></Orders> : null
                    : (tabState === 1 
                      ? partsTabStatus === true ? <Parts partsDetails={partsDetails as PartsDetails} submitParts={validateAndPostParts}></Parts> : null
                      : techPackTabStatus === true ? <TechPack onChange={(file) => setTechPackBase64(file)} imageBase64 = {techPackBase64} submitTechPack = {validateAndPostTechPack} validationMessage = {techpackFileValidationMessage}/> : null) 
                }
              
              </Box >
              
              <Box alignContent={'center'} sx={{backgroundColor: 'surface.lightest', width: '30%', paddingLeft: 1}}>
                <ManagementOrderProgress managementProgressDetails={managementProgress as ManagementProgress}/>  
              </Box>
            </Box>)
          }
          
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default ManageOrdersDashboard