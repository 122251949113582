import React, { useEffect, useState } from 'react'
import { HourlySewingData, SewingEfficiencyDetails } from '../types'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material'
import {roundNumberToSignificantDigits, convertNumberToLocaleString} from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'
interface SewingSingleDateTableProps {
  data: SewingEfficiencyDetails[]
}

type TableDataType = {
  [x: string]: SewingEfficiencyDetails[]
}

type HourlyDataType = {
  [x: string]: HourlySewingData[]
}

const SewingSingleDateTable = (props: SewingSingleDateTableProps) => {
  const { data } = props

  const [tableData, setTableData] = useState<TableDataType>({})
  const [hourlyData, setHourlyData] = useState<HourlyDataType>({})

  useEffect(() => {
    const formatDataForTable = (data: SewingEfficiencyDetails[]) => {
      const result: TableDataType = groupBy(data, (i) => i.batchNumber)
      return result
    }

    const combinedHourlyDetails = data.reduce((acc: HourlySewingData[], value: SewingEfficiencyDetails) => {
      acc.push(...value.hourlyDetails)
      return acc
    }, [])

    const groupDataByStartTime = (data: HourlySewingData[]) => {
      const result: HourlyDataType = groupBy(data, (i) => i.startTime)
      return result
    }

    const formattedData = formatDataForTable(data)
    setTableData(formattedData)

    const hourlyFormattedData = groupDataByStartTime(combinedHourlyDetails)
    setHourlyData(hourlyFormattedData)
  }, [data])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)
    
  const getRowEfficiency = (hourlyDetails: HourlySewingData[]) => {
    const rowSamProduced = hourlyDetails.reduce((acc, curr) => {
      const { sewingSamProduced } = curr
      return acc + sewingSamProduced
    }, 0)
    const rowMachineMinutes = hourlyDetails.reduce((acc, curr) => {
      const { sewingMachineMinutes } = curr
      return acc + sewingMachineMinutes
    }, 0)

    if (rowSamProduced > 0){
      return roundNumberToSignificantDigits(((rowSamProduced / rowMachineMinutes) * 100), 2)
    }
    return 0
  }

  
  const renderTableRows = (tableData: TableDataType) => {
    const keys = Object.keys(tableData)
    return keys.map((key, index) => {
      return tableData[key].map((item, index) => {
        const { batchNumber, hourlyDetails, efficiency, totalOutputQuantity, productCode, productDescription, buyerName, sam } = item
       
        return (
          <TableRow key={`${batchNumber}+${productCode}+${efficiency}+${totalOutputQuantity}+${index}`}>
            <TableCell align='center'>{batchNumber}</TableCell>
            <TableCell align='center'>{productCode}</TableCell>
            <TableCell align='center'>{productDescription}</TableCell>
            <TableCell align='center'>{buyerName}</TableCell>
            <TableCell align='center'>{roundNumberToSignificantDigits(sam, 2)}</TableCell>

            {
              Object.keys(hourlyData).map((key, index) => {
                const filteredObject = hourlyDetails.filter(obj => {
                  return obj.startTime === key
                })
                if (filteredObject.length > 0){
                  return (<TableCell align='center'>{filteredObject[0].outputQuantity}</TableCell>)  
                }
                return (<TableCell align='center'>{0}</TableCell>)  
              })
            }
            <TableCell align='center'>{`${getRowEfficiency(hourlyDetails)}%`}</TableCell>
            <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(totalOutputQuantity, 2))}</TableCell>
          </TableRow>
        )
      })
    })
  }

  const overAllEfficiency = (hourlyData: HourlyDataType) => {
    let totalSamProduced = 0
    let totalMachineMinutes = 0
    
    Object.entries(hourlyData).map(([key, value], index) =>{
      totalSamProduced += value.reduce((acc, curr) => {
        const { sewingSamProduced } = curr
        return acc + sewingSamProduced
      }, 0)

      totalMachineMinutes += value.reduce((acc, curr) => {
        const { sewingMachineMinutes } = curr
        return acc + sewingMachineMinutes
      }, 0)
      return null
    })

    if (totalSamProduced > 0){
      return roundNumberToSignificantDigits(((totalSamProduced / totalMachineMinutes) * 100), 2)
    }
    return 0
  }

  const overAllTotalQuantity = (hourlyData: HourlyDataType) => {
    let totalQuantity = 0
    Object.entries(hourlyData).map(([key, value], index) =>{
      totalQuantity += value.reduce((acc, curr) => {
        const { outputQuantity } = curr
        return acc + outputQuantity
      }, 0)
      return null
    })
    return convertNumberToLocaleString(roundNumberToSignificantDigits(totalQuantity, 2))
  }

  /**Total row download */
  const calculateTotalRowToDownload = ()=>{
    const object:any = {
      batchNumber: 'TOTAL', 
      product: '',
      productName: '',
      buyerName: '',
      machineSam: ''
    }

    
    Object.entries(hourlyData).map(([key, value], index) =>{
      const outputQuantity = value.reduce((acc, curr) => {
        const { outputQuantity } = curr
        return acc + outputQuantity
      }, 0)

      object[`${value[0].startTime}-${value[0].endTime}`] = roundNumberToSignificantDigits(outputQuantity, 2)
      return null
    })
    object['efficiency%'] = overAllEfficiency(hourlyData)
    object['quantity'] = overAllTotalQuantity(hourlyData)
    
    return object
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []

    const keys = Object.keys(tableData)
    keys.map((key, index) => {
      tableData[key].map((item, index) => {
        const { batchNumber, hourlyDetails, totalOutputQuantity, productCode, productDescription, buyerName, sam } = item
       
        const pushObject: any = {
          batchNumber, 
          product: productCode,
          productName: productDescription,
          buyerName,
          machineSam: roundNumberToSignificantDigits(sam, 2)
        }
        
        Object.keys(hourlyData).map((key, index) => {
          
          const filteredObject = hourlyDetails.filter(obj => {
            return obj.startTime === key
          })
          if (filteredObject.length > 0){
            pushObject[`${filteredObject[0].startTime}-${filteredObject[0].endTime}`] = filteredObject[0].outputQuantity  
            return null
          }
          pushObject[key] = 0
          return null
        })
        
        pushObject['efficiency%'] = getRowEfficiency(hourlyDetails)
        pushObject['quantity'] = roundNumberToSignificantDigits(totalOutputQuantity, 2)
        jsonData.push(pushObject)
        return null
      })
      return null
    })
    
    const totalRowResult = calculateTotalRowToDownload()
    jsonData.push(totalRowResult)

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'BatchEfficiency-Single-Date')
    XLSX.writeFile(workBook, 'BatchEfficiency-Single-Date.xlsx')
  }

  return (
    <TableContainer component={Paper}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}
      >
        <Typography variant='h5' padding={2}>Batch Efficiency-Single Date</Typography>
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>
      
      <Table sx={{
        overflowX: 'auto',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        height: 450,
        overflowY: 'auto'
      }}>
        <TableHead>
          <TableRow sx={{
            backgroundColor: 'alpha.lightest'
          }}>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>BATCH</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>PRODUCT</TableCell>


            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>PRODUCT NAME</TableCell>

            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>BUYER NAME</TableCell>

            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>MACHINE SAM</TableCell>
            
            {
              Object.keys(hourlyData).map((key, index) => {
                const { startTime,  endTime} = hourlyData[key][0]
                return <TableCell align='center' sx={{
                  fontWeight: 'bold'
                }}>{`${startTime}-${endTime}`}</TableCell>
              })
            }
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>EFFICIENCY</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>QUANTITY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows(tableData)}
          
          <TableRow key={`final-row`}>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>TOTAL</TableCell>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            {
              Object.entries(hourlyData).map(([key, value], index) =>{
                const outputQuantity = value.reduce((acc, curr) => {
                  const { outputQuantity } = curr
                  return acc + outputQuantity
                }, 0)

                return <TableCell align='center' sx={{
                  fontWeight: 'bold'
                }}>{convertNumberToLocaleString(roundNumberToSignificantDigits(outputQuantity, 2))}</TableCell>

              })
            }

            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>{`${overAllEfficiency(hourlyData)}%`}</TableCell>

            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>{`${overAllTotalQuantity(hourlyData)}`}</TableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SewingSingleDateTable