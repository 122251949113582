
const StringValues = {
  emptyRackMessage: 'Enter Rack to proceed',
  submitSuccessMessage: 'Submitted Successfully',
  approvedConsumptionMessage: 'Please enter approved consumption',
  sizeQuantitesMessage: 'Please check the size quantites you have entered',
  submitConfirmationMessage: 'Are you sure you want to submit these order details?',
  ocNumberLabel: 'Enter OC Number',
  poItemLabel: 'Enter PO Number Or Item Code',
  poOcLabel: 'Enter PO Number Or OC Number',
  articleNumberLabel: 'Enter Article Number',
  unitLabel: 'Enter Unit Number',
  rackLabel: 'Enter Rack Number',
  fabricBarcodeLabel: 'Enter Fabric Barcode',
  itemCodeLabel: 'Enter Item Code',
  styleLabel: 'Enter style',
  styleValidationMessage: 'Enter style and try again!!',
  partsValidationMessage: 'Enter parts and try again!!',
  partsConfirmationMessage: 'Are you sure you want to save these parts details?',
  techPackValidationMessage: 'Select or upload techPack to proceed',
  techPackConfirmationMessage: 'Are you sure you want to upload techPack?',
  techPackFileValidationMessage: 'Upload image file and try again!!',
  poLabel: 'Enter PO Number',
  barcodeConfirmationMessage: 'Are you sure you want to submit these roll details?',
  barcodeRollLengthMessage: 'Please check the roll and length you have entered',
  barcodeRollLengthValidationMessage: 'PO allowed quantity exceeds please check and try again',
  zebraPrinterNotFoundMessage: 'Cannot detect a Zebra printer attached to this system',
  barcodePrintSuccessMessage: 'Submitted Successfully',
  barcodePrintBackConfirmationMessage: 'Please make sure to print the barcodes before leaving this page',
  locationMessage: 'Select location to proceed',
  buyerspoMessage: 'Select po to proceed',
  CountryMessage: 'Select country to proceed',
  locationBatchMessage: 'Select batch to proceed',
  buyerPoBatchMessage: 'Select buyer Po to proceed',
  itemCodeValidationMessage: 'Select item code and try again',
  searchTermValidationMessage: 'Enter search term and try again',
  fitTypeValidationMessage: 'Select fit type and try again',
  techPackProductValidationMessage: 'Select product and try again',
  jobworkSizeQuantityDeleteValidationMessage: 'Colour should have least one size and quantity',
  jobworkColourValidationMessage: 'Colour Code or Colour description cannot be blank',
  jobworkColourDeleteValidationMessage: 'Job work order should have least one colour group',
  jobworkColourDeleteDialogMessage: 'Are you sure you want to remove these colour details along with sizes?',
  jobworkOrdersubmitConfirmationMessage: 'Are you sure you want to submit these job work order details?',
  sewingSamValidationMessage: 'Sewing sam should be greater than zero',
  globalSamValidationMessage: 'Global sam should be greater than zero',
  barcodeRePrintValidateMessage: 'Please select least on barcode to print',
  barcodeSupplierRollValidationMessage: 'Please check for repeated supplier roll numbers!',
  supplierInvoiceNumberValidationMessage: 'Enter supplier invoice number and try again',
  supplierNumber: 'Enter Supplier Number',

  productLabelStickerQuantityValidationMessage: 'Product label total sticker should be greater than zero',
  productStartLabelStickerValidationMessage: 'Product label start sticker number should be greater than zero',
  productEndLabelStickerValidationMessage: 'Product label end sticker number should be greater than zero',
  productLabelStickerValidationMessage: 'start sticker number should be lesser than end sticker number',
  productLabelsubmitConfirmationMessage: (location: string, batch: string, quantity: number) => {
    return `Are you sure you want to submit and print ${quantity} number of QR labels for location ${location} and batch ${batch} !!`
  },
  productLabelReprintConfirmationMessage: `Are you sure you want to re-print labels !!`,
  productScannedBarcodeValidationMessage: 'Enter bundle barcode and try again',
  scanLabelEnterBarcodeLabel: 'Enter Barcode',
  scanLabelBarcodeLabel: 'Scan Barcode',
  bomStatusOcLabelBarcodeLabel: 'Enter OC Number',
  bomStatusBuyerPoLabelBarcodeLabel: 'Enter Buyer PO Number',
  scanLabelBarcodeLabelListValidationMessage: 'Should scan least one barcode to submit',

  bomProductValidationMessage: 'Select Product to proceed',
  bomPOValidationMessage: 'Select PO to proceed',
  bomCountryValidationMessage: 'Select Country to proceed',
  bomColourValidationMessage: 'Select Colour to proceed'
}

export default StringValues