import React, { useState, useEffect } from 'react'
// import { Box, Typography, Stack, TextField, Skeleton, Grid } from '@mui/material'
// import { getProductLabelDetails } from '../api/apiFunctions'
import useAuthenticationToken from '../../Services/Authentication/useAuthenticationToken'
import { getALLRackDetails, getRackDetailsType } from '../types'
// import { getQrCodeDetailsType } from '../types'
import { AxiosError } from 'axios'
import { FlatButton, SnackBarMessage } from '../../UI/Components'
import { roundNumberToSignificantDigits } from '../../utils'
import { default as RackBarcodeRetreive } from './RackBarcodeRetreive'
import StringValues from '../../Providers/StringValues'
import {
  Typography, Box, TableContainer, Paper, Table, TableCell, TableHead,
  TableBody, TableRow, FormGroup, FormControlLabel, Checkbox, Switch, Stack, TextField, Skeleton, Grid
} from '@mui/material'
import { getAllRackDetails, getRackDetailsbyLocation } from '../api/apiFunctions'


const ReprintRackQrLabel = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [rackLocation, setrackBarcodeloc] = useState<string>('')
  const [rackBarcodeDetailsbyLocation, setRackBarcodeDetailsbyLocation] = useState<getRackDetailsType[]>([])
  const [selectedRackBarcodeDetailsbyLocation, setSelectedRackBarcodeDetailsbyLocation] = useState<getRackDetailsType[]>([])
  const [rackAllDetails, setRackAllDetails] = useState<getALLRackDetails[]>([])
  // const [selectedProductLabelDetails, setSelectedProductLabelDetails] = useState<getProductLabelDetailsType[]>([])
  const [startNumbering, setStartNumbering] = useState<number>(0)
  const [endNumbering, setEndNumbering] = useState<number>(0)
  const [dataSubmitStatus, setDataSubmitStatus] = useState(false)

  useAuthenticationToken()

  // --------------

  // postRackBarcodeGenerate


  useEffect(() => {

    handleFetchAllRackDetails()

  }, [])

  const handleFetchAllRackDetails = async () => {
    await new Promise(r => setTimeout(r, 500))
    fetchAllRackDetails()
  }


  /**Handle API call to fetch all rack details */
  const fetchAllRackDetails = async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    setRackAllDetails([])
    try {
      const rackAllDetailsResult = await getAllRackDetails()
      setRackAllDetails(rackAllDetailsResult)
      console.log("rackAllDetailsResult", rackAllDetailsResult[0].rackBarcode)
      console.log("i have accessed the api")
      setIsLoading(false)
      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }

    setIsLoading(false)
  }

  /**This function is responsible to update barcode
     * @param {string}colourDesc The colour description
     * @param {number}indexToChange The index number to update value
     */
  const handlerackBarcodelocChange = (location: string) => {
    setrackBarcodeloc(location)
    console.log("location", location)
  }

  // ----------------

  useEffect(() => {
    // if (rackLocation.length === 10) {
    fetchRackBarcodesByLoc()
    // }
  }, [rackLocation])


  /**This function is responsible to search productlabel barcodes for API result list
   */
  const fetchRackBarcodesByLoc = async () => {

    // setrackBarcodeloc(barcode)
    console.log("Rack barcodes to that particular location")
    if (isLoading) {
      return
    }

    if (rackLocation === undefined || rackLocation === null || rackLocation === '') {
      return
    }
    setRackBarcodeDetailsbyLocation([])
    setSelectedRackBarcodeDetailsbyLocation([])
    setIsLoading(true)
    try {
      const rackDetailsByLocationResult = await getRackDetailsbyLocation(rackLocation)
      setRackBarcodeDetailsbyLocation(rackDetailsByLocationResult)
      console.log("rackDetailsByLocationResult", rackDetailsByLocationResult)

      // if (productLabelDetailsResult.length > 0) {
      //   const { qrDetails } = productLabelDetailsResult[0]
      //   setStartNumbering(qrDetails[0].serialNumber)
      //   setEndNumbering(qrDetails[qrDetails.length - 1].serialNumber)
      // }

      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)


  }
  // --------------





  /**This function is responsible to search productlabel barcodes for API result list
     */
  const filterRackBarcodesByLoc = async () => {

    // setrackBarcodeloc(barcode)
    console.log("Rack barcodes to that search location", rackBarcodeDetailsbyLocation)

    setIsLoading(true)

    rackBarcodeDetailsbyLocation.map((rackDeatilsByLocationList) => {
      if (rackBarcodeDetailsbyLocation) {
        // list.push(qrObject)
        console.log(rackDeatilsByLocationList)
      }
      return null
    })
    // const actualDetails: getRackDetailsType = {...rackBarcodeDetailsbyLocation, rackDeatilsByLocationList: list}

    // setSelectedRackBarcodeDetailsbyLocation([actualDetails])

    console.log("rackBarcodeDetailsbyLocation", rackBarcodeDetailsbyLocation)

    // if (productLabelDetailsResult.length > 0) {
    //   const { qrDetails } = productLabelDetailsResult[0]
    //   setStartNumbering(qrDetails[0].serialNumber)
    //   setEndNumbering(qrDetails[qrDetails.length - 1].serialNumber)
    // }

    setIsError(false)
    setErrorMessage('')

    setIsLoading(false)


  }
  /**This function is responsible to handle snack bar message
   * @param {string}message The message
   */
  const handleSnackBarMessage = (message: string) => {
    setSnackBarOpen(true)
    setErrorMessage(message)
  }

  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setDataSubmitStatus(false)
    setSnackBarOpen(value)
  }

  const handleLoading = (loadingStatus: boolean) => {

    if (loadingStatus) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }

  return (
    <React.Fragment>
      <SnackBarMessage message={errorMessage} successStatus={dataSubmitStatus} open={snackBarOpen} onClose={handleSnackBarClose} />

      <Box sx={{ padding: 2 }}>

        <Box>
          <Stack spacing={3} direction="row">

            <TextField
              type='text'
              placeholder='Enter Location'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  height: "20px",
                  width: "300px"
                },
              }}
              variant="outlined"
              size="small"
              value={rackLocation}
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handlerackBarcodelocChange(event.target.value)}
            />


            <FlatButton label='SEARCH' onClick={fetchRackBarcodesByLoc} disableControlsStatus={false} />


          </Stack>
        </Box>
        <Grid container sx={{
          height: '100vh'
        }}>

          <Grid item xs={12}
            sx={{
              paddingTop: 2,
              paddingLeft: 1,
              paddingRight: 1,
              paddingBottom: 1
            }}>
            {
              (isLoading) ? (
                <Skeleton variant='rectangular' sx={{
                  minWidth: '100%',
                  height: '100%',
                  bgcolor: 'primary.pane'
                }} />
              ) :
                <Box sx={{ paddingTop: 2 }}>
                  {
                    rackBarcodeDetailsbyLocation.length > 0
                      ? <RackBarcodeRetreive rackBarcodeDetails={rackBarcodeDetailsbyLocation} handleSnackBarMessage={handleSnackBarMessage}
                        rackBarcodeLocation={rackLocation} handleLoading={handleLoading} />
                      : <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow
                              sx={{
                                backgroundColor: 'alpha.lightest'
                              }}
                            >
                              <TableCell><Typography variant='subtitle1'>SERIAL NUMBER</Typography></TableCell>
                              <TableCell><Typography variant='subtitle1'>LOCATION</Typography></TableCell>
                              <TableCell><Typography variant='subtitle1'>RACK NUMBER</Typography></TableCell>
                              <TableCell><Typography variant='subtitle1'>ACTION</Typography></TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rackAllDetails.map((rackDetail, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{rackDetail.rackLocation}</TableCell>
                                <TableCell>{rackDetail.rackBarcode}</TableCell>

                                <TableCell>{rackDetail.rackBarcodeCreated}</TableCell>

                              </TableRow>
                            ))}

                          </TableBody>

                        </Table>
                      </TableContainer>
                  }
                  {/* this is my general table to dicplay all the racks  */}





                </Box>
            }
          </Grid>
        </Grid>
      </Box>
    </React.Fragment >
  )
}

export default ReprintRackQrLabel