import React from 'react'
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography, Stack } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { FabricDataInfo } from '../types'
import { roundNumberToSignificantDigits } from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'
interface FabricDataForItemCodeAndRackProps {
  fabricForItemCodeAndRackResults: FabricDataInfo[],
  isDataBasedOnItemCode: boolean
}

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial'
  }
})

function FabricDataForItemCodeAndRack(props: FabricDataForItemCodeAndRackProps) {
  const { fabricForItemCodeAndRackResults, isDataBasedOnItemCode } = props
  const classes = useStyles()

  const convertDateTimeToLocalString = (date: string | null) => {
    if (date === null || date === undefined) {
      return null
    }
    return new Date(date as string)
      .toLocaleTimeString('en-GB',
        { timeZone: 'UTC', hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
      ).replace(',', '')
  }

  const totalRollMetersInStock = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => {
      if (curr.warehouseExitDateTime === null || curr.warehouseExitDateTime === undefined) {
        return acc + curr.fabricLength
      }
      return acc + 0
    }, 0)
  }

  const totalRollsInStock = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => {
      if (curr.warehouseExitDateTime === null || curr.warehouseExitDateTime === undefined) {
        return acc + 1
      }
      return acc + 0
    }, 0)
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {

    const jsonData = fabricForItemCodeAndRackResults.map((object) => {
      if (isDataBasedOnItemCode === true) {
        return {
          rackNumber: object.rackNumber,
          fabricBarcode: object.fabricBarcode,
          itemCode: object.itemCode,
          itemDescription: object.itemDescription,
          fabricLength: roundNumberToSignificantDigits(object.fabricLength, 2),
          mirNumber: object.mirNumber,
          mirDate: object.mirDate,
          warehouseEntryDateTime: convertDateTimeToLocalString(object.warehouseEntryDateTime),
          rackAssociationDateTime: convertDateTimeToLocalString(object.rackAssociationDateTime),
          rackDisassociationDateTime: convertDateTimeToLocalString(object.rackDisassociationDateTime),
          warehouseExitDateTime: convertDateTimeToLocalString(object.warehouseExitDateTime)
        }
      }
      return {
        rackNumber: object.rackNumber,
        fabricBarcode: object.fabricBarcode,
        itemCode: object.itemCode,
        itemDescription: object.itemDescription,
        fabricLength: roundNumberToSignificantDigits(object.fabricLength, 2),
        mirNumber: object.mirNumber,
        mirDate: object.mirDate,
        warehouseEntryDateTime: convertDateTimeToLocalString(object.warehouseEntryDateTime),
        rackAssociationDateTime: convertDateTimeToLocalString(object.rackAssociationDateTime)
      }
    })

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)

    if (isDataBasedOnItemCode === true) {
      XLSX.utils.book_append_sheet(workBook, workSheet, 'fabricItemCodeData')
      XLSX.writeFile(workBook, 'fabricDetailsBasedOnItemCode.xlsx')
    } else {
      XLSX.utils.book_append_sheet(workBook, workSheet, 'fabricRackNumberData')
      XLSX.writeFile(workBook, 'fabricDetailsBasedOnRackNumber.xlsx')
    }


  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          bgcolor: 'primary.card',
          paddingTop: 1
        }}
      >
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false} />
      </Box>

      <Typography variant='subtitle1' fontSize="20px" fontWeight="bold" sx={{ paddingLeft: 1 }}>
        {isDataBasedOnItemCode === true
          ? 'ITEM CODE DETAILS'
          : 'FABRIC RACK INVENTORY DETAILS'}
      </Typography>

      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}
      >


        <Typography variant='subtitle1' fontSize="17px" fontWeight="bold">
          {isDataBasedOnItemCode === true
            ? fabricForItemCodeAndRackResults[0].itemDescription
            : fabricForItemCodeAndRackResults[0].rackNumber
          }
        </Typography>

        <Stack direction="row" spacing={3} >
          <Typography variant='subtitle1' sx={{ background: 'white' }} fontSize="20px" fontWeight="bold">
            TOTAL ROLLS IN STOCK: {totalRollsInStock(fabricForItemCodeAndRackResults)}
          </Typography>

          <Typography variant='subtitle1' sx={{ background: 'white' }} fontSize="20px" fontWeight="bold">
            TOTAL METERS IN STOCK: {roundNumberToSignificantDigits(totalRollMetersInStock(fabricForItemCodeAndRackResults), 2)}
          </Typography>
        </Stack>

      </Box>


      <TableContainer component={Paper} classes={{ root: classes.customTableContainer }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>SERIAL NO.</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>
                {isDataBasedOnItemCode === true
                  ? 'RACK NUMBER'
                  : 'ITEM CODE'
                }
              </Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>SUPPLIER  NUMBER</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>FABRIC BARCODE </Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>FABRIC LENGTH</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>MIR NUMBER</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>MIR DATE</Typography></TableCell>
              {/* <TableCell><Typography variant='subtitle1'>WH ENTRY DATE & TIME</Typography></TableCell> */}
              <TableCell><Typography variant='subtitle1'>RACK ENTRY DATE & TIME</Typography></TableCell>
              {isDataBasedOnItemCode === true
                ? <TableCell><Typography variant='subtitle1'>RACK EXIT DATE & TIME</Typography></TableCell>
                : null
              }
              {/*  {isDataBasedOnItemCode === true
                ? <TableCell><Typography variant='subtitle1'>WH EXIT DATE & TIME</Typography></TableCell>
                : null
              }*/}

            </TableRow>
          </TableHead>


          <TableBody>
            {
              fabricForItemCodeAndRackResults.map((fabricObject, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {isDataBasedOnItemCode === true
                        ? fabricObject.rackNumber
                        : fabricObject.itemCode
                      }
                    </TableCell>
                    <TableCell>{fabricObject.supplierFabricBarcode}</TableCell>
                    <TableCell>{fabricObject.fabricBarcode}</TableCell>
                    <TableCell>{roundNumberToSignificantDigits(fabricObject.fabricLength, 2)} Meters</TableCell>
                    <TableCell>{fabricObject.mirNumber}</TableCell>
                    <TableCell>{fabricObject.mirDate}</TableCell>
                    {/* <TableCell>{convertDateTimeToLocalString(fabricObject.warehouseEntryDateTime)}</TableCell> */}
                    <TableCell>{convertDateTimeToLocalString(fabricObject.rackAssociationDateTime)}</TableCell>

                    {isDataBasedOnItemCode === true
                      ? <TableCell>{convertDateTimeToLocalString(fabricObject.rackDisassociationDateTime)}</TableCell>
                      : null}
                    {/*  {isDataBasedOnItemCode === true
                      ? <TableCell>{convertDateTimeToLocalString(fabricObject.warehouseExitDateTime)}</TableCell>
                      : null}*/}
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default FabricDataForItemCodeAndRack