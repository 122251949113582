import React, { useState, useCallback, useEffect } from 'react'
import { Box, Grid, Tabs, Tab, Paper, LinearProgress } from '@mui/material'
import { SearchBar } from '../UI/Components'
import { format } from 'date-fns'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { getPalletResultsBasedOnArticleNumber, getFabricDataBasedOnItemCode, 
  getConsolidatedPalletDetails, getConsolidatedFabricDetails, 
  getPalletResultsBasedOnRackNumber, getFabricDataBasedOnRack, getFabricDataBasedOnFabricBarcode, 
  getPalletResultsBasedOnWareHouseEntryDateRange, getPalletResultsBasedOnWareHouseExitDateRange,
  getFabricResultsBasedOnWareHouseEntryDateRange, getFabricResultsBasedOnWareHouseExitDateRange } from './api/apiFunctions'
import { PalletDataInfo, FabricDataInfo } from './types'
import { TabPanel, PalletsDataForArticleNumber, FabricDataForItemCodeAndRack, PalletsConsolidateData, 
  FabricConsolidateData, WarehouseDropdownMenu, PalletsDataForRackNumber, 
  FabricDataForFabricBarcode, PalletsDataForTracker, FabricDataForTracker } from './components'
import { AxiosError } from 'axios'
import SnackBarMessage from '../UI/Components/SnackBarMessage'
import StringValues from '../Providers/StringValues'
import { FlatButton, DatePicker } from '../UI/Components'
import { dateRangeType } from '../UI/types'

interface WarehouseDashboardProps {
  componentHeader : (headerName : string) => void
}

enum DropdownMenuValues {
  articleNumber = 'Article Number',
  rackNumber = 'Rack Number',
  itemCode = 'Item Code',
  fabricBarcode = 'Fabric Barcode',
  pallet = 'Pallet',
  fabric = 'Fabric',
  wareHouseEntry = 'Warehouse Entry',
  wareHouseExit = 'Warehouse Exit'
}

const WarehouseDashboard = (props: WarehouseDashboardProps) => {

  useAuthenticationToken()
  props.componentHeader('Warehouse')
  
  const [isLoading, setIsLoading] = useState(false)

  const [tabState, setTabState] = useState(0)
  const [palletSearchTerm, setPalletSearchTerm] = useState<string>('')
  const [fabricSearchTerm, setFabricSearchTerm] = useState<string>('')

  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [palletSearchResults, setPalletSearchResults] = useState<PalletDataInfo[]>([])
  const [palletSearchResultsForRack, setPalletSearchResultsForRack] = useState<PalletDataInfo[]>([])
  const [palletConsolidateData, setPalletConsolidateData] = useState<PalletDataInfo[]>([])
  const [palletTrackData, setPalletTrackData] = useState<PalletDataInfo[]>([])

  const [fabricSearchResults, setFabricSearchResults] = useState<FabricDataInfo[]>([])
  const [fabricSearchResultsBasedOnBarcode, setFabricSearchResultsBasedOnBarcode] = useState<FabricDataInfo[]>([])
  const [fabricConsolidateData, setFabricConsolidateData] = useState<FabricDataInfo[]>([])
  const [fabricTrackData, setFabricTrackData] = useState<FabricDataInfo[]>([])

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isFabricSearchdataLoaded, setIsFabricSearchdataLoaded] = useState(false)
  const [isPalletSearchdataLoaded, setIsPalletSearchdataLoaded] = useState(false)

  const [selectedPalletDropDown, setSelectedPalletDropDown] = useState<string>(DropdownMenuValues.articleNumber)
  const [selectedFabricDropDown, setSelectedFabricDropDown] = useState<string>(DropdownMenuValues.itemCode)
  const [selectedTrackerDropDown, setSelectedTrackerDropDown] = useState<string>(DropdownMenuValues.pallet)
  const [selectedTrackerEntryExitDropDown, setSelectedTrackerEntryExitDropDown] = useState<string>(DropdownMenuValues.wareHouseEntry)

  const [dateRange, setDateRange] = useState<dateRangeType>()


  /**API to fetch pallet data based on article number or Rack Number */
  const fetchPalletDataBasedOnArticleNumberOrRackNumber = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setPalletSearchResults([])
    setPalletSearchResultsForRack([])

    try {
      if (selectedPalletDropDown === DropdownMenuValues.articleNumber){
        const palletDataResultBasedOnArticleNumber = await getPalletResultsBasedOnArticleNumber(palletSearchTerm)
        setPalletSearchResults(palletDataResultBasedOnArticleNumber)
      } else {
        const palletDataResultBasedOnRackNumber = await getPalletResultsBasedOnRackNumber(palletSearchTerm)
        setPalletSearchResultsForRack(palletDataResultBasedOnRackNumber)
      }
      
      setIsError(false)
      setIsDataLoading(false)
      setIsPalletSearchdataLoaded(true)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsDataLoading(false)
        setIsPalletSearchdataLoaded(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [palletSearchTerm])

  /**API call to Fetch fabric data based on itemCode or rack number or fabric barcode */
  const fetchFabricDataBasedOnItemCodeOrRackNumberOrBarcode = useCallback(async () => {
    
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    setFabricSearchResults([])
    setFabricSearchResultsBasedOnBarcode([])

    try {
      if (selectedFabricDropDown === DropdownMenuValues.itemCode){
        const fabricDataResult = await getFabricDataBasedOnItemCode(fabricSearchTerm)
        setFabricSearchResults(fabricDataResult)
      }
      if (selectedFabricDropDown === DropdownMenuValues.rackNumber){
        const fabricDataResult = await getFabricDataBasedOnRack(fabricSearchTerm)
        setFabricSearchResults(fabricDataResult)
      }

      if (selectedFabricDropDown === DropdownMenuValues.fabricBarcode){
        const fabricDataResult = await getFabricDataBasedOnFabricBarcode(fabricSearchTerm)
        setFabricSearchResultsBasedOnBarcode(fabricDataResult)
      }
      
      setIsError(false)
      setIsDataLoading(false)
      setIsFabricSearchdataLoaded(true)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsDataLoading(false)
        setIsFabricSearchdataLoaded(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [fabricSearchTerm])


  /**API to fetch pallet details track based on warehouse entry or exit date */
  const fetchPalletDataBasedOnWarehouseEntryOrExitDate = useCallback(async () => {
    if (isLoading) {
      return
    }
    if (dateRange?.startDate === null || dateRange?.startDate === undefined || dateRange?.endDate === null || dateRange?.endDate === undefined) {
      return
    }

    const formattedStartDate = format(dateRange?.startDate as Date, "yyyyMMdd")
    const formattedEndDate = format(dateRange?.endDate as Date, "yyyyMMdd")
    setIsLoading(true)
    setIsDataLoading(true)
    setPalletTrackData([])

    try {
      if (selectedTrackerEntryExitDropDown === DropdownMenuValues.wareHouseEntry){
        const palletDataResultBasedOnWarehouseEntryDateRange = await getPalletResultsBasedOnWareHouseEntryDateRange(formattedStartDate, formattedEndDate)
        setPalletTrackData(palletDataResultBasedOnWarehouseEntryDateRange)
      } else {
        const palletDataResultBasedOnWarehouseExitDateRange = await getPalletResultsBasedOnWareHouseExitDateRange(formattedStartDate, formattedEndDate)
        setPalletTrackData(palletDataResultBasedOnWarehouseExitDateRange)
      }
      
      setIsError(false)
      setIsDataLoading(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [selectedTrackerDropDown, selectedTrackerEntryExitDropDown, dateRange])

  /**API to fetch fabric details track based on warehouse entry or exit date */
  const fetchFabricDataBasedOnWarehouseEntryOrExitDate = useCallback(async () => {
    if (isLoading) {
      return
    }
    if (dateRange?.startDate === null || dateRange?.startDate === undefined || dateRange?.endDate === null || dateRange?.endDate === undefined) {
      return
    }

    const formattedStartDate = format(dateRange?.startDate as Date, "yyyyMMdd")
    const formattedEndDate = format(dateRange?.endDate as Date, "yyyyMMdd")
    setIsLoading(true)
    setIsDataLoading(true)
    setFabricTrackData([])

    try {
      if (selectedTrackerEntryExitDropDown === DropdownMenuValues.wareHouseEntry){
        const fabricDataResultBasedOnWarehouseEntryDateRange = await getFabricResultsBasedOnWareHouseEntryDateRange(formattedStartDate, formattedEndDate)
        setFabricTrackData(fabricDataResultBasedOnWarehouseEntryDateRange)
      } else {
        const fabricDataResultBasedOnWarehouseExitDateRange = await getFabricResultsBasedOnWareHouseExitDateRange(formattedStartDate, formattedEndDate)
        setFabricTrackData(fabricDataResultBasedOnWarehouseExitDateRange)
      }
      
      setIsError(false)
      setIsDataLoading(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsDataLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
   
  }, [selectedTrackerDropDown, selectedTrackerEntryExitDropDown, dateRange])

  
  /** Fetch pallet and fabric consolidate data */
  useEffect(() => {
    if (isFabricSearchdataLoaded === false && isPalletSearchdataLoaded === false){
      fetchPalletAndFabricConsolidateData()
      handleContinueButtonClick()
    }
  }, [isFabricSearchdataLoaded, isPalletSearchdataLoaded])

  /**API call to Fetch pallet and fabric consolidate data */
  const fetchPalletAndFabricConsolidateData = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsDataLoading(true)
    try {
      const palletConsolidateDataResult = await getConsolidatedPalletDetails()
      const fabricConsolidateDataResult = await getConsolidatedFabricDetails()
        
        
      setPalletConsolidateData(palletConsolidateDataResult)
      setFabricConsolidateData(fabricConsolidateDataResult)

      setIsError(false)
      setIsDataLoading(false)
      setIsFabricSearchdataLoaded(false)
      setIsPalletSearchdataLoaded(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsDataLoading(false)
        setIsFabricSearchdataLoaded(false)
        setIsPalletSearchdataLoaded(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
    
  }, [palletConsolidateData])

  /**Handle search bar change for pallet */
  const handleSearchBarChangeForPallet = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPalletSearchTerm(event.target.value)
  }

  /**Handle search bar change for fabric */
  const handleSearchBarChangeForFabric = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFabricSearchTerm(event.target.value)
  }

  /**Handle search bar finder button click  for pallets*/
  const handleSearchBarButtonClickForPallet = () => {
    fetchPalletDataBasedOnArticleNumberOrRackNumber()
  }

  /**Handle search bar finder button click  for fabric*/
  const handleSearchBarButtonClickForFabric = () => {
    fetchFabricDataBasedOnItemCodeOrRackNumberOrBarcode()
  }

  /**Handle snack Bar close 
   * @param {boolean} value The status of snack bar
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }
  
  /**This function is responsible to handle pallet dropDown value
   * @param {string} value The selected pallet option
   */
  const handlePalletDropdownMenuChange = (value: string) => {
    setPalletSearchResults([])
    setPalletSearchResultsForRack([])
    setPalletSearchTerm('')
    setIsError(false)
    setSelectedPalletDropDown(value)
  }
  
  /**This function is responsible to handle fabric dropDown value
   * @param {string} value The selected pallet option
   */
  const handleFabricDropdownMenuChange = (value: string) => {
    setFabricSearchResults([])
    setFabricSearchResultsBasedOnBarcode([])
    setFabricSearchTerm('')
    setIsError(false)
    setSelectedFabricDropDown(value)
  }

  /**
   * This function is responsible to handle dropDown value for pallet
   */
  const palletDropdownList = () => {
    return ([DropdownMenuValues.articleNumber, DropdownMenuValues.rackNumber])
  }

  /**
   * This function is responsible to handle dropDown value for fabric
   */
  const fabricDropdownList = () => {
    return ([DropdownMenuValues.itemCode, DropdownMenuValues.rackNumber, DropdownMenuValues.fabricBarcode])
  }

  /**
   * This function is responsible to handle dropDown value for tracker pallet/fabric
   */
  const trackerPalletFabricDropdownList = () => {
    return ([DropdownMenuValues.pallet, DropdownMenuValues.fabric])
  }

  /**This function is responsible to handle tracker pallet / fabric dropDown value
   * @param {string} value The selected tracker option
   */
  const handleTrackerPalletFabricDropdownMenuChange = (value: string) => {
    setPalletTrackData([])
    setSelectedTrackerDropDown(value)
  }

  /**
   * This function is responsible to handle dropDown value for tracker entry/exit
   */
  const trackerEntryExitDropdownList = () => {
    return ([DropdownMenuValues.wareHouseEntry, DropdownMenuValues.wareHouseExit])
  }

  /**This function is responsible to handle tracker entry/exit dropDown value
   * @param {string} value The selected tracker entry/exit option
   */
  const handleTrackerEntryExitDropdownMenuChange = (value: string) => {
    setPalletTrackData([])
    setSelectedTrackerEntryExitDropDown(value)
  }

  /**This function is responsible to handle dateRange change
   * @param {dateRangeType} value The date range
   */
  const handleDateRangeChange = (value: dateRangeType) => {
    setDateRange(value)
  }

  /**Handle track continue button click */
  const handleContinueButtonClick = () => {

    if (selectedTrackerDropDown === DropdownMenuValues.pallet){
      fetchPalletDataBasedOnWarehouseEntryOrExitDate()
    }

    if (selectedTrackerDropDown === DropdownMenuValues.fabric){
      fetchFabricDataBasedOnWarehouseEntryOrExitDate()
    }

  }

  return (
    <React.Fragment>
      <SnackBarMessage message = {errorMessage} successStatus={false} open = {snackBarOpen} onClose={handleSnackBarClose}/>
      <Grid container sx={{
        height: '100vh'
      }}>
        <Grid item xs={3}
          sx={{
            paddingTop: 2,
            paddingBottom: 2
          }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'surface.dark'
          }}>
            <Paper elevation={8} sx={{
              height: '100%'
            }}>
              <Box sx={{
                bgcolor: 'primary.card'
              }}>
                <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
                  setTabState(newValue)
                  setIsError(false)
                }} textColor='secondary' TabIndicatorProps={{
                  style: {
                    backgroundColor: 'alpha.light'
                  }
                }}>
                  <Tab sx={{
                    color: 'alpha.light'
                  }} label="PALLETS" />
                  <Tab sx={{
                    color: 'alpha.light'
                  }} label="FABRIC" />
                  <Tab sx={{
                    color: 'alpha.light'
                  }} label="TRACK" />
                </Tabs>
              </Box>
         
              <Box sx={{
                paddingTop: 2
              }}>
                <TabPanel value={tabState} index={0}>
                  <Box sx={{paddingLeft: 2, paddingBottom: 3}}>
                    <WarehouseDropdownMenu onChange={handlePalletDropdownMenuChange} menuValues={palletDropdownList()} buttonText={selectedPalletDropDown}></WarehouseDropdownMenu>
                  </Box>
                  
                  <SearchBar value={palletSearchTerm} onChange={handleSearchBarChangeForPallet} 
                    onButtonClick={handleSearchBarButtonClickForPallet} error={isError} errorMessage={errorMessage} 
                    inputLabel = {selectedPalletDropDown === DropdownMenuValues.articleNumber ? StringValues.articleNumberLabel : StringValues.rackLabel} 
                    width = {'80%'} marginLeft = {10}/>
                </TabPanel>

                <TabPanel value={tabState} index={1}>
                  <Box sx={{paddingLeft: 2, paddingBottom: 3}}>
                    <WarehouseDropdownMenu onChange={handleFabricDropdownMenuChange} menuValues={fabricDropdownList()} buttonText={selectedFabricDropDown}></WarehouseDropdownMenu>
                  </Box>
                  
                  <SearchBar value={fabricSearchTerm} onChange={handleSearchBarChangeForFabric} 
                    onButtonClick={handleSearchBarButtonClickForFabric} error={isError} errorMessage={errorMessage} 
                    inputLabel = {selectedFabricDropDown === DropdownMenuValues.itemCode ? StringValues.itemCodeLabel 
                      : selectedFabricDropDown === DropdownMenuValues.rackNumber ? StringValues.rackLabel
                        : StringValues.fabricBarcodeLabel} 
                    width = {'80%'} marginLeft = {10}/>
                </TabPanel>

                <TabPanel value={tabState} index={2}>
                  <Box sx={{paddingLeft: 1, paddingBottom: 2}}>
                    <WarehouseDropdownMenu onChange={handleTrackerPalletFabricDropdownMenuChange} menuValues={trackerPalletFabricDropdownList()} buttonText={DropdownMenuValues.pallet}></WarehouseDropdownMenu>
                  </Box>

                  <Box sx={{paddingLeft: 1, paddingBottom: 3}}>
                    <WarehouseDropdownMenu onChange={handleTrackerEntryExitDropdownMenuChange} menuValues={trackerEntryExitDropdownList()} buttonText={DropdownMenuValues.wareHouseEntry}></WarehouseDropdownMenu>
                  </Box>

                  <Box sx={{paddingLeft: 1, paddingBottom: 2, paddingRight: 1}}>
                    <DatePicker onChange={handleDateRangeChange} defaultDateRange={(value: dateRangeType)=> setDateRange(value) } direction = {'vertical'}/>
                  </Box>
                  
                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 3 }}>
                    <FlatButton label='CONTINUE' onClick={handleContinueButtonClick} disableControlsStatus={false}/>
                  </Box>
                  
                </TabPanel>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={9} sx={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 2,
          paddingBottom: 2
        }}>
          {
            (isDataLoading) ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
              >
                <LinearProgress sx={{ width: '30%' }}/>
              </Box>
            ) : (<Box sx={{
              width: '100%',
              height: '100%',
              bgcolor: 'primary.pane',
              backgroundColor: 'surface.dark'
            }}>
              {isError === true ? null :
                tabState === 0
                  ? (palletConsolidateData.length > 0 && palletSearchResults.length === 0 && palletSearchResultsForRack.length === 0 
                    ? <PalletsConsolidateData palletsConsolidateResults={palletConsolidateData}/>
                    : selectedPalletDropDown === DropdownMenuValues.articleNumber && palletSearchResults.length > 0  
                      ? <PalletsDataForArticleNumber palletsForArticleNumberResults={palletSearchResults}/> 
                      : selectedPalletDropDown === DropdownMenuValues.rackNumber && palletSearchResultsForRack.length > 0 
                        ? <PalletsDataForRackNumber palletsForRackNumberResults={palletSearchResultsForRack}/> 
                        : null
                  )
                  : tabState === 1 
                    ? (fabricConsolidateData.length > 0 && fabricSearchResults.length === 0 && fabricSearchResultsBasedOnBarcode.length === 0
                      ? <FabricConsolidateData fabricConsolidateResults={fabricConsolidateData}/>
                      : selectedFabricDropDown === DropdownMenuValues.itemCode && fabricSearchResults.length > 0
                        ? <FabricDataForItemCodeAndRack fabricForItemCodeAndRackResults={fabricSearchResults} isDataBasedOnItemCode = {true}/>
                        : selectedFabricDropDown === DropdownMenuValues.rackNumber && fabricSearchResults.length > 0
                          ? <FabricDataForItemCodeAndRack fabricForItemCodeAndRackResults={fabricSearchResults} isDataBasedOnItemCode = {false}/>
                          : selectedFabricDropDown === DropdownMenuValues.fabricBarcode && fabricSearchResultsBasedOnBarcode.length > 0
                            ? <FabricDataForFabricBarcode fabricForFabricBarcodeResults={fabricSearchResultsBasedOnBarcode}/>
                            : null)
                    : (selectedTrackerDropDown === DropdownMenuValues.pallet
                      ? palletTrackData.length > 0
                        ? <PalletsDataForTracker palletsDataForWareHouseDateRange={palletTrackData} dateRange={dateRange as dateRangeType} 
                          isDataBasedOnWarehouseEntryDate={selectedTrackerEntryExitDropDown === DropdownMenuValues.wareHouseEntry ? true : false}/>
                        : null
                      : selectedTrackerDropDown === DropdownMenuValues.fabric
                        ? fabricTrackData.length > 0
                          ? <FabricDataForTracker fabricDataForWareHouseDateRange={fabricTrackData} dateRange={dateRange as dateRangeType}
                            isDataBasedOnWarehouseEntryDate={selectedTrackerEntryExitDropDown === DropdownMenuValues.wareHouseEntry ? true : false}/>
                          : null
                        : null)

              }
            </Box>)
          }
          
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default WarehouseDashboard