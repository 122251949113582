import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { convertNumberToLocaleString, roundNumberToSignificantDigits } from '../../utils'
import { DHUDataDetails, HourlyDefectData } from '../types'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface DHUPassedDateRangeTableProps {
  data: DHUDataDetails[]
}

type TableData = Record<string, DHUDataDetails[]>

const DHUPassedDateRangeTable = (props: DHUPassedDateRangeTableProps) => {
  const { data } = props

  const [tableData, setTableData] = useState<TableData>({})
  const [batchData, setBatchData] = useState<TableData>({})

  useEffect(() => {
    const groupedData = groupBy(data, (i) => i.date)
    const aggregatedData = aggregateData(groupedData)
    const reducedData = reduceHourlyDetailsInAggregatedData(aggregatedData)

    const groupedDataByBatch = groupBy(data, (i) => i.batchNumber)
    setTableData(reducedData)
    setBatchData(groupedDataByBatch)
  }, [data])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const aggregateData = (data: Record<string, DHUDataDetails[]>) => {
    const newObject: Record<string, DHUDataDetails[]> = {}
    for (const key of Object.keys(data)) {
      const value = data[key]
      const aggregatedValue = value.reduce((acc: DHUDataDetails[], curr) => {
        const { batchNumber, hourlyDetails } = curr
        const indexWithBatchNumber = acc.findIndex((i) => i.batchNumber === batchNumber)
        if (indexWithBatchNumber === -1) {
          acc.push(curr)
          return acc
        }
        acc[indexWithBatchNumber] = Object.assign(
          {},
          { ...acc[indexWithBatchNumber] },
          { hourlyDetails: acc[indexWithBatchNumber].hourlyDetails.concat(hourlyDetails) }
        )
        return acc
      }, [])
      newObject[key] = aggregatedValue
    }
    return newObject
  }

  const reduceHourlyDetailsInAggregatedData = (data: Record<string, DHUDataDetails[]>) => {
    const result = Object.entries(data).map(([key, value]) => {
      const updatedValue = value.map((item) => {
        const { hourlyDetails } = item
        const reducedHourlyDetails = hourlyDetails.reduce((acc: (HourlyDefectData & { count: number })[], curr) => {
          const doesTimeIndexExist = acc.findIndex(({ startTime, endTime }) => {
            return curr.startTime === startTime && curr.endTime === endTime
          })
          if (doesTimeIndexExist === -1) {
            acc.push(Object.assign({}, curr, { count: 1 }))
            return acc
          }
          const { hourEfficiency, passedQuantity, rejectedQuantity, defectDetails } = curr

          const defectQuantity = defectDetails.reduce((acc, curr) => {
            return acc += curr.quantity
          }, 0)

          acc[doesTimeIndexExist].hourEfficiency += hourEfficiency
          acc[doesTimeIndexExist].passedQuantity += passedQuantity
          acc[doesTimeIndexExist].rejectedQuantity += rejectedQuantity
          acc[doesTimeIndexExist].reworkedQuantity += defectQuantity
          acc[doesTimeIndexExist].count += 1
          return acc
        }, [])
        return Object.assign({}, item, { hourlyDetails: reducedHourlyDetails })
      })
      return [key, updatedValue]
    })
    return Object.fromEntries(result)
  }

  const renderTableRows = (tableData: TableData) => {
    
    const keys = Object.keys(tableData)
    return keys.map((key, index) => {
      let totalPassQuantity = 0
      let totalDefectQuantity = 0
      let totalRejectQuantity = 0
      let totalDHUQuantity = 0

      /**Date from YYYY-MM-DD to DD-MM-YYYY in string format*/
      const dateSplitList = key.split('-')
      let formattedDate = ''
      if (dateSplitList.length > 0) {
        formattedDate = `${dateSplitList[2]}-${dateSplitList[1]}-${dateSplitList[0]}`
      }

      return (
        <TableRow key={key}>
          <TableCell align='center'>{formattedDate}</TableCell>
          {
            Object.keys(batchData).map((batchDatakey, index) => {
              

              const filteredObject = tableData[key].filter(obj => {
                return obj.batchNumber === batchDatakey
              })
              
              if (filteredObject.length > 0) {
                const {totalPassedQuantity, totalRejectedQuantity, totalReworkedQuantity, hourlyDetails} = filteredObject[0]
                let defectQuantity = 0

                totalPassQuantity += totalPassedQuantity
                totalRejectQuantity += totalRejectedQuantity

                totalDHUQuantity += totalPassedQuantity + totalRejectedQuantity + totalReworkedQuantity

                hourlyDetails.map((obj)=>{
                  const {defectDetails} = obj
                  defectQuantity += defectDetails.reduce((acc, curr) => {
                    return acc += curr.quantity
                  }, 0)
                  return null
                })
                totalDefectQuantity += defectQuantity

                return (<TableCell align='center'>{totalPassedQuantity}/{defectQuantity}</TableCell>)
              }
              return (<TableCell/>)
            })
          }

          <TableCell align='center' sx={{ fontWeight: 'bold' }}>
            {convertNumberToLocaleString(roundNumberToSignificantDigits(totalPassQuantity, 0))}
          </TableCell>

          <TableCell align='center' sx={{ fontWeight: 'bold' }}>
            {roundNumberToSignificantDigits(totalDefectQuantity, 0)}
          </TableCell>

          <TableCell align='center' sx={{ fontWeight: 'bold' }}>
            {roundNumberToSignificantDigits(totalRejectQuantity, 0)}
          </TableCell>

          <TableCell align='center' sx={{ fontWeight: 'bold' }}>
            {totalDHUQuantity > 0 ? roundNumberToSignificantDigits((totalDefectQuantity * 100 / totalDHUQuantity), 2) : 0}
          </TableCell>
        </TableRow>
      )
    })
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []

    const keys = Object.keys(tableData)
    keys.map((key, index) => {
      let totalPassQuantity = 0
      let totalDefectQuantity = 0
      let totalRejectQuantity = 0
      let totalDHUQuantity = 0

      /**Date from YYYY-MM-DD to DD-MM-YYYY in string format*/
      const dateSplitList = key.split('-')
      let formattedDate = ''
      if (dateSplitList.length > 0) {
        formattedDate = `${dateSplitList[2]}-${dateSplitList[1]}-${dateSplitList[0]}`
      }

      const pushObject: any = {date: formattedDate}

      
      Object.keys(batchData).map((batchDatakey, index) => {

        const filteredObject = tableData[key].filter(obj => {
          return obj.batchNumber === batchDatakey
        })
          
        if (filteredObject.length > 0) {
          const {totalPassedQuantity, totalRejectedQuantity, totalReworkedQuantity, hourlyDetails} = filteredObject[0]
          let defectQuantity = 0

          totalPassQuantity += totalPassedQuantity
          totalRejectQuantity += totalRejectedQuantity

          totalDHUQuantity += totalPassedQuantity + totalRejectedQuantity + totalReworkedQuantity

          hourlyDetails.map((obj)=>{
            const {defectDetails} = obj
            defectQuantity += defectDetails.reduce((acc, curr) => {
              return acc += curr.quantity
            }, 0)
            return null
          })
          totalDefectQuantity += defectQuantity

          pushObject[`BATCH ${batchDatakey}`] = `${totalPassedQuantity}/${defectQuantity}`
          return null
        }
        pushObject[`BATCH ${batchDatakey}`] = ''
        return null
      })
      
      pushObject['totalPass'] = roundNumberToSignificantDigits(totalPassQuantity, 0)
      pushObject['totalDefect'] = roundNumberToSignificantDigits(totalDefectQuantity, 0)
      pushObject['totalRejected'] = roundNumberToSignificantDigits(totalRejectQuantity, 0)
      pushObject['DHU%'] = `${totalDHUQuantity > 0 ? roundNumberToSignificantDigits((totalDefectQuantity * 100 / totalDHUQuantity), 2) : 0}`
      jsonData.push(pushObject)
      return null
    })

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Hourly-End-Table-Date-Range')
    XLSX.writeFile(workBook, 'Hourly-End-Table-Date-Range.xlsx')
  }
  
  return (
    <TableContainer component={Paper}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}>
        <Typography variant='h5' padding={2}>Hourly End Table Pass-Date Range</Typography>
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>
      
      <Table>
        <TableHead>
          <TableRow sx={{
            backgroundColor: 'alpha.lightest',
          }}>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>DATE</TableCell>
            {
              Object.keys(batchData).map((key, index) => {

                return <TableCell align='center' key={key} sx={{
                  fontWeight: 'bold'
                }}>BATCH {key}</TableCell>
              })
            }

            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>TOTAL PASS</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>TOTAL DEFECTS</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>TOTAL REJECTED</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>DHU%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows(tableData)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DHUPassedDateRangeTable