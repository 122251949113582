import React from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

interface DropdownMenuProps {
  onChange: (value: DropdownMenuValues) => void
}

export enum DropdownMenuValues {
  poNumber = 'PO NUMBER',
  ocNumber = 'OC NUMBER'
}

const DropdownMenu = (props: DropdownMenuProps) => {
  const [buttonText, setButtonText] = React.useState<DropdownMenuValues>(DropdownMenuValues.poNumber)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget.textContent === DropdownMenuValues.poNumber) {
      changeButtonText(DropdownMenuValues.poNumber)
    } else {
      changeButtonText(DropdownMenuValues.ocNumber)
    }
    handleClose()
  }

  const changeButtonText = (text: DropdownMenuValues) => {
    if (text !== buttonText) {
      props.onChange(text)
    }
    setButtonText(text)
  }

  return (
    <React.Fragment>
      <Button
        id='dropdown-menu-button'
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          fontFamily: 'button.medium',
          border: '1px solid black'
        }}
        endIcon={<KeyboardArrowDown />}>
        {buttonText}
      </Button>
      <Menu id='dropdown-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": 'dropdown-menu-button'
        }}>
        <MenuItem onClick={handleMenuItemClick}>{DropdownMenuValues.poNumber}</MenuItem>
        <MenuItem onClick={handleMenuItemClick}>{DropdownMenuValues.ocNumber}</MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default DropdownMenu