import React, { useState, useEffect } from 'react'
import { Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Stack } from '@mui/material'
import { CuttingDetailsData, SizeDetailsData } from '../types'
import {roundNumberToSignificantDigits} from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface CuttingSummaryTableProps {
  trackerCuttingDetailsData: CuttingDetailsData[]
}

type TableDataType = {
  [x: string]: CuttingDetailsData[]
}

function CuttingSummaryTable(props: CuttingSummaryTableProps) {
  const {trackerCuttingDetailsData} = props
  
  const [sizeList, setSizeList] = useState<string[]>([])
  const [groupedTableData, setGroupedTableData] = useState<TableDataType>({})

  /** Function responsible to group data */
  useEffect(() => {
    const sizeObjectList: SizeDetailsData[] = [...new Set(trackerCuttingDetailsData.flatMap(({sizeDetails}) => sizeDetails))]
    const sizeList = [...new Set(sizeObjectList.map(sizeObject => sizeObject.size))]
    setSizeList(sizeList)

    const groupedDataresult: TableDataType = groupBy(trackerCuttingDetailsData, (i) => i.itemCode)
    setGroupedTableData(groupedDataresult)

  }, [trackerCuttingDetailsData])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  /** Function responsible to create table rows for summary order grid */
  const getSizeWiseOrderRow = ()=>{
    return Object.entries(groupedTableData).map(([key, value]) =>{
      let rowTotal = 0
      let totalCutQuantity = 0
      let totalFabricUtilized = 0
      return (
        <TableRow key={key}>
          <TableCell>{key}</TableCell>
          {
            sizeList.map((size) => {
              let sizeOrderQuantity = 0
              value.map((object)=>{
                const {sizeDetails}  = object
                sizeDetails.map((sizeObject)=>{
                  if (sizeObject.size === size){
                    sizeOrderQuantity += sizeObject.orderQuantity
                    rowTotal += sizeObject.orderQuantity
                  }
                  return null
                })
                totalCutQuantity += object.totalQuantity
                totalFabricUtilized += object.totalFabricUtilized

                return null
              })
              return (<TableCell>{sizeOrderQuantity}</TableCell>)
            })
          }
          <TableCell>{rowTotal}</TableCell>
          <TableCell>{totalCutQuantity > 0 ? (roundNumberToSignificantDigits((totalFabricUtilized / totalCutQuantity), 2)) : 0}m</TableCell>
        </TableRow>
      )
    })
  }

  /** Function responsible to create table rows for size wise order quantity */
  const getItemCodeAndSizeWiseOrderQuantityRow = (data: CuttingDetailsData[])=>{
    let rowTotal = 0
    return (
      <TableRow key={'orderQuantity'}>
        <TableCell>{'ORDER QUANTITY'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeOrderQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeOrderQuantity += sizeObject.orderQuantity
                  rowTotal += sizeObject.orderQuantity
                }
                return null  
              })
              return null
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeOrderQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }

  /** Function responsible to create table rows for size wise cut quantity */
  const getItemCodeAndSizeWiseCutQuantityRow = (data: CuttingDetailsData[])=>{
    let rowTotal = 0
    return (
      <TableRow key={'cutQuantity'}>
        <TableCell>{'CUT QUANTITY'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeCutQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeCutQuantity += sizeObject.cutQuantity
                  rowTotal += sizeObject.cutQuantity
                }
                return null  
              })
              return null
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeCutQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }

  /** Function responsible to create table rows for size wise DHU pass quantity */
  const getItemCodeAndSizeWiseDHUPassQuantityRow = (data: CuttingDetailsData[])=>{
    let rowTotal = 0
    return (
      <TableRow key={'endTablePass'}>
        <TableCell>{'END TABLE PASS'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeEndTablePassQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeEndTablePassQuantity += sizeObject.DHUPassedQuantity
                  rowTotal += sizeObject.DHUPassedQuantity
                }
                return null  
              })
              return null
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeEndTablePassQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }

  /** Function responsible to create table rows for size wise dispatch production quantity */
  const getItemCodeAndSizeWiseDispatchProductionQuantityRow = (data: CuttingDetailsData[])=>{
    let rowTotal = 0
    return (
      <TableRow key={'dispatchProduction'}>
        <TableCell>{'DISPATCH PRODUCTION'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeDispatchProductionQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeDispatchProductionQuantity += sizeObject.dispatchQuantity
                  rowTotal += sizeObject.dispatchQuantity
                }
                return null  
              })
              return null
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeDispatchProductionQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }

  /** Function responsible to create table rows for size wise cut to dispatch difference quantity */
  const getItemCodeAndSizeWiseCutToDispatchRow = (data: CuttingDetailsData[])=>{
    let rowTotalDifference = 0
    return (
      <TableRow key={'cutToDispatchDiff'}>
        <TableCell>{'CUT TO DISPATCH DIFF'}</TableCell>
        {
          sizeList.map((size) => {
            let sizeCutQuantity = 0
            let sizeDispatchQuantity = 0
            data.map((object)=>{
              const {sizeDetails}  = object
              sizeDetails.map((sizeObject)=>{
                if (sizeObject.size === size){
                  sizeCutQuantity += sizeObject.cutQuantity
                  sizeDispatchQuantity += sizeObject.dispatchQuantity
                  rowTotalDifference += (sizeDispatchQuantity - sizeCutQuantity)
                }
                return null  
              })
              return null
            })
            return (<TableCell sx={sizeDispatchQuantity - sizeCutQuantity < 0 ? {color: 'font.red'} : null}>{roundNumberToSignificantDigits((sizeDispatchQuantity - sizeCutQuantity), 0)}</TableCell>)
          })
        }
        <TableCell sx={rowTotalDifference < 0 ? {color: 'font.red'} : null}>{roundNumberToSignificantDigits(rowTotalDifference, 0)}</TableCell>
      </TableRow>
    )
  }


  /** Function responsible to create table rows for size wise order quantity to download */
  const getItemCodeAndSizeWiseOrderQuantityRowToDownload = (data: CuttingDetailsData[])=>{
    let rowTotal = 0

    const pushObject: any = {
      size: 'orderQuantity'
    }

    sizeList.map((size) => {
      let sizeOrderQuantity = 0
      data.map((object)=>{
        const {sizeDetails}  = object
        sizeDetails.map((sizeObject)=>{
          if (sizeObject.size === size){
            sizeOrderQuantity += sizeObject.orderQuantity
            rowTotal += sizeObject.orderQuantity
          }
          return null  
        })
        return null
      })
            
      pushObject[`size-${size}`] = roundNumberToSignificantDigits(sizeOrderQuantity, 0)
      return null
    })
    pushObject['total'] = roundNumberToSignificantDigits(rowTotal, 0)
    return pushObject
  }

  /** Function responsible to create table rows for size wise cut quantity to download to excel */
  const getItemCodeAndSizeWiseCutQuantityRowToDownload = (data: CuttingDetailsData[])=>{
    let rowTotal = 0

    const pushObject: any = {
      size: 'cutQuantity'
    }
    
    sizeList.map((size) => {
      let sizeCutQuantity = 0
      data.map((object)=>{
        const {sizeDetails}  = object
        sizeDetails.map((sizeObject)=>{
          if (sizeObject.size === size){
            sizeCutQuantity += sizeObject.cutQuantity
            rowTotal += sizeObject.cutQuantity
          }
          return null  
        })
        return null
      })
      pushObject[`size-${size}`] = roundNumberToSignificantDigits(sizeCutQuantity, 0)
      return null
    })
        
    pushObject['total'] = roundNumberToSignificantDigits(rowTotal, 0)
    return pushObject
  }

  /** Function responsible to create table rows for size wise DHU pass quantity to download in excel */
  const getItemCodeAndSizeWiseDHUPassQuantityRowToDownload = (data: CuttingDetailsData[])=>{
    let rowTotal = 0

    const pushObject: any = {
      size: 'endTablePass'
    }

      
    sizeList.map((size) => {
      let sizeEndTablePassQuantity = 0
      data.map((object)=>{
        const {sizeDetails}  = object
        sizeDetails.map((sizeObject)=>{
          if (sizeObject.size === size){
            sizeEndTablePassQuantity += sizeObject.DHUPassedQuantity
            rowTotal += sizeObject.DHUPassedQuantity
          }
          return null  
        })
        return null
      })
      pushObject[`size-${size}`] = roundNumberToSignificantDigits(sizeEndTablePassQuantity, 0)
      return null
    })
        
    pushObject['total'] = roundNumberToSignificantDigits(rowTotal, 0)
    return pushObject
  }

  /** Function responsible to create table rows for size wise dispatch production quantity to download in excel */
  const getItemCodeAndSizeWiseDispatchProductionQuantityRowToDownload = (data: CuttingDetailsData[])=>{
    let rowTotal = 0

    const pushObject: any = {
      size: 'dispatchProduction'
    }

      
    sizeList.map((size) => {
      let sizeDispatchProductionQuantity = 0
      data.map((object)=>{
        const {sizeDetails}  = object
        sizeDetails.map((sizeObject)=>{
          if (sizeObject.size === size){
            sizeDispatchProductionQuantity += sizeObject.dispatchQuantity
            rowTotal += sizeObject.dispatchQuantity
          }
          return null  
        })
        return null
      })
      pushObject[`size-${size}`] = roundNumberToSignificantDigits(sizeDispatchProductionQuantity, 0)
      return null
    })
        
    pushObject['total'] = roundNumberToSignificantDigits(rowTotal, 0)
    return pushObject
  }



  /** Function responsible to create table rows for size wise cut to dispatch difference quantity to download in excel */
  const getItemCodeAndSizeWiseCutToDispatchRowToDownload = (data: CuttingDetailsData[])=>{
    let rowTotalDifference = 0

    const pushObject: any = {
      size: 'cutToDispatchDiff'
    }
  
    sizeList.map((size) => {
      let sizeCutQuantity = 0
      let sizeDispatchQuantity = 0
      data.map((object)=>{
        const {sizeDetails}  = object
        sizeDetails.map((sizeObject)=>{
          if (sizeObject.size === size){
            sizeCutQuantity += sizeObject.cutQuantity
            sizeDispatchQuantity += sizeObject.dispatchQuantity
            rowTotalDifference += (sizeDispatchQuantity - sizeCutQuantity)
          }
          return null  
        })
        return null
      })

      pushObject[`size-${size}`] = roundNumberToSignificantDigits((sizeDispatchQuantity - sizeCutQuantity), 0)
      return null
    })
        
    pushObject['total'] = roundNumberToSignificantDigits(rowTotalDifference, 0)
    return pushObject
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    
    const workBook = XLSX.utils.book_new()

    Object.entries(groupedTableData).map(([key, value]) =>{
      const jsonData: any[] = []

      jsonData.push(getItemCodeAndSizeWiseOrderQuantityRowToDownload(value))
      jsonData.push(getItemCodeAndSizeWiseCutQuantityRowToDownload(value))
      jsonData.push(getItemCodeAndSizeWiseDHUPassQuantityRowToDownload(value))
      jsonData.push(getItemCodeAndSizeWiseDispatchProductionQuantityRowToDownload(value))
      jsonData.push(getItemCodeAndSizeWiseCutToDispatchRowToDownload(value))

      const workSheet = XLSX.utils.json_to_sheet(jsonData)
      XLSX.utils.book_append_sheet(workBook, workSheet, `${value[0].itemCode}`)
      return null
    })
    
    XLSX.writeFile(workBook, 'cutting-Details.xlsx')
  }

  return (
    
    <React.Fragment>
      <Box sx={{
        width: '100%',
        bgcolor: 'primary.pane',
        backgroundColor: 'surface.dark',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 1,
        paddingRight: 1
      }}> 

        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            bgcolor: 'primary.card',
            paddingTop: 1
          }}
        >
          <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
        </Box>
        
        <Typography variant='body1' sx={{color: 'alpha.main'}}>ORDER GRID</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>DETAILS</Typography></TableCell>
                {
                  sizeList.map((size) => {
                    return (
                      <TableCell><Typography variant='subtitle1'>{size}</Typography></TableCell>
                    )
                  })
                }
                <TableCell><Typography variant='subtitle1'>TOTAL</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>AVG ACHIVED CONSUMPTION</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                getSizeWiseOrderRow()
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {
        Object.entries(groupedTableData).map(([key, value]) =>{
          return (
            <Box sx={{paddingTop: 1}}>
              <Stack direction='row' justifyContent='center'>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>{`${value[0].itemCode} - ${value[0].itemDescription}`}</Typography>
              </Stack>
              
             
              
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: 'alpha.lightest'
                      }}
                    >
                      <TableCell><Typography variant='subtitle1'>SIZES</Typography></TableCell>
                      {
                        sizeList.map((size) => {
                          return (
                            <TableCell><Typography variant='subtitle1'>{size}</Typography></TableCell>
                          )
                        })
                      }
                      <TableCell><Typography variant='subtitle1'>TOTAL</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getItemCodeAndSizeWiseOrderQuantityRow(value)}
                    {getItemCodeAndSizeWiseCutQuantityRow(value)}
                    {getItemCodeAndSizeWiseDHUPassQuantityRow(value)}
                    {getItemCodeAndSizeWiseDispatchProductionQuantityRow(value)}
                    {getItemCodeAndSizeWiseCutToDispatchRow(value)}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        })
      }
    </React.Fragment>
  )
}

export default CuttingSummaryTable