import React from 'react'
import { Typography, Box } from '@mui/material'
import { ManagementProgress } from '../types'
import {DoneRounded as DoneRoundedIcon, DangerousRounded as DangerousRoundedIcon, Circle as CircleIcon}  from '@mui/icons-material'
import { withStyles } from "@mui/styles"
import {Timeline, TimelineItem as MuiTimelineItem, TimelineSeparator, TimelineConnector, TimelineContent} from '@mui/lab'

interface ManagementProgressProps {
  managementProgressDetails: ManagementProgress
}

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none"
    }
  }
})(MuiTimelineItem)

function ManagementOrderProgress(props: ManagementProgressProps) {
  const {managementProgressDetails} = props

  return (
    <React.Fragment>
      {
        managementProgressDetails === null || managementProgressDetails === undefined 
          ? null
          : <Timeline position='right'sx={{paddingLeft: 0, paddingRight: 0}}>
            <TimelineItem>
              <TimelineSeparator>
                {
                  managementProgressDetails.orders.length > 0
                    ? <DoneRoundedIcon sx={{ backgroundColor: '#00D100', color: 'white', borderRadius: '25px'}}/>
                    : <DangerousRoundedIcon sx={{ backgroundColor: '#D10000', color: 'white', borderRadius: '25px'}}/>
                }
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>ORDERS</Typography>
                {
                  managementProgressDetails.orders.map((object) => {
                    return (
                      <Box display={'flex'} flexDirection={'row'}>
                        <CircleIcon fontSize='inherit' sx={{ color: 'alpha.main'}}/>
                        <Typography variant='body2'> {object.itemCode} - {object.fitType}</Typography>
                      </Box>
                    )
                  })
                }
                <br/>
              </TimelineContent>
          
              
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                {
                  managementProgressDetails.parts === true
                    ? <DoneRoundedIcon sx={{ backgroundColor: '#00D100', color: 'white', borderRadius: '25px'}}/>
                    : <DangerousRoundedIcon sx={{ backgroundColor: '#D10000', color: 'white', borderRadius: '25px'}}/>
                }
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>PARTS</Typography>
                <br/>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                {
                  managementProgressDetails.techPack.length > 0
                    ? <DoneRoundedIcon sx={{ backgroundColor: '#00D100', color: 'white', borderRadius: '25px'}}/>
                    : <DangerousRoundedIcon sx={{ backgroundColor: '#D10000', color: 'white', borderRadius: '25px'}}/>
                }
              </TimelineSeparator>
              <TimelineContent>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>TECH PACK</Typography>
                {
                  managementProgressDetails.techPack.map((object) => {
                    return (
                      <Box display={'flex'} flexDirection={'row'}>
                        <CircleIcon fontSize='inherit' sx={{ color: 'alpha.main'}}/>
                        <Typography variant='body2'> {object.productCode} - {object.productDescription}</Typography>
                      </Box>
                    )
                  })
                }
          
              </TimelineContent>
            </TimelineItem>
          </Timeline>
      }
    </React.Fragment>
  )
}

export default ManagementOrderProgress