import { apiClient } from "../../Services/Networking/apiClient"
import { PalletDataInfo, FabricDataInfo } from "../types"

export const getConsolidatedPalletDetails = async (): Promise<PalletDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/pallets`
  })
  return response as unknown as PalletDataInfo[]
}

export const getPalletResultsBasedOnArticleNumber = async (articleNumber: string): Promise<PalletDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/pallets`,
    params: { articleNumber: articleNumber }
  })
  return response as unknown as PalletDataInfo[]
}

export const getPalletResultsBasedOnRackNumber = async (rackNumber: string): Promise<PalletDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/pallets`,
    params: { rackNumber: rackNumber }
  })
  return response as unknown as PalletDataInfo[]
}

export const getConsolidatedFabricDetails = async (): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/fabric`
  })
  return response as unknown as FabricDataInfo[]
}

export const getFabricDataBasedOnItemCode = async (itemCode: string): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/fabric`,
    params: { itemCode: itemCode }
  })
  return response as unknown as FabricDataInfo[]
}

export const getFabricDataBasedOnRack = async (rackNumber: string): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/fabric`,
    params: { rackNumber: rackNumber }
  })
  return response as unknown as FabricDataInfo[]
}

export const getFabricDataBasedOnFabricBarcode = async (fabricBarcode: string): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/fabric`,
    params: { fabricBarcode: fabricBarcode }
  })
  return response as unknown as FabricDataInfo[]
}

export const getPalletResultsBasedOnWareHouseEntryDateRange = async (fromEntryDate: string, toEntryDate: string): Promise<PalletDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/pallets`,
    params: { fromEntryDate: fromEntryDate, toEntryDate: toEntryDate}
  })
  return response as unknown as PalletDataInfo[]
}

export const getPalletResultsBasedOnWareHouseExitDateRange = async (fromExitDate: string, toExitDate: string): Promise<PalletDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/pallets`,
    params: { fromExitDate: fromExitDate, toExitDate: toExitDate}
  })
  return response as unknown as PalletDataInfo[]
}

export const getFabricResultsBasedOnWareHouseEntryDateRange = async (fromEntryDate: string, toEntryDate: string): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/fabric`,
    params: { fromEntryDate: fromEntryDate, toEntryDate: toEntryDate}
  })
  return response as unknown as FabricDataInfo[]
}

export const getFabricResultsBasedOnWareHouseExitDateRange = async (fromExitDate: string, toExitDate: string): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/warehouse/fabric`,
    params: { fromExitDate: fromExitDate, toExitDate: toExitDate}
  })
  return response as unknown as FabricDataInfo[]
}