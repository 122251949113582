const storagePrefix = 'fabtrakr_app'

const storage = {
  getToken: (): string => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}_token`) as string)
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}_token`, JSON.stringify(token))
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}_token`)
  },
}

export default storage