import React, {useEffect} from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

interface ProductLabelDropdownMenuProps {
  onChange: (value: string) => void
  menuValues: Array<string>
  buttonText: string
}

const ProductLabelDropdownMenu = (props: ProductLabelDropdownMenuProps) => {

  const [buttonText, setButtonText] = React.useState<string>(props.buttonText)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)


  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
    props.menuValues.map(item => {
      if (e.currentTarget.textContent === item) {
        changeButtonText(item)
      }
      return null
    })
    handleClose()
  }

  const changeButtonText = (text: string) => {
    if (text !== buttonText) {
      props.onChange(text)
    }
    setButtonText(text)
  }

  return (
    <React.Fragment>
      <Button
        color='alpha'
        id='dropdown-menu-button'
        aria-controls={open ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          fontFamily: 'button.medium',
          border: '1px solid black'
        }}
        size='small' variant='contained' endIcon={<KeyboardArrowDown />}>
        {buttonText}
      </Button>
      <Menu id='dropdown-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": 'dropdown-menu-button'
        }}>
        {
          props.menuValues.map(item => {
            return (
              <MenuItem onClick={handleMenuItemClick}>{item}</MenuItem>
            )
          })
        }
      </Menu>
    </React.Fragment>
  )
}

export default ProductLabelDropdownMenu