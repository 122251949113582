const sortAlphaNumericArrayList = (arrayList: string[]) => {
  const reA = /[^a-zA-Z]/g
  const reN = /[^0-9]/g

  const result = arrayList.sort((a, b) => {
    const aA = a.replace(reA, '')
    const bA = b.replace(reA, '')
    if (aA === bA) {
      const aN = parseInt(a.replace(reN, ''), 10)
      const bN = parseInt(b.replace(reN, ''), 10)
      return aN === bN ? 0 : aN > bN ? 1 : -1
    } 
    return aA > bA ? 1 : -1
  })
  return result
}

export default sortAlphaNumericArrayList