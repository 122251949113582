import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography, Stack, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { BomDetailsType, locationList, locationDetailsType } from '../types'
import React, { useEffect, useState } from 'react'
import {roundNumberToSignificantDigits} from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface BomStatusDataDataProps {
  bomDetailsList: BomDetailsType[]
  locationResultList: locationDetailsType[]
}


function BomStatusDataData(props: BomStatusDataDataProps) {
  const { bomDetailsList, locationResultList } = props

  const [locationList, setLocationList] = useState<locationList[]>([])
  const [bomDetailsFliteredData, setBomDetailsFliteredData] = useState<BomDetailsType[]>([])
  
  

  useEffect(()=>{
    const locationList = [...bomDetailsList.reduce((reducer, object) => {
      const { location } = object
      const locationName = getLocationNameBasedOnLocationCode(location)
      return reducer.set(location, reducer.get(location) || Object.assign({}, { location: locationName, locationCode: location, selected: true }))
    }, new Map()).values()]
    setLocationList(locationList)
  }, [bomDetailsList])


  /** Function responsible to return location based on location name 
   * @param {string} The location code
   * @return {string} locationName The location name
   */
  const getLocationNameBasedOnLocationCode = (locationCode: string)=>{
    const locationObject = locationResultList.find(locationObject => locationObject.location === locationCode)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.locationName
  }

  /**
   * This function is responsible to handle list of locations selected
   */
  const locationCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const actionedLocation = event.target.name

    const locationSelectedResult = locationList.map((locationObject)=>{
      const {location, locationCode, selected} = locationObject
      
      if (actionedLocation === location){
        let isSelected = false
        if (selected === false) { isSelected = true}

        return {location, locationCode, selected: isSelected}
      }
      return {location, locationCode, selected: selected}
    })
    setLocationList(locationSelectedResult)
  }
 
  useEffect(()=>{

    const filteredData: BomDetailsType[] = []
    locationList.map((locationObject)=>{
      const {locationCode, selected} = locationObject

      if (selected ===  true){
        bomDetailsList.map((bomDetailObject)=>{
          if (locationCode === bomDetailObject.location) {
            filteredData.push(bomDetailObject)
          }
          return null
        })
      }
      return null
    })
    setBomDetailsFliteredData(filteredData)
  }, [locationList])


  /** Function responsible to create table rows for size wise bom quantity */
  const getBomDetailsBomQuantityRow = (data: BomDetailsType)=>{
    let rowTotal = 0
    return (
      <TableRow key={'bomQuantity'}>
        <TableCell>{'BOM QUANTITY'}</TableCell>
        {
          data.sizeDetails.map(({size}) => {
            let sizeBomQuantity = 0
            data.sizeDetails.map((sizeObject)=>{
              if (sizeObject.size === size){
                sizeBomQuantity += sizeObject.quantity
                rowTotal += sizeObject.quantity
              }
              return null  
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeBomQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }


  /** Function responsible to create table rows for size wise Scanned quantity */
  const getBomDetailsScannedQuantityRow = (data: BomDetailsType)=>{
    let rowTotal = 0
    return (
      <TableRow key={'scannedQuantity'}>
        <TableCell>{'SCANNED QUANTITY'}</TableCell>
        {
          data.sizeDetails.map(({size}) => {
            let sizeScannedQuantity = 0
            data.sizeDetails.map((sizeObject)=>{
              if (sizeObject.size === size){
                sizeScannedQuantity += sizeObject.scannedQuantity
                rowTotal += sizeObject.scannedQuantity
              }
              return null  
            })
            return (<TableCell>{roundNumberToSignificantDigits(sizeScannedQuantity, 0)}</TableCell>)
          })
        }
        <TableCell>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }

  /** Function responsible to create table rows for size wise Difference quantity */
  const getBomDetailsDifferenceQuantityRow = (data: BomDetailsType)=>{
    let rowTotal = 0
    return (
      <TableRow key={'differenceQuantity'}>
        <TableCell>{'DIFFERENCE'}</TableCell>
        {
          data.sizeDetails.map(({size}) => {
            let sizeDifferenceQuantity = 0
            data.sizeDetails.map((sizeObject)=>{
              if (sizeObject.size === size){
                sizeDifferenceQuantity += (sizeObject.scannedQuantity - sizeObject.quantity)
                rowTotal += (sizeObject.scannedQuantity - sizeObject.quantity)
              }
              return null  
            })
            return (<TableCell sx={{color: sizeDifferenceQuantity < 0 ? 'red' : null, fontWeight: 'bold'}}>{roundNumberToSignificantDigits(sizeDifferenceQuantity, 0)}</TableCell>)
          })
        }
        <TableCell sx={{color: rowTotal < 0 ? 'red' : null, fontWeight: 'bold'}}>{roundNumberToSignificantDigits(rowTotal, 0)}</TableCell>
      </TableRow>
    )
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []
    bomDetailsFliteredData.map((object, Index)=>{
      const {sizeDetails} = object
      return sizeDetails.map((sizeObject)=>{
        const {size, quantity, scannedQuantity} = sizeObject
        const rowObject =  {
          SlNo: Index + 1, 
          factory: object.location, 
          ocNumber: object.ocNumber,
          productCode: object.productCode,
          productDescription: object.productDescription,
          poNumber: object.poNumber, 
          country: object.shipTo, 
          colourCode: object.colourCode,
          size,
          quantity,
          scannedQuantity,
          differenceQuantity: (scannedQuantity - quantity)
        }
        jsonData.push(rowObject)
        return null
      })
    })
    
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'bomData')
    XLSX.writeFile(workBook, 'bomData.xlsx')
  }

  return (
    <React.Fragment>
      <Box sx={{
        paddingTop: 2,
        paddingBottom: 1,
        backgroundColor: 'white'
      }}>

        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ paddingLeft: 2, fontWeight: 'bold'}} variant='body2' align='left'>LOCATIONS</Typography>
          <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
        </Box>

        <Box sx={{
          paddingBottom: 2,
          backgroundColor: 'white',
          paddingTop: 2
        }}>
          <Stack spacing={2} direction="row">
            
            {
              locationList.map((locationObject)=>{
                const {location, selected} = locationObject
                return (
                  <FormGroup sx={{ paddingLeft: 2}}>
                    <FormControlLabel  control={<Checkbox checked={selected} onChange={locationCheckBoxChange} name={location} />} label={location} />
                  </FormGroup>
                )
              })
            }
            
          </Stack>
        </Box>
      </Box>
      
      {
        bomDetailsFliteredData.map((bomObject)=>{
          const locationName = getLocationNameBasedOnLocationCode(bomObject.location)
          return (
            <Box sx={{
              paddingTop: 2,
              paddingBottom: 1,
              marginTop: 2,
              marginBottom: 2,
              backgroundColor: 'white'
            }}>
              <Stack direction='row' justifyContent='space-between' sx={{paddingLeft: 2, paddingRight: 2}}>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>{`UNIT: ${locationName}`}</Typography>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>{`OC: ${bomObject.ocNumber}`}</Typography>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>{`PO: ${bomObject.poNumber}`}</Typography>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>{`COUNTRY: ${bomObject.shipTo}`}</Typography>
                <Typography variant='subtitle1' sx={{color: 'alpha.main'}}>{`COLOUR: ${bomObject.colourCode}`}</Typography>
              </Stack>

              <Box sx={{paddingTop: 2}}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: 'alpha.lightest'
                        }}
                      >
                        <TableCell><Typography variant='subtitle1'>SIZES</Typography></TableCell>
                        {
                          bomObject.sizeDetails.map((size) => {
                            return (
                              <TableCell><Typography variant='subtitle1'>{size.size}</Typography></TableCell>
                            )
                          })
                        }
                        <TableCell><Typography variant='subtitle1'>TOTAL</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getBomDetailsBomQuantityRow(bomObject)}
                      {getBomDetailsScannedQuantityRow(bomObject)}
                      {getBomDetailsDifferenceQuantityRow(bomObject)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

            </Box>
          )
               
        })
      }
     

    </React.Fragment>
  )
}

export default BomStatusDataData