import React, { useEffect, useState } from 'react'
import { HourlyDefectData, DHUDataDetails } from '../types'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material'
import {roundNumberToSignificantDigits, convertNumberToLocaleString} from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface DHUPassedSingleDateTableProps {
  data: DHUDataDetails[]
}

type TableDataType = {
  [x: string]: DHUDataDetails[]
}

type HourlyDataType = {
  [x: string]: HourlyDefectData[]
}

const DHUPassedSingleDateTable = (props: DHUPassedSingleDateTableProps) => {
  const { data } = props

  const [tableData, setTableData] = useState<TableDataType>({})
  const [hourlyData, setHourlyData] = useState<HourlyDataType>({})

  useEffect(() => {
    const formatDataForTable = (data: DHUDataDetails[]) => {
      const result: TableDataType = groupBy(data, (i) => i.batchNumber)
      return result
    }

    const combinedHourlyDetails = data.reduce((acc: HourlyDefectData[], value: DHUDataDetails) => {
      acc.push(...value.hourlyDetails)
      return acc
    }, [])

    const groupDataByStartTime = (data: HourlyDefectData[]) => {
      const result: HourlyDataType = groupBy(data, (i) => i.startTime)
      return result
    }

    const formattedData = formatDataForTable(data)
    setTableData(formattedData)

    const hourlyFormattedData = groupDataByStartTime(combinedHourlyDetails)
    setHourlyData(hourlyFormattedData)
  }, [data])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)
    
  const renderTableRows = (tableData: TableDataType) => {
    let rowIndex = 0

    const keys = Object.keys(tableData)
    return keys.map((key, index) => {
      return tableData[key].map((item, index) => {
        const { batchNumber, hourlyDetails, productCode, productDescription, buyerName, totalPassedQuantity, totalRejectedQuantity, totalReworkedQuantity } = item
        rowIndex += 1
        let totalDefects = 0
        const totalQuantity = totalPassedQuantity + totalRejectedQuantity + totalReworkedQuantity

        return (
          <TableRow key={`${rowIndex}`}>
            <TableCell align='center'>{batchNumber}</TableCell>
            <TableCell align='center'>{productCode}</TableCell>
            <TableCell align='center'>{productDescription}</TableCell>
            <TableCell align='center'>{buyerName}</TableCell>
            
            {
              Object.keys(hourlyData).map((key, index) => {
                const filteredObject = hourlyDetails.filter(obj => {
                  return obj.startTime === key
                })
                if (filteredObject.length > 0){
                  const {passedQuantity, defectDetails} = filteredObject[0]
                  let defectQuantity = 0

                  defectDetails.map((obj)=>{
                    totalDefects += obj.quantity
                    defectQuantity += obj.quantity
                    return null
                  })

                  return (<TableCell align='center'>{passedQuantity}/{defectQuantity}</TableCell>)  
                }
                return (<TableCell align='center'>{0}</TableCell>)  
              })
            }
            <TableCell align='center' sx={{fontWeight: 'bold'}}>{totalPassedQuantity}</TableCell>
            <TableCell align='center' sx={{fontWeight: 'bold'}}>{totalDefects}</TableCell>
            <TableCell align='center' sx={{fontWeight: 'bold'}}>{totalRejectedQuantity}</TableCell>
            <TableCell align='center' sx={{fontWeight: 'bold'}}>{totalQuantity > 0 ? roundNumberToSignificantDigits((totalDefects * 100 / totalQuantity), 2) : 0 }</TableCell>

          </TableRow>
        )
      })
    })
  }

  const renderRowsTotal = (data: HourlyDataType) => {
    let overAllPassedQuantity = 0
    let overAllDefectQuantity = 0
    let overAllRejectedQuantity = 0
    let totalDHUQuantity = 0

    return (
      <TableRow key={`final-row`}>
        <TableCell align='center' sx={{
          fontWeight: 'bold'
        }}>TOTAL</TableCell>
        <TableCell/>
        <TableCell/>
        <TableCell/>

        {
          Object.entries(data).map(([key, value], index) =>{
            let totalDefectQuantity = 0

            const totalPassedQuantity = value.reduce((acc, curr) => {
              const { passedQuantity } = curr
              return acc += passedQuantity
            }, 0)
            
            
            value.map((obj)=>{
              const { defectDetails } = obj
              totalDefectQuantity += defectDetails.reduce((acc, curr) => {
                return acc += curr.quantity
              }, 0)
              return null
            })
            

            overAllRejectedQuantity += value.reduce((acc, curr) => {
              const { rejectedQuantity } = curr
              return acc += rejectedQuantity
            }, 0)

            overAllDefectQuantity += totalDefectQuantity
            overAllPassedQuantity += totalPassedQuantity
            totalDHUQuantity = overAllPassedQuantity + overAllDefectQuantity + overAllRejectedQuantity

            return <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>{totalPassedQuantity}/{totalDefectQuantity}</TableCell>

          })
        }
        <TableCell align='center' sx={{
          fontWeight: 'bold'
        }}>{convertNumberToLocaleString(roundNumberToSignificantDigits(overAllPassedQuantity, 0))}</TableCell>

        <TableCell align='center' sx={{
          fontWeight: 'bold'
        }}>{convertNumberToLocaleString(roundNumberToSignificantDigits(overAllDefectQuantity, 0))}</TableCell>

        <TableCell align='center' sx={{
          fontWeight: 'bold'
        }}>{convertNumberToLocaleString(roundNumberToSignificantDigits(overAllRejectedQuantity, 0))}</TableCell>

        <TableCell align='center' sx={{
          fontWeight: 'bold'
        }}>{totalDHUQuantity > 0 ? roundNumberToSignificantDigits((overAllDefectQuantity * 100 / totalDHUQuantity), 2) : 0}</TableCell>
        

      </TableRow>
    )

  }

  /** Row total to include in download row */
  const rowTotalForDownload = () => {
    let overAllPassedQuantity = 0
    let overAllDefectQuantity = 0
    let overAllRejectedQuantity = 0
    let totalDHUQuantity = 0

    const object: any = {
      batchNumber: 'total', 
      product: '',
      productName: '',
      buyerName: ''
    }

    Object.entries(hourlyData).map(([key, value], index) =>{
      let totalDefectQuantity = 0

      const totalPassedQuantity = value.reduce((acc, curr) => {
        const { passedQuantity } = curr
        return acc += passedQuantity
      }, 0)
        
        
      value.map((obj)=>{
        const { defectDetails } = obj
        totalDefectQuantity += defectDetails.reduce((acc, curr) => {
          return acc += curr.quantity
        }, 0)
        return null
      })
        

      overAllRejectedQuantity += value.reduce((acc, curr) => {
        const { rejectedQuantity } = curr
        return acc += rejectedQuantity
      }, 0)

      overAllDefectQuantity += totalDefectQuantity
      overAllPassedQuantity += totalPassedQuantity
      totalDHUQuantity = overAllPassedQuantity + overAllDefectQuantity + overAllRejectedQuantity

      
      object[`${value[0].startTime}-${value[0].endTime}`] = `${totalPassedQuantity}/${totalDefectQuantity}`
      return null

    })

    object['totalPass'] = roundNumberToSignificantDigits(overAllPassedQuantity, 0)
    object['totalDefect'] = roundNumberToSignificantDigits(overAllDefectQuantity, 0)
    object['totalRejected'] = roundNumberToSignificantDigits(overAllRejectedQuantity, 0)
    object['DHU%'] = `${totalDHUQuantity > 0 ? roundNumberToSignificantDigits((overAllDefectQuantity * 100 / totalDHUQuantity), 2) : 0}`

    return object
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []

    const keys = Object.keys(tableData)
    keys.map((key, index) => {
      tableData[key].map((item, index) => {
        const { batchNumber, hourlyDetails, productCode, productDescription, buyerName, totalPassedQuantity, totalRejectedQuantity, totalReworkedQuantity } = item
        let totalDefects = 0
        const totalQuantity = totalPassedQuantity + totalRejectedQuantity + totalReworkedQuantity
        
        const pushObject: any = {
          batchNumber,
          product: productCode,
          productName: productDescription,
          buyerName
        }

        Object.keys(hourlyData).map((key, index) => {
          const filteredObject = hourlyDetails.filter(obj => {
            return obj.startTime === key
          })
          if (filteredObject.length > 0){
            const {passedQuantity, defectDetails} = filteredObject[0]
            let defectQuantity = 0

            defectDetails.map((obj)=>{
              totalDefects += obj.quantity
              defectQuantity += obj.quantity
              return null
            })

            pushObject[`${filteredObject[0].startTime}-${filteredObject[0].endTime}`] = `${passedQuantity}/${defectQuantity}`
            return null
          }
          pushObject[key] = 0
        })

        pushObject['totalPass'] = totalPassedQuantity
        pushObject['totalDefect'] = totalDefects
        pushObject['totalRejected'] = totalRejectedQuantity
        pushObject['DHU%'] = `${totalQuantity > 0 ? roundNumberToSignificantDigits((totalDefects * 100 / totalQuantity), 2) : 0 }`
        jsonData.push(pushObject)
        return null
      })
      return null
    })

    const rowTotalResult = rowTotalForDownload()
    jsonData.push(rowTotalResult)

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'Hourly-End-Table-Single-Date')
    XLSX.writeFile(workBook, 'Hourly-End-Table-Single-Date.xlsx')
  }


  return (
    <TableContainer component={Paper}>

      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}>
        <Typography variant='h5' padding={2}>Hourly End Table Pass-Single Date</Typography>
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>
      
      <Table sx={{
        overflowX: 'auto',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        height: 450,
        overflowY: 'auto'
      }}>
        <TableHead>
          <TableRow sx={{
            backgroundColor: 'alpha.lightest'
          }}>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>BATCH</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>PRODUCT</TableCell>


            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>PRODUCT NAME</TableCell>

            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>BUYER NAME</TableCell>
            
            {
              Object.keys(hourlyData).map((key, index) => {
                const { startTime,  endTime} = hourlyData[key][0]
                return <TableCell align='center' sx={{
                  fontWeight: 'bold'
                }}>{`${startTime}-${endTime}`}</TableCell>
              })
            }
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>TOTAL PASS</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>TOTAL DEFECT</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>TOTAL REJECTED</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>DHU%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows(tableData)}
          {renderRowsTotal(hourlyData)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DHUPassedSingleDateTable