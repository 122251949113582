import React, { useState } from 'react'
import { Box, Tabs, Tab} from '@mui/material'
import { GenerateQrLabel, ReprintQrLabel } from './components'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { TabPanel } from './components'
import LoadingDetails from './components/LoadingDetails'


interface ProductLabelProps {
  componentHeader : (headerName : string) => void
}



const ProductLabel = (props: ProductLabelProps) => {

  const [tabState, setTabState] = useState(0)
  
  useAuthenticationToken()
  props.componentHeader('PRODUCT LABEL BARCODES')

  
  return (
    <React.Fragment>
      <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
        setTabState(newValue)
      }} textColor='secondary' TabIndicatorProps={{
        style: {
          backgroundColor: 'alpha.light'
        }
      }}>
        <Tab sx={{color: 'alpha.light'}} label="GENERATE QR LABELS" />
        <Tab sx={{color: 'alpha.light'}} label="RE-PRINT QR LABELS" />
        <Tab sx={{color: 'alpha.light'}} label="LOADING DETAILS" />
      </Tabs>

      <Box sx={{
        paddingTop: 2
      }}> 
        <TabPanel value={tabState} index={0}>
          <Box sx={{ paddingTop: 2, marginLeft: 1}}>
            <GenerateQrLabel/>  
          </Box>
        </TabPanel>

        <TabPanel value={tabState} index={1}>

          <Box sx={{ paddingTop: 2, marginLeft: 1}}>
            <ReprintQrLabel/>
          </Box>

        </TabPanel>
        <TabPanel value={tabState} index={2}>

          <Box sx={{ paddingTop: 2, marginLeft: 1}}>
            <LoadingDetails data={[]}/>
          </Box>

        </TabPanel>
      </Box> 
                
    </React.Fragment >
  )
}

export default ProductLabel