
enum Permissions {
  createBarcodes = 'create:Barcodes',
  viewDashboard = 'view:Dashboard',
  createZebraPrint = 'create:ZebraPrint',
  createOrders = 'create:Orders',
  updateRfid = 'update:Rfid',
  updateTechPack = 'update:Tech-Pack',
  createFabricBarcode = 'create:FabricBarcode',
  readFabricBarcode = 'read:FabricBarcode',
  updateFabricBarcode = 'update:FabricBarcode',
  deleteFabricBarcode = 'delete:FabricBarcode',
  createMarker = 'create:Marker',
  readMarker = 'read:Marker',
  updateMarker = 'update:Marker',
  deleteMarker = 'delete:Marker',
  createJob = 'create:Job',
  readJob = 'read:Job',
  updateJob = 'update:Job',
  deleteJob = 'delete:Job',
  createParts = 'create:Parts',
  readParts = 'read:Parts',
  updateParts = 'update:Parts',
  deleteParts = 'delete:Parts',
  createPartsBarcode = 'create:PartsBarcode',
  readPartsBarcode = 'read:PartsBarcode',
  updatePartsBarcode = 'update:PartsBarcode',
  deletePartsBarcode = 'delete:PartsBarcode',
  createBatch = 'create:Batch',
  readBatch = 'read:Batch',
  updateBatch = 'update:Batch',
  deleteBatch = 'delete:Batch',
  readDefectsList = 'read:DefectsList',
  createDefectsList = 'create:DefectsList',
  updateDefectsList = 'update:DefectsList',
  deleteDefectsList = 'delete:DefectsList',
  createDHU = 'create:DHU',
  readDHU = 'read:DHU',
  deleteDHU = 'delete:DHU',
  createDispatch = 'create:Dispatch',
  readDispatch = 'read:Dispatch',
  deleteDispatch = 'delete:Dispatch',
  createTechPack = 'create:TechPack',
  readTechPack = 'read:TechPack',
  deleteTechPack = 'delete:TechPack',
  createCarton = 'create:Carton',
  readCarton = 'read:Carton',
  updateCarton = 'update:Carton',
  deleteCarton = 'delete:Carton',
  createEPCDetails = 'create:EPCDetails',
  readEPCDetails = 'read:EPCDetails',
  deleteEPCDetails = 'delete:EPCDetails',
  createRack = 'create:Rack',
  readRack = 'read:Rack',
  updateRack = 'update:Rack',
  deleteRack = 'delete:Rack',
  createPallet = 'create:Pallet',
  readPallet = 'read:Pallet',
  deletePallet = 'delete:Pallet',
  updatePallet = 'update:Pallet',
  readSageData = 'read:SageData',
  createSageData = 'create:SageData',
  readOverView = 'read:OverView',
  readOcTracker = 'read:OcTracker',
  createJobwork = 'create:Jobwork',
  readJobwork = 'read:Jobwork',
  createProductQr = 'create:ProductQr',
  readProductQr = 'read:ProductQr',
  readFinishing = 'read:Finishing',
  createFinishing = 'create:Finishing',
  readOcDetails = 'read:OcDetails'

}

export default Permissions