import React, { useState, useEffect } from 'react'
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, 
  Typography, Stack, FormGroup, FormControlLabel, Checkbox, Dialog } from '@mui/material'
import { TrackerCuttingData, TrackerOutPutData, GroupedOutPutData, locationList, CuttingDetailsData, TrackerProduct } from '../types'
import {roundNumberToSignificantDigits, convertNumberToLocaleString} from '../../utils'
import {FlatButton} from '../../UI/Components'
import ProductionDetail from './ProductionDetail'
import * as XLSX from 'xlsx'

interface TrackerSummaryProps {
  trackerCuttingData: TrackerCuttingData[]
  trackerOutPutData: TrackerOutPutData[]
  trackerCuttingColourData: CuttingDetailsData[]
  productList: TrackerProduct[]
}

type GroupedDataType = {
  [x: string]: GroupedOutPutData
}

type GroupedDataByLocationType = {
  [x: string]: GroupedOutPutData[]
}

function TrackerSummary(props: TrackerSummaryProps) {
  const {trackerCuttingData, trackerOutPutData, trackerCuttingColourData, productList} = props
  
  const [groupedTableData, setGroupedTableData] = useState<GroupedDataByLocationType>({})
  const [locationList, setLocationList] = useState<locationList[]>([])
  const [dialogOpen, setDialogOpen] = useState(false)

  const [locationFilteredOutPutData, setLocationFilteredOutPutData] = useState<TrackerOutPutData[]>([])
  const [locationFilteredCuttingData, setLocationFilteredCuttingData] = useState<TrackerCuttingData[]>([])
  const [locationFilteredCuttingDetailsData, setLocationFilteredCuttingDetailsData] = useState<CuttingDetailsData[]>([])
  const [selectedBatchDetails, setSelectedBatchDetails] = useState<TrackerOutPutData[]>([])


  
  useEffect(() => {
    const locationList = [...trackerOutPutData.reduce((reducer, object) => {
      const { location, locationName } = object
      return reducer.set(location, reducer.get(location) || Object.assign({}, { location: locationName, selected: true, locationCode: location }))
    }, new Map()).values()]
    setLocationList(locationList)

  }, [trackerOutPutData])

  /** Data preparation for table presentation */
  useEffect(() => {

    const selectedLocationOutputList : TrackerOutPutData[] = []
    const selectedLocationCuttingList : TrackerCuttingData[] = []
    const selectedLocationCuttingDetailsList : CuttingDetailsData[] = []

    const sortedData = sortData(trackerOutPutData)

    /** Filter output data based on selected location */
    locationList.map((locationObject)=>{
      const {location, selected} = locationObject

      if (selected ===  true){
        sortedData.map((outputObject)=>{
          if (location === outputObject.locationName) {
            selectedLocationOutputList.push(outputObject)
          }
          return null
        })
      }
      return null
    })

    const groupedDataList = presentationDataGroupedByLocationAndBatch(selectedLocationOutputList)
    const groupedDataByLocation  =  groupBy(groupedDataList, i => i.location)
    setGroupedTableData(groupedDataByLocation)

    setLocationFilteredOutPutData(selectedLocationOutputList)


    /** Filter cutting summary data based on selected location */
    locationList.map((locationObject)=>{
      const {selected, locationCode} = locationObject

      if (selected ===  true){
        trackerCuttingData.map((cuttingObject)=>{
          if (locationCode === cuttingObject.location) {
            selectedLocationCuttingList.push(cuttingObject)
          }
          return null
        })
      }
      return null
    })
    setLocationFilteredCuttingData(selectedLocationCuttingList)

    /** Filter cutting colour details data based on selected location */
    locationList.map((locationObject)=>{
      const {location, selected} = locationObject

      if (selected ===  true){
        trackerCuttingColourData.map((cuttingDetailObject)=>{
          if (location === cuttingDetailObject.locationName) {
            selectedLocationCuttingDetailsList.push(cuttingDetailObject)
          }
          return null
        })
      }
      return null
    })
    setLocationFilteredCuttingDetailsData(selectedLocationCuttingDetailsList)


  }, [locationList])

  const sortData = (data: TrackerOutPutData[]) => {
    return data.sort((a, b) => {
      if (a.batchUnique > b.batchUnique) {
        return 1
      } else if (a.batchUnique < b.batchUnique) {
        return -1
      } return 0
    })
  }

  useEffect(() => {
    const selectedBatchData: TrackerOutPutData[] = []

    Object.entries(groupedTableData as GroupedDataByLocationType).map(([key, value], index) =>{
      value.map((object)=>{
        const {location, batchNumber, selected} = object

        if (selected ===  true){
          const searchKey = `${location}-${batchNumber}`
          trackerOutPutData.map((outputObject)=>{
            if (`${outputObject.location}-${outputObject.batchNumber}` === searchKey){
              selectedBatchData.push(outputObject)
            }
            return null
          })
        }
        return null
      })
      return null
    })
    
    setSelectedBatchDetails(selectedBatchData)
  }, [groupedTableData])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)


  const presentationDataGroupedByLocationAndBatch = (data: TrackerOutPutData[])=>{
    const groupedDataListByLocationAndBatch: GroupedOutPutData[] = []

    const groupedData =  data.reduce(function (acc: GroupedDataType, cur) {
      const { date, location, locationName, batchUnique, batchNumber, outputQuantity, sewingSamProduced, sewingMachineMinutes, globalSamProduced, globalMachineMinutes, 
        totalWorkStation, numberOfMachines, sewingSam, globalSam, DHUPassedQuantity,  DHUReworkedQuantity, DHURejectedQuantity, loadingDate, loadingQuanity} = cur
      const uniqueKey = `${location}-${batchNumber}`

      const splitDateList = date.split('-')
      const formattedDate = `${splitDateList[2]}-${splitDateList[1]}-${splitDateList[0]}`

      let formattedDateLoadingDate = ''
      if (loadingDate !== null){
        const loadingDatesplitList = loadingDate.split('-')
        formattedDateLoadingDate = `${loadingDatesplitList[2]}-${loadingDatesplitList[1]}-${loadingDatesplitList[0]}`
      }

      

      let peakEfficiency = 0
      if (sewingMachineMinutes > 0){
        const sewingEfficiency = ((sewingSamProduced / sewingMachineMinutes) * 100)

        if (peakEfficiency < sewingEfficiency){
          peakEfficiency = sewingEfficiency
        }
      }

      if (!acc[uniqueKey]) {
        acc[uniqueKey] = {
          date: formattedDate,
          location,
          locationName,
          batchUnique,
          batchNumber,
          outputQuantity: 0,
          sewingSamProduced: 0,
          sewingMachineMinutes: 0,
          globalSamProduced: 0,
          globalMachineMinutes: 0,
          totalWorkStation: 0,
          numberOfMachines: 0,
          sewingSam,
          globalSam,
          DHUPassedQuantity: 0,
          DHUReworkedQuantity: 0,
          DHURejectedQuantity: 0,
          daysCount: 0,
          peakProductionQuantity: outputQuantity,
          peakEfficiency,
          selected: true,
          loadingDate: formattedDateLoadingDate,
          loadingQuanity
        }
      }
      acc[uniqueKey].outputQuantity += outputQuantity
      acc[uniqueKey].sewingSamProduced += sewingSamProduced
      acc[uniqueKey].sewingMachineMinutes += sewingMachineMinutes
      acc[uniqueKey].globalSamProduced += globalSamProduced
      acc[uniqueKey].globalMachineMinutes += globalMachineMinutes
      acc[uniqueKey].totalWorkStation += totalWorkStation
      acc[uniqueKey].numberOfMachines += numberOfMachines
      acc[uniqueKey].DHUPassedQuantity += DHUPassedQuantity
      acc[uniqueKey].DHUReworkedQuantity += DHUReworkedQuantity
      acc[uniqueKey].DHURejectedQuantity += DHURejectedQuantity
      acc[uniqueKey].daysCount += 1

      const splitFormattedDate = formattedDate.split('-')
      const splitFormattedActualDate = acc[uniqueKey].date.split('-')
      
      if (parseFloat(`${splitFormattedDate[2]}-${splitFormattedDate[1]}-${splitFormattedDate[0]}`) 
      < parseFloat(`${splitFormattedActualDate[2]}-${splitFormattedActualDate[1]}-${splitFormattedActualDate[0]}`)){
        acc[uniqueKey].date = formattedDate
      }

      if (outputQuantity > acc[uniqueKey].peakProductionQuantity){
        acc[uniqueKey].peakProductionQuantity = outputQuantity
      }

      if (peakEfficiency > acc[uniqueKey].peakEfficiency){
        acc[uniqueKey].peakEfficiency = peakEfficiency
      }

      return acc
    }, {})
      
    Object.entries(groupedData).forEach(([key, value]) => {
      groupedDataListByLocationAndBatch.push(value)
    })

    return groupedDataListByLocationAndBatch
  }
  /** Function to calculate total cut quantity 
   * @return {number} The total cut quantity 
   */
  const calculateCutQuantitiesTotal = () => {
    return locationFilteredCuttingData.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)
  }

  /** Function to calculate end table pass quantity 
   * @return {number} The total DHU pass quantity 
   */
  const calculateEndTablePassQuantitiesTotal = () => {
    return locationFilteredOutPutData.reduce((acc, curr) => {
      return acc + curr.DHUPassedQuantity
    }, 0)
  }

  /** Function to calculate dispatch(output) quantity 
   * @return {number} The total dispatch quantity 
   */
  const calculateDispatchQuantitiesTotal = () => {
    return locationFilteredOutPutData.reduce((acc, curr) => {
      return acc + curr.outputQuantity
    }, 0)
  }
  
  /**
   * This function is responsible to calculate DHU percentage
   */
  const calculateDHUPercent = () => {
    let totalPassedQuantity = 0
    let totalDefect = 0
    locationFilteredOutPutData.map((overViewObject)=>{
      const {DHUPassedQuantity, DHUReworkedQuantity, DHURejectedQuantity} = overViewObject

      totalPassedQuantity += DHUPassedQuantity
      totalDefect += (DHUReworkedQuantity + DHURejectedQuantity)
      return null
    })

    if (totalPassedQuantity > 0){
      return (totalDefect * 100) / totalPassedQuantity
    }
    return 0
  }

  /**
   * This function is responsible to calculate average global efficiency
   */
  const calculateAverageGlobalEfficiency = () => {
    const globalSamProduced = locationFilteredOutPutData.reduce((acc, curr) => {
      return acc + curr.globalSamProduced
    }, 0) as number

    const globalMachineMinutes = locationFilteredOutPutData.reduce((acc, curr) => {
      return acc + curr.globalMachineMinutes
    }, 0) as number

    let averageGlobalEfficiency = 0
    if (globalMachineMinutes > 0){
      averageGlobalEfficiency = ((globalSamProduced / globalMachineMinutes) * 100)
    }
    return averageGlobalEfficiency
  }

  /**
   * This function is responsible to calculate average sewing efficiency
   */
  const calculateAverageSewingEfficiency = () => {
    const sewingSamProduced = locationFilteredOutPutData.reduce((acc, curr) => {
      return acc + curr.sewingSamProduced
    }, 0) as number

    const sewingMachineMinutes = locationFilteredOutPutData.reduce((acc, curr) => {
      return acc + curr.sewingMachineMinutes
    }, 0) as number

    let averageSewingEfficiency = 0
    if (sewingMachineMinutes > 0){
      averageSewingEfficiency = ((sewingSamProduced / sewingMachineMinutes) * 100)
    }
    return averageSewingEfficiency
  }

  /**
   * This function is responsible to fetch peak production value
   */
  const getPeakProduction = () => {
    let peakProductionValue = 0

    locationFilteredOutPutData.map((outputObject)=>{
      const {outputQuantity} = outputObject

      if (peakProductionValue < outputQuantity){
        peakProductionValue = outputQuantity
      }
      return null
    })

    return peakProductionValue
  }

  /**
   * This function is responsible to fetch peak sewing efficiency
   */
  const getPeakSewingEfficiency = () => {
    let peakSewingEfficiency = 0

    locationFilteredOutPutData.map((outputObject)=>{
      const {sewingSamProduced, sewingMachineMinutes} = outputObject

      if (sewingMachineMinutes > 0){
        const sewingEfficiency = ((sewingSamProduced / sewingMachineMinutes) * 100)

        if (peakSewingEfficiency < sewingEfficiency){
          peakSewingEfficiency = sewingEfficiency
        }
      }
      return null
    })
    return peakSewingEfficiency
  }


  /**
   * This function is responsible to calculate average machines used
   */
  const calculateAverageMachinesUsed = () => {
    
    const DateList =  [...locationFilteredOutPutData.reduce((reducer, object) => {
      const { location, date } = object
      const key = `${date}-${location}`
      return reducer.set(key, reducer.get(key) || Object.assign({}, { date, location }))
    }, new Map()).values()]
    
    const totalMachines = locationFilteredOutPutData.reduce((acc, curr) => {
      return acc + curr.numberOfMachines
    }, 0) as number
    
    if (DateList.length > 0){
      return (totalMachines / DateList.length)
    }
    return totalMachines
  }

  /**
   * This function is responsible to calculate average workstations used
   */
  const calculateAverageWorkStationUsed = () => {
   
    const DateList =  [...locationFilteredOutPutData.reduce((reducer, object) => {
      const { location, date } = object
      const key = `${date}-${location}`
      return reducer.set(key, reducer.get(key) || Object.assign({}, { date, location }))
    }, new Map()).values()]
  
    const totalWorkStation = locationFilteredOutPutData.reduce((acc, curr) => {
      return acc + curr.totalWorkStation
    }, 0) as number
  
    if (DateList.length > 0){
      return (totalWorkStation / DateList.length)
    }
    return totalWorkStation
  }


  /**
   * This function is responsible to calculate average machines used in combination of location and batch
   */
  const tableRowAverageMachinesUsed = (data : GroupedOutPutData) => {
    const {numberOfMachines, daysCount} = data
    
    if (daysCount > 0){
      return (numberOfMachines / daysCount)
    }
    return numberOfMachines
  }

  /**
   * This function is responsible to calculate average workstations used in combination of location and batch
   */
  const tableRowAverageWorkStationUsed = (data : GroupedOutPutData) => {
    const {totalWorkStation, daysCount} = data
  
    if (daysCount > 0){
      return (totalWorkStation / daysCount)
    }
    return totalWorkStation
  }

  /**
   * This function is responsible to calculate average sewing efficiency in combination of location and batch
   */
  const tableRowAverageSewingEfficiency = (data : GroupedOutPutData) => {
    const {sewingSamProduced, sewingMachineMinutes} = data

    let averageSewingEfficiency = 0
    if (sewingMachineMinutes > 0){
      averageSewingEfficiency = ((sewingSamProduced / sewingMachineMinutes) * 100)
    }
    return averageSewingEfficiency
  }

  /**
   * This function is responsible to calculate average global efficiency in combination of location and batch
   */
  const tableRowAverageGlobalEfficiency = (data : GroupedOutPutData) => {

    const {globalSamProduced, globalMachineMinutes} = data

    let averageGlobalEfficiency = 0
    if (globalMachineMinutes > 0){
      averageGlobalEfficiency = ((globalSamProduced / globalMachineMinutes) * 100)
    }
    return averageGlobalEfficiency
  }

  /**
   * This function is responsible to calculate average DHU percentage in combination of location and batch
   */
  const tableRowAverageDHUPercent = (data : GroupedOutPutData) => {

    const {DHUPassedQuantity, DHUReworkedQuantity, DHURejectedQuantity} = data
    const totalDefect = DHUReworkedQuantity + DHURejectedQuantity

    if (DHUPassedQuantity > 0){
      return (totalDefect * 100) / DHUPassedQuantity
    }
    return 0
  }

  /**
   * This function is responsible to handle list of locations selected
   */
  const locationCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const actionedLocation = event.target.name

    const locationSelectedResult = locationList.map((locationObject)=>{
      const {location, selected, locationCode} = locationObject
      
      if (actionedLocation === location){
        let isSelected = false
        if (selected === false) { isSelected = true}

        return {location, selected: isSelected, locationCode}
      }
      return {location, selected: selected, locationCode}
    })
    setLocationList(locationSelectedResult)
  }



  /**Handle tracker details button click */
  const handleTrackerButtonClick = () => {
    setDialogOpen(true)
  }

  /**Handle dialog close
   * @param {boolean} value The status of dialog
   */
  const handleDialogComponentClose = () => {
    setDialogOpen(false)
  }


  const batchCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const locationBatch = event.target.name
    const locationBatchSplitList = locationBatch.split('-')
    const checkedStatus = event.target.checked

    const groupedData: GroupedDataByLocationType = {}
    Object.entries(groupedTableData as GroupedDataByLocationType).map(([key, value], index) =>{
      if (locationBatchSplitList[0] === key){

        const batchDetails = value.map((object)=>{
          if (locationBatchSplitList[1] === object.batchNumber){
            return {...object, selected: checkedStatus}
          }
          return {...object}
        })
        groupedData[key] = [...batchDetails]
      } else {
        groupedData[key] = [...value]
      }
      return null
    })
    setGroupedTableData(groupedData)
  }

  /**Function to calculate average fabric consumption  */
  const calculateTotalAverageFabricConsumption = () => {

    const totalQuantity = locationFilteredCuttingDetailsData.reduce((acc, curr) => {
      return acc + curr.totalQuantity
    }, 0) as number

    const totalFabricUtilized = locationFilteredCuttingDetailsData.reduce((acc, curr) => {
      return acc + curr.totalFabricUtilized
    }, 0) as number

    if (totalQuantity > 0){
      return (totalFabricUtilized / totalQuantity)
    }
    return 0
  }


  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []
    
    Object.entries(groupedTableData as GroupedDataByLocationType).map(([key, value], index) =>{
      value.map((object)=>{
        const {locationName, batchNumber, date, DHUPassedQuantity, outputQuantity, daysCount, peakProductionQuantity, peakEfficiency}  = object
        const pushObject: any = {
          product: productList[0].productCode,
          productName: productList[0].productDescription,
          style: productList[0].styleNumber,
          styleName: productList[0].styleDescription,
          factoryName: locationName, 
          lineName: `batch ${batchNumber}`,
          startDate: date,
          endTablePass: DHUPassedQuantity,
          totalDispatch: outputQuantity,
          agvMachinesUsed: roundNumberToSignificantDigits(tableRowAverageMachinesUsed(object), 0),
          avgWorkStationUsed: roundNumberToSignificantDigits(tableRowAverageWorkStationUsed(object), 0),
          avgMachinesEfficiency: roundNumberToSignificantDigits(tableRowAverageSewingEfficiency(object), 2),
          avgGlobalEfficiency: roundNumberToSignificantDigits(tableRowAverageGlobalEfficiency(object), 2),
          peakProduction: roundNumberToSignificantDigits(peakProductionQuantity, 0),
          peakMachineEfficiency: roundNumberToSignificantDigits(peakEfficiency, 2),
          numberOfManDays: daysCount,
          avgDHUPercentage: roundNumberToSignificantDigits(tableRowAverageDHUPercent(object), 2)
        }
        jsonData.push(pushObject)
        return null
      })
      return null
    })

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'ocTracker-LineSummary')
    XLSX.writeFile(workBook, 'ocTracker-LineSummary.xlsx')
  }

  return (
    
    <React.Fragment>
      <Box sx={{
        paddingTop: 2,
        paddingBottom: 1,
        backgroundColor: 'white'
      }}>

        <Typography sx={{ paddingLeft: 2}} variant='body2' align='left'>OC RUN FACTORIES</Typography>
        
        <Box sx={{
          paddingBottom: 2,
          backgroundColor: 'white'
        }}>
          <Stack spacing={2} direction="row">
            
            {
              locationList.map((locationObject)=>{
                const {location, selected} = locationObject
                return (
                  <FormGroup sx={{ paddingLeft: 2}}>
                    <FormControlLabel  control={<Checkbox checked={selected} onChange={locationCheckBoxChange} name={location} />} label={location} />
                  </FormGroup>
                )
              })
            }
            
          </Stack>
        </Box>
      </Box>
      <Box>
        <Typography>Note: No. of run/man days considered based on loading start date and end date of dispatch</Typography>
      </Box>
      {
        locationFilteredOutPutData.length > 0 ?
          <Box sx={{paddingTop: 1}}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: 'alpha.lightest'
                    }}
                  >
                    <TableCell align='center'><Typography variant='subtitle1'>AVG FABRIC CONSUMPTION</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>TOTAL CUT</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>END TABLE PASS</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>TOTAL DISPATCH</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>MACHINE SAM</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>GLOBAL SAM</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG MACHINES USED</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG W/S USED</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG MACHINE EEFICIENCY</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG GLOBAL EEFICIENCY</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>PEAK PRODUCTION</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>PEAK MACHINE EFFICIENCY</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG DHU%</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>NO OF RUN/MAN DAYS</Typography></TableCell>
                  </TableRow>
                </TableHead>
            

                <TableBody>
                  <TableRow key={1}>
                    <TableCell align='center'>{roundNumberToSignificantDigits(calculateTotalAverageFabricConsumption(), 2)}m</TableCell>
                    <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(calculateCutQuantitiesTotal(), 0))}</TableCell>
                    <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(calculateEndTablePassQuantitiesTotal(), 0))}</TableCell>
                    <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(calculateDispatchQuantitiesTotal(), 0))}</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(locationFilteredOutPutData[0].sewingSam, 2)}</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(locationFilteredOutPutData[0].globalSam, 2)}</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(calculateAverageMachinesUsed(), 0)}</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(calculateAverageWorkStationUsed(), 0)}</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(calculateAverageSewingEfficiency(), 2)}%</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(calculateAverageGlobalEfficiency(), 2)}%</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(getPeakProduction(), 0)}</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(getPeakSewingEfficiency(), 2)}%</TableCell>
                    <TableCell align='center'>{roundNumberToSignificantDigits(calculateDHUPercent(), 2)}</TableCell>
                    <TableCell align='center'>{locationFilteredOutPutData.length}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
      
            <Box display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{paddingTop: 1, paddingBottom: 1}}>
              <Stack direction= 'row' spacing={2}>
                <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
                <FlatButton label='Track' onClick={handleTrackerButtonClick} disableControlsStatus={false}/>
              </Stack>
              
            </Box>
 
            <Dialog onClose={handleDialogComponentClose} open={dialogOpen} PaperProps={{
              sx: {
                width: '100%',
                maxWidth: '90%',
                overflowY: 'scroll',
                overflowX: 'scroll',
                scrollBehavior: 'auto'
              }
            }}>
              {
                selectedBatchDetails.length > 0
                  ? <ProductionDetail trackerOutPutData={selectedBatchDetails}/>
                  : null
              }
              
            </Dialog> 

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: 'alpha.lightest'
                    }}
                  >
                    <TableCell align='center'><Typography variant='subtitle1'>FACTORY NAME</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>LINE NAME</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>DISPATCH START DATE</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>END TABLE PASS</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>TOTAL DISPATCH</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG MACHINES USED</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG W/S USED</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG MACHINES EFFICIENCY</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG GLOBAL EFFICIENCY</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>PEAK PRODUCTION</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>PEAK MACHINE EFFICIENCY</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>NO OF RUN/MAN DAYS</Typography></TableCell>
                    <TableCell align='center'><Typography variant='subtitle1'>AVG DHU%</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    Object.entries(groupedTableData as GroupedDataByLocationType).map(([key, value], index) =>{
                      return value.map((object)=>{
                        const {locationName, batchNumber, date, DHUPassedQuantity, outputQuantity, daysCount, peakProductionQuantity, 
                          peakEfficiency, selected, loadingDate, loadingQuanity}  = object
                        return (
                          <TableRow key={`${key}-${batchNumber}`}>
                            <TableCell align='center'>{locationName}</TableCell>
                            <TableCell align='center'>
                              <FormGroup sx={{ paddingLeft: 2}}>
                                <FormControlLabel  control={<Checkbox checked={selected} onChange={batchCheckBoxChange} name={`${key}-${batchNumber}`} />} label={batchNumber} />
                              </FormGroup>
                            </TableCell>
                            
                            <TableCell align='center'>{date}</TableCell>
                            <TableCell align='center'>{convertNumberToLocaleString(DHUPassedQuantity)}</TableCell>
                            <TableCell align='center'>{convertNumberToLocaleString(outputQuantity)}</TableCell>
                            <TableCell align='center'>{roundNumberToSignificantDigits(tableRowAverageMachinesUsed(object), 0)}</TableCell>
                            <TableCell align='center'>{roundNumberToSignificantDigits(tableRowAverageWorkStationUsed(object), 0)}</TableCell>
                            <TableCell align='center'>{roundNumberToSignificantDigits(tableRowAverageSewingEfficiency(object), 2)}%</TableCell>
                            <TableCell align='center'>{roundNumberToSignificantDigits(tableRowAverageGlobalEfficiency(object), 2)}%</TableCell>
                            <TableCell align='center'>{roundNumberToSignificantDigits(peakProductionQuantity, 0)}</TableCell>
                            <TableCell align='center'>{roundNumberToSignificantDigits(peakEfficiency, 2)}%</TableCell>
                            <TableCell align='center'>{daysCount}</TableCell>
                            <TableCell align='center'>{roundNumberToSignificantDigits(tableRowAverageDHUPercent(object), 2)}%</TableCell>
                          </TableRow>
                        )
                      })
                
                    })
                  }
          
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          : null
      }
    </React.Fragment>
  )
}

export default TrackerSummary