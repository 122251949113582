import React, {useEffect} from 'react'
import { Button, Menu, MenuItem, FormControl } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'

interface DropdownMenuProps {
  onChange: (value: string) => void
  menuValues: Array<string>
}

const ManageOrderDropdownMenu = (props: DropdownMenuProps) => {

  const {onChange, menuValues} = props

  const [buttonText, setButtonText] = React.useState<string>(menuValues[0])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    if (menuValues.length === 1){
      setButtonText(menuValues[0])
    }
  }, [menuValues])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
    menuValues.map(item => {
      if (e.currentTarget.textContent === item) {
        changeButtonText(item)
      }
      return null
    })
    handleClose()
  }

  const changeButtonText = (text: string) => {
    if (text !== buttonText) {
      onChange(text)
    }
    setButtonText(text)
  }

  return (
    <React.Fragment>
      <FormControl sx={{marginLeft: 1, marginRight: 1}}>
        <Button
          color='alpha'
          id='dropdown-menu-button'
          aria-controls={open ? 'dropdown-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          sx={{
            fontFamily: 'button.medium',
            border: '1px solid black'
          }}
          size='small' variant='contained' endIcon={<KeyboardArrowDown />}>
          {buttonText}
        </Button>
        <Menu id='dropdown-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": 'dropdown-menu-button'
          }}>
          {
            menuValues.map(item => {
              return (
                <MenuItem onClick={handleMenuItemClick}>{item}</MenuItem>
              )
            })
          }
        </Menu>
      </FormControl>
    </React.Fragment>
  )
}

export default ManageOrderDropdownMenu