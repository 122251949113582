import React, { useState, useCallback, useEffect } from 'react'
import { Grid, Box, LinearProgress, Typography, Stack, Card, CardContent, TextField } from '@mui/material'
import { default as ProductLabelDropdownMenu } from './ProductLabelDropdownMenu'
import { getPartBarcodeDetails, postBarcodeLabel, getLocationList, getBatchesAssociatedWithLocation, getBuyerPoBasedOnOcNumber, getBuyerCountryBasedOnPoNumber, getQuantitybasedOnSize } from '../api/apiFunctions'
import useAuthenticationToken from '../../Services/Authentication/useAuthenticationToken'
import { PartsbarcodeDetailsType, PostProductLabelType, locationDetailsType, barcodeDetailsType, SageQty } from '../types'
import { AxiosError } from 'axios'
import { FlatButton, SnackBarMessage, SubmitDialog } from '../../UI/Components'
import StringValues from '../../Providers/StringValues'
import globalEnums from '../../UI/enum'
import { sortAlphaNumericArrayList, roundNumberToSignificantDigits } from '../../utils'
import { nanoid } from 'nanoid'
import { CleaningServices } from '@mui/icons-material'
const ZebraBrowserPrintWrapper = require('zebra-browser-print-wrapper')


enum DropdownMenuValues {
  location = 'Select Location',
  batch = 'Select Batch',
  buyerPo = 'Select PO Number',
  poCountry = 'Select Country'
}


const GenerateQrLabel = () => {

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [scannedBarcode, setScannedBarcode] = useState<string>('')


  const [partBarcodeDetailResults, setPartBarcodeDetailResults] = useState<PartsbarcodeDetailsType>()
  const [locationListResults, setLocationListResults] = useState<locationDetailsType[]>([])
  const [batchesListResults, setBatchesListResults] = useState<string[]>([])

  const [selectedLocationDropDown, setSelectedLocationDropDown] = useState<string>(DropdownMenuValues.location)
  const [selectedBatchDropDown, setSelectedBatchDropDown] = useState<string>(DropdownMenuValues.batch)
  const [selectedBuyerPoDropDown, setSelectedBuyerPoDropDown] = useState<string>(DropdownMenuValues.buyerPo)
  const [selectedCountryPoDropDown, setSelectedCountryPoDropDown] = useState<string>(DropdownMenuValues.poCountry)

  const [totalStickersToGenerate, setTotalStickersToGenerate] = useState<number>(0)
  const [locationList, setLocationList] = useState<string[]>([])
  const [buyerPoList, setBuyerPoList] = useState<string[]>([])
  const [buyerPoCountryList, setBuyerPoCountryList] = useState<string[]>([])
  const [ocNumber, setOCNumber] = useState<string>('')
  const [Color, setColor] = useState<string>('')
  const [size, setSize] = useState<string>('')
  const [sizequantity, setSizeQuantity] = useState<SageQty>()


  const [startNumbering, setStartNumbering] = useState<number>(0)
  const [endNumbering, setEndNumbering] = useState<number>(0)

  const [dataSubmitStatus, setDataSubmitStatus] = useState(false)

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState<string>('')

  const [productSeasonCode, setProductSeasonCode] = useState<string>('')
  const [productSeasonDescription, setProductSeasonDescription] = useState<string>('')

  useAuthenticationToken()

  useEffect(() => {
    if (partBarcodeDetailResults !== null && partBarcodeDetailResults !== undefined && locationListResults.length > 0) {
      setSelectedLocationDropDown(getLocationNameBasedOnLocationCode(partBarcodeDetailResults.location))

      setLocationList(locationDropdownList())
      fetchListOfBatches(partBarcodeDetailResults.location)
    }
  }, [partBarcodeDetailResults, locationListResults])


  useEffect(() => {
    setSelectedBatchDropDown(DropdownMenuValues.batch)
    if (locationList.length > 0 && selectedLocationDropDown !== null && selectedLocationDropDown !== undefined && selectedLocationDropDown !== DropdownMenuValues.location) {
      const locationCode = getLocationCodeBasedOnLocationName(selectedLocationDropDown)
      fetchListOfBatches(locationCode)
    }
  }, [selectedLocationDropDown, locationList])


  useEffect(() => {
    if (scannedBarcode.length === 10) {
      fetchPartBarcodeDetails()
    }
  }, [scannedBarcode])


  useEffect(() => {
    let totalStickerToGenerate = 0

    if (startNumbering > 0 && endNumbering > 0 && startNumbering <= endNumbering) {
      totalStickerToGenerate = (endNumbering + 1) - startNumbering
    }

    setTotalStickersToGenerate(totalStickerToGenerate)
  }, [startNumbering, endNumbering])

  const fetchCountryList = async (ocNumberValue: string, selectedBuyerPoDropDownValue: string) => {
    const buyesPoCountryList = await getBuyerCountryBasedOnPoNumber(ocNumberValue, selectedBuyerPoDropDownValue)
    setBuyerPoCountryList([DropdownMenuValues.poCountry, ...buyesPoCountryList])
    // console.log("Po Number ", selectedBuyerPoDropDownValue)
    // console.log("OC Number : ", ocNumberValue)
    // console.log("Country List : ", buyesPoCountryList[0])
  }

  useEffect(() => {
    try {
      fetchCountryList(ocNumber, selectedBuyerPoDropDown)
    } catch (err) {
      console.log("Error : ", err)
    }
  }, [selectedBuyerPoDropDown])

  /**
 * handle for getting quantity value from the api  
 */
  const fetchQuantity = async (ocNumberValue: string, selectedBuyerPoDropDownValue: string, countryValue: string, colorvalue: string, sizevalue: string) => {
    const SizeQuantity = await getQuantitybasedOnSize(ocNumberValue, selectedBuyerPoDropDownValue, countryValue, colorvalue, sizevalue)
    console.log("SizeQuantity1", SizeQuantity)
    setSizeQuantity(SizeQuantity)

  }

  useEffect(() => {
    try {
      fetchQuantity(ocNumber, selectedBuyerPoDropDown, selectedCountryPoDropDown, Color, size)
    } catch (err) {
      console.log("Error : ", err)
    }
  }, [selectedCountryPoDropDown])



  /**Handle API call to fetch partBarcode details
   */
  const fetchPartBarcodeDetails = async () => {
    if (isLoading) {
      return
    }

    if (scannedBarcode === undefined) {
      return
    }
    setPartBarcodeDetailResults(undefined)
    setLocationListResults([])
    setLocationList([])
    setBuyerPoList([DropdownMenuValues.buyerPo])
    setSelectedBuyerPoDropDown(DropdownMenuValues.buyerPo)
    setStartNumbering(0)
    setEndNumbering(0)

    setIsLoading(true)

    try {

      const partBarcodeDetailsResult = await getPartBarcodeDetails(scannedBarcode)
      setPartBarcodeDetailResults(partBarcodeDetailsResult)
      setOCNumber(partBarcodeDetailsResult.ocNumber)
      setColor(partBarcodeDetailsResult.colourCode)
      setSize(partBarcodeDetailsResult.size)
      console.log("partBarcodeDetailsResult", partBarcodeDetailsResult)

      const { productSeasonCode, productSeasonDescription } = partBarcodeDetailsResult

      setProductSeasonCode(productSeasonCode)
      setProductSeasonDescription(productSeasonDescription)

      const locationList = await getLocationList()
      setLocationListResults(locationList)

      const buyerPoListResult = await getBuyerPoBasedOnOcNumber(partBarcodeDetailsResult.ocNumber)
      setBuyerPoList([DropdownMenuValues.buyerPo, ...buyerPoListResult])


      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }

  /**Handle API call to fetch batches associated with location
   */
  const fetchListOfBatches = async (location: string) => {
    setBatchesListResults([])
    setIsLoading(true)

    try {
      const batchesListResult = await getBatchesAssociatedWithLocation(location)
      setBatchesListResults(batchesListResult)

      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }

  /** API call to post product label */
  const postProductLabelDetails = useCallback(async (data: PostProductLabelType) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsError(false)
    setErrorMessage('')
    setStartNumbering(0)
    setEndNumbering(0)
    setBatchesListResults([DropdownMenuValues.batch])
    setSelectedBatchDropDown(DropdownMenuValues.batch)

    try {
      await postBarcodeLabel(selectedBatchDropDown, data)

      printBarcode(data as PostProductLabelType)

      setDataSubmitStatus(true)
      setPartBarcodeDetailResults(undefined)
      setLocationListResults([])
      setBatchesListResults([])
      setIsLoading(false)
      setScannedBarcode('')
      handleSnackBarMessage(StringValues.submitSuccessMessage)


    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)

  }, [scannedBarcode, selectedLocationDropDown, selectedBatchDropDown, totalStickersToGenerate])


  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setDataSubmitStatus(false)
    setSnackBarOpen(value)
  }

  /**This function is responsible to update barcode
   * @param {string}colourDesc The colour description
   * @param {number}indexToChange The index number to update value
   */
  const handleScannedBarcodeChange = (barcode: string) => {
    setScannedBarcode(barcode)
  }

  /**This function is responsible to handle buyer Country po dropDown value
   * @param {string} value The selected country
   */
  const handleBuyerPoCountryDropdownMenuChange = (value: string) => {
    setSelectedCountryPoDropDown(value)
  }


  /**This function is responsible to submit productlabel barcodes and generate labels
   */
  const submitProductLabelBarcodes = async () => {

    if (scannedBarcode === undefined || scannedBarcode === '' || scannedBarcode === null) {
      handleSnackBarMessage(StringValues.productScannedBarcodeValidationMessage)
      return
    }

    if (startNumbering < 1) {
      handleSnackBarMessage(StringValues.productStartLabelStickerValidationMessage)
      return
    }

    if (endNumbering < 1) {
      handleSnackBarMessage(StringValues.productEndLabelStickerValidationMessage)
      return
    }

    if (endNumbering < startNumbering) {
      handleSnackBarMessage(StringValues.productLabelStickerValidationMessage)
      return
    }

    if (totalStickersToGenerate < 1) {
      handleSnackBarMessage(StringValues.productLabelStickerQuantityValidationMessage)
      return
    }

    if (selectedLocationDropDown === DropdownMenuValues.location) {
      handleSnackBarMessage(StringValues.locationMessage)
      return
    }
    if (selectedBuyerPoDropDown === DropdownMenuValues.buyerPo) {
      handleSnackBarMessage(StringValues.buyerspoMessage)
      return
    }
    if (selectedCountryPoDropDown === DropdownMenuValues.poCountry) {
      handleSnackBarMessage(StringValues.CountryMessage)
      return
    }

    if (selectedBatchDropDown === DropdownMenuValues.batch) {
      handleSnackBarMessage(StringValues.locationBatchMessage)
      return
    }

    const browserPrint = new ZebraBrowserPrintWrapper.default()
    const defaultPrinter = await browserPrint.getAvailablePrinters()
    if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0) {
      handleSnackBarMessage(StringValues.zebraPrinterNotFoundMessage)
      return
    }

    setDialogMessage(StringValues.productLabelsubmitConfirmationMessage(selectedLocationDropDown, selectedBatchDropDown, totalStickersToGenerate))
    setDialogOpen(true)

  }

  const presentDataAndPostProductLabeldetails = () => {
    const productLabelBarcodeList: barcodeDetailsType[] = []

    for (var i = startNumbering; i <= endNumbering; i++) {
      productLabelBarcodeList.push({
        barcode: nanoid(10),
        serialNumber: i
      })
    }

    const locationCode = getLocationCodeBasedOnLocationName(selectedLocationDropDown)
    const poNumber = selectedBuyerPoDropDown === 'Select PO Number' || selectedBuyerPoDropDown === undefined || selectedBuyerPoDropDown === null ? '' : selectedBuyerPoDropDown
    const poCountry = selectedCountryPoDropDown === 'Select Country' || selectedCountryPoDropDown === undefined || selectedCountryPoDropDown === null ? '' : selectedCountryPoDropDown
    const postData = {
      ocNumber: partBarcodeDetailResults?.ocNumber,
      location: locationCode,
      buyerName: partBarcodeDetailResults?.buyerName,
      style: partBarcodeDetailResults?.style,
      styleDescription: partBarcodeDetailResults?.styleDescription,
      productCode: partBarcodeDetailResults?.productCode,
      productDescription: partBarcodeDetailResults?.productDescription,
      fitType: partBarcodeDetailResults?.fitType,
      colourCode: partBarcodeDetailResults?.colourCode,
      colourDescription: partBarcodeDetailResults?.colourDescription,
      size: partBarcodeDetailResults?.size,
      cutNumber: partBarcodeDetailResults?.cutNumber,
      quantity: totalStickersToGenerate,
      buyerPoNumber: poNumber,
      buyerPoCountry: poCountry, //Country
      bundleBarcode: scannedBarcode,
      barcodes: productLabelBarcodeList
    }
    postProductLabelDetails(postData as PostProductLabelType)
    console.log("postData", postData)
  }


  /**This function is responsible to handle snack bar message
   * @param {string}message The message
   */
  const handleSnackBarMessage = (message: string) => {
    setSnackBarOpen(true)
    setErrorMessage(message)
  }

  /**
   * This function is responsible to handle dropDown list for location
   */
  const locationDropdownList = () => {
    let locations: string[] = []

    if (locationListResults.length > 0) {
      locations = locationListResults.map((locationObject) => {
        const { locationName, locationType } = locationObject

        if (locationType === globalEnums.production) {
          return locationName
        }
        return ''
      }).filter((obj) => { return obj !== '' })
    }

    locations = sortAlphaNumericArrayList(locations)
    locations.unshift(DropdownMenuValues.location)

    return locations
  }

  /**
   * This function is responsible to handle dropDown list for batches
   */
  const batchesDropdownList = () => {
    let batches: string[] = []

    if (batchesListResults.length > 0) {
      batches = [...batchesListResults]
    }

    batches = sortAlphaNumericArrayList(batches)
    batches.unshift(DropdownMenuValues.batch)
    return batches
  }


  /**This function is responsible to handle location dropDown value
   * @param {string} value The selected location
   */
  const handleLocationDropdownMenuChange = (value: string) => {
    setSelectedLocationDropDown(value)
  }

  /**This function is responsible to handle batch dropDown value
   * @param {string} value The selected batch
   */
  const handleBatchDropdownMenuChange = (value: string) => {
    setSelectedBatchDropDown(value)
  }

  /**This function is responsible to handle buyer po dropDown value
   * @param {string} value The selected country
   */
  const handleBuyerPoDropdownMenuChange = (value: string) => {
    setSelectedBuyerPoDropDown(value)
  }

  /** Function responsible to return location based on location name 
   * @param {string} locationName The location name
   * @return {string} The location code
   */
  const getLocationCodeBasedOnLocationName = (locationName: string) => {
    const locationObject = locationListResults.find(locationObject => locationObject.locationName === locationName)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.location
  }

  /** Function responsible to return location name based on location code 
   * @param {string} locationCode The location code
   * @return {string} The location name
   */
  const getLocationNameBasedOnLocationCode = (locationCode: string) => {
    const locationObject = locationListResults.find(locationObject => locationObject.location === locationCode)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.locationName
  }

  /**This function is responsible convert to number and update start sticker number to generate
   * @param {string}startStickerNumber The start stickers to generate
   */
  const handleStartStickerNumberChange = (startStickerNumber: string) => {
    let startNumber = 0

    if (parseFloat(startStickerNumber) > 0) {
      startNumber = roundNumberToSignificantDigits(parseFloat(startStickerNumber), 0)
    }

    setStartNumbering(startNumber)
  }

  /**This function is responsible convert to number and update end sticker number to generate
   * @param {string}endStickerNumber The end stickers to generate
   */
  const handleEndStickerNumberChange = (endStickerNumber: string) => {
    let endNumber = 0

    if (parseFloat(endStickerNumber) > 0) {
      endNumber = roundNumberToSignificantDigits(parseFloat(endStickerNumber), 0)
    }

    setEndNumbering(endNumber)
  }

  /**This function is responsible to generate QR code labels for product
   * @param {PostProductLabelType}data The  data to generate labels
   */
  const generalBarcodePrint = async (data: PostProductLabelType) => {
    const { ocNumber, buyerName, style, fitType, colourDescription, size, cutNumber, barcodes } = data
    try {
      const browserPrint = new ZebraBrowserPrintWrapper.default()
      const defaultPrinter = await browserPrint.getAvailablePrinters()
      if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0) {
        handleSnackBarMessage(StringValues.zebraPrinterNotFoundMessage)
        return
      }

      browserPrint.setPrinter(defaultPrinter[0])
      const printerStatus = await browserPrint.checkPrinterStatus()

      if (printerStatus.isReadyToPrint) {
        for (var i = 0; i <= barcodes.length; i++) {
          (function (i) {
            //   setTimeout(()=> { 
            //     browserPrint.print(`^XA
            // ^MMT
            // ^PW203
            // ^LL559
            // ^LS0
            // ^FT50,525^BQN,2,5
            // ^FDLA,${barcodes[i].barcode}^FS
            // ^FT67,398^A0B,17,18^FH\^CI28^FD${buyerName}^FS^CI27
            // ^FT86,398^A0B,17,18^CI28^FDCUT NO / BUNDLE NO: ${cutNumber} / ${scannedBarcode}^FS^CI27
            // ^FT105,398^A0B,17,18^FH\^CI28^FDFIT: ${fitType}^FS^CI27
            // ^FT124,398^A0B,17,18^FH\^CI28^FD${colourDescription}^FS^CI27
            // ^FT144,398^A0B,17,18^FH\^CI28^FDSIZE: ${size}^FS^CI27
            // ^FT169,398^A0B,23,23^FH\^CI28^FDSL NO: ${barcodes[i].serialNumber} / ${barcodes[i].barcode}^FS^CI27
            // ^FT47,398^A0B,17,18^FH\^CI28^FDOC / STYLE: ${ocNumber}/${style}^FS^CI27
            // ^FO23,0^GB159,0,8^FS
            // ^FT144,281^A0B,17,18^FH\^CI28^FDBATCH:${selectedBatchDropDown}^FS^CI27
            // ^PQ1,0,1,Y
            // ^XZ`)

            //   }, 100 * i)

            // -----------------

            setTimeout(() => {
              browserPrint.print(`^XA
                  ^MMT
                  ^PW160
                  ^LL360
                  ^LS0
                  ^FT40,230^A0B,17,18^FH\^CI28^FD${style}-${productSeasonCode}^FS^CI27
                  ^FT61,230^A0B,17,18^FH\^CI28^FD${colourDescription}^FS^CI27
                  ^FT126,230^A0B,17,18^FH\^CI28^FD${scannedBarcode}^FS^CI27
                  ^FT104,230^A0B,23,23^FH\^CI28^FD${size} (${barcodes[i].serialNumber})^FS^CI27
                  ^FT82,230^A0B,17,18^FH\^CI28^FDLINE ${selectedBatchDropDown}^FS^CI27
                  ^FO27,225^BQN,2,4.5^FDMA,${barcodes[i].barcode}^FS
                  ^FO49,359^GB54,0,1^FS
                  ^PQ1,0,1,Y
                  ^XZ`)

            }, 100 * i)

          })(i)
        }

      } else {
        handleSnackBarMessage(`Printer Error: ${printerStatus.errors}`)
        return
      }
    } catch (error) {
      handleSnackBarMessage(`${error}`)
      return

    }
  }

  /**This function is responsible to generate QR code labels for Old Navy buyer
   * @param {PostProductLabelType}data The  data to generate labels
   */
  const barcodePrintForOldNavy = async (data: PostProductLabelType) => {
    const { style, barcodes, size, colourDescription } = data
    try {
      const browserPrint = new ZebraBrowserPrintWrapper.default()
      const defaultPrinter = await browserPrint.getAvailablePrinters()
      if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0) {
        handleSnackBarMessage(StringValues.zebraPrinterNotFoundMessage)
        return
      }
      browserPrint.setPrinter(defaultPrinter[0])
      const printerStatus = await browserPrint.checkPrinterStatus()

      if (printerStatus.isReadyToPrint) {
        for (var i = 0; i <= barcodes.length; i++) {
          (function (i) {
            // setTimeout(() => {
            //   browserPrint.print(`^XA
            //   ^MMT
            //   ^PW203
            //   ^LL559
            //   ^LS0
            //   ^FT50,525^BQN,2,5
            //   ^FDLA,${barcodes[i].barcode}^FS
            //   ^FT67,398^A0B,23,23^FH\^CI28^FD${style}-${productSeasonCode}^FS^CI27
            //   ^FT96,398^A0B,18,18^CI28^FD${colourDescription}^FS^CI27
            //   ^FT125,398^A0B,23,23^FH\^CI28^FDLine ${selectedBatchDropDown}^FS^CI27
            //   ^FT154,398^A0B,18,18^FH\^CI28^FD${barcodes[i].barcode} / ${scannedBarcode}^FS^CI27
            //   ^FT80,0^GB40,0,3^FS
            //   ^FT125,310^A0B,23,23^FH\^CI28^FD${size}(${barcodes[i].serialNumber})^FS^CI27
            //   ^FT140,190^A0B,23,5^FH\^CI28^FD^GB0,10,8,B^FS^CI27
            //   ^PQ1,0,1,Y
            //   ^XZ`)

            // }, 100 * i)
            // ------------

            setTimeout(() => {
              browserPrint.print(`^XA
                  ^MMT
                  ^PW160
                  ^LL360
                  ^LS0
                  ^FT40,230^A0B,17,18^FH\^CI28^FD${style}-${productSeasonCode}^FS^CI27
                  ^FT61,230^A0B,17,18^FH\^CI28^FD${colourDescription}^FS^CI27
                  ^FT126,230^A0B,17,18^FH\^CI28^FD${scannedBarcode}^FS^CI27
                  ^FT104,230^A0B,23,23^FH\^CI28^FD${size} (${barcodes[i].serialNumber})^FS^CI27
                  ^FT82,230^A0B,17,18^FH\^CI28^FDLINE ${selectedBatchDropDown}^FS^CI27

                  ^FO27,225^BQN,2,4.5^FDMA,${barcodes[i].barcode}^FS
                  ^FO49,359^GB54,0,1^FS
                  ^PQ1,0,1,Y
                  ^XZ`)

            }, 100 * i)


          })(i)
        }

      } else {
        handleSnackBarMessage(`Printer Error: ${printerStatus.errors}`)
        return
      }
    } catch (error) {
      handleSnackBarMessage(`${error}`)
      return

    }
  }

  /**This function is responsible to generate QR code labels for product
   * @param {PostProductLabelType}data The  data to generate labels
   */
  const printBarcode = async (data: PostProductLabelType) => {
    const { buyerName } = data
    const buyerNameInUppercase = buyerName.toUpperCase()

    if (buyerNameInUppercase.includes('OLD') && buyerNameInUppercase.includes('NAVY')) {
      barcodePrintForOldNavy(data)
    } else {
      generalBarcodePrint(data)
    }


  }

  /**Function responsible to handle confirmation dialog on close
   * @param {boolean} value The dialog confirmation status
   */
  const handleSubmitDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true) {
      presentDataAndPostProductLabeldetails()
    }
  }

  return (
    <React.Fragment>
      <SnackBarMessage message={errorMessage} successStatus={dataSubmitStatus} open={snackBarOpen} onClose={handleSnackBarClose} />

      <SubmitDialog
        onClose={handleSubmitDialogClose}
        open={dialogOpen}
        submitStatus={false}
        dialogMessage={dialogMessage} />

      <Box sx={{ paddingLeft: 2 }}>
        <Typography variant='body1'>Scanned Barcode</Typography>
        <TextField
          type='text'
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            style: {
              height: "20px",
              width: "300px"
            },
          }}
          variant="outlined"
          size="small"
          value={scannedBarcode}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleScannedBarcodeChange(event.target.value)}
        />
      </Box>

      <Grid container sx={{
        height: '100vh'
      }}>

        <Grid item xs={12}
          sx={{
            paddingTop: 4,
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1
          }}>
          {
            (isLoading) ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
              >
                <LinearProgress sx={{ width: '50%' }} />
              </Box>
            ) : (partBarcodeDetailResults !== null && partBarcodeDetailResults !== undefined && locationList.length > 0 && setBatchesListResults.length > 0
              ? (
                <Box sx={{ padding: 2 }}>
                  <Box>
                    <Stack spacing={3} direction="row">

                      <Box>
                        <Stack spacing={1} direction="row">
                          <Typography variant='body1' align='center'>Start Sticker Number : </Typography>
                          <TextField
                            type='number'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: "20px"
                              },
                            }}
                            variant="outlined"
                            size="small"
                            value={startNumbering}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleStartStickerNumberChange(event.target.value)}
                          />
                        </Stack>

                      </Box>


                      <Box>
                        <Stack spacing={1} direction="row">
                          <Typography variant='body1' align='center'>End Sticker Number : </Typography>
                          <TextField
                            type='number'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: "20px"
                              },
                            }}
                            variant="outlined"
                            size="small"
                            value={endNumbering}
                            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleEndStickerNumberChange(event.target.value)}
                          />
                        </Stack>
                      </Box>


                      <Box>
                        <Stack spacing={1} direction="row">
                          <Typography variant='body1' align='center'>Total Stickers : </Typography>
                          <TextField
                            type='number'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: {
                                height: "20px"
                              },
                            }}
                            variant="outlined"
                            size="small"
                            value={totalStickersToGenerate}
                          />
                        </Stack>
                      </Box>

                      <ProductLabelDropdownMenu onChange={handleBuyerPoDropdownMenuChange} menuValues={buyerPoList} buttonText={selectedBuyerPoDropDown} />
                      <ProductLabelDropdownMenu onChange={handleBuyerPoCountryDropdownMenuChange} menuValues={buyerPoCountryList} buttonText={selectedCountryPoDropDown} />


                    </Stack>
                  </Box>

                  <Box sx={{ paddingTop: 4, paddingBottom: 4 }}>
                    <Stack spacing={3} direction="row">
                      <ProductLabelDropdownMenu onChange={handleLocationDropdownMenuChange} menuValues={locationList} buttonText={selectedLocationDropDown} />
                      <ProductLabelDropdownMenu onChange={handleBatchDropdownMenuChange} menuValues={batchesDropdownList()} buttonText={selectedBatchDropDown} />
                    </Stack>
                  </Box>

                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 2
                  }}>
                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>STYLE</Typography></CardContent>
                      <CardContent>
                        <Typography variant='body1' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.style}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>OC NUMBER</Typography></CardContent>
                      <CardContent>
                        <Typography variant='body1' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.ocNumber}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>BUYER NAME</Typography></CardContent>
                      <CardContent>
                        <Typography variant='body1' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.buyerName}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>CUT NO</Typography></CardContent>
                      <CardContent>
                        <Typography variant='body1' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.cutNumber}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>FIT TYPE</Typography></CardContent>
                      <CardContent>
                        <Typography variant='body1' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.fitType}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>COLOUR</Typography></CardContent>
                      <CardContent>
                        <Typography variant='body1' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.colourCode}
                        </Typography>
                        <Typography variant='body1' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.colourDescription}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>SIZE</Typography></CardContent>
                      <CardContent>
                        <Typography variant='body1' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.size}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Box>
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: 5, paddingBottom: 4,
                    gap: 2
                  }}>
                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>SAGE ORDER QTY</Typography></CardContent>
                      <CardContent>
                        <Typography variant='h6' color={'alpha.light'} align='center'>
                          {sizequantity?.quantity ?? '0'}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>ISSUED/LOADED QTY</Typography></CardContent>
                      <CardContent>
                        <Typography variant='h6' color={'alpha.light'} align='center'>
                          {partBarcodeDetailResults.qr_count}
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card sx={{
                      flex: 1
                    }}>
                      <CardContent><Typography variant='body2' align='center'>BALANCE LOAD QTY</Typography></CardContent>
                      <CardContent>
                        <Typography variant='h6' color={(typeof sizequantity?.quantity === 'number' && typeof partBarcodeDetailResults.qr_count === 'number'
                          ? (sizequantity?.quantity - partBarcodeDetailResults.qr_count) >= 0
                            ? 'green'
                            : 'red'
                          : 'alpha.light')} align='center'>
                          {typeof sizequantity?.quantity === 'number' && typeof partBarcodeDetailResults.qr_count === 'number'
                            ? sizequantity?.quantity - partBarcodeDetailResults.qr_count
                            : '0'}
                        </Typography>
                      </CardContent>
                    </Card>

                  </Box>

                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 6 }}>
                    <FlatButton label='SUBMIT' onClick={submitProductLabelBarcodes} disableControlsStatus={false} />
                  </Box>

                </Box>


              )
              : null
            )
          }


        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default GenerateQrLabel
