import { useEffect, useState, useCallback } from "react"
import { DefectData, DHUDataDetails } from "../types"
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

type DHUDateRangeChartProps = {
  data: DHUDataDetails[]
}

type ChartDataType = {
  defect: string,
  quantity: number,
  cumulativePercentage: number,
  label: number
}

type chartSeries = {
  name: string,
  type: string,
  data: number[]
}

/** This is a pareto chart for defects frequency over a date range */
const DHUDateRangeChart = (props: DHUDateRangeChartProps) => {
  const { data } = props
  const [chartCategories, setChartCategories] = useState<string[]>([])
  const [chartSeriesData, setChartSeriesData] = useState<chartSeries[]>([])

  useEffect(() => {
    const defectsAndQuantitiesList = getDefectsAndQuantitiesList(data)
    const uniqueDefectAndQuantitiesList = aggregateUniqueDefectsInList(defectsAndQuantitiesList)
    const sortedDefectsAndQuantitiesList = sortListBasedOnNumberOfDefects(uniqueDefectAndQuantitiesList)
    const defectsListWithCumulativePercentage = calculateCumulativePercentageForEachDefect(sortedDefectsAndQuantitiesList)

    const defectList = [...new Set(defectsListWithCumulativePercentage.map(item => item.defect))]
    const seriesDataList = formatDataForPresentation(defectList, defectsListWithCumulativePercentage)

    setChartCategories(defectList)
    setChartSeriesData(seriesDataList)
  }, [data])

  const formatDataForPresentation = useCallback((defectList: string[], data: ChartDataType[]) => {
    const seriesDataList : chartSeries[] = []
    const quantityData : chartSeries = {name: 'Frequency', type: 'column', data: []}
    const defectPercent : chartSeries = {name: 'Defect-Percent', type: 'line', data: []}

    defectList.map((defect) =>{
      const getDataObject = data.find(dataObject => dataObject.defect === defect)
      if (getDataObject === null || getDataObject === undefined) {
        quantityData.data.push(0)
        defectPercent.data.push(0)
      } else {
        quantityData.data.push(getDataObject.quantity)
        defectPercent.data.push(getDataObject.cumulativePercentage)
      }
      return null
    })
    seriesDataList.push(quantityData)
    seriesDataList.push(defectPercent)
    return seriesDataList
  }, [])

  const getDefectsAndQuantitiesList = (data: DHUDataDetails[]) => {
    const defectsAndQuantities: DefectData[] = []
    data.forEach(each => {
      const { hourlyDetails } = each
      hourlyDetails.forEach(hourDetail => {
        const { defectDetails } = hourDetail
        defectDetails.forEach(defectDetail => {
          const { defect, quantity } = defectDetail
          defectsAndQuantities.push({ defect, quantity })
        })
      })
    })
    return defectsAndQuantities
  }

  const aggregateUniqueDefectsInList = (data: DefectData[]) => {
    return data.reduce((acc: DefectData[], curr) => {
      const { defect, quantity } = curr
      const defectIndexInAcc = acc.findIndex((item: DefectData) => item.defect === defect)
      //  This defect does not exist in the list
      if (defectIndexInAcc === -1) {
        acc.push({ defect, quantity })
        return acc
      }
      const currentDefectQuantity = acc[defectIndexInAcc].quantity
      acc[defectIndexInAcc] = Object.assign(
        {},
        {
          defect,
          quantity: currentDefectQuantity + quantity
        }
      )
      return acc
    }, [])
  }

  const sortListBasedOnNumberOfDefects = (data: DefectData[]) => {
    return data.sort((a: DefectData, b: DefectData) => {
      return b.quantity - a.quantity
    })
  }

  const calculateCumulativePercentageForEachDefect = (data: DefectData[]) => {
    const totalDefects = data.reduce((acc, curr) => {
      const { quantity } = curr
      return acc + quantity
    }, 0)

    let cumulative = 0
    return data.map(({ defect, quantity }) => {
      cumulative += quantity
      const cumulativePercentage = Math.round(cumulative * 100 / totalDefects)
      return { defect, quantity, cumulativePercentage, label: cumulativePercentage }
    })
  }

  const getOptions = ()=>{
    const options: ApexOptions = {
      colors: ['#7a8189', '#B35676'],
      chart: {
        height: 350,
        type: 'line',
        stacked: false
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]  
      },
      stroke: {
        width: [1, 4]
      },
      title: {
        text: 'DHU - Date Range',
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        categories: chartCategories,
        labels: {
          show: true,
          rotate: -20,
          rotateAlways: true
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#7a8189'
          },
          labels: {
            style: {
              colors: '#7a8189',
            }
          },
          title: {
            text: "Frequency of Occurance",
            style: {
              color: '#7a8189',
            }
          },
          tooltip: {
            enabled: true
          }
        },
        
        {
          seriesName: 'Defect-Percent',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#B35676'
          },
          labels: {
            style: {
              colors: '#B35676',
            },
          },
          title: {
            text: "Defect in Percentage",
            style: {
              color: '#B35676',
            }
          }
        },
      ],
      legend: {
        horizontalAlign: 'center',
        offsetX: 40
      }
    }
    return options
  }

  return (
    <Chart options={getOptions()} series={chartSeriesData} type="line" width={'100%'} height={'340'} />
  )
}

export default DHUDateRangeChart