import React from 'react'
import { Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography, Box } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { PalletDataInfo } from '../types'
import { dateRangeType } from '../../UI/types'
import { format } from 'date-fns'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface PalletsDataForTrackerProps {
  palletsDataForWareHouseDateRange: PalletDataInfo[],
  isDataBasedOnWarehouseEntryDate: boolean,
  dateRange: dateRangeType
}

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial'
  }
})

function PalletsDataForTracker(props: PalletsDataForTrackerProps) {
  const { palletsDataForWareHouseDateRange, isDataBasedOnWarehouseEntryDate, dateRange } = props
  const classes = useStyles()

  const convertDateTimeToLocalString = (date: string | null) => {
    if (date === null || date === undefined) {
      return null
    }
    return new Date(date as string)
      .toLocaleTimeString('en-GB',
        { timeZone: 'UTC', hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
      ).replace(',', '')
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {

    const jsonData = palletsDataForWareHouseDateRange.map((object, index) => {
      return {
        rackNumber: object.rackBarcode,
        articleNumber: object.articleNumber,
        noOfPieces: object.numberOfPieces,
        dateOfManufacture: object.dateOfManufacture,
        warehouseEntryDateTime: convertDateTimeToLocalString(object.warehouseEntryDateTime),
        rackAssociationDateTime: convertDateTimeToLocalString(object.rackAssociationDateTime),
        rackDissociationDateTime: convertDateTimeToLocalString(object.rackDissociationDateTime),
        warehouseExitDateTime: convertDateTimeToLocalString(object.warehouseExitDateTime)
      }
    })

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)

    if (isDataBasedOnWarehouseEntryDate === true) {
      XLSX.utils.book_append_sheet(workBook, workSheet, 'palletForWareHouseEntry')
      XLSX.writeFile(workBook, 'palletDetailsBasedOnWareHouseEntry.xlsx')
    } else {
      XLSX.utils.book_append_sheet(workBook, workSheet, 'palletForWareHouseExit')
      XLSX.writeFile(workBook, 'palletDetailsBasedOnWareHouseExit.xlsx')
    }

  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          bgcolor: 'primary.card',
          paddingTop: 1
        }}
      >
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false} />
      </Box>
      <Box sx={{ paddingLeft: 1, paddingBottom: 2 }}>

        <Typography variant='subtitle1' sx={{ paddingBottom: 1 }}>
          {
            isDataBasedOnWarehouseEntryDate === true
              ? `PALLET INFORMATION FOR WAREHOUSE ENTRY DATE RANGE`
              : `PALLET INFORMATION FOR WAREHOUSE EXIT DATE RANGE`
          }
        </Typography>

        <Typography variant='subtitle1'>
          {`${format(dateRange?.startDate as Date, "dd/MM/yyyy")} - ${format(dateRange?.endDate as Date, "dd/MM/yyyy")}`}
        </Typography>
      </Box>



      <TableContainer component={Paper} classes={{ root: classes.customTableContainer }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>SERIAL NO.</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK NUMBER</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>ARTICLE NUMBER</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>NO. OF PIECES</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>DATE OF MANUFACTURE</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>WH ENTRY DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK ENTRY DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK EXIT DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>WH EXIT DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>CONATINER #</Typography></TableCell>
            </TableRow>
          </TableHead>


          <TableBody>
            {
              palletsDataForWareHouseDateRange.map((palletObject, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{palletObject.rackBarcode}</TableCell>
                    <TableCell>{palletObject.articleNumber}</TableCell>
                    <TableCell>{palletObject.numberOfPieces}</TableCell>
                    <TableCell>{palletObject.dateOfManufacture}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(palletObject.warehouseEntryDateTime)}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(palletObject.rackAssociationDateTime)}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(palletObject.rackDissociationDateTime)}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(palletObject.warehouseExitDateTime)}</TableCell>
                    <TableCell>{palletObject.containerNumber}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default PalletsDataForTracker