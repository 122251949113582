import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { BarcodePoDetails, BarcodeRollData } from '../../types'
import './DisplayBarcodes.css'
import { Can, BackButton } from '../../../UI/Components'
import rules from '../../../Services/Authentication/rbac-rules'
import { FlatButton } from '../../../UI/Components'
import StringValues from '../../../Providers/StringValues'
// import QRCode from 'qrcode'

const ZebraBrowserPrintWrapper = require('zebra-browser-print-wrapper')
const JsBarcode = require('jsbarcode')
interface DisplayBarcodesProps {
  poDetails: BarcodePoDetails,
  rollData: BarcodeRollData[],
  userPermissions: Array<Object>,
  handleSnackBar: (message: string) => void,
  handleSuccessBarcodePrint: () => void,
  handleBackDialog: () => void
}

function DisplayBarcodes(props: DisplayBarcodesProps) {
  const { poDetails, rollData, userPermissions, handleSnackBar, handleSuccessBarcodePrint, handleBackDialog } = props
  // const [qrCodes, setQrCodes] = useState<string[]>([])
  console.log("rollData:", rollData)
  console.log("poDetails:", poDetails)

  useEffect(() => {
    // generateQRCodes()
    onNextFrame()
  }, [])

  // for qr code

  // const generateQRCodes = async () => {
  //   const qrPromises = rollData.map(roll =>
  //     QRCode.toDataURL(roll.roll, { width: 100, margin: 1 })
  //   )
  //   const qrCodeURLs = await Promise.all(qrPromises)
  //   setQrCodes(qrCodeURLs)
  // }
  /**
   * This function is called to wait until the browser finishes painting the current frame and is
   * ready to pain the next frame. It will then call the function passed into the callback arg
   */
  const onNextFrame = () => {
    setTimeout(() => {
      requestAnimationFrame(drawSvgs)
    }, 1)
  }

  /**
   * Function that is called when the browser is ready to draw the barcodes on the UI
   */
  const drawSvgs = () => {
    //  Draw the SVGs for the actual barcodes that will be displayed

    for (let i = 0; i < rollData.length; i++) {
      JsBarcode(`.barcode_${i}`, rollData[i].roll, {
        format: 'CODE128',
        width: 2,
        height: 100,
        displayValue: false
      })
    }

    //  Grab the array of elements that will be used to display text
    const elementsArray = [...document.getElementsByClassName('barcode-text-container')]
    elementsArray.map((element, index) => {
      console.log("rollData entry:", rollData[index])
      console.log("poDetails entry:", poDetails)
      // element.innerHTML = `${rollData[index].roll}, ${poDetails.ocNumber}, ${rollData[index].length}, ${rollData[index].fabricWidth > 0 ? `${rollData[index].fabricWidth}",` : ''}, ${poDetails.itemDescription}, ${poDetails.FABCC ? `${poDetails.FABCC}"` : ''}, ${poDetails.FABCODE ? `${poDetails.FABCODE}"` : ''}, ${poDetails.FABCOM ? `${poDetails.FABCOM}"` : ''}, ${poDetails.FABGSM ? `${poDetails.FABGSM}"` : ''} ${rollData[index].supplierRollNo ? ` ${rollData[index].supplierRollNo}` : ''}`
      element.innerHTML = `
  Roll: ${rollData[index].roll}, 
  OC Number: ${poDetails.ocNumber}, 
  Length: ${rollData[index].length}, 
  Fabric Width: ${rollData[index].fabricWidth > 0 ? `${rollData[index].fabricWidth}"` : ''}, 
  Item Description: ${poDetails.itemDescription}, 
  FABCC: ${poDetails.FABCC || 'N/A'}, 
  FABCODE: ${poDetails.FABCODE || 'N/A'}, 
  FABCOM: ${poDetails.FABCOM || 'N/A'}, 
  FABGSM: ${poDetails.FABGSM || 'N/A'}, 
  Supplier Roll No: ${rollData[index].supplierRollNo || 'N/A'}
`
      return element
    })
  }


  const printBarcode = async () => {
    try {
      const browserPrint = new ZebraBrowserPrintWrapper.default()
      const defaultPrinter = await browserPrint.getAvailablePrinters()
      if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0) {
        console.log("Selected printer:", defaultPrinter[0]) // Log the selected printer
        handleSnackBar(StringValues.zebraPrinterNotFoundMessage)
        return
      }

      browserPrint.setPrinter(defaultPrinter[0])
      const printerStatus = await browserPrint.checkPrinterStatus()
      if (printerStatus.isReadyToPrint) {
        rollData.map(({ roll, length, supplierRollNo, fabricWidth }) => {
          // browserPrint.print(`^XA
          //   ^CF0,18
          //   ^FO200,20,^BY3,
          //   ^BCN,80,Y,N,N,N
          //   ^FD${roll}^FS
          //   ^FO150,140
          //   ^FB500,2,,
          //    ^FD${length}, ${fabricWidth > 0 ? `${fabricWidth}",` : ''} ${poDetails.itemDescription}  ${supplierRollNo ? `, ${supplierRollNo}` : ''}^FS
            
          //   ^FO60,150
          //   ^FDStyle: ${poDetails.styleNumber}^FS
          //   ^FO60,190
          //   ^FDShade: ^FS
          //   ^FO60,220
          //   ^FDShrinkage: ^FS
          //   ^FO60,250
          //   ^FDStart Date & Time: ^FS
          //   ^FO60,280
          //   ^FDEnd Date & Time: ^FS

          //   ^FO450,150
          //   ^FDFABGSM ${poDetails.FABGSM}^FS
          //   ^FO450,190
          //   ^FDFABCOM ${poDetails.FABCOM}^FS
           
          //   ^FO450,220
          //   ^FDFABCODE: ${poDetails.FABCODE}^FS
          //    ^FO450,250
          //   ^FDFABCC: ${poDetails.FABCC}^FS
          //    ^FO450,280
          //   ^FDRelaxation ^FS
            
          //   ^XZ`)
          browserPrint.print(`^XA
            ^CF0,22
            ^FO200,29,^BY3,
            ^BCN,70,Y,N,N,N
            ^FD${roll}^FS
            ^FO20,130
            ^FB800,3.5,,
            ^FD${length}, ${fabricWidth > 0 ? `${fabricWidth}",` : ''} ${poDetails.itemDescription} ${supplierRollNo ? `, ${supplierRollNo}` : ''}^FS
        
            ^FO30,210
    
            ^FDFABCODE: ${poDetails.FABCODE && !isNaN(Number(poDetails.FABCODE)) && Number(poDetails.FABCODE) > 0 ? poDetails.FABCODE : "----"}^FS
 
            ^FO30,235
            ^FDFABCC: ${poDetails.FABCC && !isNaN(Number(poDetails.FABCC)) && Number(poDetails.FABCC) > 0 ? poDetails.FABCC : "----"}^FS
            ^FO30,260
            ^FDFABCOM: ${poDetails.FABCOM && !isNaN(Number(poDetails.FABCOM)) && Number(poDetails.FABCOM) > 0 ? poDetails.FABCOM : "----"}^FS
            ^FO30,285
            ^FDFABGSM: ${poDetails.FABGSM && !isNaN(Number(poDetails.FABGSM)) && Number(poDetails.FABGSM) > 0 ? poDetails.FABGSM : "----"}^FS

            ^FO410,200
            ^FDStyle: ${poDetails.styleNumber}^FS
            ^FO410,230
            ^FDShrinkage:                  Shade: ^FS
            ^FO410,260
            ^FDStart Date & Time: ^FS
            ^FO410,290
            ^FDEnd Date & Time: ^FS

           
            
            ^XZ`)
          //   return { roll, length, supplierRollNo }
          // })
          //   {
          // browserPrint.print(`
          //   ^XA
          //   ^MMT
          //   ^PW799
          //   ^LL400
          //   ^LS0
          //   ^FT392,73^A0N,20,20^FH\^CI28^FDShrinkage:^FS^CI27
          //   ^FT218,315^A0N,23,23^FH\^CI28^FDQR ID: ${roll}^FS^CI27
          //   ^FT461,330^BQN,2,5
          //   ^FH\^FDLA,${roll}0A^FS
          //   ^FT219,51^A0N,20,20^FH\^CI28^FDStyle: ${poDetails.styleNumber}^FS^CI27
          //   ^FT219,76^A0N,20,20^FH\^CI28^FDShade:^FS^CI27
          //   ^FT58,160^BQN,2,5.5
          //   ^FH\^FDLA,${roll}0A^FS
          //   ^FT26,192^A0N,20,20^FH\^CI28^FD${supplierRollNo} length : ${length} & width: ${fabricWidth > 0 ? `${fabricWidth}",` : ''}  ^FS^CI27
          //   ^FT219,98^A0N,20,20^FH\^CI28^FDRelaxation^FS^CI27
          //   ^FT219,123^A0N,20,20^FH\^CI28^FDStart Date & Time^FS^CI27
          //   ^FT219,148^A0N,20,20^FH\^CI28^FDEnd Date & Time^FS^CI27
          //   ^FT25,170^A0N,20,16^FH\^CI28^FD ${poDetails.itemDescription}^FS^CI27
          //   ^FT26,217^A0N,20,20^FH\^CI28^FDFabCode : ----^FS^CI27
          //   ^FT26,241^A0N,20,20^FH\^CI28^FDFabCC   :---^FS^CI27
          //   ^FT26,266^A0N,20,20^FH\^CI28^FDFabCom  :---- ^FS^CI27
          //   ^FT26,291^A0N,20,20^FH\^CI28^FDFabGSM  :---^FS^CI27
          //   ^PQ1,0,1,Y
          //   ^XZ`)
          return { roll, length, supplierRollNo }
        })
        handleSuccessBarcodePrint()

      } else {
        handleSnackBar(`Printer Error: ${printerStatus.errors}`)
        return
      }
    } catch (error) {
      handleSnackBar(`${error}`)
      return

    }
  }

  return (
    <React.Fragment>
      <Box sx={{ paddingTop: 1, paddingBottom: 2 }}>
        <BackButton onClick={handleBackDialog}></BackButton>
      </Box>

      <div className='svg_barcode_container'>
        {
          new Array(rollData.length).fill(0).map((roll, index) => {
            const svgId = `barcode_${index}`
            return (
              <div className='barcode-container' key={index}>
                <svg className={`${svgId}`}></svg>
                <p className={`barcode-text-container`} id={roll.roll}></p>
              </div>
            )
          })
        }
      </div>

      {/* <div className='svg_qrcode_container'>
        {rollData.map((roll, index) => (
          <div className='qrcode-container' key={index}>
            <img src={qrCodes[index]} alt={`QR Code for ${roll.roll}`} width={100} height={100} />
            <p className={`qrcode-text-container`} id={roll.roll}>
              {`${roll.roll}, ${poDetails.ocNumber}, ${roll.length}, ${roll.fabricWidth > 0 ? `${roll.fabricWidth}"` : ''}, ${poDetails.itemDescription}, ${poDetails.FABCC ? `${poDetails.FABCC}"` : ''}, ${poDetails.FABCODE ? `${poDetails.FABCODE}"` : ''}, ${poDetails.FABCOM ? `${poDetails.FABCOM}"` : ''}, ${poDetails.FABGSM ? `${poDetails.FABGSM}"` : ''} ${roll.supplierRollNo ? ` ${roll.supplierRollNo}` : ''}`}
            </p>
          </div>
        ))}
      </div> */}


      <Can
        userPermissions={userPermissions}
        action={rules.ZebraPrinter}
        yes={() => (
          <Box display="flex"
            justifyContent="center"
            alignItems="center" sx={{ paddingTop: 4 }}>
            <FlatButton label='PRINT FROM ZEBRA PRINTER' onClick={printBarcode} disableControlsStatus={false} />
          </Box>
        )} no={() => null}
        isValidateStrict={true} />
    </React.Fragment>
  )
}

export default DisplayBarcodes