import { Auth0Provider } from "@auth0/auth0-react"
import { ReactElement, JSXElementConstructor } from "react"
import { useNavigate } from "react-router-dom"

const AuthenticationProvider = (props: { children: ReactElement<any, string | JSXElementConstructor<any>> }) => {
  const navigate = useNavigate()
  if (process.env.REACT_APP_AUTH0_DOMAIN === undefined) {
    throw new Error("REACT_APP_AUTH0_DOMAIN is undefined")
  }
  if (process.env.REACT_APP_AUTH0_CLIENT_ID === undefined) {
    throw new Error("REACT_APP_AUTH0_CLIENT_ID is undefined")
  }

  const onRedirectCallback = (appState: { returnTo: any; }) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}>
      {props.children}
    </Auth0Provider>
  )
}

export default AuthenticationProvider