// import React from 'react'
// import Lottie from 'react-lottie'
// import LottieLogo from './lottie.json'
// import { Grid } from '@mui/material'

// interface HomeProps {
//   componentHeader : (headerName : string) => void
// }

// const Home = (props: HomeProps) => {
//   props.componentHeader('')

//   const defaultOptions = {
//     loop: false,
//     autoplay: true,
//     animationData: LottieLogo,
//     rendererSettings: {
//       preserveAspectRatio: "xMidYMid slice"
//     }
//   }

//   return (
//     <React.Fragment>
//       <Grid
//         container
//         spacing={0}
//         direction="column"
//         alignItems="center"
//         justifyContent="center"
//         style={{ minHeight: '70vh' }}
//       >
//         <Grid item xs={3}>
//           <Lottie 
// 	    options={defaultOptions}
//             style={{ width: 400 }}
//           />
//         </Grid>
//       </Grid>




//     </React.Fragment >
//   )
// }

// export default Home
import React from 'react'
import Lottie from 'react-lottie'
import LottieLogo from './lottie.json'
import { Grid } from '@mui/material'
import img from './bd1.jpeg'

interface HomeProps {
  componentHeader: (headerName: string) => void
}

const Home = (props: HomeProps) => {
  props.componentHeader('')

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: LottieLogo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '70vh' }}
      >
        <Grid item xs={12}>
          <img src={img} alt="BD Logo" style={{ width: '35rem', height: '300px', animation: 'example 2s ease-in-out 1' }} />
          <style>
            {`@keyframes example {
            0% { transform: scale(1); } 50% { transform: scale(1.1); } 100% { transform: scale(1); }
          }
        `}
          </style>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Home