import React, { useState, useEffect } from 'react'
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography, Stack } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { FabricDataInfo, locationDetailsType } from '../types'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { roundNumberToSignificantDigits, sortAlphaNumericArrayList } from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'
import LocationDropdownMenu from './LocationDropdownMenu'
import globalEnums from '../../UI/enum'
import { getLocationList } from '../api/apiFunctions'
import { AxiosError } from 'axios'

interface FabricConsolidateDataProps {
  fabricConsolidateResults: FabricDataInfo[]
}

interface FabricSortedObject {
  key: string
  value: FabricDataInfo[]
}

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial'
  }
})

enum DropdownMenuValues {

  locationid = 'GROUP (APPAREL)',
  locationCode = 'Select Org'

}

function FabricConsolidateData(props: FabricConsolidateDataProps) {
  const classes = useStyles()
  const { fabricConsolidateResults } = props
  const [rollColumnarrowStatus, setRollColumnarrowStatus] = useState(false)
  const [meterColumnarrowStatus, setMeterColumnarrowStatus] = useState(false)
  const [fabricConsolidateData, setFabricConsolidateData] = useState<Array<FabricSortedObject>>([])

  const [location, setLocation] = useState<string>('')

  const [locationListResults, setLocationListResults] = useState<locationDetailsType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [locationCode, setLocationCode] = useState<string>('')



  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const filterDataByLocation = (data: FabricDataInfo[]) => {

    if (location === DropdownMenuValues.locationid)  {
      return data


    }
    return data.filter(item => item.rackLocation === location)
  }

  const groupedDataByItemCode = groupBy(filterDataByLocation(fabricConsolidateResults), i => i.itemCode)

  const sortDataByNumberOfRolls = () => {
    if (rollColumnarrowStatus === true) {
      setRollColumnarrowStatus(false)
      setFabricConsolidateData(
        Object.keys(groupedDataByItemCode)
          .map(k => ({ key: k, value: groupedDataByItemCode[k] }))
          .sort((a, b) => a.value.length - b.value.length)
      )
    } else {
      setRollColumnarrowStatus(true)
      setFabricConsolidateData(
        Object.keys(groupedDataByItemCode)
          .map(k => ({ key: k, value: groupedDataByItemCode[k] }))
          .sort((a, b) => b.value.length - a.value.length)
      )
    }
  }

  useEffect(() => {
    sortDataByNumberOfRolls()
    setMeterColumnarrowStatus(true)
  }, [fabricConsolidateResults, location])

  const calculateTotalFabricLength = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => acc + curr.fabricLength, 0)
  }

  const calculateTotalFabricRolls = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => acc + curr.numberOfRolls, 0)
  }

  const sortDataByFabricMeter = () => {
    if (meterColumnarrowStatus === true) {
      setMeterColumnarrowStatus(false)
      setFabricConsolidateData(
        Object.keys(groupedDataByItemCode)
          .map(k => ({ key: k, value: groupedDataByItemCode[k] }))
          .sort((a, b) => {
            const aValue = a.value.reduce((acc, curr) => acc + curr.fabricLength, 0)
            const bValue = b.value.reduce((acc, curr) => acc + curr.fabricLength, 0)
            return aValue - bValue
          })
      )
    } else {
      setMeterColumnarrowStatus(true)
      setFabricConsolidateData(
        Object.keys(groupedDataByItemCode)
          .map(k => ({ key: k, value: groupedDataByItemCode[k] }))
          .sort((a, b) => {
            const aValue = a.value.reduce((acc, curr) => acc + curr.fabricLength, 0)
            const bValue = b.value.reduce((acc, curr) => acc + curr.fabricLength, 0)
            return bValue - aValue
          })
      )
    }
  }

  const totalFabricLength = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => acc + curr.fabricLength, 0)
  }

  const totalRolls = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => acc + curr.numberOfRolls, 0)
  }

  useEffect(() => {
    handleFetchLocation()
  }, [])

  const handleFetchLocation = async () => {
    await new Promise(r => setTimeout(r, 500))
    fetchLocationList()
  }

  const fetchLocationList = async () => {
    try {
      setIsLoading(true)
      const locationsList = await getLocationList()
      setLocationListResults(locationsList)
      setIsLoading(false)
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsLoading(false)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
  }

  /**This function is responsible to handle location dropDown value
  * @param {string} value The selected location
  */
  const handleLocationDropdownMenuChange = (value: string) => {
    setLocation(value)
  }

  /**This function is responsible to handle location dropDown value
  * @param {string} value The selected location
  */
  const handleLocationCodeDropdownMenuChange = (value: string) => {
    setLocationCode(value)
    // Optionally, reset the location selection when location code changes
    setLocation('')
  }



  /**
  * This function is responsible to handle dropDown list for location
  */
  const locationDropdownList = () => {
    let locations: string[] = []


    if (locationListResults.length > 0) {
      locations = locationListResults
        .filter(locationObject => locationObject.locationCode === locationCode)
        .map(locationObject => {
          const { locationName, locationType } = locationObject

          if (locationType === globalEnums.production) {
            return locationName
          }
          return ''
        })
        .filter(obj => obj !== '')
    }
    locations = sortAlphaNumericArrayList(locations)
    locations.unshift(DropdownMenuValues.locationid)

    return locations
  }
  /**
 * This function is responsible to handle dropDown list for locationCode
 */
  const locationCodeDropdownList = () => {
    let locationCodesSet: Set<string> = new Set()

    if (locationListResults.length > 0) {
      locationListResults.forEach(locationObject => {
        const { locationCode, locationType } = locationObject

        if (locationType === globalEnums.production) {
          locationCodesSet.add(locationCode)
        }
      })
    }

    // Convert the Set back to an array and sort it
    let locationCodes: string[] = Array.from(locationCodesSet)
    locationCodes = sortAlphaNumericArrayList(locationCodes)

    // Add the default dropdown value at the beginning
    locationCodes.unshift(DropdownMenuValues.locationCode)
    return locationCodes
  }


  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData = fabricConsolidateData.map((object) => ({
      itemCode: object.key,
      noOfRolls: roundNumberToSignificantDigits(calculateTotalFabricRolls(object.value), 2),
      meters: roundNumberToSignificantDigits(calculateTotalFabricLength(object.value), 2)
    }))

    const filteredData = filterDataByLocation(fabricConsolidateResults).map((object) => ({
      itemCode: object.itemCode,
      noOfRolls: roundNumberToSignificantDigits(object.numberOfRolls, 2),
      meters: roundNumberToSignificantDigits(object.fabricLength, 2)
    }))

    // Add the total rolls and total meters data to the Excel file
    const totalRollsInStockRow = {
      itemCode: 'TOTAL ROLLS IN STOCK:',
      noOfRolls: roundNumberToSignificantDigits(totalRollsInStock, 2),
      meters: '' // Leave this blank for the total rolls row
    }

    const totalMetersInStockRow = {
      itemCode: 'TOTAL METERS IN STOCK:',
      noOfRolls: '', // Leave this blank for the total meters row
      meters: roundNumberToSignificantDigits(totalMetersInStock, 2)
    }

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet([...filteredData, totalRollsInStockRow, totalMetersInStockRow])
    XLSX.utils.book_append_sheet(workBook, workSheet, 'fabricConsolidateData')
    // Get the selected location name for file naming
    let selectedLocationName = location

    if (selectedLocationName === DropdownMenuValues.locationid) {
      selectedLocationName = 'GROUP (APPAREL)' // Default name if no location is selected

    }

    const fileName = `fabricConsolidate_${selectedLocationName}.xlsx`
    XLSX.writeFile(workBook, fileName)    
  }

  const filteredFabricConsolidateResults = filterDataByLocation(fabricConsolidateResults)
  const totalRollsInStock = totalRolls(filteredFabricConsolidateResults)
  const totalMetersInStock = totalFabricLength(filteredFabricConsolidateResults)

  return (
    <React.Fragment>

      <Box sx={{ bgcolor: 'primary.card', paddingTop: 2, paddingBottom: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <LocationDropdownMenu
            onChange={handleLocationCodeDropdownMenuChange}
            menuValues={locationCodeDropdownList()}
            buttonText={'SELECT ORG'}
          />
          <LocationDropdownMenu
            onChange={handleLocationDropdownMenuChange}
            menuValues={locationDropdownList()}
            buttonText={'GROUP (APPAREL)'}
          />
          <FlatButton
            label='DOWNLOAD'
            onClick={handleDownloadButtonClick}
            disableControlsStatus={false}
          />
        </Stack>

      </Box>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ bgcolor: 'primary.card' }}
      >
        <Typography variant='subtitle1' fontSize="20px" fontWeight="bold" sx={{ background: 'white' }}>
          RACK MANAGEMENT FABRIC INVENTORY SUMMARY
        </Typography>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography variant='subtitle1' fontSize="20px" fontWeight="bold" sx={{ background: 'white' }}>
            TOTAL ROLLS IN STOCK: {roundNumberToSignificantDigits(totalRollsInStock, 2)}
          </Typography>
          <Typography variant='subtitle1' fontSize="20px" fontWeight="bold" sx={{ background: 'white' }}>
            TOTAL METERS IN STOCK: {roundNumberToSignificantDigits(totalMetersInStock, 2)}
          </Typography>
        </Stack>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ bgcolor: 'primary.card', paddingTop: 1 }}
      >
        <Paper style={{ width: '100%', overflowX: 'auto' }} >
          <TableContainer className={classes.customTableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>
                    <Typography variant='subtitle1' fontSize="20px" fontWeight="bold">
                      ITEM CODE
                    </Typography>
                  </TableCell>
                  <TableCell align='center' onClick={sortDataByNumberOfRolls} style={{ cursor: 'pointer' }}>
                    <Typography variant='subtitle1' fontSize="20px" fontWeight="bold">
                      NO. OF ROLLS
                      {rollColumnarrowStatus === true ? (
                        <ArrowUpwardIcon fontSize="small" />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align='center' onClick={sortDataByFabricMeter} style={{ cursor: 'pointer' }}>
                    <Typography variant='subtitle1' fontSize="20px" fontWeight="bold">
                      METERS
                      {meterColumnarrowStatus === true ? (
                        <ArrowUpwardIcon fontSize="small" />
                      ) : (
                        <ArrowDownwardIcon fontSize="small" />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fabricConsolidateData.map(object => (
                  <TableRow key={object.key}>
                    <TableCell align='center'>
                      <Typography variant='subtitle2' fontSize="18px">
                        {object.key}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography variant='subtitle2' fontSize="18px">
                        {roundNumberToSignificantDigits(calculateTotalFabricRolls(object.value), 2)}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography variant='subtitle2' fontSize="18px">
                        {roundNumberToSignificantDigits(calculateTotalFabricLength(object.value), 2)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default FabricConsolidateData
