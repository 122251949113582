import React from 'react'
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography } from '@mui/material'
import { CuttingDataInfo, Jobs } from '../types'
import {roundNumberToSignificantDigits, convertNumberToLocaleString} from '../../utils'

interface CuttingDataForItemCodeProps {
  cuttingItemCodeResults: CuttingDataInfo
  itemCode: string
  fitType: string
}

function CuttingDataForItemCode(props: CuttingDataForItemCodeProps) {
  const {itemCode, fitType} = props
  const { itemDescription, customerName, styleNumber, approvedConsumption, sizeQuantities, jobs } = props.cuttingItemCodeResults

  /**Function to calculate total order quantity 
   *@return {number} The total order quantity 
   */
  const calculateSizeQuantitiesTotal = () => {
    return sizeQuantities.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)
  }

  /**Function to calculate row total
   * @param {Jobs[]} data The List of jobs
   * @return {number} The job quantity total 
   */
  const calculateRowTotal = (data: Jobs[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)
  }

  /**Function to calculate consumption for job
   * @param {number} numOfPiecesPerPly The number of pieces per plie
   * @param {number} jobQuantity The job quantity
   * @param {number} layLength The lay length of job
   * @return {number} The consumption of job
   */
  const calculateConsumptionForRow = (numOfPiecesPerPly : number, jobQuantity: number, layLength: number) => {
    const numberOfPlies = jobQuantity / numOfPiecesPerPly
    const consumption = (numberOfPlies * layLength) / jobQuantity
    return roundNumberToSignificantDigits(consumption, 2)
  }

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const groupedDataByJobUnique =  groupBy(jobs, i => i.unique)
  const groupedDataBySize =  groupBy(jobs, i => i.size)
  
  /**Function to calculate balance quantity for size
   *@param {number} orderQuantity The size order quantity
   *@param {string} size The job size
   *@return {number} The size balance quantity
   */
  const calculateSizeWiseBalanceQuantity = (orderQuantity: number, size: string) => {
    let totalSizeWiseCutQuantity = 0

    Object.entries(groupedDataBySize).map(([key, value]) =>{
      if (key === size){
        totalSizeWiseCutQuantity = value.reduce((acc, curr) => {
          return acc + curr.quantity
        }, 0)
      }
      return null
    })

    return (roundNumberToSignificantDigits(orderQuantity - totalSizeWiseCutQuantity, 0))
  }

  /**Function to calculate total for balance quantity */
  const calculateBalanceQuantityTotal = () => {
    const totalOrderQuantity =  sizeQuantities.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)


    const totalCutQuantity = jobs.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)

    return (roundNumberToSignificantDigits(totalOrderQuantity - totalCutQuantity, 0))

  }

  /**Function to calculate actual consumption  */
  const calculateTotalActualConsumption = () => {
    let overallTotal = 0
    let overallFabricUtilized = 0

    Object.entries(groupedDataByJobUnique).map(([key, value], index) =>{
      const totalQuantity = calculateRowTotal(value)
      const numOfPlies =  totalQuantity / value[0].numOfPiecesPerPly
      overallTotal += totalQuantity
      overallFabricUtilized += numOfPlies * value[0].layLength
      return null
    })
    
    if (overallTotal > 0){
      return (overallFabricUtilized / overallTotal)
    }
    return 0
  }


  return (
    
    <React.Fragment>
      <Box sx={{
        paddingTop: 2,
        paddingBottom: 2,
        display: 'flex',
        flexDirection: 'row',
        gap: 2
      }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>ITEM CODE</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ITEM DESCRIPTION</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>BUYER NAME</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>STYLE NAME</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>FIT TYPE</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>APPROVED CONSUMPTION</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ACTUAL CONSUMPTION</Typography></TableCell>
              </TableRow>
            </TableHead>
            

            <TableBody>
              <TableRow key={1}>
                <TableCell>{itemCode}</TableCell>
                <TableCell>{itemDescription}</TableCell>
                <TableCell>{customerName}</TableCell>
                <TableCell>{styleNumber}</TableCell>
                <TableCell>{fitType}</TableCell>
                <TableCell>{approvedConsumption}m</TableCell>
                <TableCell>{roundNumberToSignificantDigits(calculateTotalActualConsumption(), 2)}m</TableCell>
              </TableRow>
            </TableBody>
          </Table> 
        </TableContainer>
      </Box>

      <Box sx={{paddingBottom: 2}}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>SIZE</Typography></TableCell>
                {
                  sizeQuantities.map((object) => {
                    return (
                      <TableCell><Typography variant='subtitle1'>{object.size}</Typography></TableCell>
                    )
                  })
                }
                <TableCell><Typography variant='subtitle1'>TOTAL</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            
              <TableRow key={'SizeWiseOrderQuantity'}>
                <TableCell><Typography variant='subtitle1'>ORDER QUANTITY</Typography></TableCell>
                {
                /** size wise order quantity */
                  sizeQuantities.map((object) => {
                    return (
                      <TableCell>{convertNumberToLocaleString(object.quantity)}</TableCell>
                    )
                  })
                }
                <TableCell><Typography variant='subtitle1'>{convertNumberToLocaleString(calculateSizeQuantitiesTotal())}</Typography></TableCell>
              </TableRow>

              <TableRow key='totalSizeWiseQuantity'>
                <TableCell><Typography variant='subtitle1'>CUT QUANTITY</Typography></TableCell>
                {
                  /** size wise total quantity */
                  Object.entries(groupedDataBySize).length > 0
                    ? Object.entries(groupedDataBySize).map(([key, value]) =>{
                      return (
                        <TableCell>
                          {
                            convertNumberToLocaleString(
                              value.reduce((acc, curr) => {
                                return roundNumberToSignificantDigits((acc + curr.quantity), 0)
                              }, 0)
                            )
                          }
                        </TableCell>
                      )
                    })
                    : sizeQuantities.map((object) => {
                      return (
                        <TableCell>0</TableCell>
                      )
                    })
                }

                {
                  <TableCell><Typography variant='subtitle1'>
                    {
                      /** overall total quantity */
                      convertNumberToLocaleString(
                        jobs.reduce((acc, curr) => {
                          return acc + curr.quantity
                        }, 0)
                      )
                    }
                  </Typography></TableCell>
                }
              </TableRow>

              <TableRow key={'SizeWiseOrderQuantity'}>
                <TableCell><Typography variant='subtitle1'>BALANCE QUANTITY</Typography></TableCell>
                {
                /** size wise order quantity */
                  sizeQuantities.map((object) => {
                    return (
                      <TableCell>{calculateSizeWiseBalanceQuantity(object.quantity, object.size)}</TableCell>
                    )
                  })
                }
                <TableCell><Typography variant='subtitle1'>{convertNumberToLocaleString(calculateBalanceQuantityTotal())}</Typography></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>          
      </Box>


      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>LAY NO.</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>DATE/TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>TABLE</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>LAY LENGTH</Typography></TableCell>
              {
                sizeQuantities.map((object) => {
                  return (
                    <TableCell><Typography variant='subtitle1'>{object.size}</Typography></TableCell>
                  )
                })
              }
              <TableCell><Typography variant='subtitle1'>TOTAL</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>CONSUMPTION</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {
              /** size wise quantity and row wise total */
              Object.entries(groupedDataByJobUnique).map(([key, value], index) =>{
                return (
                  <TableRow key={key}>
                    <TableCell>{value[0].layNumber}</TableCell>
                    <TableCell>{value[0].date}</TableCell>
                    <TableCell>{value[0].tableNum}</TableCell>
                    <TableCell>{value[0].layLength}</TableCell>

                    {
                      sizeQuantities.map((object) => {
                        const filteredSizeObject = value.filter(obj => {
                          return obj.size === object.size
                        })
                        if (filteredSizeObject.length > 0){
                          return (<TableCell>{roundNumberToSignificantDigits(filteredSizeObject[0].quantity, 0)}</TableCell>)  
                        }
                        return (<TableCell>{0}</TableCell>)
                      })
                    }
                    <TableCell>{convertNumberToLocaleString(calculateRowTotal(value))}</TableCell>
                    <TableCell>{calculateConsumptionForRow(value[0].numOfPiecesPerPly, calculateRowTotal(value), value[0].layLength)}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default CuttingDataForItemCode