import { useAuth0 } from '@auth0/auth0-react'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = (props: { children: any, path: string, userPermissions: Array<Object>, action: Array<String> }) => {
  const { isAuthenticated } = useAuth0()
  if (!isAuthenticated) {
    return <Navigate to='/' />
  }
 
  const actualPermissionsListToMatch = props.action.filter(permission => props.userPermissions.includes(permission))
  if (actualPermissionsListToMatch.length === props.action.length) {
    return props.children
  }
  /** Here instead of null required to show error message */
  return props.children
}

export default ProtectedRoute