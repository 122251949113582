import React, { useState, useEffect } from 'react'
import { Box, Grid, Card, CardContent, Typography, Radio, RadioGroup, FormControl, FormControlLabel, LinearProgress } from '@mui/material'

// import { getOverViewDetails, getOverViewTrend, getCuttingOverView } from './api/apiFunctions'
import { getOverViewDetails, getCuttingOverView, getloadingOverView } from './api/apiFunctions'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { OverViewData, OverViewTrendData, CuttingOverview, LoadingOverview } from './types'
import { EfficiencyContributionChart, OverViewDropdownMenu, SewingEfficiencyChart, SewingDHUChart, SewingTable, SewingTrendChart } from './components'
import { AxiosError } from 'axios'
import SnackBarMessage from '../UI/Components/SnackBarMessage'
import { roundNumberToSignificantDigits, convertNumberToLocaleString, sortAlphaNumericArrayList } from '../utils'
import { DatePicker } from '../UI/Components'
import { dateRangeType } from '../UI/types'
import format from 'date-fns/format'
import { RunningWithErrors, InsertChart, ContentCut, PrecisionManufacturing, TableRestaurant, Public, BookOnline, JoinFull, Timer } from '@mui/icons-material'
import Button from '@mui/material/Button'
import RefreshIcon from '@mui/icons-material/Refresh'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import LocationDropdownMenu from './components/LocationDropdownMenu'
import { keyframes } from '@mui/system'
// import img from './logo.gif'
import img from './logo1.gif'
const pianoEffect = keyframes`
0% { opacity: 0; transform: translateY(50px); }
100% { opacity: 1; transform: translateY(0); }`

enum locationInitalValue {
  locationValue = 'GROUP (APPAREL)'
}
interface OverViewDashboardProps {
  componentHeader: (headerName: string) => void
}

const OverViewDashboard = (props: OverViewDashboardProps) => {

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false)


  const [overViewResults, setOverViewResults] = useState<OverViewData[]>([])
  const [actualOverViewResults, setActualOverViewResults] = useState<OverViewData[]>([])

  const [cuttingoverViewResults, setCuttingOverViewResults] = useState<CuttingOverview[]>([])
  const [actualCuttingOverViewResults, setCuttingActualOverViewResults] = useState<CuttingOverview[]>([])

  const [loadingoverViewResults, setloadingOverViewResults] = useState<LoadingOverview[]>([])
  const [actualloadingOverViewResults, setLoadingActualOverViewResults] = useState<LoadingOverview[]>([])

  const [overViewTrendResults, setOverViewTrendResults] = useState<OverViewTrendData[]>([])
  const [actualOverViewTrendResults, setActualOverViewTrendResults] = useState<OverViewTrendData[]>([])


  const [selectedLocation, setSelectedLocation] = useState<string>(locationInitalValue.locationValue)
  const [dateRange, setDateRange] = useState<dateRangeType>({ startDate: new Date(), endDate: new Date(), key: 'selection' })
  const [radioButonSelect, setRadioButonSelect] = React.useState<string>('visualView')
  const [isLocationSelected, setIsLocationSelected] = useState(false)
  const [organizationCode, setOrgCode] = useState<string>('')
  // const [organizationCode, setOrgCode] = useState('BD')
  const [locationsList, setLocationsList] = useState<string[]>([locationInitalValue.locationValue])
  const [locationCodesList, setLocationCodesList] = useState<string[]>([])
  const [selectedLocationCode, setSelectedLocationCode] = useState<string>('')


  useAuthenticationToken()
  props.componentHeader('OverView')

  /**
   * API call to fetch list of overView details (i.e, Efficiency and DHU data)
   */
  useEffect(() => {
    if (dateRange.startDate === new Date() && dateRange.endDate === new Date() && organizationCode) {
      handleFetchOverViewDetails()
      return
    }

    fetchOverViewDetails()
  }, [dateRange, organizationCode])

  const handleFetchOverViewDetails = async () => {
    await new Promise(r => setTimeout(r, 500))
    fetchOverViewDetails()
  }

  /** Create/Update list of locations */
  useEffect(() => {
    setLocationsList(locationList(overViewResults, cuttingoverViewResults, overViewTrendResults))
    setLocationCodesList(getLocationCodesList(overViewResults, cuttingoverViewResults, overViewTrendResults))
  }, [overViewResults, cuttingoverViewResults, loadingoverViewResults, overViewTrendResults])


  /**
   * API call to fetch list of overView trend (i.e, Efficiency and production data)
   */
  // useEffect(() => {
  //   handleFetchOverViewTrend()
  // }, [])

  // const handleFetchOverViewTrend = async () => {
  //   await new Promise(r => setTimeout(r, 500))
  //   fetchOverViewTrend()
  // }

  const fetchOverViewDetails = async () => {

    if (dateRange === undefined) {
      setSnackBarOpen(true)
      setIsSuccess(true)
      setErrorMessage("Select   Start & End date")
      return
    }
    if (organizationCode === "" || organizationCode === undefined) {
      setSnackBarOpen(true)
      setIsSuccess(true)
      setErrorMessage("Select   Group  ")
      return
    }

    if (isLoading) {
      return
    }


    setIsLoading(true)

    try {

      console.log("Fetching overview details...")
      const startDate = format(dateRange?.startDate as Date, "yyyyMMdd")
      const endDate = format(dateRange?.endDate as Date, "yyyyMMdd")
      const overViewDetailsResult = await getOverViewDetails(startDate, endDate, selectedLocationCode)
      console.log("Overview details:", overViewDetailsResult)
      setOverViewResults(overViewDetailsResult)

      filterOverViewDataListBasedOnLocation(selectedLocation, overViewDetailsResult)

      // Fetch cutting overview data
      console.log("Fetching cutting overview data...")
      const cuttingOverViewResult = await getCuttingOverView(startDate, endDate, selectedLocationCode)
      console.log("Cutting overview data:", cuttingOverViewResult)
      setCuttingOverViewResults(cuttingOverViewResult)

      filterCuttingOverViewDataListBasedOnLocation(selectedLocation, cuttingOverViewResult)

      // Fetch loading overview data
      console.log("Fetching cutting overview data...")
      const loadingOverViewResult = await getloadingOverView(startDate, endDate, selectedLocationCode)
      console.log("loading overview data:", loadingOverViewResult)
      setloadingOverViewResults(loadingOverViewResult)

      filterLoadingOverViewDataListBasedOnLocation(selectedLocation, loadingOverViewResult)


      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {

        setOverViewResults([])
        setCuttingOverViewResults([])
        setloadingOverViewResults([])
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }

    setIsLoading(false)
  }


  // const fetchOverViewTrend = async () => {
  //   if (isLoading) {
  //     return
  //   }
  //   setIsLoading(true)
  //   setOverViewTrendResults([])

  //   try {

  //     const overViewTrendsResult = await getOverViewTrend()

  //     const sortedOverViewTrendsResult = overViewTrendsResult.sort(function (a, b) {
  //       return a.location.localeCompare(b.location) || a.date.localeCompare(b.date)
  //     })

  //     setOverViewTrendResults(sortedOverViewTrendsResult)
  //     filterOverViewTrendDataListBasedOnLocation(selectedLocation, sortedOverViewTrendsResult)

  //     setIsError(false)
  //     setErrorMessage('')
  //   } catch (err) {
  //     if (err instanceof AxiosError || err instanceof Error) {
  //       setOverViewResults([])
  //       setSnackBarOpen(true)
  //       setIsError(true)
  //       setErrorMessage(err.message)
  //     } else {
  //       throw err
  //     }
  //   }
  //   setIsLoading(false)
  // }

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)


  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  /**This function is responsible to refresh overView Data */
  const refreshOverViewData = () => {
    fetchOverViewDetails()
  }

  /**This function is responsible to handle dropDown location menu change 
   * @param {string} location The location
   */
  const handleDropdownMenuChange = (location: string) => {
    setSelectedLocation(location)
    filterOverViewDataListBasedOnLocation(location, overViewResults)
    filterCuttingOverViewDataListBasedOnLocation(location, cuttingoverViewResults)
    filterLoadingOverViewDataListBasedOnLocation(location, loadingoverViewResults)
    filterOverViewTrendDataListBasedOnLocation(location, overViewTrendResults)

    if (location === locationInitalValue.locationValue) { setIsLocationSelected(false) } else { setIsLocationSelected(true) }
  }

  const handleLocationCodeChange = (value: string) => {
    setSelectedLocationCode(value)
    setOrgCode(value)

    const filteredLocations = actualOverViewResults
      .filter((item) => item.locationCode === value)
      .map((item) => item.locationName)
      .filter((value, index, self) => self.indexOf(value) === index)

    setLocationsList([locationInitalValue.locationValue, ...filteredLocations])
  }

  /**This function is responsible to filter overView data list based on location
   * @param {string} location The location
   * @param {OverViewData[]} data The overView data list
   * @return {OverViewData[]}
   */
  const filterOverViewDataListBasedOnLocation = (location: string, data: OverViewData[]) => {
    if (location === locationInitalValue.locationValue) {
      setActualOverViewResults(data)
    } else {
      const filteredResults = data.reduce((accumulator: OverViewData[], value) => {
        if (location === value.locationName) {
          accumulator.push({ ...value })
        }
        return accumulator
      }, [])

      console.log("Filtered overview results:", filteredResults)
      setActualOverViewResults(filteredResults)
    }
  }


  /**This function is responsible to filter cutting overView data list based on location
   * @param {string} location The location
   * @param {CuttingOverview[]} data The overView data list
   * @return {CuttingOverview[]}
   */
  const filterCuttingOverViewDataListBasedOnLocation = (location: string, data: CuttingOverview[]) => {
    if (location === locationInitalValue.locationValue) {
      setCuttingActualOverViewResults(data)
    } else {
      const filteredResults = data.reduce((accumulator: CuttingOverview[], value) => {
        if (location === value.locationName) {
          accumulator.push({ ...value })
        }
        return accumulator
      }, [])

      console.log("Filtered cutting results:", filteredResults)
      setCuttingActualOverViewResults(filteredResults)
    }
  }

  /**This function is responsible to filter loading overView data list based on location
   * @param {string} location The location
   * @param {CuttingOverview[]} data The overView data list
   * @return {CuttingOverview[]}
   */
  const filterLoadingOverViewDataListBasedOnLocation = (location: string, data: LoadingOverview[]) => {
    if (location === locationInitalValue.locationValue) {
      setLoadingActualOverViewResults(data)
    } else {
      const filteredResults = data.reduce((accumulator: LoadingOverview[], value) => {
        if (location === value.locationName) {
          accumulator.push({ ...value })
        }
        return accumulator
      }, [])

      console.log("Filtered loading results:", filteredResults)
      setLoadingActualOverViewResults(filteredResults)
    }
  }

  /**This function is responsible to filter overView trend data list based on location
   * @param {string} location The location
   * @param {OverViewTrendData[]} data The overView trend data list
   * @return {OverViewTrendData[]}
   */
  const filterOverViewTrendDataListBasedOnLocation = (location: string, data: OverViewTrendData[]) => {
    if (location === locationInitalValue.locationValue) {
      setActualOverViewTrendResults(data)
    } else {
      const filteredResults = data.reduce((accumulator: OverViewTrendData[], value) => {
        if (location === value.locationName) {
          accumulator.push({ ...value })
        }
        return accumulator
      }, [])

      console.log("Filtered trend results:", filteredResults)
      setActualOverViewTrendResults(filteredResults)
    }
  }
  /**This function is responsible to prepare list of location for dropDown menu
   * @return {string[]} The list of location
   */
  const getLocationCodesList = (
    overviewData: OverViewData[],
    cuttingOverViewResults: CuttingOverview[],
    overviewTrendData: OverViewTrendData[]
  ): string[] => {
    let locationCodes: string[] = []

    if (overviewData.length > 0) {
      locationCodes = overviewData
        .map((item) => item.locationCode)
        .filter((value, index, self) => self.indexOf(value) === index)
    }

    if (cuttingOverViewResults.length > 0) {
      cuttingOverViewResults.forEach((cuttingObject) => {
        const { locationCode } = cuttingObject

        if (!locationCodes.includes(locationCode)) {
          locationCodes.push(locationCode)
        }
      })
    }


    locationCodes = sortAlphaNumericArrayList(locationCodes)
    locationCodes.unshift("")
    return locationCodes
  }



  /**This function is responsible to prepare list of location for dropDown menu
   * @return {string[]} The list of location
   */
  const locationList = (
    overviewData: OverViewData[],
    cuttingOverViewResults: CuttingOverview[],
    overviewTrendData: OverViewTrendData[]
  ): string[] => {
    let locations: string[] = []

    if (overviewData.length > 0) {
      locations = overviewData
        .filter((item) => selectedLocationCode === locationInitalValue.locationValue || item.locationCode === selectedLocationCode)
        .map((item) => item.locationName)
        .filter((value, index, self) => self.indexOf(value) === index)
    }

    if (cuttingOverViewResults.length > 0) {
      cuttingOverViewResults.forEach((cuttingObject) => {
        const { locationName, locationCode } = cuttingObject

        if (!locations.includes(locationName) && (selectedLocationCode === locationInitalValue.locationValue || locationCode === selectedLocationCode)) {
          locations.push(locationName)
        }
      })
    }

    if (overviewTrendData.length > 0) {
      overviewTrendData.forEach((trendObject) => {
        const { locationName } = trendObject

        if (!locations.includes(locationName) && (selectedLocationCode === locationInitalValue.locationValue)) {
          locations.push(locationName)
        }
      })
    }

    locations = sortAlphaNumericArrayList(locations)
    locations.unshift(locationInitalValue.locationValue)
    return locations
  }

  /**This function is responsible to handle dateRange change
   * @param {dateRangeType} value The date range
   */
  const handleDateRangeChange = (value: dateRangeType) => {
    setDateRange(value)
  }

  /**
   * This function is responsible to handle radio button change
   */
  const handleRadioButtonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioButonSelect((event.target as HTMLInputElement).value)
  }

  /**
   * This function is responsible to calculate DHU percentage
   */
  const calculateDHUPercent = () => {
    let totalPassedQuantity = 0
    let totalDefect = 0
    actualOverViewResults.map((overViewObject) => {
      const { DHUPassedQuantity, DHUReworkedQuantity, DHURejectedQuantity } = overViewObject

      totalPassedQuantity += DHUPassedQuantity
      totalDefect += (DHUReworkedQuantity + DHURejectedQuantity)
      return null
    })

    if (totalPassedQuantity > 0) {
      return (totalDefect * 100) / totalPassedQuantity
    }
    return 0
  }

  /**
   * This function is responsible to calculate total production
   */
  const calculateTotalProduction = () => {
    return actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.outputQuantity
    }, 0) as number
  }
  /**
   * This function is responsible to calculate end table pass
   */
  const calculateEndTablePass = () => {
    return actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.DHUPassedQuantity
    }, 0) as number
  }

  /**
   * This function is responsible to calculate total cutting 
   */
  const calculateTotalCutting = () => {
    return actualCuttingOverViewResults.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0) as number
  }

  /**function responsible to calculate average days for factories
   * @param {OverViewData[]} data The overView results
   */
  const getFactoriesAverageWorkDays = (data: OverViewData[]) => {
    const groupedData = groupBy(data, (i) => i.location)
    let totalLocations = 0
    let totalDays = 0

    Object.entries(groupedData).map(([key, value]) => {
      totalDays += value[0].locationWorkDays
      totalLocations += 1
      return null
    })

    if (totalLocations > 0) {
      return (totalDays / totalLocations)
    }
    return 0
  }

  /**function responsible to calculate average days for batches
   * @param {OverViewData[]} data The overView results
   */
  const getBatchesAverageWorkDays = (data: OverViewData[]) => {
    const groupedData = groupBy(data, (i) => i.batchNumber)
    let totalBatches = 0
    let totalDays = 0

    Object.entries(groupedData).map(([key, value]) => {
      totalDays += value[0].batchWorkDays
      totalBatches += 1
      return null
    })

    if (totalBatches > 0) {
      return (totalDays / totalBatches)
    }
    return 0
  }

  /**
   * This function is responsible to calculate total workstations
   */
  const calculateTotalWorkStations = () => {
    let averageWorkDays = 0

    if (isLocationSelected === true) {
      averageWorkDays = getBatchesAverageWorkDays(actualOverViewResults)
    } else {
      averageWorkDays = getFactoriesAverageWorkDays(actualOverViewResults)
    }


    const totalWorkStation = actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.totalWorkStation
    }, 0) as number

    if (averageWorkDays > 0) {
      return roundNumberToSignificantDigits((totalWorkStation / averageWorkDays), 0)
    }
    return totalWorkStation
  }

  /**
   * This function is responsible to calculate number of machines
   */
  const calculateNumberOfMachines = () => {

    let averageWorkDays = 0

    if (isLocationSelected === true) {
      averageWorkDays = getBatchesAverageWorkDays(actualOverViewResults)
    } else {
      averageWorkDays = getFactoriesAverageWorkDays(actualOverViewResults)
    }

    const numberOfMachines = actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.numberOfMachines
    }, 0) as number

    if (averageWorkDays > 0) {
      return roundNumberToSignificantDigits((numberOfMachines / averageWorkDays), 0)
    }
    return numberOfMachines
  }

  /**
   * This function is responsible to calculate average global efficiency
   */
  const calculateAverageGlobalEfficiency = () => {
    const globalSamProduced = actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.globalSamProduced
    }, 0) as number

    const globalMachineMinutes = actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.globalMachineMinutes
    }, 0) as number

    let averageGlobalEfficiency = 0
    if (globalMachineMinutes > 0) {
      averageGlobalEfficiency = ((globalSamProduced / globalMachineMinutes) * 100)
    }
    return averageGlobalEfficiency
  }



  /**
   * This function is responsible to calculate average sewing efficiency
   */
  const calculateAverageSewingEfficiency = () => {
    const sewingSamProduced = actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.sewingSamProduced
    }, 0) as number

    const sewingMachineMinutes = actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.sewingMachineMinutes
    }, 0) as number

    let averageSewingEfficiency = 0
    if (sewingMachineMinutes > 0) {
      averageSewingEfficiency = ((sewingSamProduced / sewingMachineMinutes) * 100)
    }
    return averageSewingEfficiency
  }

  /**
   * This function is responsible to calculate global sam produced
   */
  const calculateGlobalSamProduced = () => {
    return Math.round(actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.globalSamProduced
    }, 0)) as number
  }

  /**
   * This function is responsible to calculate sewing sam produced
   */
  const calculateSewingSamProduced = () => {
    return Math.round(actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.sewingSamProduced
    }, 0)) as number
  }

  /**
 * This function is responsible for calculating the total defect
 */
  const calculateDefect = () => {
    let totalDefect = 0
    actualOverViewResults.forEach((overViewObject) => {
      const { DHUReworkedQuantity, DHURejectedQuantity } = overViewObject

      totalDefect += DHUReworkedQuantity + DHURejectedQuantity
    })
    return totalDefect
  }

  /**
    * This function is responsible to calculate total target
    */
  const calculateTotaltarget = () => {
    return actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.batchTargetQuantity
    }, 0) as number
  }
  /**
     * This function is responsible to calculate total loading 
     */
  const calculateTotalLoading = () => {
    return actualloadingOverViewResults.reduce((acc, curr) => {
      return acc + curr.total_loading
    }, 0) as number
  }

  /**
 * Function responsible to calculate the average length of batches
 */
  const getBatchesAverageLength = () => {
    const totalBatchCountSum = actualOverViewResults.reduce((acc, curr) => {
      return acc + curr.batchNumber.split(',').length
    }, 0) as number

    const totalBatches = actualOverViewResults.length

    // let averageBatchLength = 0
    // if (totalBatches > 0) {
    //   averageBatchLength = totalBatchCountSum / totalBatches
    // }
    return totalBatches
  }



  return (
    <React.Fragment>
      <SnackBarMessage message={errorMessage} successStatus={false} open={snackBarOpen} onClose={handleSnackBarClose} />

      <Grid container sx={{
        height: '100vh'
      }}>

        <Grid item xs={12}
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1
          }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 1
          }}>
            <Box sx={{
              display: 'flex',
              width: '50%',
              justifyContent: 'flex-start'
            }}>

              <FormControl>
                <RadioGroup row value={radioButonSelect} onChange={handleRadioButtonChange}>
                  <FormControlLabel value="visualView" control={<Radio size="small" />} label="Visual View" />
                  <FormControlLabel value="detailView" control={<Radio size="small" />} label="Detail View" />
                </RadioGroup>
              </FormControl>
            </Box>

            <Box sx={{
              display: 'flex',
              width: '50%',
              flexDirection: 'row',
              gap: 3,
              justifyContent: 'flex-end'
            }}>
              <DatePicker onChange={handleDateRangeChange} defaultDateRange={(value: dateRangeType) => setDateRange(value)} direction={'horizontal'} />
              {/* <LocationDropdownMenu onChange={handleLocationCodeChange} menuValues={locationCodesList} buttonText={'GROUP'} /> */}
              <LocationDropdownMenu onChange={handleLocationCodeChange} menuValues={["ID", "BD"]} buttonText={'GROUP'} />
              {/* <input type="text" value={selectedLocationCode} onChange={(e) => setOrgCode(e.target.value)} /> */}
              <OverViewDropdownMenu onChange={handleDropdownMenuChange} menuValues={locationsList} />
              <Button
                onClick={refreshOverViewData}
                sx={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  '& .MuiSvgIcon-root': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    fontSize: '1.8rem',
                    marginRight: '0.0rem',
                  },
                  backgroundColor: 'alpha.main',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'alpha.main',
                  },
                }}
                startIcon={<RefreshIcon />}
              />

            </Box>
          </Box>
          {
            (isLoading) ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
              >
                <LinearProgress sx={{ width: '50%' }} />
              </Box>
            ) : (
              // <Box sx={{
              //   width: '100%',
              //   height: '100%',
              //   bgcolor: 'primary.pane',
              //   backgroundColor: 'surface.dark'
              // }}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  // bgcolor: 'primary.pane',
                  // backgroundColor: 'surface.dark',
                  backgroundImage: `url(${img})`, // Replace with your image path
                  // backgroundSize: 'cover',  // This makes sure the image covers the entire box
                  backgroundSize: '100% 90%',
                  backgroundPosition: 'center', // Centers the image in the box
                  backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                }} >
               
                {
                  overViewResults.length > 0 ? (
                    radioButonSelect === 'visualView' ? (
                      <React.Fragment>
                        {
                          actualOverViewResults.length > 0
                            ?
                            <Box sx={{
                              padding: 2,
                              display: 'flex',
                              flexDirection: 'row',
                              gap: 2
                            }}>
                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #ffd1dc 30%, #ff9a8b 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${0.2}s forwards`,
                                opacity: 0, // Initial opacity for animation
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",
                              }} >


                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <RunningWithErrors sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateDHUPercent(), 2))}%`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    AVG DHU%
                                  </Typography>
                                  <Box sx={{ width: '100%', height: 0, borderTop: '1px dotted', borderColor: 'alpha.light', marginY: '12px' }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateDefect(), 0))}`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    TOTAL DEFECTS
                                  </Typography>
                                </CardContent>

                                <CardContent>
                                </CardContent>

                              </Card>

                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #e0c3fc 30%, #8ec5fc 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${0.4}s forwards`,
                                opacity: 0, // Initial opacity for animation
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",


                              }} >

                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <ContentCut sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalCutting(), 0))}`}
                                  </Typography>

                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    CUTTING
                                  </Typography>
                                  <Box sx={{ width: '100%', height: 0, borderTop: '1px dotted', borderColor: 'alpha.light', marginY: '12px' }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalLoading(), 0))}`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    TOTAL LOADING
                                  </Typography>
                                </CardContent>


                              </Card>
                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #ff9a9e 30%, #fad0c4 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${0.6}s forwards`,
                                opacity: 0, // Initial opacity for animation  
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",
                              }} >

                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <CrisisAlertIcon sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotaltarget(), 0))}`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    TARGET
                                  </Typography>
                                  <Box sx={{ width: '100%', height: 0, borderTop: '1px dotted', borderColor: 'alpha.light', marginY: '12px' }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(getBatchesAverageLength(), 2))}`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    RUNNING LINES
                                  </Typography>

                                </CardContent>


                              </Card>

                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #d4fc79 30%, #96e6a1 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${0.8}s forwards`,
                                opacity: 0, // Initial opacity for animation    
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",
                              }}>

                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <InsertChart sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateEndTablePass(), 0))}`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    END TABLE
                                  </Typography>
                                  <Box sx={{ width: '100%', height: 0, borderTop: '1px dotted', borderColor: 'alpha.light', marginY: '12px' }} />
                                  <Typography variant='h6' color='alpha.light' align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalProduction(), 0))}`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    PRODUCTION
                                  </Typography>
                                </CardContent>
                              </Card>


                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #fbc2eb 30%, #a6c1ee 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${1.0}s forwards`,
                                opacity: 0, // Initial opacity for animation  
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",
                              }}>

                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <TableRestaurant sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalWorkStations(), 0))}`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    TOTAL AVG W/S
                                  </Typography>
                                  <Box sx={{ width: '100%', height: 0, borderTop: '1px dotted', borderColor: 'alpha.light', marginY: '12px' }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateNumberOfMachines(), 0))}`}
                                  </Typography>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    AVG MACHINES
                                  </Typography>

                                </CardContent>

                              </Card>

                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #ffecd2 30%, #fcb69f 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${1.2}s forwards`,
                                opacity: 0, // Initial opacity for animation    
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",
                              }}>

                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <Public sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateAverageGlobalEfficiency(), 2))}%`}
                                  </Typography>
                                </CardContent>

                                <CardContent>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    AVG GLOBAL EFFICIENCY
                                  </Typography>
                                </CardContent>
                              </Card>

                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #f6d365 30%, #fda085 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${1.4}s forwards`,
                                opacity: 0, // Initial opacity for animation    
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",
                              }} >


                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <BookOnline sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateAverageSewingEfficiency(), 2))}%`}
                                  </Typography>
                                </CardContent>

                                <CardContent>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    AVG SEWING EFFICIENCY
                                  </Typography>
                                </CardContent>
                              </Card>

                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #89f7fe 30%, #66a6ff 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${1.6}s forwards`,
                                opacity: 0, // Initial opacity for animation  
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",
                              }} >


                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <JoinFull sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateGlobalSamProduced(), 2))}`}
                                  </Typography>
                                </CardContent>

                                <CardContent>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    GLOBAL SAM PRODUCED
                                  </Typography>
                                </CardContent>
                              </Card>

                              <Card sx={{
                                flex: 1,
                                borderRadius: "10px",
                                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                                background: "linear-gradient(135deg, #ffb8b8 30%, #ffdede 90%)",
                                color: "white",
                                padding: "20px",
                                animation: `${pianoEffect} 0.5s ease-in-out ${1.8}s forwards`,
                                opacity: 0, // Initial opacity for animation   
                                perspective: "1000px",
                                transformStyle: "preserve-3d",
                                transition: "transform 0.3s ease-in-out",
                                transform: "rotateY(15deg) rotateX(10deg)",
                              }} >

                                <CardContent sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center"
                                }}>
                                  <Timer sx={{ color: 'alpha.light', fontSize: 40, marginBottom: 2 }} />
                                  <Typography variant='h6' color={'alpha.light'} align='center' fontWeight='bold'>
                                    {`${convertNumberToLocaleString(roundNumberToSignificantDigits(calculateSewingSamProduced(), 2))}`}
                                  </Typography>
                                </CardContent>

                                <CardContent>
                                  <Typography variant='body2' color={'alpha.light'} align='center' sx={{ fontWeight: 'bold', fontSize: '17px' }}>
                                    M/C SAM PRODUCED
                                  </Typography>
                                </CardContent>
                              </Card>


                            </Box>
                            : null
                        }
                        {
                          actualOverViewResults.length > 0
                            ? <Box sx={{
                              paddingLeft: 2,
                              paddingRight: 2,
                              backgroundColor: '#FFFFFF'
                            }}>
                              <EfficiencyContributionChart overViewData={actualOverViewResults} isLocationSelected={isLocationSelected} />
                            </Box>
                            : null
                        }
                        {
                          actualOverViewResults.length > 0
                            ?
                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingTop: 2
                            }}>
                              <Box sx={{
                                width: '50%'
                              }}>
                                <SewingEfficiencyChart data={actualOverViewResults} isLocationSelected={isLocationSelected} />
                              </Box>

                              <Box sx={{
                                width: '50%'
                              }}>
                                <SewingDHUChart data={actualOverViewResults} isLocationSelected={isLocationSelected} />
                              </Box>
                            </Box>

                            : null
                        }
                        {/* 
                        {
                          actualOverViewTrendResults.length > 0
                            ? (
                              <Box>
                                <SewingTrendChart data={actualOverViewTrendResults} />
                              </Box>
                            )
                            : null
                        } */}

                      </React.Fragment>
                    ) : (
                      <Box>
                        <SewingTable data={actualOverViewResults} isLocationSelected={isLocationSelected} data1={actualCuttingOverViewResults} data2={actualloadingOverViewResults} />
                      </Box>
                    )
                  ) : (null)
                }
              </Box>

            )
          }
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default OverViewDashboard
