import { useCallback, useEffect, useState } from 'react'
import {OverViewData} from '../types'
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import {roundNumberToSignificantDigits, sortAlphaNumericArrayList} from '../../utils'

interface SewingEfficiencyChartProps {
  data: OverViewData[]
  isLocationSelected: boolean
}

type chartSeries = {
  name: string,
  type: string,
  data: number[]
}


type groupedDataType = {
  [x: string]: OverViewData[]
}

const SewingEfficiencyChart = (props: SewingEfficiencyChartProps) => {
  const { data, isLocationSelected } = props
  const [chartCategories, setChartCategories] = useState<string[]>([])
  const [chartSeriesData, setChartSeriesData] = useState<chartSeries[]>([])
  const [efficiencyHeaderText, setEfficiencyHeaderText] = useState<string>('')

  useEffect(() => {
    if (isLocationSelected ===  true){
      setEfficiencyHeaderText('Sewing Efficiency by Line')
    } else {
      setEfficiencyHeaderText('Sewing Efficiency by Factory')
    }
    const locationList = getChartCategories(data)
    const seriesData = formatDataForPresentation(data)
    setChartCategories(locationList)
    setChartSeriesData(seriesData)
    
  }, [data])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  
  const groupDataBasedOnLocationSelection = (data: OverViewData[])=>{
    if (isLocationSelected === false){
      return groupBy(data, item => item.locationName)
    }
    return groupBy(data, item => item.batchNumber)
  }
    
  const getChartCategories = useCallback((overViewData: OverViewData[]) => {
    const groupedData = groupDataBasedOnLocationSelection(overViewData)
    let locations: string[] = []
    
    Object.entries(groupedData).map(([key, value]) =>{
      locations.push(key)
      return null
    })
    locations = sortAlphaNumericArrayList(locations)
    return locations
  }, [data])

  const sortPresentableData = (data: groupedDataType)=>{
    let sortedKeyList :string[]  = []
    const sortedGroupedData : groupedDataType = {}

    Object.entries(data).map(([key, value]) =>{
      sortedKeyList.push(key)
      return null
    })

    sortedKeyList = sortAlphaNumericArrayList(sortedKeyList)    

    sortedKeyList.map((keyName)=>{
      Object.entries(data).map(([key, value]) =>{
        if (keyName === key){
          sortedGroupedData[key] = [...value]
        } return null })
      return null })

    return sortedGroupedData
  }

  const formatDataForPresentation = useCallback((overViewData: OverViewData[]) => {
    const groupedData = groupDataBasedOnLocationSelection(overViewData)
    const sortedGroupedData = sortPresentableData(groupedData)

    const seriesDataList : chartSeries[] = []
    const productionData : chartSeries = {name: 'Production', type: 'column', data: []}
    const targetData : chartSeries = {name: 'Target', type: 'column', data: []}
    const efficiencyData : chartSeries = {name: 'Efficiency', type: 'line', data: []}

    Object.entries(sortedGroupedData).map(([key, value]) =>{
      const sewingSamProduced = value.reduce((acc, curr) => {
        return acc + curr.sewingSamProduced
      }, 0) as number

      const sewingMachineMinutes = value.reduce((acc, curr) => {
        return acc + curr.sewingMachineMinutes
      }, 0) as number

      const outPutQuantity = value.reduce((acc, curr) => {
        return acc + curr.outputQuantity
      }, 0) as number

      const targetQuantity = value.reduce((acc, curr) => {
        return acc + curr.batchTargetQuantity
      }, 0) as number

      let efficiency = 0 
      if (sewingMachineMinutes > 0){
        efficiency = roundNumberToSignificantDigits(((sewingSamProduced / sewingMachineMinutes) * 100), 2)
      }

      productionData.data.push(roundNumberToSignificantDigits(outPutQuantity, 0))
      targetData.data.push(roundNumberToSignificantDigits(targetQuantity, 0))
      efficiencyData.data.push(efficiency)
      return null
    })

    seriesDataList.push(productionData)
    seriesDataList.push(targetData)
    seriesDataList.push(efficiencyData)
    return seriesDataList
  }, [data])


  const getOptions = ()=>{
    const options: ApexOptions = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2]  
      },
      stroke: {
        width: [1, 4]
      },
      title: {
        text: efficiencyHeaderText,
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        categories: chartCategories,
        labels: {
          show: true,
          rotate: -20,
          rotateAlways: true
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#0F4750'
          },
          labels: {
            formatter(val, opts?) {
              return val.toFixed(0)
            },
            style: {
              colors: '#0F4750',
            }
          },
          title: {
            text: "Production",
            style: {
              color: '#0F4750',
            }
          },
          tooltip: {
            enabled: true
          }
        },
        
        {
          seriesName: 'Production',
          opposite: true,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: '#3ACF3A'
          },
          labels: {
            show: false,
            formatter(val, opts?) {
              return val.toFixed(0)
            }
          }
        },
        {
          seriesName: 'Efficiency',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FEB019'
          },
          labels: {
            style: {
              colors: '#FEB019',
            },
          },
          title: {
            text: "Efficiency in Percentage",
            style: {
              color: '#FEB019',
            }
          }
        },
      ],
      colors: ['#0F4750', '#3ACF3A', '#F9A847'],
      legend: {
        horizontalAlign: 'center',
        offsetX: 40
      }
    }
    return options
  }

  return (
    <Chart options={getOptions()} series={chartSeriesData} type="line" width={'100%'} height={'350'} />
  )
}

export default SewingEfficiencyChart