import { apiClient } from "../../Services/Networking/apiClient"
import { BundleTrackvalue, DHUDataDetails, DispatchScanningData, SewingEfficiencyDetails, locationDetailsType,  } from "../types"


export const getEfficiencyDetails = async (location: string, startDate: string, endDate: string) => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/sewing/dateWise/efficiency/location/${location}`,
    params: { startDate, endDate }
  })
  return response as unknown as SewingEfficiencyDetails[]
}

export const getDefectDetails = async (location: string, startDate: string, endDate: string) => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/sewing/defects/location/${location}`,
    params: { startDate, endDate }
  })
  return response as unknown as DHUDataDetails[]
}

export const getLocationList = async (): Promise<locationDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/service/locations`
  })
  return response as unknown as locationDetailsType[]
}

export const getDispatchScanningDetails = async (location: string, startDate: string) => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/sewing/DispatchScanning/location/${location}`,
    params: { startDate }
  })
  return response as unknown as DispatchScanningData[]
}
export const getBundleTrackDetails = async (location: string) => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/kanban/webReport/location/${location}`,
    
  })
  return response as unknown as BundleTrackvalue[]
}