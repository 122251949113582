import React from 'react'
import { FormControl, Button } from '@mui/material'

interface FlatButtonProps {
  label: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  disableControlsStatus: boolean
}

const FlatButton = (props: FlatButtonProps) => {
  const { label, onClick, disableControlsStatus } = props
  return (
    <FormControl sx={{
      width: '120px'
    }}>
      <Button variant="contained" size="medium" sx={{
        backgroundColor: 'alpha.light'
      }} onClick={onClick} disabled={disableControlsStatus}>{label}</Button>
    </FormControl>
  )
}

export default FlatButton