import React, {useEffect, useState} from 'react'
import {OverViewData} from '../types'
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import {roundNumberToSignificantDigits, sortAlphaNumericArrayList} from '../../utils'


interface EfficiencyContributionChartProps {
  overViewData : OverViewData[]
  isLocationSelected: boolean
}

type chartSeriesType = {
  name: string,
  data: number[]
}

type groupedDataType = {
  [x: string]: OverViewData[]
}

function EfficiencyContributionChart(props: EfficiencyContributionChartProps) {
  const {overViewData, isLocationSelected} = props
  const [chartSeriesData, setChartSeriesData] = useState<chartSeriesType[]>([])
  const [contributionHeaderText, setContributionHeaderText] = useState<string>('')

  useEffect(() => {
    if (isLocationSelected ===  true){
      setContributionHeaderText('Efficiency Contribution By Line')
    } else {
      setContributionHeaderText('Efficiency Contribution By Factory')
    }
    const chartSeries = calculateContribution(overViewData)
    setChartSeriesData(chartSeries)
    
  }, [overViewData])
  
  
  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)


  const groupDataBasedOnLocationSelection = (data: OverViewData[])=>{
    if (isLocationSelected === false){
      return groupBy(data, item => item.locationName)
    }
    return groupBy(data, item => item.batchNumber)
  }

  const sortPresentableData = (data: groupedDataType)=>{
    let sortedKeyList :string[]  = []
    const sortedGroupedData : groupedDataType = {}

    Object.entries(data).map(([key, value]) =>{
      sortedKeyList.push(key)
      return null
    })

    sortedKeyList = sortAlphaNumericArrayList(sortedKeyList)    

    sortedKeyList.map((keyName)=>{
      Object.entries(data).map(([key, value]) =>{
        if (keyName === key){
          sortedGroupedData[key] = [...value]
        } return null })
      return null })

    return sortedGroupedData
  }
  const calculateContribution = (data: OverViewData[])=>{
    const groupedData = groupDataBasedOnLocationSelection(data)
    const sortedGroupedData: groupedDataType = sortPresentableData(groupedData)

    const efficiencyCalculatedData: chartSeriesType[]  = []
    let sumOfEfficiency = 0

    Object.entries(sortedGroupedData).map(([key, value]) =>{

      const sewingSamProduced = value.reduce((acc, curr) => {
        return acc + curr.sewingSamProduced
      }, 0) as number

      const sewingMachineMinutes = value.reduce((acc, curr) => {
        return acc + curr.sewingMachineMinutes
      }, 0) as number

      let efficiency = 0 
      if (sewingMachineMinutes > 0){
        efficiency = ((sewingSamProduced / sewingMachineMinutes) * 100)
      }
      sumOfEfficiency += efficiency
      efficiencyCalculatedData.push({name: key, data: [efficiency]})
      return null
    })

    /** Calculate contribution based on location efficiency */
    let totalContribution = 0
    const seriesData: chartSeriesType[] = efficiencyCalculatedData.map((Object)=>{
      const {name, data} = Object
      let contribution = 0
      if (sumOfEfficiency > 0){
        contribution = roundNumberToSignificantDigits(((data[0] / sumOfEfficiency) * 100), 2)
      }
      totalContribution += contribution
      return {name, data: [contribution]}
    })
    
    if (totalContribution > 100 && totalContribution < 101){
      const differenceIncontribution = totalContribution - 100
  
      const lastElementValue = seriesData[seriesData.length - 1]
      const actualContribution = roundNumberToSignificantDigits((lastElementValue.data[0] - differenceIncontribution), 2)
      seriesData.pop()
      seriesData.push({name: lastElementValue.name, data: [actualContribution]})
    }

    return seriesData
  }

  const getOptions = ()=>{
    const options: ApexOptions = {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%'
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      title: {
        text: contributionHeaderText
      },
      xaxis: {
        categories: [''],
        labels: {
          formatter: function (val) {
            return val + '%'
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      colors: ['#AB21FE', '#2B6688', '#811B4C', '#62754E', '#045C9E', '#0A5A36', '#B391A5', '#C59C61', '#3C3D14', '#4F7A21', '#4A3930', '#464B8C', '#019476', '#A23D11', '#D4992E'],
      tooltip: {
        y: {
          formatter: function (val) {
            return val + '%'
          }
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
      
    }

    return options
  }


  return (
    <React.Fragment>

      <Chart options={getOptions()} series={chartSeriesData} type="bar" width={'100%'} height={'160'} />

    </React.Fragment>
  )
}

export default EfficiencyContributionChart