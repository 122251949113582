import { apiClient } from "../../Services/Networking/apiClient"
import { TrackerProduct, TrackerData, CuttingDetailsData } from "../types"


export const getTrackerProductDetails = async (ocNumber: string): Promise<TrackerProduct[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabstitch/ocNumber/${ocNumber}/products`
  })
  return response as unknown as TrackerProduct[]
}

export const getTrackerDetails = async (ocNumber: string, product: string): Promise<TrackerData> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/tracker/product/${product}`,
    params: { ocNumber: ocNumber }
  })
  return response as unknown as TrackerData
}

export const getCuttingDetails = async (product: string): Promise<CuttingDetailsData[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/tracker/cutting/product/${product}`
  })
  return response as unknown as CuttingDetailsData[]
}

export const getLoadDetails = async (product: string): Promise<CuttingDetailsData[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/tracker/poandcountry/product/${product}`
  })
  return response as unknown as CuttingDetailsData[]
}