import React from 'react'
import { FormControl, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

interface BorderRoundedButtonProps {
  label: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const BorderRoundedButton = (props: BorderRoundedButtonProps) => {
  const { label, onClick } = props
  return (
    <FormControl sx={{
      width: '135px'
    }}>
      <Button variant="contained" size="small" sx={{
        backgroundColor: 'alpha.light',
        borderRadius: '25px'
      }} onClick={onClick} endIcon={<AddIcon sx={{
        backgroundColor: 'white',
        color: 'alpha.light',
        borderRadius: '25px'
      }}></AddIcon>}>{label}</Button>
    </FormControl>
  )
}

export default BorderRoundedButton