import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'
import storage from '../Storage/localStorage'

function useAuthenticationToken() {
  const { getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState<string>('')
  useEffect(() => {
    const getToken = async () => {
      const retrievedToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      })
      setToken(retrievedToken)
      storage.setToken(retrievedToken)
    }
    getToken()
  })

  return token
}

export default useAuthenticationToken