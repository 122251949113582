import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ListItem, ListItemIcon, Box, Drawer, List, Divider, Typography, Link, IconButton } from '@mui/material'
import SpeedIcon from '@mui/icons-material/Speed'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLongOutlined'
import ContentCutIcon from '@mui/icons-material/ContentCut'
import AllInboxSharpIcon from '@mui/icons-material/AllInboxSharp'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined'
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import WarehouseIcon from '@mui/icons-material/WarehouseOutlined'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import LogoutIcon from '@mui/icons-material/Logout'
import OutboundOutlinedIcon from '@mui/icons-material/OutboundOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import Can from './Can'
import rules from '../../Services/Authentication/rbac-rules'
import { ReactComponent as OCIcon } from '../Icon/ocIcon.svg'
import { ReactComponent as ProductLabelLogo } from '../Icon/productLabelLogo.svg'
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"

const CustomListItemIcon = (props: { children: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment, isSelected: boolean }) => {
  return (
    <ListItemIcon sx={{
      minWidth: '20px',
      color: props.isSelected ? 'white' : 'white'
    }}>
      {props.children}
    </ListItemIcon>
  )
}

const SideMenu = (props: { userPermissions: Array<Object>, logout: any }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  const handleMenuItemClick = (item: string) => {
    setSelectedItem(item)
    setIsDrawerOpen(false)
  }

  const getMenuItemStyles = (item: string) => ({
    backgroundColor: selectedItem === item ? '#5a81bc' : 'transparent',
    color: 'white'
  })

  return (
    <Box>
      <IconButton
        size='large'
        edge='start'
        color='inherit'
        aria-label='logo'
        onClick={() => setIsDrawerOpen(true)}
        sx={{ paddingLeft: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer variant='permanent' anchor='left'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: 'alpha.main',
            color: 'white',
            minWidth: '50px',
            overflowY: 'scroll', // Enable vertical scrolling
            scrollbarWidth: 'none', // Hide the default scrollbar in Firefox
            '&::-webkit-scrollbar': { // Hide the default scrollbar in WebKit browsers (Chrome, Safari, Edge)
              display: 'none'
            },
            '&:hover': {
              '&::-webkit-scrollbar': {
                display: 'auto' // Show scrollbar on hover in WebKit browsers
              }
            }
          }
        }}>

        <List>
          <Divider component={'li'} sx={{
            border: 'none'
          }} />

          <Can
            userPermissions={props.userPermissions}
            action={rules.OverView}
            yes={() => (
              <Typography sx={{
                paddingTop: 3
              }}>
                <Link component={RouterLink} to='Overview' sx={{ color: 'white' }}>
                  <ListItem button onClick={() => handleMenuItemClick('Overview')} sx={getMenuItemStyles('Overview')}>
                    <CustomListItemIcon isSelected={selectedItem === 'Overview'}>
                      <SpeedIcon />
                    </CustomListItemIcon>
                  </ListItem>
                </Link>
              </Typography>
            )}
            no={() => null}
            isValidateStrict={true}
          />

          <Can
            userPermissions={props.userPermissions}
            action={rules.OcTracker}
            yes={() => (
              <Typography sx={{
                paddingTop: 3
              }}>
                <Link component={RouterLink} to='OcTracker' sx={{ color: 'white' }}>
                  <ListItem button onClick={() => handleMenuItemClick('OcTracker')} sx={getMenuItemStyles('OcTracker')}>
                    <CustomListItemIcon isSelected={selectedItem === 'OcTracker'}>
                      <OCIcon />
                    </CustomListItemIcon>
                  </ListItem>
                </Link>
              </Typography>
            )}
            no={() => null}
            isValidateStrict={true}
          />

          <Divider component={'li'} sx={{
            border: 'none'
          }} />

          {/**Departments side menu  */}
          <Can
            userPermissions={props.userPermissions}
            action={rules.DepartmentsTitle}
            yes={() => (
              <li>


                <Can
                  userPermissions={props.userPermissions}
                  action={rules.FabricAnalytics}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='fabric' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('fabric')} sx={getMenuItemStyles('fabric')}>
                          <CustomListItemIcon isSelected={selectedItem === 'fabric'}>
                            <ReceiptLongIcon />
                          </CustomListItemIcon>
                        </ListItem>
                      </Link>
                    </Typography>
                  )}
                  no={() => null}
                  isValidateStrict={true}
                />
                <Can
                  userPermissions={props.userPermissions}
                  action={rules.FabricAnalytics}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='rack_manage' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('rack_manage')} sx={getMenuItemStyles('rack_manage')}>
                          <CustomListItemIcon isSelected={selectedItem === 'rack_manage'}>
                            <AllInboxSharpIcon />
                          </CustomListItemIcon>
                        </ListItem>
                      </Link>
                    </Typography>
                  )}
                  no={() => null}
                  isValidateStrict={true}
                />

                <Can
                  userPermissions={props.userPermissions}
                  action={rules.CuttingAnalytics}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='cutting' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('cutting')} sx={getMenuItemStyles('cutting')}>
                          <CustomListItemIcon isSelected={selectedItem === 'cutting'}>
                            <ContentCutIcon />
                          </CustomListItemIcon>
                        </ListItem>
                      </Link>
                    </Typography>
                  )}
                  no={() => null}
                  isValidateStrict={true}
                />

                <Can
                  userPermissions={props.userPermissions}
                  action={rules.SewingAnalytics}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='sewing' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('sewing')} sx={getMenuItemStyles('sewing')}>
                          <CustomListItemIcon isSelected={selectedItem === 'sewing'}>
                            <ConfirmationNumberIcon />
                          </CustomListItemIcon>
                        </ListItem></Link>
                    </Typography>
                  )} no={() => null}
                  isValidateStrict={true}
                />

                <Can
                  userPermissions={props.userPermissions}
                  action={rules.Finishing}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='warehouse' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('warehouse')} sx={getMenuItemStyles('warehouse')}>
                          <CustomListItemIcon isSelected={selectedItem === 'warehouse'}>
                            <WarehouseIcon />
                          </CustomListItemIcon>
                        </ListItem></Link>
                    </Typography>
                  )} no={() => null}
                  isValidateStrict={true}
                />

                <Can
                  userPermissions={props.userPermissions}
                  action={rules.Finishing}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='finishing' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('finishing')} sx={getMenuItemStyles('finishing')}>
                          <CustomListItemIcon isSelected={selectedItem === 'finishing'}>
                            <Inventory2OutlinedIcon />
                          </CustomListItemIcon>
                        </ListItem></Link>
                    </Typography>
                  )} no={() => null}
                  isValidateStrict={true}
                />

                <Divider component={'li'} sx={{
                  border: 'none'
                }} />


              </li>
            )}
            no={() => null}
            isValidateStrict={false}
          />



          {/**Products side menu  */}

          <Can
            userPermissions={props.userPermissions}
            action={rules.ProductsTitle}
            yes={() => (
              <li>


                <Can
                  userPermissions={props.userPermissions}
                  action={rules.ManageOrders}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='ManageOrders' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('ManageOrders')} sx={getMenuItemStyles('ManageOrders')}>
                          <CustomListItemIcon isSelected={selectedItem === 'ManageOrders'}>
                            <AssignmentIcon />
                          </CustomListItemIcon>
                        </ListItem></Link>
                    </Typography>
                  )} no={() => null}
                  isValidateStrict={true}
                />
                <Can
                  userPermissions={props.userPermissions}
                  action={rules.ManageOrders}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='OcDetails' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('OcDetails')} sx={getMenuItemStyles('OcDetails')}>
                          <CustomListItemIcon isSelected={selectedItem === 'OcDetails'}>
                            <ManageSearchIcon />
                          </CustomListItemIcon>
                        </ListItem></Link>
                    </Typography>
                  )} no={() => null}
                  isValidateStrict={true}
                />

                <Can
                  userPermissions={props.userPermissions}
                  action={rules.Barcodes}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='Barcode' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('Barcode')} sx={getMenuItemStyles('Barcode')}>
                          <CustomListItemIcon isSelected={selectedItem === 'Barcode'}>
                            <QrCodeScannerIcon />
                          </CustomListItemIcon>
                        </ListItem></Link>
                    </Typography>
                  )} no={() => null}
                  isValidateStrict={true} />

                <Can
                  userPermissions={props.userPermissions}
                  action={rules.JobworkOrder}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='Jobwork' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('Jobwork')} sx={getMenuItemStyles('Jobwork')}>
                          <CustomListItemIcon isSelected={selectedItem === 'Jobwork'}>
                            <OutboundOutlinedIcon />
                          </CustomListItemIcon>
                        </ListItem></Link>
                    </Typography>
                  )} no={() => null}
                  isValidateStrict={true} />

                <Can
                  userPermissions={props.userPermissions}
                  action={rules.ProductQr}
                  yes={() => (
                    <Typography sx={{
                      paddingTop: 3
                    }}>
                      <Link component={RouterLink} to='productlabel' sx={{ color: 'white' }}>
                        <ListItem button onClick={() => handleMenuItemClick('productlabel')} sx={getMenuItemStyles('productlabel')}>
                          <CustomListItemIcon isSelected={selectedItem === 'productlabel'}>
                            <ProductLabelLogo />
                          </CustomListItemIcon>
                        </ListItem></Link>
                    </Typography>
                  )} no={() => null}
                  isValidateStrict={true} />

              </li>
            )}
            no={() => null}
            isValidateStrict={false}
          />



          <li>
            <Typography sx={{
              paddingLeft: 2,
              paddingTop: 4
            }} onClick={() => props.logout({ returnTo: process.env.REACT_APP_HOME_URL })}>

              <Link component={RouterLink} to='' sx={{ color: 'white' }}>
                <CustomListItemIcon isSelected={false}>
                  <LogoutIcon />
                </CustomListItemIcon>

              </Link>
            </Typography>
          </li>

        </List>
      </Drawer>
    </Box>
  )
}
export default SideMenu