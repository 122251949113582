import { useCallback, useEffect, useState } from 'react'
import {OverViewData} from '../types'
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import {roundNumberToSignificantDigits, sortAlphaNumericArrayList} from '../../utils'

interface SewingDHUChartProps {
  data: OverViewData[]
  isLocationSelected: boolean
}

type chartSeries = {
  name: string,
  type: string,
  data: number[]
}


type groupedDataType = {
  [x: string]: OverViewData[]
}

const SewingDHUChart = (props: SewingDHUChartProps) => {
  const { data, isLocationSelected } = props
  const [chartCategories, setChartCategories] = useState<string[]>([])
  const [chartSeriesData, setChartSeriesData] = useState<chartSeries[]>([])
  const [DHUHeaderText, setDHUHeaderText] = useState<string>('')

  useEffect(() => {
    if (isLocationSelected === true){
      setDHUHeaderText('Average DHU by Line')
    } else { 
      setDHUHeaderText('Average DHU by Factory')
    }
    const locationList = getChartCategories(data)
    const seriesData = formatDataForPresentation(data)
    setChartCategories(locationList)
    setChartSeriesData(seriesData)
    
  }, [data])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  
  const groupDataBasedOnLocationSelection = (data: OverViewData[])=>{
    if (isLocationSelected === false){
      return groupBy(data, item => item.locationName)
    }
    return groupBy(data, item => item.batchNumber)
  }
    
  const getChartCategories = useCallback((overViewData: OverViewData[]) => {
    const groupedData = groupDataBasedOnLocationSelection(overViewData)
    let locations: string[] = []
    
    Object.entries(groupedData).map(([key, value]) =>{
      locations.push(key)
      return null
    })
    locations = sortAlphaNumericArrayList(locations)
    return locations
  }, [data])

  const sortPresentableData = (data: groupedDataType)=>{
    let sortedKeyList :string[]  = []
    const sortedGroupedData : groupedDataType = {}

    Object.entries(data).map(([key, value]) =>{
      sortedKeyList.push(key)
      return null
    })

    sortedKeyList = sortAlphaNumericArrayList(sortedKeyList)    

    sortedKeyList.map((keyName)=>{
      Object.entries(data).map(([key, value]) =>{
        if (keyName === key){
          sortedGroupedData[key] = [...value]
        } return null })
      return null })

    return sortedGroupedData
  }

  const formatDataForPresentation = useCallback((overViewData: OverViewData[]) => {
    const groupedData = groupDataBasedOnLocationSelection(overViewData)
    const sortedGroupData = sortPresentableData(groupedData)
    const seriesDataList : chartSeries[] = []
    const DHUData : chartSeries = {name: 'DHU%', type: 'column', data: []}

    Object.entries(sortedGroupData).map(([key, value]) =>{
      const DHUPassedQuantity = value.reduce((acc, curr) => {
        return acc + curr.DHUPassedQuantity
      }, 0) as number

      const DHUReworkedQuantity = value.reduce((acc, curr) => {
        return acc + curr.DHUReworkedQuantity
      }, 0) as number

      const DHURejectedQuantity = value.reduce((acc, curr) => {
        return acc + curr.DHURejectedQuantity
      }, 0) as number

      let DHU = 0 
      if (DHUPassedQuantity > 0){
        DHU = roundNumberToSignificantDigits((((DHUReworkedQuantity + DHURejectedQuantity) / DHUPassedQuantity) * 100), 2)
      }

      DHUData.data.push(DHU)
      return null
    })

    seriesDataList.push(DHUData)
    return seriesDataList
  }, [data])


  const getOptions = ()=>{
    const options: ApexOptions = {
      chart: {
        height: 350,
        type: 'bar',
        stacked: false,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          }
        }
      },
      dataLabels: {
        enabled: true
      },
      title: {
        text: DHUHeaderText,
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        categories: chartCategories,
        tickPlacement: 'on',
        labels: {
          show: true,
          rotate: -20,
          rotateAlways: true
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#26A0FC'
          },
          labels: {
            style: {
              colors: '#26A0FC',
            }
          },
          title: {
            text: "DHU Percentage",
            style: {
              color: '#26A0FC',
            }
          },
          tooltip: {
            enabled: true
          }
        }
      ],
      colors: ['#26A0FC', '#F9A847'],
      legend: {
        horizontalAlign: 'center',
        offsetX: 40
      }
    }
    return options
  }

  return (
    <Chart options={getOptions()} series={chartSeriesData} type="bar" width={'100%'} height={'350'} />
  )
}

export default SewingDHUChart