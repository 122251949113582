import React from 'react'
import { Box, Typography } from '@mui/material'

const NoDataFound = () => {
  return (
    <>
      <Box
       
      >
        <Typography variant="h6" color="textSecondary">
          No Data Found
        </Typography>
      </Box>
    
      <Box>
        <h3> Select single date to see Data of Dispatch</h3>
      </Box>
    </>  
  )
}

export default NoDataFound
