import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import StyleProvider from './Providers/StyleProvider'
import AuthenticationProvider from './Providers/AuthenticationProvider'
import reportWebVitals from './reportWebVitals'
import ErrorBoundary from './UI/Components/ErrorBoundary'

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <StyleProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <AuthenticationProvider>
            <Routes>
              <Route path='*' element={<App />} />
            </Routes>
          </AuthenticationProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </StyleProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
