import React, { useState, useEffect } from 'react'
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography } from '@mui/material'
import { TrackerOutPutData } from '../types'
import {roundNumberToSignificantDigits} from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface ProductionDetailProps {
  trackerOutPutData: TrackerOutPutData[]
}

type GroupedDataByLocationType = {
  [x: string]: TrackerOutPutData[]
}

function ProductionDetail(props: ProductionDetailProps) {
  const {trackerOutPutData} = props

  const [groupedTableData, setGroupedTableData] = useState<GroupedDataByLocationType>({})

  useEffect(() => {
    const groupedDataByLocation  =  groupBy(trackerOutPutData, i => i.location)
    
    setGroupedTableData(groupedDataByLocation)
  }, [trackerOutPutData])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)


  /**
    * This function is responsible to get start date
    */
  const getStartDate = () => {
    let productionStartDate = ''

    trackerOutPutData.map((object)=>{
      const {date} = object

      const splitDateList = date.split('-')
      const formattedDate = `${splitDateList[2]}-${splitDateList[1]}-${splitDateList[0]}`

      if (formattedDate < productionStartDate || productionStartDate === ''){
        productionStartDate = formattedDate
      }
      return null
    })
    return productionStartDate
  }

  /**
    * This function is responsible to calculate average sewing efficiency
    */
  const calculateAverageSewingEfficiency = () => {
    const sewingSamProduced = trackerOutPutData.reduce((acc, curr) => {
      return acc + curr.sewingSamProduced
    }, 0) as number

    const sewingMachineMinutes = trackerOutPutData.reduce((acc, curr) => {
      return acc + curr.sewingMachineMinutes
    }, 0) as number

    let averageSewingEfficiency = 0
    if (sewingMachineMinutes > 0){
      averageSewingEfficiency = ((sewingSamProduced / sewingMachineMinutes) * 100)
    }
    return averageSewingEfficiency
  }

  /**
    * This function is responsible to calculate average global efficiency
    */
  const calculateAverageGlobalEfficiency = () => {
    const globalSamProduced = trackerOutPutData.reduce((acc, curr) => {
      return acc + curr.globalSamProduced
    }, 0) as number

    const globalMachineMinutes = trackerOutPutData.reduce((acc, curr) => {
      return acc + curr.globalMachineMinutes
    }, 0) as number

    let averageGlobalEfficiency = 0
    if (globalMachineMinutes > 0){
      averageGlobalEfficiency = ((globalSamProduced / globalMachineMinutes) * 100)
    }
    return averageGlobalEfficiency
  }
  

  /**
   * This function is responsible to calculate sewing efficiency in combination of location, batch and date
   */
  const tableRowSewingEfficiency = (data : TrackerOutPutData) => {
    const {sewingSamProduced, sewingMachineMinutes} = data

    let averageSewingEfficiency = 0
    if (sewingMachineMinutes > 0){
      averageSewingEfficiency = ((sewingSamProduced / sewingMachineMinutes) * 100)
    }
    return averageSewingEfficiency
  }

  /**
   * This function is responsible to calculate global efficiency in combination of location, batch and date
   */
  const tableRowGlobalEfficiency = (data : TrackerOutPutData) => {

    const {globalSamProduced, globalMachineMinutes} = data

    let averageGlobalEfficiency = 0
    if (globalMachineMinutes > 0){
      averageGlobalEfficiency = ((globalSamProduced / globalMachineMinutes) * 100)
    }
    return averageGlobalEfficiency
  }

  const renderTableRows = (tableData: GroupedDataByLocationType) => {
    let serialNumber = 0
    return Object.entries(tableData).map(([key, value], index) =>{
      return value.map((object)=>{
        const {locationName, batchNumber, date, DHUPassedQuantity, outputQuantity, numberOfMachines, totalWorkStation, availableMinutes, sewingSam, loadingQuanity}  = object
        serialNumber += 1
        const splitDateList = date.split('-')
        const formattedDate = `${splitDateList[2]}-${splitDateList[1]}-${splitDateList[0]}`
      
        return (
          <TableRow key={`${key}-${batchNumber}-${date}`}>
            <TableCell align='center'>{serialNumber}</TableCell>
            <TableCell align='center'>{locationName}</TableCell>
            <TableCell align='center'>{batchNumber}</TableCell>
            <TableCell align='center'>{formattedDate}</TableCell>
            <TableCell align='center'>{numberOfMachines}</TableCell>
            <TableCell align='center'>{totalWorkStation}</TableCell>
            <TableCell align='center'>{loadingQuanity}</TableCell>
            <TableCell align='center'>{DHUPassedQuantity}</TableCell>
            <TableCell align='center'>{outputQuantity}</TableCell>
            <TableCell align='center'>{availableMinutes}</TableCell>
            <TableCell align='center'>{roundNumberToSignificantDigits((outputQuantity * sewingSam), 0)}</TableCell>
            <TableCell align='center'>{roundNumberToSignificantDigits(tableRowSewingEfficiency(object), 2)}</TableCell>
            <TableCell align='center'>{roundNumberToSignificantDigits(tableRowGlobalEfficiency(object), 2)}</TableCell>
          </TableRow>
        )
      })

    })

  }


  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []
    let serialNumber = 0

    Object.entries(groupedTableData).map(([key, value], index) =>{
      value.map((object)=>{
        const {locationName, batchNumber, date, DHUPassedQuantity, outputQuantity, numberOfMachines, totalWorkStation, availableMinutes, sewingSam}  = object
        serialNumber += 1

        const splitDateList = date.split('-')
        const formattedDate = `${splitDateList[2]}-${splitDateList[1]}-${splitDateList[0]}`

        const pushObject: any = {
          slNo: serialNumber,
          factoryName: locationName,
          lineName: batchNumber,
          date: formattedDate,
          totalMachines: numberOfMachines,
          totalWorkStation: totalWorkStation,
          endTablePass: DHUPassedQuantity,
          production: outputQuantity,
          availableMinutes: availableMinutes,
          producedMinutes: roundNumberToSignificantDigits((outputQuantity * sewingSam), 0),
          machineEfficiency: roundNumberToSignificantDigits(tableRowSewingEfficiency(object), 2),
          globalEfficiency: roundNumberToSignificantDigits(tableRowGlobalEfficiency(object), 2)
        }
        jsonData.push(pushObject)
        return null
      })

      return null
    })

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'ocTracker-ProductionDetail')
    XLSX.writeFile(workBook, 'ocTracker-ProductionDetail.xlsx')
  }


  return (
    
    <React.Fragment>
      <Box sx={{
        paddingTop: 2,
        paddingBottom: 2
      }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell align='center'><Typography variant='subtitle1'>START DATE</Typography></TableCell>
                <TableCell align='center'><Typography variant='subtitle1'>NO. OF DAYS RUN</Typography></TableCell>
                <TableCell align='center'><Typography variant='subtitle1'>AVG MACHINE EFF%</Typography></TableCell>
                <TableCell align='center'><Typography variant='subtitle1'>AVG GLOBAL EFF%</Typography></TableCell>
                <TableCell align='center'><Typography variant='subtitle1'>MACHINE SAM</Typography></TableCell>
                <TableCell align='center'><Typography variant='subtitle1'>GLOBAL SAM</Typography></TableCell>
              </TableRow>
            </TableHead>
            

            <TableBody>
              <TableRow key={1}>
                <TableCell align='center'>{getStartDate()}</TableCell>
                <TableCell align='center'>{trackerOutPutData.length}</TableCell>
                <TableCell align='center'>{roundNumberToSignificantDigits(calculateAverageSewingEfficiency(), 2)}%</TableCell>
                <TableCell align='center'>{roundNumberToSignificantDigits(calculateAverageGlobalEfficiency(), 2)}%</TableCell>
                <TableCell align='center'>{roundNumberToSignificantDigits(trackerOutPutData[0].sewingSam, 2)}</TableCell>
                <TableCell align='center'>{roundNumberToSignificantDigits(trackerOutPutData[0].globalSam, 2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table> 
        </TableContainer>
      </Box>

      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}>
        
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>

      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell align='center'><Typography variant='subtitle1'>SL NO.</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>FACTORY NAME</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>LINE NAME</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>DATE</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>TOTAL MACHINES</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>TOTAL W/S</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>LOADING QUANTITY</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>END TABLE PASS</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>PRODUCTION</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>AVAILABLE MINUTES(M/C)</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>PRODUCED MINUTES(M/C)</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>MACHINE EFFICIENCY%</Typography></TableCell>
              <TableCell align='center'><Typography variant='subtitle1'>GLOBAL EFFICIENCY%</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              renderTableRows(groupedTableData)
            }
          
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default ProductionDetail