import { useEffect, useState, useCallback } from 'react'
import { SewingEfficiencyDetails } from '../types'
import {roundNumberToSignificantDigits} from '../../utils'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'

interface SewingDateRangeChartProps {
  data: SewingEfficiencyDetails[]
}

type ChartDataType = {
  date: string,
  efficiency: number,
  label: number,
  targetQuantity: number
}

type chartSeries = {
  name: string,
  type: string,
  data: number[]
}

const SewingDateRangeChart = (props: SewingDateRangeChartProps) => {
  const { data } = props
  const [chartCategories, setChartCategories] = useState<string[]>([])
  const [chartSeriesData, setChartSeriesData] = useState<chartSeries[]>([])


  useEffect(() => {
    const sortedData = sortData(data)
    const efficiencyData = calculateEfficiencyAndTotalQuantityForDate(sortedData)
    const dateList = [...new Set(efficiencyData.map(item => item.date))]

    const seriesDataList = formatDataForPresentation(dateList, efficiencyData)
    
    setChartCategories(dateList)
    setChartSeriesData(seriesDataList)
  }, [data])

  const sortData = (data: SewingEfficiencyDetails[]) => {
    return data.sort((a, b) => {
      if (a.date > b.date) {
        return 1
      } else if (a.date < b.date) {
        return -1
      } return 0
    })
  }

  const formatDataForPresentation = useCallback((dateList: string[], data: ChartDataType[]) => {
    const seriesDataList : chartSeries[] = []
    const productionData : chartSeries = {name: 'Production', type: 'column', data: []}
    const targetData : chartSeries = {name: 'Target', type: 'column', data: []}
    const efficiencyData : chartSeries = {name: 'Efficiency', type: 'line', data: []}

    dateList.map((dateInDDMM) =>{
      const getDataObject = data.find(dataObject => dataObject.date === dateInDDMM)
      if (getDataObject === null || getDataObject === undefined) {
        productionData.data.push(0)
        targetData.data.push(0)
        efficiencyData.data.push(0)
      } else {
        productionData.data.push(getDataObject.label)
        targetData.data.push(getDataObject.targetQuantity)
        efficiencyData.data.push(getDataObject.efficiency)
      }
      return null
    })
    seriesDataList.push(productionData)
    seriesDataList.push(targetData)
    seriesDataList.push(efficiencyData)
    return seriesDataList
  }, [])


  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const calculateEfficiencyAndTotalQuantityForDate = (data: SewingEfficiencyDetails[]) => {
    const groupedData = groupBy(data, item => item.date)
    const chartData: ChartDataType[]  = []

    Object.entries(groupedData).map(([key, value]) =>{
      let totalSamProduced = 0
      let totalMachineMinutes = 0
      let totalQuantities = 0
      let totalTargetQuantities = 0
      let dateEfficiency = 0
      
      const dateSplit = key.split('-')
      const dateInMMDD = `${dateSplit[1]}-${dateSplit[2]}`

      
      value.map((object) => {
        const { hourlyDetails, totalOutputQuantity, batchTargetQuantity } = object
        totalQuantities += totalOutputQuantity
        totalTargetQuantities += batchTargetQuantity

        totalSamProduced += hourlyDetails.reduce((acc, curr) => {
          const { sewingSamProduced } = curr
          return acc + sewingSamProduced
        }, 0)
        totalMachineMinutes += hourlyDetails.reduce((acc, curr) => {
          const { sewingMachineMinutes } = curr
          return acc + sewingMachineMinutes
        }, 0)
        return null
      })

      if (totalSamProduced > 0){
        dateEfficiency = ((totalSamProduced / totalMachineMinutes) * 100)
      }
      chartData.push({
        date: dateInMMDD,
        efficiency: roundNumberToSignificantDigits(dateEfficiency, 2),
        label: roundNumberToSignificantDigits(totalQuantities, 2),
        targetQuantity: roundNumberToSignificantDigits(totalTargetQuantities, 2)
      })
      
      return null
    })
    return chartData
  }

  const getOptions = ()=>{
    const options: ApexOptions = {
      colors: ['#4576b5', '#3db374', '#ff7400'],
      chart: {
        height: 350,
        type: 'line',
        stacked: false
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2]  
      },
      stroke: {
        width: [1, 1, 4]
      },
      title: {
        text: 'Production & Sewing Efficiency - Date Range',
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        categories: chartCategories,
        labels: {
          show: true,
          rotate: -20,
          rotateAlways: true
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#4576b5'
          },
          labels: {
            formatter(val, opts?) {
              return val.toFixed(0)
            },
            style: {
              colors: '#4576b5',
            }
          },
          title: {
            text: "Production",
            style: {
              color: '#4576b5',
            }
          },
          tooltip: {
            enabled: true
          }
        },
        
        {
          seriesName: 'Production',
          opposite: true,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: '#3db374'
          },
          labels: {
            show: false,
            formatter(val, opts?) {
              return val.toFixed(0)
            },
            style: {
              colors: '#3db374',
            },
          }
        },
        {
          seriesName: 'Efficiency',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#ff7400'
          },
          labels: {
            style: {
              colors: '#ff7400',
            },
          },
          title: {
            text: "Efficiency in Percentage",
            style: {
              color: '#ff7400',
            }
          }
        },
      ],
      legend: {
        horizontalAlign: 'center',
        offsetX: 40
      }
    }
    return options
  }

  return (
    <Chart options={getOptions()} series={chartSeriesData} type="line" width={'100%'} height={'350'} />
  )
}

export default SewingDateRangeChart