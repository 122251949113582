import { apiClient } from "../../Services/Networking/apiClient"
import { locationDetailsType, RackDataInfo, getRackDetailsType, getALLRackDetails, PostRackGenerateBarcodeVariables, FabricDataInfo } from "../types"
// import { PalletDataInfo, FabricDataInfo, locationDetailsType, RackDataInfo} from "../types"




export const getLocationList = async (): Promise<locationDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/service/locations`
  })
  return response as unknown as locationDetailsType[]
}



// export const getConsolidatedRackDetails = async (rackBarcodeloc: string, data: RackDataInfo): Promise<RackDataInfo[]> => {
//   const response = await apiClient({
//     method: 'get',
//     url: `/fabrack/racks/${rackBarcodeloc}`,
//     data: data
//   })
//   return response as unknown as RackDataInfo[]
// }

export const getRackDetailsbyLocation = async (rackLocation: string): Promise<getRackDetailsType[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabrack/racks/${rackLocation}`,
  })
  console.log("response", response)
  return response as unknown as getRackDetailsType[]
}


export const getAllRackDetails = async (): Promise<getALLRackDetails[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/fabrack/racks/rackAllBarcodeDetails`,
  })
  console.log("response", response)
  return response as unknown as getALLRackDetails[]
}

export const postRackBarcodeGenerate = async (data: PostRackGenerateBarcodeVariables): Promise<Boolean> => {
  console.log("data", data)
  const response = await apiClient({
    method: 'post',
    url: `/fabrack/racks/rackBarcodeGenerate`,
    data: data
  })
  return response as unknown as Boolean
}


// export const getConsolidatedPalletDetails = async (): Promise<PalletDataInfo[]> => {
//   const response = await apiClient({
//     method: 'get',
//     url: `/analytics/warehouse/pallets`
//   })
//   return response as unknown as PalletDataInfo[]
// }

// export const getPalletResultsBasedOnArticleNumber = async (articleNumber: string): Promise<PalletDataInfo[]> => {
//   const response = await apiClient({
//     method: 'get',
//     url: `/analytics/warehouse/pallets`,
//     params: { articleNumber: articleNumber }
//   })
//   return response as unknown as PalletDataInfo[]
// }

// export const getPalletResultsBasedOnRackNumber = async (rackNumber: string): Promise<PalletDataInfo[]> => {
//   const response = await apiClient({
//     method: 'get',
//     url: `/analytics/warehouse/pallets`,
//     params: { rackNumber: rackNumber }
//   })
//   return response as unknown as PalletDataInfo[]
// }

export const getConsolidatedFabricDetails = async (): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/rack/fabrack`
  })
  return response as unknown as FabricDataInfo[]
}

export const getFabricDataBasedOnItemCode = async (itemCode: string, location: string): Promise<FabricDataInfo[]> => {
  console.log("itemCode,location", itemCode, location)
  const response = await apiClient({
    method: 'get',
    url: `/analytics/rack/fabrack`,
    params: { itemCode: itemCode, rackLocation: location }
  })
  return response as unknown as FabricDataInfo[]
}

export const getFabricDataBasedOnRack = async (rackNumber: string, location: string): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/rack/fabrack`,
    params: { rackNumber: rackNumber, rackLocation: location }
  })
  return response as unknown as FabricDataInfo[]
}

export const getFabricDataBasedOnFabricBarcode = async (fabricBarcode: string, location: string): Promise<FabricDataInfo[]> => {

  const response = await apiClient({
    method: 'get',
    url: `/analytics/rack/fabrack`,
    params: { fabricBarcode: fabricBarcode, rackLocation: location }
  })
  return response as unknown as FabricDataInfo[]
}

// export const getPalletResultsBasedOnWareHouseEntryDateRange = async (fromEntryDate: string, toEntryDate: string): Promise<PalletDataInfo[]> => {
//   const response = await apiClient({
//     method: 'get',
//     url: `/analytics/warehouse/pallets`,
//     params: { fromEntryDate: fromEntryDate, toEntryDate: toEntryDate}
//   })
//   return response as unknown as PalletDataInfo[]
// }

// export const getPalletResultsBasedOnWareHouseExitDateRange = async (fromExitDate: string, toExitDate: string): Promise<PalletDataInfo[]> => {
//   const response = await apiClient({
//     method: 'get',
//     url: `/analytics/warehouse/pallets`,
//     params: { fromExitDate: fromExitDate, toExitDate: toExitDate}
//   })
//   return response as unknown as PalletDataInfo[]
// }

export const getFabricResultsBasedOnWareHouseEntryDateRange = async (fromEntryDate: string, toEntryDate: string): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/rack/fabrack`,
    params: { fromEntryDate: fromEntryDate, toEntryDate: toEntryDate }
  })
  return response as unknown as FabricDataInfo[]
}

export const getFabricResultsBasedOnWareHouseExitDateRange = async (fromExitDate: string, toExitDate: string): Promise<FabricDataInfo[]> => {
  const response = await apiClient({
    method: 'get',
    url: `/analytics/rack/fabrack`,
    params: { fromExitDate: fromExitDate, toExitDate: toExitDate }
  })
  return response as unknown as FabricDataInfo[]
}








export const getFabricDataBasedOnSupplierBarcode = async (supplierFabricBarcode: string, location: string): Promise<FabricDataInfo[]> => {
  console.log("itemCode,location", supplierFabricBarcode, location)
  const response = await apiClient({
    method: 'get',
    url: `/analytics/rack/fabrack`,
    params: { supplierFabricBarcode: supplierFabricBarcode, rackLocation: location }
  })
  console.log("response", response)
  return response as unknown as FabricDataInfo[]
}