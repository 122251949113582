import React, { useState, useEffect } from 'react'
import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography, Stack } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { FabricDataInfo } from '../types'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import {roundNumberToSignificantDigits} from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'
interface FabricConsolidateDataProps {
  fabricConsolidateResults: FabricDataInfo[]
}

interface FabricSortedObject {
  key: string,
  value: FabricDataInfo[]
}

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial'
  }
})


function FabricConsolidateData(props: FabricConsolidateDataProps) {
  const classes = useStyles()
  const { fabricConsolidateResults } = props
  const [rollColumnarrowStatus, setRollColumnarrowStatus] = useState(false)
  const [meterColumnarrowStatus, setMeterColumnarrowStatus] = useState(false)
  const [fabricConsolidateData, setFabricConsolidateData] = useState<Array<FabricSortedObject>>([])


  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const groupedDataByItemCode =  groupBy(fabricConsolidateResults, i => i.itemCode)

  const sortDataByNumberOfRolls = () => {
    if (rollColumnarrowStatus === true){
      setRollColumnarrowStatus(false)
      setFabricConsolidateData(
        Object.keys(groupedDataByItemCode)
          .map(function(k) { return { key: k, value: groupedDataByItemCode[k] } })
          .sort(function(a, b) { return a.value.length - b.value.length })
      )
    }

    if (rollColumnarrowStatus === false){
      setRollColumnarrowStatus(true)
      setFabricConsolidateData( 
        Object.keys(groupedDataByItemCode)
          .map(function(k) { return { key: k, value: groupedDataByItemCode[k] } })
          .sort(function(a, b) { return b.value.length - a.value.length })
      )
    }
  }

  useEffect(()=>{
    sortDataByNumberOfRolls()
    setMeterColumnarrowStatus(true)
  }, [])

  const calculateTotalFabricLength = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.fabricLength
    }, 0)
  }

  const calculateTotalFabricRolls = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.numberOfRolls
    }, 0)
  }


  const sortDataByFabricMeter = () => {
    if (meterColumnarrowStatus === true){
      setMeterColumnarrowStatus(false)
      setFabricConsolidateData(
        Object.keys(groupedDataByItemCode)
          .map(function(k) { return { key: k, value: groupedDataByItemCode[k] } })
          .sort(function(a, b) { 
            const aValue = a.value.reduce((acc, curr) => {
              return acc + curr.fabricLength
            }, 0)

            const bValue = b.value.reduce((acc, curr) => {
              return acc + curr.fabricLength
            }, 0)
            return aValue - bValue
          
          })
      )

    }
    if (meterColumnarrowStatus === false){
      setMeterColumnarrowStatus(true)
      setFabricConsolidateData( 
        Object.keys(groupedDataByItemCode)
          .map(function(k) { return { key: k, value: groupedDataByItemCode[k] } })
          .sort(function(a, b) { 
            const aValue = a.value.reduce((acc, curr) => {
              return acc + curr.fabricLength
            }, 0)

            const bValue = b.value.reduce((acc, curr) => {
              return acc + curr.fabricLength
            }, 0)
            
            return bValue - aValue
          })
      )
    }
  }

  const totalFabricLength = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.fabricLength
    }, 0)
  }

  const totalRolls = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.numberOfRolls
    }, 0)
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
            
    const jsonData = fabricConsolidateData.map((object)=>{
      return {
        itemCode: object.key, 
        noOfRolls: roundNumberToSignificantDigits(calculateTotalFabricRolls(object.value), 2), 
        meters: roundNumberToSignificantDigits(calculateTotalFabricLength(object.value), 2)
      }
    })
    
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'fabricConsolidateData')
    XLSX.writeFile(workBook, 'fabricConsolidate.xlsx')
    
  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          bgcolor: 'primary.card',
          paddingTop: 1
        }}
      >
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}
      >
        <Typography variant='subtitle1'>
        WAREHOUSE FABRIC INVENTORY SUMMARY
        </Typography>

        <Stack direction="row" spacing={3}>
          <Typography variant='subtitle1' sx={{background: 'white'}}>
          TOTAL ROLLS IN STOCK: {roundNumberToSignificantDigits(totalRolls(fabricConsolidateResults), 0)}
          </Typography>

          <Typography variant='subtitle1'sx={{background: 'white'}}>
          TOTAL METERS IN STOCK: {roundNumberToSignificantDigits(totalFabricLength(fabricConsolidateResults), 2)}
          </Typography>
        </Stack>

   
      </Box>

      <TableContainer component={Paper} classes={{ root: classes.customTableContainer }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>SERIAL NO.</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>ITEM CODE</Typography></TableCell>
              <TableCell
                onClick={() => sortDataByNumberOfRolls()}
              ><Typography variant='subtitle1'>
                NO. OF ROLLS {rollColumnarrowStatus === true ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> }
                </Typography></TableCell>

              <TableCell
                onClick={() => sortDataByFabricMeter()}
              ><Typography variant='subtitle1'>
                METERS {meterColumnarrowStatus === true ? <ArrowDownwardIcon /> : <ArrowUpwardIcon /> }
                </Typography></TableCell>
            </TableRow>
          </TableHead>
            

          <TableBody>
            { 
              fabricConsolidateData.map((object, index)=>{
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{object.key}</TableCell>
                    <TableCell>{roundNumberToSignificantDigits(calculateTotalFabricRolls(object.value), 2)}</TableCell>
                    <TableCell>{roundNumberToSignificantDigits(calculateTotalFabricLength(object.value), 2)} Meters</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table> 
      </TableContainer>
    </React.Fragment>
  )
}

export default FabricConsolidateData