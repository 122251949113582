import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Card, Box, Typography } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { FlatButton } from '../../UI/Components'

interface TechPackDropZoneProps {
  onChange: (file: string) => void
  imageBase64: String
  submitTechPack : () => void
  validationMessage: () => void
}

function TechPackDropZone(props: TechPackDropZoneProps) {
  const {onChange, imageBase64, submitTechPack, validationMessage} = props

  const {getRootProps, getInputProps} = useDropzone({
    onDropAccepted: (files) => handleUpload(files),
    onDropRejected: () => onChange(""),
    multiple: false
  })

  /**Function to validate file
   * @param {File} the file uploaded
   * @return {boolean}
   */
  const validateFile = (file: File) => {
    const fileType = file.type
    const allowedFileTypeList = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif']
    return (allowedFileTypeList.includes(fileType.toLowerCase()))
  }

  /**Function responsible to upload image
   * @param {File[]} The file uploaded
   */
  const handleUpload = (files: File[]) => {
    const file = files[0]
    const validateResult: boolean = validateFile(file)

    if (validateResult === false){
      validationMessage()
      return
    }
    /** convert image to base64 */
    const reader = new FileReader()
    let base64String: string = ''
    reader.onloadend = () => {
      base64String = reader.result?.toString() || ''
      base64String = base64String.split(',')[1]
      onChange(base64String)
    }
    reader.readAsDataURL(file)
  }

  return (
    <React.Fragment>
      <Card sx={{width: '100%', height: '60vh', display: 'flex'}}>

        <Box display={'flex'} alignContent={'center'} justifyContent={'center'} flexDirection={'column'}
          sx={{width: '50%', height: '100%', 
            backgroundColor: 'alpha.lightest', borderRadius: 1, borderStyle: 'dotted',
            borderColor: 'alpha.main'}} 
          {...getRootProps()}>
          <input type={'file'} {...getInputProps()} />
          <Typography variant="h5" color="text.secondary" component="div" align='center'>
            <FileUploadIcon />
          </Typography>
          <Typography variant="h5" color="text.secondary" component="div"
            align='center'>Drag and drop techPack here, </Typography>
          <Typography variant="h5" color="text.secondary" component="div" align='center'>or</Typography>
          <Typography variant="h5" color="text.secondary" component="div" align='center'>click to select techPack </Typography>
        </Box>
        <Box sx={{paddingLeft: 2}}>
          {
            imageBase64 === null || imageBase64 === undefined || imageBase64 === ''
              ? null
              : <img src={`data:image/jpeg;base64,${imageBase64}`}/>
          }
        </Box> 
      </Card>

      <Box display="flex"
        justifyContent="center"
        alignItems="center" sx={{ paddingTop: 4 }}>
        <FlatButton label='SUBMIT' onClick={submitTechPack} disableControlsStatus={false}/>
      </Box>
    </React.Fragment>
  )
}

export default TechPackDropZone