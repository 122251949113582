import React from 'react'
import {
  Box, Paper, Table, TableCell, TableContainer, TableHead, TableBody,
  TableRow, Typography, Stack
} from '@mui/material'
import { FabricDataInfo } from '../types'
import { roundNumberToSignificantDigits } from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'
interface FabricDataForFabricBarcodeProps {
  fabricForFabricBarcodeResults: FabricDataInfo[]
}

function FabricDataForFabricBarcode(props: FabricDataForFabricBarcodeProps) {
  const { fabricForFabricBarcodeResults } = props
  console.log("fabricForFabricBarcodeResults", fabricForFabricBarcodeResults[0].rackDisassociationDateTime)
  const convertDateTimeToLocalString = (date: string | null) => {
    if (date === null || date === undefined) {
      return null
    }
    return new Date(date as string)
      .toLocaleTimeString('en-GB',
        { timeZone: 'UTC', hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }
      ).replace(',', '')
  }

  const totalRollMeters = (data: FabricDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.fabricLength
    }, 0)
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {

    const jsonData = fabricForFabricBarcodeResults.map((object) => {
      return {
        rackNumber: object.rackNumber,
        itemCode: object.itemCode,
        itemDescription: object.itemDescription,
        fabricLengthInMeters: roundNumberToSignificantDigits(object.fabricLength, 2),
        mirNumber: object.mirNumber,
        mirDate: object.mirDate,
        rackAssociationDateTime: convertDateTimeToLocalString(object.rackAssociationDateTime),
        rackDisassociationDateTime: convertDateTimeToLocalString(object.rackDisassociationDateTime)
      }
    })
    console.log("rackDisassociationDateTime", jsonData)
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'fabricDataForBarcode')
    XLSX.writeFile(workBook, 'fabricDataBasedOnBarcode.xlsx')

  }

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{
          bgcolor: 'primary.card',
          paddingTop: 1
        }}
      >
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false} />
      </Box>

      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}
      >


        <Typography variant='subtitle1' fontSize="30px" fontWeight="bold">
          ROLL INFORMATION
        </Typography>

        <Stack direction="row" spacing={3}>
          <Typography variant='subtitle1' sx={{ background: 'white' }} fontSize="20px" fontWeight="bold">
            TOTAL METERS IN STOCK: {roundNumberToSignificantDigits(totalRollMeters(fabricForFabricBarcodeResults), 2)}
          </Typography>
        </Stack>
      </Box>
      <Typography variant='subtitle1' sx={{ paddingLeft: 1, paddingTop: 1, paddingBottom: 1 }}>
        {`${fabricForFabricBarcodeResults[0].fabricBarcode} - ${fabricForFabricBarcodeResults[0].itemDescription}`}
      </Typography>


      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>SERIAL NO.</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK NUMBER</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>ITEM CODE</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>FABRIC LENGTH</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>MIR NUMBER</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>MIR DATE</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK ENTRY DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK EXIT DATE & TIME</Typography></TableCell>
            </TableRow>
          </TableHead>


          <TableBody>
            {
              fabricForFabricBarcodeResults.map((fabricObject, index) => {
                return (

                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{fabricObject.rackNumber}</TableCell>
                    <TableCell>{fabricObject.itemCode}</TableCell>
                    <TableCell>{roundNumberToSignificantDigits(fabricObject.fabricLength, 2)} Meters</TableCell>
                    <TableCell>{fabricObject.mirNumber}</TableCell>
                    <TableCell>{fabricObject.mirDate}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(fabricObject.rackAssociationDateTime)}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(fabricObject.rackDisassociationDateTime)}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default FabricDataForFabricBarcode