import React from 'react'
import { FormControl, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

interface BackButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const BackButton = (props: BackButtonProps) => {
  const { onClick } = props
  return (
    <FormControl sx={{
      width: '80px'
    }}>
      <Button variant="contained" size="small" sx={{
        backgroundColor: 'alpha.light',
        borderRadius: '25px'
      }} onClick={onClick} startIcon ={<ArrowBackIcon sx={{
        backgroundColor: 'white',
        color: 'alpha.light',
        borderRadius: '25px'
      }}></ArrowBackIcon>}>BACK</Button>
    </FormControl>
  )
}

export default BackButton