import React, { useState, useCallback, useEffect } from 'react'
import {
  Grid, Box, FormGroup, FormControlLabel, Checkbox, Typography,
  TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Stack, Tabs, Tab,
  LinearProgress
} from '@mui/material'
import { SearchBar } from '../UI/Components'
import { getTrackerProductDetails, getTrackerDetails, getCuttingDetails, getLoadDetails } from './api/apiFunctions'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { TrackerCuttingData, TrackerOutPutData, TrackerProduct, CuttingDetailsData, LoadDetailsData } from './types'
import { TrackerSummary, TabPanel, CuttingDetails } from './components'
import { AxiosError } from 'axios'
import { SnackBarMessage } from '../UI/Components'
import StringValues from '../Providers/StringValues'
import LoadedDetails from './components/LoadedDetails'

interface OcTrackerDashboardProps {
  componentHeader: (headerName: string) => void
}

const OcTrackerDashboard = (props: OcTrackerDashboardProps) => {

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState<string>('')

  const [productSearchResults, setProductSearchResults] = useState<TrackerProduct[]>([])
  const [cuttingDetailsResults, setCuttingDetailsResults] = useState<TrackerCuttingData[]>([])
  const [outputDetailsResults, setOutputDetailsResults] = useState<TrackerOutPutData[]>([])
  const [cuttingItemDetailsResults, setCuttingItemDetailsResults] = useState<CuttingDetailsData[]>([])
  const [loadItemDetailsResults, setLoadItemDetailsResults] = useState<LoadDetailsData[]>([])
  const [tabState, setTabState] = useState(0)


  useAuthenticationToken()
  props.componentHeader('OC TRACKER')

  /**
   * API call to fetch list of products associated with oc number
   */
  const fetchProductList = useCallback(async () => {
    if (isLoading || searchTerm === '' || searchTerm === undefined || searchTerm === null) {
      return
    }
    setIsLoading(true)
    setOutputDetailsResults([])
    setCuttingItemDetailsResults([])
    setLoadItemDetailsResults([])
    setProductSearchResults([])
    setTabState(0)

    try {
      const productListResult = await getTrackerProductDetails(searchTerm)

      const productResultWithSelectedStatus = productListResult.map((productObject) => {
        return { ...productObject, selected: false }
      })

      setProductSearchResults(productResultWithSelectedStatus)
      setIsError(false)
      setErrorMessage('')

    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }, [searchTerm])

  /**
   * API call to fetch list of dispatch and cutting data
   */

  useEffect(() => {
    let selectedProduct = ''
    productSearchResults.map((productObject) => {
      const { productCode, selected } = productObject

      if (selected === true) {
        selectedProduct = productCode
      }
      return null
    })

    const fetchTrackerAndCuttingDetailsData = async () => {
      if (isLoading || searchTerm === '' || searchTerm === undefined || searchTerm === null) {
        return
      }
      if (selectedProduct === '' || searchTerm === undefined || searchTerm === null) {
        return
      }

      setIsLoading(true)
      setTabState(0)

      try {
        const trackerDataResult = await getTrackerDetails(searchTerm, selectedProduct)
        const cuttingDetailsDataResult = await getCuttingDetails(selectedProduct)
        const loadDetailsDataResult = await getLoadDetails(selectedProduct)

        const { cuttingDetails, outputDetails } = trackerDataResult


        setCuttingItemDetailsResults(cuttingDetailsDataResult)
        setLoadItemDetailsResults(loadDetailsDataResult)
        setCuttingDetailsResults(cuttingDetails)
        setOutputDetailsResults(outputDetails)
        setIsLoading(false)

      } catch (err) {
        if (err instanceof AxiosError || err instanceof Error) {
          setIsLoading(false)
          setSnackBarOpen(true)
          setIsError(true)
          setErrorMessage(err.message)
        } else {
          throw err
        }
      }
    }

    fetchTrackerAndCuttingDetailsData()


  }, [productSearchResults])

  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }


  /**Handle search bar changes */
  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }


  /**Handle search bar finder button click */
  const handleSearchBarButtonClick = () => {
    fetchProductList()
  }

  /**Handle product checkbox change */
  const productCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const product = event.target.name

    const productSelectedResult = productSearchResults.map((productObject) => {
      const { productCode, selected } = productObject

      if (productCode === product) {
        let isSelected = false
        if (selected === false) { isSelected = true }

        if (isSelected === false) {
          setOutputDetailsResults([])
          setCuttingItemDetailsResults([])
          setLoadItemDetailsResults([])
        }
        return { ...productObject, selected: isSelected }
      }
      return { ...productObject, selected: false }
    })
    setProductSearchResults(productSelectedResult)
  }



  return (
    <React.Fragment>
      <SnackBarMessage message={errorMessage} successStatus={false} open={snackBarOpen} onClose={handleSnackBarClose} />
      <SearchBar value={searchTerm} onChange={handleSearchBarChange}
        onButtonClick={handleSearchBarButtonClick} error={isError} errorMessage={errorMessage}
        inputLabel={StringValues.ocNumberLabel} width={'20%'} marginLeft={2} />

      <Grid container sx={{
        height: '100vh'
      }}>

        <Grid item xs={12}
          sx={{
            paddingTop: 2,
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1
          }}>
          {
            (isLoading) ? (
              <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                <Box sx={{ width: "50%" }}>
                  <LinearProgress />
                </Box>
              </Grid>
            ) : (productSearchResults.length > 0 ?
              <Box sx={{
                width: '100%',
                bgcolor: 'primary.pane',
                backgroundColor: 'surface.light'
              }}>
                <Typography sx={{ paddingLeft: 2, paddingTop: 1 }} variant='body2' align='left'>
                  PRODUCT
                </Typography>

                <Stack spacing={2} direction="row">

                  {
                    productSearchResults.map((productObject) => {
                      const { productCode, productDescription, selected } = productObject
                      return (
                        <FormGroup sx={{ paddingLeft: 2 }}>
                          <FormControlLabel control={<Checkbox checked={selected} onChange={productCheckBoxChange} name={productCode} />} label={`${productCode}-${productDescription}`} />
                        </FormGroup>
                      )

                    })
                  }

                </Stack>


                <Box sx={{
                  paddingTop: 1
                }}>
                  {
                    productSearchResults.map((productObject) => {
                      const { productCode, productDescription, styleDescription, orderQuantity, buyerName, selected } = productObject
                      if (selected === true) {
                        return (
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow
                                  sx={{
                                    backgroundColor: 'alpha.lightest'
                                  }}>
                                  <TableCell><Typography variant='subtitle1'>PRODUCT ID</Typography></TableCell>
                                  <TableCell><Typography variant='subtitle1'>PRODUCT TYPE</Typography></TableCell>
                                  <TableCell><Typography variant='subtitle1'>STYLE NAME</Typography></TableCell>
                                  <TableCell><Typography variant='subtitle1'>ORDER QUANTITY</Typography></TableCell>
                                  <TableCell><Typography variant='subtitle1'>BUYER NAME</Typography></TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                <TableRow key={1}>
                                  <TableCell>{productCode}</TableCell>
                                  <TableCell>{productDescription}</TableCell>
                                  <TableCell>{styleDescription}</TableCell>
                                  <TableCell>{orderQuantity}</TableCell>
                                  <TableCell>{buyerName}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )
                      }
                      return null
                    })
                  }
                </Box>
              </Box>
              : null
            )
          }

          {
            (isLoading) ? (
              <Grid container justifyContent='center' alignItems='center' sx={{ height: '100%' }}>
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              </Grid>
            ) : (outputDetailsResults.length > 0 || cuttingItemDetailsResults.length > 0 || loadItemDetailsResults.length > 0 ?
              <Box sx={{
                width: '100%',
                bgcolor: 'primary.pane',
                backgroundColor: 'surface.dark',
                paddingTop: 2
              }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
                    setTabState(newValue)
                  }} textColor='secondary' TabIndicatorProps={{
                    style: {
                      backgroundColor: 'alpha.light'
                    }
                  }}>
                    <Tab sx={{
                      color: 'alpha.light'
                    }} label="LINE SUMMARY" />
                    <Tab sx={{
                      color: 'alpha.light'
                    }} label="COLOR DETAILS" />
                    <Tab sx={{
                      color: 'alpha.light'
                    }} label="PO AND COUNTRY DETAILS" />
                  </Tabs>
                </Box>
                <TabPanel value={tabState} index={0}>
                  {
                    outputDetailsResults.length > 0 ?
                      <TrackerSummary trackerCuttingData={cuttingDetailsResults} trackerOutPutData={outputDetailsResults} trackerCuttingColourData={cuttingItemDetailsResults} productList={productSearchResults} />
                      : null
                  }
                </TabPanel>

                <TabPanel value={tabState} index={1}>
                  {
                    cuttingItemDetailsResults.length > 0 ?
                      <CuttingDetails trackerCuttingDetailsData={cuttingItemDetailsResults} />
                      : null
                  }
                </TabPanel>
                <TabPanel value={tabState} index={2}>
                  {
                    loadItemDetailsResults.length > 0 ?
                      <LoadedDetails trackerloadDetailsData={loadItemDetailsResults} />
                      : null
                  }
                </TabPanel>
              </Box>
              : null
            )

          }

        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default OcTrackerDashboard