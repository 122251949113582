import React, { useState } from 'react'
import { Box, Paper, Tab, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Tabs, Typography } from '@mui/material'
import { TabPanel } from '../components'
import { FabricLocationInfo } from '../types'
import { roundNumberToSignificantDigits, convertNumberToLocaleString } from '../../utils'

interface ItemCodeStockProps {
  fabricItemCodeResults: FabricLocationInfo | undefined
}

function ItemCodeStock(props: ItemCodeStockProps) {
  const { fabricItemCodeResults } = props
  const [tabState, setTabState] = useState(0)

  return (
    <div>
      <Box sx={{
        bgcolor: 'primary.card'
      }}>
        <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
          setTabState(newValue)
        }} textColor='secondary' TabIndicatorProps={{
          style: {
            backgroundColor: 'alpha.light'
          }
        }}>
          <Tab sx={{
            color: 'alpha.light'
          }} label="STOCK" />
          <Tab sx={{
            color: 'alpha.light'
          }} label="RECEIVED" />
          <Tab sx={{
            color: 'alpha.light'
          }} label="TRANSFERRED" />
          <Tab sx={{
            color: 'alpha.light'
          }} label="ADJUSTED" />
        </Tabs>
      </Box>

      <TabPanel value={tabState} index={0}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>FACTORY</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>QUANTITY RECEIVED</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                fabricItemCodeResults?.locationStock.map((locationStockItem, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{locationStockItem.location}</TableCell>
                      <TableCell>{convertNumberToLocaleString(roundNumberToSignificantDigits(locationStockItem.quantity, 2))} Meters</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabState} index={1}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>DATE</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>MIR NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>LOCATION</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>QUANTITY</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                fabricItemCodeResults?.mirInfo.map((mirInfoItem, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{mirInfoItem.date}</TableCell>
                      <TableCell>{mirInfoItem.number}</TableCell>
                      <TableCell>{mirInfoItem.location}</TableCell>
                      <TableCell>{convertNumberToLocaleString(roundNumberToSignificantDigits(mirInfoItem.quantity, 2))} Meters</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabState} index={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>DATE</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>FROM</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>TO</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>QUANTITY</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                fabricItemCodeResults?.dcInfo.map((dcInfoItem, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{dcInfoItem.date}</TableCell>
                      <TableCell>{dcInfoItem.fromLocation}</TableCell>
                      <TableCell>{dcInfoItem.toLocation}</TableCell>
                      <TableCell>{convertNumberToLocaleString(roundNumberToSignificantDigits(dcInfoItem.quantity, 2))} Meters</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabState} index={3}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>ADJUSTMENT NUMBER</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>ITEM CODE</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>QUANTITY</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                fabricItemCodeResults?.adjustmentInfo.map((adjustmentInfoItem, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{adjustmentInfoItem.documentNumber}</TableCell>
                      <TableCell>{adjustmentInfoItem.itemCode}</TableCell>
                      {
                        adjustmentInfoItem.adjustmentType === 'decrease'
                          ? <TableCell sx={{color: 'font.red'}}>-{convertNumberToLocaleString(roundNumberToSignificantDigits(adjustmentInfoItem.quantity, 2))} Meters</TableCell> 
                          : <TableCell sx={{color: 'font.green'}}>+{convertNumberToLocaleString(roundNumberToSignificantDigits(adjustmentInfoItem.quantity, 2))} Meters</TableCell>
                      }
                      
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </div>
  )
}

export default ItemCodeStock