import React, { useEffect, useState } from 'react'
import { OverViewData, CuttingOverview, LoadingOverview } from '../types'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material'
import { roundNumberToSignificantDigits, convertNumberToLocaleString, sortAlphaNumericArrayList } from '../../utils'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface SewingTableProps {
  data: OverViewData[]
  data1: CuttingOverview[]
  data2: LoadingOverview[]
  isLocationSelected: boolean
}

type TableDataType = {
  [x: string]: OverViewData[]
}

type LoadingDataType = {
  [x: string]: LoadingOverview[]
}

const SewingTable = (props: SewingTableProps) => {
  const { data, data1, data2, isLocationSelected } = props

  const [tableData, setTableData] = useState<TableDataType>({})
  const [tableHeader, setTableHeader] = useState<string>('')
  const [columnHeader, setColumnHeader] = useState<string>('')
  const [cuttingHeader, setCuttingHeader] = useState<string>('')
  const [loadingData, setLoadingData] = useState<LoadingDataType>({})


  useEffect(() => {
    if (isLocationSelected === true) {
      setTableHeader('OverView Details for Batch')
      setColumnHeader('BATCH')
      setCuttingHeader('')
      const result: TableDataType = groupBy(data, (i) => i.batchNumber)
      const loadingGroupedResult: LoadingDataType = groupBy(data2, (i) => i.batchNumber)
      const sortedGroupedData = sortPresentableData(result)
      setTableData(sortedGroupedData)
      setLoadingData(loadingGroupedResult)
    } else {
      setTableHeader('OverView Details for Factories')
      setColumnHeader('UNIT')
      setCuttingHeader('CUTTING')
      const result: TableDataType = groupBy(data, (i) => i.locationName)
      const loadingGroupedResult: LoadingDataType = groupBy(data2, (i) => i.locationName)
      const sortedGroupedData = sortPresentableData(result)
      setTableData(sortedGroupedData)
      setLoadingData(loadingGroupedResult)
    }
  }, [data, data2, isLocationSelected])


  const sortPresentableData = (data: TableDataType) => {
    let sortedKeyList: string[] = []
    const sortedGroupedData: TableDataType = {}

    Object.entries(data).map(([key, value]) => {
      sortedKeyList.push(key)
      return null
    })

    sortedKeyList = sortAlphaNumericArrayList(sortedKeyList)

    sortedKeyList.map((keyName) => {
      Object.entries(data).map(([key, value]) => {
        if (keyName === key) {
          sortedGroupedData[key] = [...value]
        } return null
      })
      return null
    })

    return sortedGroupedData
  }

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const getAverageMachinesRunning = (data: OverViewData[]) => {
    let totalDays = 0

    if (isLocationSelected === true) {
      totalDays = data[0].batchWorkDays
    } else {
      totalDays = data[0].locationWorkDays
    }

    const numberOfMachines = data.reduce((acc, curr) => {
      return acc + curr.numberOfMachines
    }, 0) as number

    if (totalDays > 0) {
      return (numberOfMachines / totalDays)
    }

    return numberOfMachines
  }

  const getTotalAverageWorkStation = (data: OverViewData[]) => {
    let totalDays = 0

    if (isLocationSelected === true) {
      totalDays = data[0].batchWorkDays
    } else {
      totalDays = data[0].locationWorkDays
    }

    const totalWorkStation = data.reduce((acc, curr) => {
      return acc + curr.totalWorkStation
    }, 0) as number

    if (totalDays > 0) {
      return (totalWorkStation / totalDays)
    }
    return totalWorkStation
  }

  const getEndTablePassPieces = (data: OverViewData[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.DHUPassedQuantity
    }, 0) as number

  }
  //cutting total funtion 
  const getTotalCutting = (data1: CuttingOverview[]) => {
    return data1.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0) as number

  }

  /**
    * This function is responsible to calculate total target
    */
  const calculateTotaltarget = (data: OverViewData[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.batchTargetQuantity
    }, 0) as number
  }
  /**
   * This function is responsible to calculate total loading 
   */
  const calculateTotalLoading = (data2: LoadingOverview[]) => {
    return data2.reduce((acc, curr) => {
      return acc + curr.total_loading
    }, 0) as number
  }

  const getDispatchProduction = (data: OverViewData[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.outputQuantity
    }, 0) as number
  }

  const getAvailableMinutes = (data: OverViewData[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.sewingMachineMinutes
    }, 0) as number
  }

  const getSamProduced = (data: OverViewData[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.sewingSamProduced
    }, 0) as number
  }

  const getDHUPercent = (data: OverViewData[]) => {
    const DHUPassedQuantity = data.reduce((acc, curr) => {
      return acc + curr.DHUPassedQuantity
    }, 0) as number

    const DHUReworkedQuantity = data.reduce((acc, curr) => {
      return acc + curr.DHUReworkedQuantity
    }, 0) as number

    const DHURejectedQuantity = data.reduce((acc, curr) => {
      return acc + curr.DHURejectedQuantity
    }, 0) as number

    let DHU = 0
    if (DHUPassedQuantity > 0) {
      DHU = roundNumberToSignificantDigits((((DHUReworkedQuantity + DHURejectedQuantity) / DHUPassedQuantity) * 100), 2)
    }

    return DHU
  }

  const getSewingEfficiency = (data: OverViewData[]) => {
    const sewingSamProduced = data.reduce((acc, curr) => {
      return acc + curr.sewingSamProduced
    }, 0) as number

    const sewingMachineMinutes = data.reduce((acc, curr) => {
      return acc + curr.sewingMachineMinutes
    }, 0) as number

    let efficiency = 0
    if (sewingMachineMinutes > 0) {
      efficiency = roundNumberToSignificantDigits(((sewingSamProduced / sewingMachineMinutes) * 100), 2)
    }
    return efficiency

  }

  const getGlobalEfficiency = (data: OverViewData[]) => {
    const globalSamProduced = data.reduce((acc, curr) => {
      return acc + curr.globalSamProduced
    }, 0) as number

    const globalMachineMinutes = data.reduce((acc, curr) => {
      return acc + curr.globalMachineMinutes
    }, 0) as number

    let globalEfficiency = 0
    if (globalMachineMinutes > 0) {
      globalEfficiency = roundNumberToSignificantDigits(((globalSamProduced / globalMachineMinutes) * 100), 2)
    }
    return globalEfficiency

  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []
    const columnObject: any = {}


    Object.entries(tableData).map(([key, value]) => {
      if (isLocationSelected === true) {
        columnObject['BATCH'] = key
      }
      if (isLocationSelected === false) {
        columnObject['UNIT'] = key
      }

      const pushObject: any = {
        ...columnObject,
        avgMachinesRunning: roundNumberToSignificantDigits(getAverageMachinesRunning(value), 0),
        totalAvgWorkStation: roundNumberToSignificantDigits(getTotalAverageWorkStation(value), 0),
        [cuttingHeader]: columnHeader === 'UNIT' ? convertNumberToLocaleString(roundNumberToSignificantDigits(getTotalCutting(data1.filter(item => item.locationName === key)), 0)) : null,
        Loading: convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalLoading(loadingData[key] || []), 0)),
        target: roundNumberToSignificantDigits(calculateTotaltarget(value), 0),
        endTablePassPieces: roundNumberToSignificantDigits(getEndTablePassPieces(value), 0),
        dispatchProduction: roundNumberToSignificantDigits(getDispatchProduction(value), 0),
        availableMinutes: roundNumberToSignificantDigits(getAvailableMinutes(value), 0),
        samProduced: roundNumberToSignificantDigits(getSamProduced(value), 0),
        dhuInPercentage: roundNumberToSignificantDigits(getDHUPercent(value), 2),
        sewingEfficiency: roundNumberToSignificantDigits(getSewingEfficiency(value), 2),
        globalEfficiency: roundNumberToSignificantDigits(getGlobalEfficiency(value), 2)
      }
      jsonData.push(pushObject)
      return null
    })

    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'overViewData')
    XLSX.writeFile(workBook, 'overViewData.xlsx')
  }

  return (
    <TableContainer component={Paper}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}>
        <Typography variant='h5' padding={2}>{tableHeader}</Typography>
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false} />
      </Box>

      <Table sx={{
        overflowX: 'auto',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        height: 450,
        overflowY: 'auto'
      }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'alpha.lightest' }}>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>{columnHeader}</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>AVG MACHINES</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>TOTAL AVG W/S</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>{cuttingHeader}</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>LOADING</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>TARGET</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>END TABLE PASS</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>DISPATCH</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>AVAILABLE MINUTES</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>SAM PRODUCED</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>DHU%</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>SEWING EFFICIENCY</TableCell>
            <TableCell align='center' sx={{ fontWeight: 'bold' }}>GLOBAL EFFICIENCY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            Object.entries(tableData).map(([key, value]) => {
              return (
                <TableRow key={key}>
                  <TableCell align='center'>{key}</TableCell>
                  <TableCell align='center'>{roundNumberToSignificantDigits(getAverageMachinesRunning(value), 0)}</TableCell>
                  <TableCell align='center'>{roundNumberToSignificantDigits(getTotalAverageWorkStation(value), 0)}</TableCell>
                  <TableCell align='center'>{columnHeader === 'UNIT' ? convertNumberToLocaleString(roundNumberToSignificantDigits(getTotalCutting(data1.filter(item => item.locationName === key)), 0)) : null}</TableCell>
                  <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalLoading(loadingData[key] || []), 0))}</TableCell>
                  <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotaltarget(value), 0))}</TableCell>
                  <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(getEndTablePassPieces(value), 0))}</TableCell>
                  <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(getDispatchProduction(value), 0))}</TableCell>
                  <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(getAvailableMinutes(value), 0))}</TableCell>
                  <TableCell align='center'>{convertNumberToLocaleString(roundNumberToSignificantDigits(getSamProduced(value), 0))}</TableCell>
                  <TableCell align='center'>{roundNumberToSignificantDigits(getDHUPercent(value), 2)}</TableCell>
                  <TableCell align='center'>{roundNumberToSignificantDigits(getSewingEfficiency(value), 2)}</TableCell>
                  <TableCell align='center'>{roundNumberToSignificantDigits(getGlobalEfficiency(value), 2)}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SewingTable