import React, { useState, useEffect } from 'react'
import { TextField, Typography, Box, TableContainer, Paper, Table, TableCell, TableHead, TableBody, TableRow, IconButton } from '@mui/material'
import { OrderDetails, SizeQuantityListWithId, sizeQuantities } from '../types'
import { nanoid } from 'nanoid'
import {AddButton, FlatButton} from '../../UI/Components'
import DeleteIcon from '@mui/icons-material/Delete'
import {roundNumberToSignificantDigits} from '../../utils'

interface OrdersProps {
  orderDetails: OrderDetails
  submitOrder : (approvedConsumption: number, orderQuantitiesDetails : sizeQuantities[]) => void
}

function Orders(props: OrdersProps) {
  const {orderDetails, submitOrder} = props

  const [sizeQuantityList, setSizeQuantityList] = useState<SizeQuantityListWithId[]>([])
  const [approvedConsumption, setApprovedConsumption] = useState<number>(0)

  useEffect(() => {
    if (orderDetails !== null && orderDetails !== undefined){
      setApprovedConsumption(orderDetails.approvedConsumption)
      const sizeQuantityWithId =  orderDetails.sizeQuantitiesList.map((object) => {
        const {size, quantity} = object
        return ({
          size,
          quantity,
          id: nanoid(4)
        })
      })
      setSizeQuantityList(sizeQuantityWithId)
    }
  }, [orderDetails])
  
  /**Function to handle approved consumption */
  const handleApprovedConsumptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setApprovedConsumption(roundNumberToSignificantDigits(parseFloat(event.target.value), 2))
  }

  /**This function is responsible to update size
   * @param {string}size The size to update
   * @param {string}indexToChange The index value to effect
   */
  const handleSizeChange = (size: string, indexToChange: string) => {
    const createSizeQuantityListWithChanges = [...sizeQuantityList]
    const sizeQuantityIndex = createSizeQuantityListWithChanges.findIndex((obj => obj.id === indexToChange))
    createSizeQuantityListWithChanges[sizeQuantityIndex].size = size.toUpperCase()
    
    setSizeQuantityList(createSizeQuantityListWithChanges)
  }

  /**This function is responsible convert to number and update quantity
   * @param {string}quantity The quantity to update
   * @param {string}indexToChange The index value to effect
   */
  const handleQuantityChange = (quantity: string, indexToChange: string) => {
    const createSizeQuantityListWithChanges = [...sizeQuantityList]
    const sizeQuantityIndex = createSizeQuantityListWithChanges.findIndex((obj => obj.id === indexToChange))
    createSizeQuantityListWithChanges[sizeQuantityIndex].quantity = roundNumberToSignificantDigits(parseFloat(quantity), 0)
    
    setSizeQuantityList(createSizeQuantityListWithChanges)
  }

  /**This function is responsible to remove row from entry
   * @param {string}indexToRemove The index value to remove row
   */
  const removeSizeQuantityRow = (indexToRemove: string) => {
    if (sizeQuantityList.length === 1) {
      return
    }
    const createSizeQuantityListWithChanges = [...sizeQuantityList]
    const sizeQuantityIndex = createSizeQuantityListWithChanges.findIndex((obj => obj.id === indexToRemove))
    createSizeQuantityListWithChanges.splice(sizeQuantityIndex, 1)

    setSizeQuantityList(createSizeQuantityListWithChanges)
  }

  /**This function is responsible to add row in entry */
  const addSizeQuantityRow = () => {
    setSizeQuantityList([...sizeQuantityList, { size: '', quantity: 0, id: nanoid(4) }])
  }

  /**This function is responsible to submit order quantity entry */
  const submitOrderQuantities = () => {
    const sizeQuantityListWithIdRemoved = sizeQuantityList.map(({ size, quantity }) => {
      return { size, quantity }
    })
    submitOrder(approvedConsumption, sizeQuantityListWithIdRemoved)
  }

  return (
    <React.Fragment>
      <Typography variant='subtitle1'> CONSUMPTION </Typography>
      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        size="small"
        onChange={handleApprovedConsumptionChange}
        value={approvedConsumption}
      />

      <Box sx={{
        paddingTop: 2
      }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>SIZE</Typography></TableCell>
                <TableCell><Typography variant='subtitle1'>QUANTITY</Typography></TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                sizeQuantityList.map((object) => {
                  return (
                    <TableRow key={object.id}>
                      <TableCell>
                        <TextField
                          type='string'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          size="small"
                          value={object.size}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleSizeChange(event.target.value, object.id)}
                        /> 
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="number"
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          size="small"
                          value={object.quantity}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleQuantityChange(event.target.value, object.id)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton aria-label="delete" size="large" onClick={() => removeSizeQuantityRow(object.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display="flex"
        justifyContent="left"
        alignItems="center" sx={{ paddingTop: 3 }}>
        <AddButton label='ADD ROW' onClick={addSizeQuantityRow}/>
      </Box>

      <Box display="flex"
        justifyContent="center"
        alignItems="center" sx={{ paddingTop: 4 }}>
        <FlatButton label='SUBMIT' onClick={submitOrderQuantities} disableControlsStatus={false}/>
      </Box>
    </React.Fragment>
  )
}

export default Orders