import React, {useState, useEffect} from 'react'
import { Paper, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography, Box, Stack } from '@mui/material'
import { makeStyles } from "@mui/styles"
import { PalletDataInfo } from '../types'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface PalletsDataForRackNumberProps {
  palletsForRackNumberResults: PalletDataInfo[]
}

const useStyles = makeStyles({
  customTableContainer: {
    overflowX: 'initial'
  }
})

function PalletsDataForRackNumber(props: PalletsDataForRackNumberProps) {
  const { palletsForRackNumberResults } = props

  const [piecesArrowStatus, setPiecesArrowStatus] = useState(false)
  const [palletRackResults, setPalletRackResults] = useState<PalletDataInfo[]>(palletsForRackNumberResults)

  const classes = useStyles()

  useEffect(()=>{
    sortDataByNumberOfPieces()
  }, [palletsForRackNumberResults])

  const convertDateTimeToLocalString = (date: string | null) => {
    if (date === null || date === undefined)
    {
      return null
    }
    return new Date(date as string)
      .toLocaleTimeString('en-GB',
        {timeZone: 'UTC', hour12: true, day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}
      ).replace(',', '')
  }

  const totalNumberOfPieces = (data: PalletDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.numberOfPieces
    }, 0)
  }


  const sortDataByNumberOfPieces = () => {
    if (piecesArrowStatus === true){
      setPiecesArrowStatus(false)
      setPalletRackResults(
        palletsForRackNumberResults.map((object)=>object)
          .sort((a, b)=> a.numberOfPieces - b.numberOfPieces)
      )
    }
    if (piecesArrowStatus === false){
      setPiecesArrowStatus(true)
      setPalletRackResults(
        palletsForRackNumberResults.map((object)=>object)
          .sort((a, b)=> b.numberOfPieces - a.numberOfPieces)
      )
    }
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    
    const jsonData = palletRackResults.map((object, index)=>{
      return {
        articleNumber: object.articleNumber, 
        noOfPieces: object.numberOfPieces, 
        dateOfManufacture: object.dateOfManufacture,
        warehouseEntryDateTime: convertDateTimeToLocalString(object.warehouseEntryDateTime),
        rackAssociationDateTime: convertDateTimeToLocalString(object.rackAssociationDateTime)
      }
    })
    
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'palletRackNumberData')
    XLSX.writeFile(workBook, 'palletDetailsBasedOnRackNumber.xlsx')
    
  }

  return (
    <React.Fragment>
      <Box>

        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            bgcolor: 'primary.card',
            paddingTop: 1
          }}
        >
          <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
        </Box>
        
        <Box
          component="span"
          m={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            bgcolor: 'primary.card'
          }}
        >

        
          <Typography variant='subtitle1'>
          FINISHED GOODS RACK DETAILS
          </Typography>

          <Stack direction="row" spacing={3}>
            <Typography variant='subtitle1' sx={{background: 'white'}}>
        TOTAL PIECES IN STOCK: {totalNumberOfPieces(palletRackResults)}
            </Typography>

            <Typography variant='subtitle1'sx={{background: 'white'}}>
        TOTAL PALLETS IN STOCK: {palletRackResults.length}
            </Typography>
          </Stack>
   
        </Box>
      
        <Typography variant='subtitle1' sx={{paddingLeft: 1}}>
          {palletRackResults.length > 0 ? palletRackResults[0].rackBarcode : ''}
        </Typography>
      </Box>

      

      <TableContainer component={Paper} classes={{ root: classes.customTableContainer }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>SERIAL NO.</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>ARTICLE NUMBER</Typography></TableCell>
             
              <TableCell onClick={() => sortDataByNumberOfPieces()}>
                <Typography variant='subtitle1'>
                NO. OF PIECES {piecesArrowStatus === true ? <ArrowUpwardIcon /> : <ArrowDownwardIcon /> }
                </Typography>
              </TableCell>

              <TableCell><Typography variant='subtitle1'>DATE OF MANUFACTURING</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>WH ENTRY DATE & TIME</Typography></TableCell>
              <TableCell><Typography variant='subtitle1'>RACK ENTRY DATE & TIME</Typography></TableCell>
            </TableRow>
          </TableHead>
            

          <TableBody>
            {
              palletRackResults.map((rackObject, index) =>{
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{rackObject.articleNumber}</TableCell>
                    <TableCell>{rackObject.numberOfPieces}</TableCell>
                    <TableCell>{rackObject.dateOfManufacture}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(rackObject.warehouseEntryDateTime)}</TableCell>
                    <TableCell>{convertDateTimeToLocalString(rackObject.rackAssociationDateTime)}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table> 
      </TableContainer>
    </React.Fragment>
  )
}

export default PalletsDataForRackNumber