import { useEffect, useState } from 'react'
import { DHUDataDetails } from '../types'
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"

interface DHUSingleDateChartProps {
  data: DHUDataDetails[]
}

type GroupedDefectsType = {
  batchNumber: string,
  ocNumber: string,
  totalPassedQuantity: number,
  totalReworkedQuantity: number,
  totalRejectedQuantity: number,
  defectsAndQuantities: { defect: string, quantity: number, DHU?: number }[]
}

type GroupedDefectsTypeWithoutOC = {
  batchNumber: string,
  totalPassedQuantity: number,
  totalReworkedQuantity: number,
  totalRejectedQuantity: number,
  defectsAndQuantities: { defect: string, quantity: number, DHU?: number }[]
}

type ChartData = ({ batchNumber: string, DHU?: string | number | undefined, label?: string | undefined }[])[]
type formattedData = {x: string, y: number, label: string}
type seriesData = {name: string, data: formattedData[]}

const DHUSingleDateChart = (props: DHUSingleDateChartProps) => {
  const { data } = props
  
  const [seriesChartData, setSeriesChartData] = useState<seriesData[]>([])
  const [chartCategories, setChartCategories] = useState<string[]>([])

  useEffect(() => {
    const flattenedData = flattenData(data)
    
    const groupedData = groupData(flattenedData)
    const groupDataByBatch = groupDataAndRemoveOcNumberFromGroup(groupedData)
    const aggregatedData = aggregateUniqueDefects(groupDataByBatch)
    const sortedData = sortDefectsBasedOnNumberOfDefects(aggregatedData)
    
    const batchList = [...new Set(sortedData.map(item => item.batchNumber))]
    
    
    const topThreeDefects = getTopNDefectsPerBatch(sortedData, 3)
    const topThreeDefectsWithDHU = calculateDHUPerDefect(topThreeDefects)
    const flattenedDefectAndQuantitiesData = flattenDefectAndQuantitiesList(topThreeDefectsWithDHU)
    const presentableData = prepareDataForPresentation(flattenedDefectAndQuantitiesData)
    const seriesData = chartSeriesData(presentableData, batchList)

    setChartCategories(batchList)
    setSeriesChartData(seriesData)
    
  }, [data])

  const chartSeriesData = (presentableData: ChartData, batchList: string[]) =>{
    const data : formattedData[] = []
    const topFirstDefect = { name: "Top-1 Defect", data }
    const topSecondDefect = { name: "Top-2 Defect", data }
    const topThirdDefect = { name: "Top-3 Defect", data }

    presentableData.map((arrayElement, index)=>{
      const topDefectlist =  batchList.map((batch)=>{
        const getDataObject = arrayElement.find(dataObject => dataObject.batchNumber === batch)
        if (getDataObject === null || getDataObject === undefined) {
          return {x: batch, y: 0, label: ''}
        }
        return {x: batch, y: getDataObject.DHU as number, label: getDataObject.label as string}
      })

      if (index === 0){
        topFirstDefect.data = topDefectlist
      }
      if (index === 1){
        topSecondDefect.data = topDefectlist
      }
      if (index === 2){
        topThirdDefect.data = topDefectlist
      }
      return null
    })
    return [topFirstDefect, topSecondDefect, topThirdDefect]
  }


  const flattenData = (dataToFlatten: DHUDataDetails[]) => {
    return dataToFlatten.map(({ batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, hourlyDetails }) => {
      return hourlyDetails.map(({ defectDetails }) => {
        if (defectDetails.length > 0){
          return defectDetails.map(({ defect, quantity }) => {
            return { batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defect, quantity }
          })
        }
        return { batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defect: '', quantity: 0 }
      }).flat()
    }).flat()
  }

  const groupData = (data: { batchNumber: string, ocNumber: string, totalPassedQuantity: number, totalReworkedQuantity: number, totalRejectedQuantity: number, defect: string, quantity: number }[]) => {
    const result = data.reduce((acc: GroupedDefectsType[], curr) => {
      const { batchNumber, ocNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defect, quantity } = curr
      const batchNumberKeyIndex = acc.findIndex((item: any) => item.batchNumber === batchNumber && item.ocNumber === ocNumber)
      if (batchNumberKeyIndex === -1) {
        acc.push({
          batchNumber,
          ocNumber,
          totalPassedQuantity,
          totalReworkedQuantity,
          totalRejectedQuantity,
          defectsAndQuantities: [
            {
              defect,
              quantity
            }
          ]
        })
        return acc
      }
      const { defectsAndQuantities } = acc[batchNumberKeyIndex]
      acc[batchNumberKeyIndex] = Object.assign({
        ...acc[batchNumberKeyIndex],
        defectsAndQuantities: [...defectsAndQuantities, { defect, quantity }]
      })
      return acc
    }, [])
    return result
  }



  const groupDataAndRemoveOcNumberFromGroup = (data: GroupedDefectsType[]) => {
    const result = data.reduce((acc: GroupedDefectsTypeWithoutOC[], curr) => {
      const { batchNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defectsAndQuantities } = curr
      const batchNumberKeyIndex = acc.findIndex((item: any) => item.batchNumber === batchNumber)
      if (batchNumberKeyIndex === -1) {
        acc.push({
          batchNumber,
          totalPassedQuantity,
          totalReworkedQuantity,
          totalRejectedQuantity,
          defectsAndQuantities
        })
        return acc
      }
      
      acc[batchNumberKeyIndex].totalPassedQuantity += totalPassedQuantity
      acc[batchNumberKeyIndex].totalReworkedQuantity += totalReworkedQuantity
      acc[batchNumberKeyIndex].totalRejectedQuantity += totalRejectedQuantity
      

      acc[batchNumberKeyIndex] = Object.assign({
        ...acc[batchNumberKeyIndex],
        defectsAndQuantities: [...acc[batchNumberKeyIndex].defectsAndQuantities, ...defectsAndQuantities]
      })
      return acc
    }, [])
    return result
  }


  const aggregateUniqueDefects = (data: GroupedDefectsTypeWithoutOC[]) => {
    return data.map(({ batchNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defectsAndQuantities }) => {
      return {
        batchNumber,
        totalPassedQuantity,
        totalReworkedQuantity,
        totalRejectedQuantity,
        defectsAndQuantities: defectsAndQuantities.reduce((acc: { defect: string, quantity: number }[], curr) => {
          const { defect, quantity } = curr
          const defectIndex = acc.findIndex((item: { defect: string, quantity: number }) => item.defect === defect)
          if (defectIndex === -1) {
            acc.push({ defect, quantity })
            return acc
          }
          acc[defectIndex] = Object.assign({
            defect,
            quantity: acc[defectIndex].quantity + quantity
          })
          return acc
        }, [])
      }
    })
  }

  const sortDefectsBasedOnNumberOfDefects = (aggregatedData: GroupedDefectsTypeWithoutOC[]) => {
    return aggregatedData.map(({ batchNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defectsAndQuantities }) => {
      return {
        batchNumber,
        totalPassedQuantity,
        totalReworkedQuantity,
        totalRejectedQuantity,
        defectsAndQuantities: defectsAndQuantities.sort((a, b) => {
          if (a.quantity > b.quantity) {
            return -1
          }
          if (a.quantity < b.quantity) {
            return 1
          }
          return 0
        })
      }
    })
  }

  const getTopNDefectsPerBatch = (data: GroupedDefectsTypeWithoutOC[], numberOfDefects: number) => {
    return data.map(({ batchNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity, defectsAndQuantities }) => {
      return {
        batchNumber,
        totalPassedQuantity,
        totalReworkedQuantity,
        totalRejectedQuantity,
        defectsAndQuantities: [...defectsAndQuantities.slice(0, numberOfDefects)]
      }
    })
  }

  const calculateDHUPerDefect = (data: GroupedDefectsTypeWithoutOC[]): GroupedDefectsTypeWithoutOC[] => {
    const result = data.map(({ defectsAndQuantities, batchNumber, totalPassedQuantity, totalReworkedQuantity, totalRejectedQuantity }) => {
     
      const totalInspected = totalPassedQuantity + totalReworkedQuantity + totalRejectedQuantity
      return Object.assign(
        {},
        {
          batchNumber,
          totalPassedQuantity,
          totalReworkedQuantity,
          totalRejectedQuantity,
          defectsAndQuantities: defectsAndQuantities.map(({ defect, quantity }) => {
            return {
              defect,
              quantity,
              DHU: Math.round(((quantity / totalInspected) * 100) * 1e2) / 1e2
            }
          })
        }
      )
    })
    return result
  }

  const flattenDefectAndQuantitiesList = (data: GroupedDefectsTypeWithoutOC[]) => {
    return data.reduce((acc: Map<string, string | number>[], curr) => {
      const { batchNumber, defectsAndQuantities } = curr
      const defectsAndQuantitiesObject = defectsAndQuantities.reduce((acc: { [x: string]: string | number }, curr) => {
        const { defect, DHU } = curr
        return Object.assign({}, acc, { [defect]: DHU })
      }, {})
      const map = new Map(Object.entries(defectsAndQuantitiesObject))
      map.set('batchNumber', batchNumber)
      acc.push(map)
      return acc
    }, [])
  }

  const prepareDataForPresentation = (data: Map<string, string | number>[]) => {
    const arrayOfBatchNumbers = data.map((map, index) => {
      const batchNumber = map.get('batchNumber') as string
      return { batchNumber }
    })
    const preparedData: { batchNumber: string, DHU?: string | number | undefined, label?: string }[][] = []
    for (let i = 0; i < 3; i++) {
      preparedData.push([...arrayOfBatchNumbers])
    }

    for (let i = 0; i < preparedData.length; i++) {
      for (let j = 0; j < preparedData[i].length; j++) {
        const { batchNumber } = preparedData[i][j]
        const batchDefectData = data.find((item) => {
          return item.get('batchNumber') === batchNumber
        })
        if (batchDefectData === null || batchDefectData === undefined) {
          break
        }
        const nthBatchDefectKey = Array.from(batchDefectData.keys())[i]
        if (nthBatchDefectKey === 'batchNumber' || nthBatchDefectKey === undefined || nthBatchDefectKey === null) {
          continue
        }
        const nthBatchDefectValue = batchDefectData.get(nthBatchDefectKey)
        preparedData[i][j] = { batchNumber, DHU: nthBatchDefectValue, label: `${nthBatchDefectKey} - ${nthBatchDefectValue}%` }
      }
    }
    return preparedData.map((subArray) => {
      return subArray.filter(({ batchNumber, DHU, label }) => {
        return batchNumber !== undefined && DHU !== undefined && label !== undefined
      })
    })
  }


  const getOptions = ()=>{
    const options: ApexOptions = {
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          borderRadius: 5
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      grid: {
        padding: {
          left: 15
        }
      },
      title: {
        text: 'DHU - Top 3 Defects',
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        categories: chartCategories,
        tickPlacement: 'on'
      },
      yaxis: {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB',
          }
        },
        title: {
          text: 'Defect Rate',
          style: {
            color: '#008FFB',
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        custom: function({series, seriesIndex, dataPointIndex, w}) {
          const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]
          return `<header> <h4>Batch- ${data.x}</h4> 
          <h5>${data.label}</h5>
          </header>`
        }
      },
      legend: {
        horizontalAlign: 'center',
        offsetX: 40
      }
    }
    return options
  }

  return (
    <Chart options={getOptions()} series={seriesChartData} type="bar" width={'100%'} height={'350'} />
  )
}

export default DHUSingleDateChart