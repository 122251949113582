import React, { useState } from 'react'
import { Box, Tabs, Tab } from '@mui/material'
import { CuttingJobs, Dhu,  Mir,  } from './components'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { TabPanel } from './components'


interface ProductLabelProps {
  componentHeader: (headerName: string) => void
}


const DeviceHandling = (props: ProductLabelProps) => {

  const [tabState, setTabState] = useState(0)

  useAuthenticationToken()
  props.componentHeader('DEVICE HANDLING')


  return (
    <React.Fragment>
      <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
        setTabState(newValue)
      }} textColor='secondary' TabIndicatorProps={{
        style: {
          backgroundColor: 'alpha.light'
        }
      }}>
        <Tab sx={{ color: 'alpha.light' }} label="MIR" />
        <Tab sx={{ color: 'alpha.light' }} label="DHU" />
        <Tab sx={{ color: 'alpha.light' }} label="CUTTING JOBS" />
      </Tabs>

      <Box sx={{
        paddingTop: 2
      }}>
        <TabPanel value={tabState} index={0}>
          <Box sx={{ paddingTop: 2, marginLeft: 1 }}>
            <Mir />
          </Box>
        </TabPanel>

        <TabPanel value={tabState} index={1}>

          <Box sx={{ paddingTop: 2, marginLeft: 1 }}>
            <Dhu />
          </Box>

        </TabPanel>
        {/* <TabPanel value={tabState} index={2}>

          <Box sx={{ paddingTop: 2, marginLeft: 1 }}>
            <LoadingDetails data={[]} />
          </Box>

        </TabPanel> */}

        <TabPanel value={tabState} index={2}>

          <Box sx={{ paddingTop: 2, marginLeft: 1 }}>
            <CuttingJobs />
          </Box>

        </TabPanel>
      </Box>

    </React.Fragment >
  )
}

export default DeviceHandling
