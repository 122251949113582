import React, { useCallback, useState } from 'react'
import { Box, Grid, List, ListItem, Paper, LinearProgress } from '@mui/material'

import { SearchBar } from '../UI/Components'
import { getCuttingItemCodeResultsBasedOnOCNumber, getCuttingDataBasedOnItemCode, getConsolidatedCuttingDataBasedOnOCNumber } from './api/apiFunctions'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { CuttingResultsListItem, CuttingDataInfo, ConsolidatedCuttingDataInfo } from './types'
import { CuttingCard, CuttingSummary, CuttingDataForItemCode, CuttingDropdownMenu } from './components'
import { AxiosError } from 'axios'
import SnackBarMessage from '../UI/Components/SnackBarMessage'
import StringValues from '../Providers/StringValues'
import { sortAlphaNumericArrayList } from '../utils'

enum locationInitalValue {
  locationValue = 'SELECT LOCATION'
}
interface CuttingDashboardProps {
  componentHeader : (headerName : string) => void
}

const CuttingDashboard = (props: CuttingDashboardProps) => {
  const [cuttingSummaryStatus, setCuttingSummaryStatus] = useState(true)

  const [isLoading, setIsLoading] = useState(false)
  const [isFetchingItemCodeList, setIsFetchingItemCodeList] = useState(false)
  const [isFetchingItemCodeDetails, setIsFetchingItemCodeDetails] = useState(false)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)

  const [cuttingSearchResults, setCuttingSearchResults] = useState<CuttingResultsListItem[]>([])
  const [cuttingItemCodeResults, setCuttingItemCodeResults] = useState<CuttingDataInfo>()
  const [cuttingSummaryResults, setCuttingSummaryResults] = useState<ConsolidatedCuttingDataInfo[]>([])
  const [actualCuttingItemCodeResults, setActualCuttingItemCodeResults] = useState<CuttingResultsListItem[]>([])

  const [itemCode, setItemCode] = useState<string>('')
  const [fitType, setFitType] = useState<string>('')
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  
  useAuthenticationToken()
  props.componentHeader('Cutting')

  /**API call to fetch list of item codes associated with oc number
   * also fetch consolidated cutting data associated with oc number
   */
  const fetchCuttingItemCodeList = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setIsFetchingItemCodeList(true)
    setSelectedLocation('')
    
    try {
      const cuttingItemCodeResult = await getCuttingItemCodeResultsBasedOnOCNumber(searchTerm)
      const cuttingSummaryResult = await getConsolidatedCuttingDataBasedOnOCNumber(searchTerm)

      const itemListIncludingSelected = cuttingItemCodeResult.map((item, index) => {
        return ({...item, isSelected: false})
      })

      setCuttingSummaryStatus(true)
      setCuttingSearchResults(itemListIncludingSelected)
      setActualCuttingItemCodeResults(itemListIncludingSelected)
      setCuttingSummaryResults(cuttingSummaryResult)

      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }

    setCuttingSummaryStatus(true)
    setIsFetchingItemCodeList(false)
    setIsLoading(false)
   
  }, [searchTerm])

  /**This function is responsible to fetch cutting details in combination of 
   * location, oc number, itemCode and fitType 
   * @param {string} ocNumber The oc number
   * @param {string} itemCode The item code associated with oc number
   * @param {string} fitType The fit type associated with oc number and item code
   */
  const fetchCuttingDataBasedOnItemCode = async (ocNumber: string, itemCode: string, fitType: string) => {
    if (isLoading) {
      return
    }
    setSnackBarOpen(false)
    setErrorMessage('')

    if (selectedLocation === '' || selectedLocation === locationInitalValue.locationValue){
      setSnackBarOpen(true)
      setErrorMessage(StringValues.locationMessage) 
      return       
    }

    setIsLoading(true)
    setIsFetchingItemCodeDetails(true)
    setItemCode(itemCode)
    setFitType(fitType)
    setCuttingSummaryStatus(false)
    setIsError(false)

    try {
      const locationCode = getLocationCodeBasedOnLocationName(selectedLocation)
      const cuttingItemCodeData = await getCuttingDataBasedOnItemCode(ocNumber, itemCode, locationCode, fitType)
      setCuttingItemCodeResults(cuttingItemCodeData)
      
      setCuttingSummaryStatus(false)
      setIsLoading(false)
      setIsFetchingItemCodeDetails(false)
      
    } catch (err) {

      if (err instanceof AxiosError || err instanceof Error) {
        setCuttingItemCodeResults(undefined)
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
      setIsLoading(false)
      setIsFetchingItemCodeDetails(false)
    }
  }

  /**Handle search bar changes */
  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }

  /** Function responsible to return location based on location name 
   * @param {string} locationName The location name
   * @return {string} The location code
   */
  const getLocationCodeBasedOnLocationName = (locationName: string)=>{
    const locationObject = cuttingSearchResults.find(locationObject => locationObject.locationName === locationName)
    if (locationObject === null || locationObject === undefined) {
      return ''
    }
    return locationObject.location
  }

  /**Handle search bar finder button click */
  const handleSearchBarButtonClick = () => {
    fetchCuttingItemCodeList()
  }

  /**This function is responsible to handle selected item card
   * @param {string} itemCode The item code associated with entered oc number
   * @param {string} location The selected location
   * @param {string} fitType The fit type associated with oc number and item code
   * @param {number} indexToChange The index value to update card status
   */
  const handleCuttingCardClick = (itemCode: string, location: string, fitType: string, indexToChange: number) => {
    const updatedCardItemResults = actualCuttingItemCodeResults.map((item, index) => {
      const {isSelected} = item
      if (indexToChange === index){
        if (isSelected === false){
          item.isSelected = true
          fetchCuttingDataBasedOnItemCode(searchTerm, itemCode, fitType)
        } else {
          item.isSelected = false
          setCuttingSummaryStatus(true)
        }
        return item
      }
      item.isSelected = false
      return item
    })

    setActualCuttingItemCodeResults(updatedCardItemResults)
  }

  /**This function is responsible to handle dropDown location menu change 
   * and filter card list based on location
   * @param {string} location The location
   */
  const handleDropdownMenuChange = (location: string) => {
    setSelectedLocation(location)
    setCuttingSummaryStatus(true)
    setCuttingItemCodeResults(undefined)
    const resultWithSelectedFalse = cuttingSearchResults.map((item, index) => {
      return ({...item, isSelected: false})
    })

    const resultFilteredByLocation = cuttingSearchResults.reduce((acc: CuttingResultsListItem[], obj) =>{
      if (obj.locationName === location) {
        obj.isSelected = false
        acc.push(obj)
      }
      return acc
    }, [])

    if (location === locationInitalValue.locationValue){
      setSelectedLocation('')
      setActualCuttingItemCodeResults(resultWithSelectedFalse)
    } else {setActualCuttingItemCodeResults(resultFilteredByLocation)}
    
  }

  /**This function is responsible to prepare list location for dropDown menu
   * @return {string[]} The list of location
   */
  const locationList = () => {
    let locations: string[] = []
   
    if (cuttingSearchResults !== null && cuttingSearchResults !== undefined) {
      locations =  cuttingSearchResults.map(item => item.locationName)
        .filter((value, index, self) => self.indexOf(value) === index)
    }
    locations = sortAlphaNumericArrayList(locations)
    locations.unshift(locationInitalValue.locationValue)
    return locations
  }

  /**Handle snackBar open/Close
   * @param {boolean} value The status of snackBar to open/Close
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  return (
    <React.Fragment>
      <SnackBarMessage message = {errorMessage} successStatus={false} open = {snackBarOpen} onClose={handleSnackBarClose}/>
      <SearchBar value={searchTerm} onChange={handleSearchBarChange} 
        onButtonClick={handleSearchBarButtonClick} error={isError} errorMessage={errorMessage} 
        inputLabel = {StringValues.ocNumberLabel} width = {'20%'} marginLeft = {2}/>
      <Grid container sx={{
        height: '100vh'
      }}>
        <Grid item xs={3}
          sx={{
            paddingTop: 2,
            paddingBottom: 2
          }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'surface.dark', display: 'flex', justifyContent: 'center', alignItems: 'center'
          }}>
            {
              isFetchingItemCodeList ? (
                <LinearProgress sx={{ width: '80%', minWidth: '100px' }} />
              ) : (
                <Paper elevation={8} sx={{
                  height: '100%'
                }}>
                  <List sx={{
                    bgcolor: 'background.paper'
                  }}>
                    
                    {
                      actualCuttingItemCodeResults.length > 0
                        ? <Box display="flex" 
                          justifyContent="flex-end"
                          alignItems="flex-end"
                          sx={{
                            paddingRight: 2
                          }}><CuttingDropdownMenu onChange={handleDropdownMenuChange} menuValues = {locationList()}/></Box>
                        : null
                    }
                    
                    {
                      actualCuttingItemCodeResults.map((item, index) => {
                        return (
                          <ListItem key={index}>
                            <CuttingCard key={index} itemCode={item.itemCode} location={item.locationName}
                              fitType={item.fitType} itemDesc={item.itemDescription} onClick={handleCuttingCardClick} index={index} isSelected={item.isSelected}/>
                          </ListItem>
                        )
                      })
                    }
                  </List>
                </Paper>
              )
            }
          </Box>
        </Grid>
        <Grid item xs={9} sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
          {
            (isFetchingItemCodeList || isFetchingItemCodeDetails) ? (
              <LinearProgress sx={{ width: '50%' }} />
            ) : (
              <Box sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'primary.pane',
                backgroundColor: 'surface.dark'
              }}>
                <React.Fragment>
                  <Box sx={{
                    paddingLeft: 2,
                    paddingRight: 2
                  }}>
                    {
                      cuttingSummaryStatus === true
                        ? (cuttingSummaryResults.length > 0
                          ? <CuttingSummary cuttingSummaryResults={cuttingSummaryResults}/> 
                          : null)
                        : (cuttingItemCodeResults !== null && cuttingItemCodeResults !== undefined
                          ? <CuttingDataForItemCode cuttingItemCodeResults={cuttingItemCodeResults as CuttingDataInfo} 
                            itemCode = {itemCode as string} fitType = {fitType as string}/>
                          : null)
                    }
                  </Box>
                </React.Fragment>
                 
              </Box>
            )
          }
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default CuttingDashboard