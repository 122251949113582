import React from 'react'
import { Box, Paper, Card, CardContent, Table, TableCell, TableContainer, TableHead, TableBody, TableRow, Typography } from '@mui/material'
import { ConsolidatedCuttingDataInfo } from '../types'
import { convertNumberToLocaleString } from '../../utils'

interface CuttingSummaryProps {
  cuttingSummaryResults: ConsolidatedCuttingDataInfo[]
}

function CuttingSummary(props: CuttingSummaryProps) {
  const { cuttingSummaryResults } = props

  const sizeList = cuttingSummaryResults.map(object => object.size)
    .filter((value, index, self) => self.indexOf(value) === index)

  /**Function is responsible to calculate consolidated cut quantity */
  const calculateTotalCut = () => {
    return cuttingSummaryResults.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)
  }

  /**Function responsible to calculate row total
   * @param {ConsolidatedCuttingDataInfo[]} data The list consolidated cutting data based on oc number
   */
  const calculateRowTotal = (data: ConsolidatedCuttingDataInfo[]) => {
    return data.reduce((acc, curr) => {
      return acc + curr.quantity
    }, 0)
  }


  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const groupedSummaryDataByDate = groupBy(cuttingSummaryResults, i => i.date)
  const groupedSummaryDataBySize =  groupBy(cuttingSummaryResults, i => i.size)

  return (
    <React.Fragment>
      <Box sx={{
        paddingTop: 2,
        paddingBottom: 2,
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        width: '25%'
      }}>
        <Card sx={{
          flex: 1
        }}>
          <CardContent>
            
            <Typography variant='h6' color={'alpha.light'}>
              {convertNumberToLocaleString(calculateTotalCut())}
            </Typography>
            
          </CardContent>
          <CardContent>
            <Typography variant='body1'>
                              TOTAL CUT
            </Typography>
          </CardContent>
        </Card>
      </Box>
                      
      <Box sx={{
        bgcolor: 'primary.card'
      }}>
        <Typography variant='h6' paddingLeft={2} paddingTop={1}>
          CUTTING OVERVIEW
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: 'alpha.lightest'
              }}
            >
              <TableCell><Typography variant='subtitle1'>DATE</Typography></TableCell>
              {
                sizeList.map((size) => {
                  return (
                    <TableCell><Typography variant='subtitle1'>{size}</Typography></TableCell>
                  )
                })
              }
              <TableCell><Typography variant='subtitle1'>TOTAL</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              /** size wise quantity and row wise total */
              Object.entries(groupedSummaryDataByDate).map(([key, value]) =>{
                
                return (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    {
                      sizeList.map((size) => {
                        const filteredSizeObject = value.filter(obj => {
                          return obj.size === size
                        })
                        if (filteredSizeObject.length > 0){
                          return (<TableCell>{filteredSizeObject[0].quantity}</TableCell>)  
                        }
                        return (<TableCell>{0}</TableCell>)
                      })
                    }
                    
                    <TableCell>{convertNumberToLocaleString(calculateRowTotal(value))}</TableCell>
                  </TableRow>
                )
                
              })
            
             

            }
            <TableRow key='totalSizeWiseQuantity'>
              <TableCell/>
              {
              /** size wise total quantity */
                Object.entries(groupedSummaryDataBySize).map(([key, value]) =>{
                  return (
                    <TableCell><Typography variant='subtitle1'>
                      {
                        convertNumberToLocaleString(
                          value.reduce((acc, curr) => {
                            return acc + curr.quantity
                          }, 0)
                        )
                      }
                    </Typography></TableCell>
                  )
                })
              }     
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default CuttingSummary