import React, { useState, useEffect } from 'react'
import { Box, Tab } from '@mui/material'
import { CuttingDetailsData } from '../types'
import {CuttingTable} from '../components'
import {TabPanel, TabContext, TabList} from '@mui/lab'

interface CuttingDetailsProps {
  trackerCuttingDetailsData: CuttingDetailsData[]
}

enum tabDefaultValue {
  default = 'SUMMARY'
}

function CuttingDetails(props: CuttingDetailsProps) {
  const {trackerCuttingDetailsData} = props
  
  const [locationList, setLocationList] = useState<string[]>([])
  const [cuttingDetailsList, setCuttingDetailsList] = useState<CuttingDetailsData[]>([])

  const [tabValue, setTabValue] = React.useState<string>(tabDefaultValue.default)

  /** Function responsible to fetch location list on change of cutting details data */
  useEffect(() => {
    const locationList = [...new Set(trackerCuttingDetailsData.map(object => object.locationName))]
    setLocationList(locationList)
    setCuttingDetailsList(trackerCuttingDetailsData)
  }, [trackerCuttingDetailsData])


  /** Function responsible to filter data based on tab value change */
  useEffect(() => {

    if (tabValue === tabDefaultValue.default){
      setCuttingDetailsList(trackerCuttingDetailsData)
    } else {
      filterCuttingDataLocationWise(tabValue, trackerCuttingDetailsData)
    }

  }, [tabValue])

  /** Function responsible to filter cutting details data based on location */
  const filterCuttingDataLocationWise = (Locationvalue: string, data: CuttingDetailsData[])=>{

    const FilteredData = data.map((object)=>{
      const {locationName} = object
      if (locationName === Locationvalue){
        return object
      }
      return null
    }).filter(obj => obj !== null)
    setCuttingDetailsList(FilteredData as CuttingDetailsData[])
  }

  /** Function responsible to handle on tab change  */
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue)
  }



  return (
    
    <React.Fragment>
      <Box sx={{
        width: '100%',
        bgcolor: 'primary.pane',
        backgroundColor: 'white',
        paddingTop: 2
      }}> 
 
        <TabContext value={tabValue}> 
          <TabList onChange={handleTabChange}  textColor='secondary' TabIndicatorProps={{style: {
            backgroundColor: 'alpha.light'
          }}}>
            <Tab sx={{
              color: 'alpha.light'
            }} label= {tabDefaultValue.default} value={tabDefaultValue.default} />
            {
              locationList.map((location)=>{
                return (
                  <Tab sx={{
                    color: 'alpha.light'
                  }} label={location}  value={location}/>
                )
              }) 
            }
          </TabList>
          <TabPanel value={tabValue}>
            <CuttingTable trackerCuttingDetailsData={cuttingDetailsList}/>
          </TabPanel>
        </TabContext>

        
      </Box>
    </React.Fragment>
  )
}

export default CuttingDetails