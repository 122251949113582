import React from 'react'
import { Snackbar, Alert, Typography } from '@mui/material'

interface SnackBarMessageProps {
  message: string
  successStatus: boolean
  open: boolean
  onClose: (value: boolean) => void
}

const SnackBarMessage = (props: SnackBarMessageProps) => {
  const {message, successStatus, open, onClose} = props

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      onClose(false)
    }

    if (reason === 'escapeKeyDown') {
      onClose(false)
    }
    onClose(false)
  }

  return (
    <React.Fragment>
      <Snackbar  
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open = {open}
        onClose={handleClose}
        autoHideDuration={4000}> 
        <Alert severity={successStatus === true ? "success" : "error"} sx={{ width: '100%' }}>
          <Typography variant="h6">
            {message}
          </Typography>
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default SnackBarMessage