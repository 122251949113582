import { useState, useEffect } from "react"
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  LinearProgress,
} from "@mui/material"
import { FlatButton } from "../../UI/Components"
import { BundleTrackvalue } from '../types'
import * as XLSX from 'xlsx'
import ProductLabelDropdownMenu from './ProductLabelDropdownMenu' // Import the new dropdown component

interface BundleTrackDataProps {
  data: BundleTrackvalue[]
}

const BundleTrackData = (props: BundleTrackDataProps) => {
  const { data } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedBatch, setSelectedBatch] = useState<string>('All Batches')
  const [filteredData, setFilteredData] = useState<BundleTrackvalue[]>(data)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      if (selectedBatch === 'All Batches') {
        setFilteredData(data)
      } else {
        setFilteredData(data.filter(item => item.batchNumber === selectedBatch))
      }
      setLoading(false)
    }, 1000)
  }, [selectedBatch, data])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleBatchDropdownMenuChange = (value: string) => {
    setSelectedBatch(value)
  }

  const batchesDropdownList = () => {
    const batchNumbers = Array.from(new Set(data.map(item => item.batchNumber)))
    batchNumbers.sort() // Sort the batch numbers in ascending order
    return ['All Batches', ...batchNumbers]
  }

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'BundleTrackData')
    XLSX.writeFile(workbook, 'BundleTrackData.xlsx')
  }

  const delayedGarmentQuantity = filteredData.reduce((total, item) => total + item.quantity, 0)
  const displayedData = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  return (
    <TableContainer component={Paper}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ bgcolor: 'primary.card' }}
      >
        <Typography variant="h5" padding={2}>Bundle Track</Typography>

        <Typography variant="h5" style={{ color: 'red', fontWeight: 'bold', padding: '5px' }}>Delayed Garments : {filteredData.length}</Typography>

        <ProductLabelDropdownMenu
          onChange={handleBatchDropdownMenuChange}
          menuValues={batchesDropdownList()}
          buttonText={selectedBatch}
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <FlatButton label="DOWNLOAD" onClick={downloadExcel} disableControlsStatus={false} />
      </Box>

      {loading && <LinearProgress />} 

      <Table sx={{ overflowX: 'auto', maxWidth: '100%', whiteSpace: 'nowrap', height: 450, overflowY: 'auto' }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'alpha.lightest' }}>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Sl #</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Unit</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Line</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Tr BDL</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Barcode</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Load Date</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Load Hours</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>OC #</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Color #</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>GMT SI #</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Size</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>PO #</TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Country</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {displayedData.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
              <TableCell align="center">{row.location}</TableCell>
              <TableCell align="center">{row.batchNumber}</TableCell>
              <TableCell align="center">{row.transactionBundleNumber}</TableCell>
              <TableCell align="center">{row.barcode}</TableCell>
              <TableCell align="center">{row.loadingDate}</TableCell>
              <TableCell align="center">{row.passedHours}</TableCell>
              <TableCell align="center">{row.ocNumber}</TableCell>
              <TableCell align="center">{row.colour}</TableCell>
              <TableCell align="center">{row.garmentSerialNumber}</TableCell>
              <TableCell align="center">{row.size}</TableCell>
              <TableCell align="center">{row.poNumber}</TableCell>
              <TableCell align="center">{row.country}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BundleTrackData
