import { useCallback, useEffect, useState } from 'react'
import { SewingEfficiencyDetails, HourlySewingData } from '../types'
import Chart from 'react-apexcharts'
import { ApexOptions } from "apexcharts"
import {roundNumberToSignificantDigits} from '../../utils'

interface SewingSingleDateChartProps {
  data: SewingEfficiencyDetails[]
}

type chartSeries = {
  name: string,
  type: string,
  data: number[]
}

const SewingSingleDateChart = (props: SewingSingleDateChartProps) => {
  const { data } = props
  const [chartCategories, setChartCategories] = useState<string[]>([])
  const [chartSeriesData, setChartSeriesData] = useState<chartSeries[]>([])

  useEffect(() => {
    const sortedData = sortData(data)
    const batchProductList = [...new Set(sortedData.map(item => `${item.batchNumber}-${item.productCode}`))]
    
    const seriesData = formatDataForPresentation(batchProductList, sortedData)
    setChartCategories(batchProductList)
    setChartSeriesData(seriesData)
    
  }, [data])

  const sortData = (data: SewingEfficiencyDetails[]) => {
    return data.sort(function(a, b) { return a.batchUnique - b.batchUnique })
  }

  const calculateSewingEfficiency = (hourlyData: HourlySewingData[]) => {
    let totalSamProduced = 0
    let totalMachineMinutes = 0

    totalSamProduced += hourlyData.reduce((acc, curr) => {
      const { sewingSamProduced } = curr
      return acc + sewingSamProduced
    }, 0)

    totalMachineMinutes += hourlyData.reduce((acc, curr) => {
      const { sewingMachineMinutes } = curr
      return acc + sewingMachineMinutes
    }, 0)
    
   

    if (totalSamProduced > 0){
      return roundNumberToSignificantDigits(((totalSamProduced / totalMachineMinutes) * 100), 2)
    }
    return 0
  }


  const formatDataForPresentation = useCallback((batchProductList: string[], data: SewingEfficiencyDetails[]) => {
    const seriesDataList : chartSeries[] = []
    const productionData : chartSeries = {name: 'Production', type: 'column', data: []}
    const targetData : chartSeries = {name: 'Target', type: 'column', data: []}
    const efficiencyData : chartSeries = {name: 'Efficiency', type: 'line', data: []}

    batchProductList.map((batchProduct) =>{
      const getDataObject = data.find(dataObject => `${dataObject.batchNumber}-${dataObject.productCode}` === batchProduct)
      if (getDataObject === null || getDataObject === undefined) {
        productionData.data.push(0)
        targetData.data.push(0)
        efficiencyData.data.push(0)
      } else {

        const {hourlyDetails} = getDataObject
        const getefficiency = calculateSewingEfficiency(hourlyDetails)

        productionData.data.push(roundNumberToSignificantDigits(getDataObject.totalOutputQuantity, 0))
        targetData.data.push(roundNumberToSignificantDigits(getDataObject.batchTargetQuantity, 0))
        efficiencyData.data.push(getefficiency)
      }
      return null
    })
    seriesDataList.push(productionData)
    seriesDataList.push(targetData)
    seriesDataList.push(efficiencyData)
    return seriesDataList
  }, [])


  const getOptions = ()=>{
    const options: ApexOptions = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [2]  
      },
      stroke: {
        width: [1, 1, 4]
      },
      title: {
        text: 'Production & Sewing Efficiency - Single Date  ',
        align: 'left',
        offsetX: 110
      },
      xaxis: {
        categories: chartCategories,
        labels: {
          show: true,
          rotate: -20,
          rotateAlways: true
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            formatter(val, opts?) {
              return val.toFixed(0)
            },
            style: {
              colors: '#008FFB',
            }
          },
          title: {
            text: "Production",
            style: {
              color: '#008FFB',
            }
          },
          tooltip: {
            enabled: true
          }
        },
        
        {
          seriesName: 'Production',
          opposite: true,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: '#00E396'
          },
          labels: {
            show: false,
            formatter(val, opts?) {
              return val.toFixed(0)
            }
          }
        },
        {
          seriesName: 'Efficiency',
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FEB019'
          },
          labels: {
            style: {
              colors: '#FEB019',
            },
          },
          title: {
            text: "Efficiency in Percentage",
            style: {
              color: '#FEB019',
            }
          }
        },
      ],
      legend: {
        horizontalAlign: 'center',
        offsetX: 40
      }
    }
    return options
  }

  return (
    <Chart options={getOptions()} series={chartSeriesData} type="line" width={'100%'} height={'350'} />
  )
}

export default SewingSingleDateChart