import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { convertNumberToLocaleString, roundNumberToSignificantDigits } from '../../utils'
import { SewingEfficiencyDetails, HourlySewingData } from '../types'
import { FlatButton } from '../../UI/Components'
import * as XLSX from 'xlsx'

interface SewingDateRangeTableProps {
  data: SewingEfficiencyDetails[]
}

type TableData = Record<string, SewingEfficiencyDetails[]>

const SewingDateRangeTable = (props: SewingDateRangeTableProps) => {
  const { data } = props

  const [tableData, setTableData] = useState<TableData>({})
  const [batchData, setBatchData] = useState<TableData>({})

  useEffect(() => {
    const groupedData = groupBy(data, (i) => i.date)
    const aggregatedData = aggregateData(groupedData)
    const reducedData = reduceHourlyDetailsInAggregatedData(aggregatedData)

    const groupedDataByBatch = groupBy(data, (i) => i.batchNumber)
    setTableData(reducedData)
    setBatchData(groupedDataByBatch)
  }, [data])

  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item)
      return groups
    }, {} as Record<K, T[]>)

  const aggregateData = (data: Record<string, SewingEfficiencyDetails[]>) => {
    const newObject: Record<string, SewingEfficiencyDetails[]> = {}
    for (const key of Object.keys(data)) {
      const value = data[key]
      const aggregatedValue = value.reduce((acc: SewingEfficiencyDetails[], curr) => {
        const { batchNumber, hourlyDetails } = curr
        const indexWithBatchNumber = acc.findIndex((i) => i.batchNumber === batchNumber)
        if (indexWithBatchNumber === -1) {
          acc.push(curr)
          return acc
        }
        acc[indexWithBatchNumber] = Object.assign(
          {},
          { ...acc[indexWithBatchNumber] },
          { hourlyDetails: acc[indexWithBatchNumber].hourlyDetails.concat(hourlyDetails) }
        )
        return acc
      }, [])
      newObject[key] = aggregatedValue
    }
    return newObject
  }

  const reduceHourlyDetailsInAggregatedData = (data: Record<string, SewingEfficiencyDetails[]>) => {
    const result = Object.entries(data).map(([key, value]) => {
      const updatedValue = value.map((item) => {
        const { hourlyDetails } = item
        const reducedHourlyDetails = hourlyDetails.reduce((acc: (HourlySewingData & { count: number })[], curr) => {
          const doesTimeIndexExist = acc.findIndex(({ startTime, endTime }) => {
            return curr.startTime === startTime && curr.endTime === endTime
          })
          if (doesTimeIndexExist === -1) {
            acc.push(Object.assign({}, curr, { count: 1 }))
            return acc
          }
          const { outputQuantity, hourEfficiency } = curr
          acc[doesTimeIndexExist].outputQuantity += outputQuantity
          acc[doesTimeIndexExist].hourEfficiency += hourEfficiency
          acc[doesTimeIndexExist].count += 1
          return acc
        }, [])
        return Object.assign({}, item, { hourlyDetails: reducedHourlyDetails })
      })
      return [key, updatedValue]
    })
    return Object.fromEntries(result)
  }

  const calculateTotalOutputQuantityForDate = (data: SewingEfficiencyDetails[]) => {
    return data.reduce((acc, curr) => {
      const { totalOutputQuantity } = curr
      return acc + totalOutputQuantity
    }, 0)
  }

  const calculateAverageEfficiencyForDate = (data: SewingEfficiencyDetails[]) => {
    let totalSamProduced = 0
    let totalMachineMinutes = 0
    data.map(item => {
      const { hourlyDetails } = item

      totalSamProduced += hourlyDetails.reduce((acc, curr) => {
        const { sewingSamProduced } = curr
        return acc + sewingSamProduced
      }, 0)

      totalMachineMinutes += hourlyDetails.reduce((acc, curr) => {
        const { sewingMachineMinutes } = curr
        return acc + sewingMachineMinutes
      }, 0)

      return null
    })
    if (totalSamProduced > 0) {
      return roundNumberToSignificantDigits(((totalSamProduced / totalMachineMinutes) * 100), 2)
    }
    return 0
  }


  const renderTableRows = (tableData: TableData) => {
    const keys = Object.keys(tableData)
    return keys.map((key, index) => {
      /**Date from YYYY-MM-DD to DD-MM-YYYY in string format*/
      const dateSplitList = key.split('-')
      let formattedDate = ''
      if (dateSplitList.length > 0) {
        formattedDate = `${dateSplitList[2]}-${dateSplitList[1]}-${dateSplitList[0]}`
      }

      return (
        <TableRow key={key}>
          <TableCell align='center'>{formattedDate}</TableCell>
          {
            Object.keys(batchData).map((batchDatakey, index) => {
              const filteredObject = tableData[key].filter(obj => {
                return obj.batchNumber === batchDatakey
              })

              if (filteredObject.length > 0) {
                return (<TableCell align='center'>{filteredObject[0].totalOutputQuantity}</TableCell>)
              }
              return (<TableCell align='center'>{0}</TableCell>)
            })
          }
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>
            {convertNumberToLocaleString(roundNumberToSignificantDigits(calculateTotalOutputQuantityForDate(tableData[key]), 2))}
          </TableCell>
          <TableCell align='center' sx={{ fontWeight: 'bold' }}>
            {calculateAverageEfficiencyForDate(tableData[key])} %
          </TableCell>
        </TableRow>
      )
    })
  }

  /**Handle download button click */
  const handleDownloadButtonClick = () => {
    const jsonData: any[] = []

    const keys = Object.keys(tableData)
    keys.map((key, index) => {
      /**Date from YYYY-MM-DD to DD-MM-YYYY in string format*/
      const dateSplitList = key.split('-')
      let formattedDate = ''
      if (dateSplitList.length > 0) {
        formattedDate = `${dateSplitList[2]}-${dateSplitList[1]}-${dateSplitList[0]}`
      }

      const pushObject: any = {
        date: formattedDate,
      }

      Object.keys(batchData).map((batchDatakey, index) => {
        
        const filteredObject = tableData[key].filter(obj => {
          return obj.batchNumber === batchDatakey
        })
        
        if (filteredObject.length > 0) {
          pushObject[`BATCH ${filteredObject[0].batchNumber}`] = filteredObject[0].totalOutputQuantity
          return null
        }
        pushObject[`BATCH ${batchDatakey}`] = 0
        return null
      })

      pushObject['output'] = roundNumberToSignificantDigits(calculateTotalOutputQuantityForDate(tableData[key]), 2)
      pushObject['efficiency%'] = calculateAverageEfficiencyForDate(tableData[key])
      jsonData.push(pushObject)
      return null
    })
    
    const workBook = XLSX.utils.book_new()
    const workSheet = XLSX.utils.json_to_sheet(jsonData)
    XLSX.utils.book_append_sheet(workBook, workSheet, 'BatchEfficiency-Date-Range')
    XLSX.writeFile(workBook, 'BatchEfficiency-Date-Range.xlsx')
  }

  return (
    <TableContainer component={Paper}>
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          bgcolor: 'primary.card'
        }}
      >
        <Typography variant='h5' padding={2}>Batch Efficiency-Date Range</Typography>
        <FlatButton label='DOWNLOAD' onClick={handleDownloadButtonClick} disableControlsStatus={false}/>
      </Box>

      
      <Table>
        <TableHead>
          <TableRow sx={{
            backgroundColor: 'alpha.lightest',
          }}>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>DATE</TableCell>
            {
              Object.keys(batchData).map((key, index) => {

                return <TableCell align='center' key={key} sx={{
                  fontWeight: 'bold'
                }}>BATCH {key}</TableCell>
              })
            }

            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>OUTPUT</TableCell>
            <TableCell align='center' sx={{
              fontWeight: 'bold'
            }}>EFFICIENCY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows(tableData)}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SewingDateRangeTable