import React from 'react'

import { Button, ButtonGroup } from '@mui/material'

export enum ButtonSelectionValues {
  Chart = 'chart',
  Table = 'table'
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    alpha: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    alpha: true
  }
}

interface ButtonSelectorProps {
  onSelect: (selection: ButtonSelectionValues) => void
}

const ButtonSelector = (props: ButtonSelectorProps) => {
  const [currentSelection, setCurrentSelection] = React.useState(ButtonSelectionValues.Chart)
  const { onSelect } = props
  return (
    <ButtonGroup color='alpha'>
      <Button variant={currentSelection === ButtonSelectionValues.Chart ? 'contained' : 'outlined'}
        onClick={() => {
          setCurrentSelection(ButtonSelectionValues.Chart)
          onSelect(ButtonSelectionValues.Chart)
        }}>{ButtonSelectionValues.Chart}</Button>
      <Button variant={currentSelection === ButtonSelectionValues.Table ? 'contained' : 'outlined'}
        onClick={() => {
          setCurrentSelection(ButtonSelectionValues.Table)
          onSelect(ButtonSelectionValues.Table)
        }}>{ButtonSelectionValues.Table}</Button>
    </ButtonGroup>
  )
}

export default ButtonSelector