import React from 'react'
import { FormControl, Button } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'

interface BorderRoundedDeleteProps {
  label: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

const BorderRoundedDeleteButton = (props: BorderRoundedDeleteProps) => {
  const { label, onClick } = props
  return (
    <FormControl sx={{
      width: '160px'
    }}>
      <Button variant="contained" size="small" sx={{
        backgroundColor: 'alpha.light',
        borderRadius: '25px'
      }} onClick={onClick} endIcon={<RemoveIcon sx={{
        backgroundColor: 'white',
        color: 'alpha.light',
        borderRadius: '25px'
      }}></RemoveIcon>}>{label}</Button>
    </FormControl>
  )
}

export default BorderRoundedDeleteButton