import React, { useState, useEffect, useCallback } from "react"
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  LinearProgress,
  Alert,
  AlertTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from "@mui/material"
import { DatePicker, FlatButton } from "../../UI/Components"
import { dateRangeType } from "../../UI/types"
import { getloadingQR } from "../api/apiFunctions"
import { QRloading } from "../types"
import format from "date-fns/format"
import * as XLSX from "xlsx"
import { SelectChangeEvent } from "@mui/material"
import RefreshIcon from '@mui/icons-material/Refresh'

interface LoadingData {
  data: QRloading[]
}

const LoadingDetails = (props: LoadingData) => {
  const [loadingData, setLoadingData] = useState<QRloading[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [dateRange, setDateRange] = useState<dateRangeType>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  })
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [selectedUnit, setSelectedUnit] = useState<string>("All")
  const [units, setUnits] = useState<string[]>([])

  const handleDateRangeChange = useCallback((value: dateRangeType) => {
    setDateRange(value)
  }, [])

  const startDate = format(dateRange?.startDate as Date, "yyyyMMdd")
  const endDate = format(dateRange?.endDate as Date, "yyyyMMdd")

  const fetchData = async () => {
    setLoading(true)
    try {
      const data = await getloadingQR(startDate, endDate)
      setLoadingData(data)

      // Extract unique units from the data
      const uniqueUnits = Array.from(new Set(data.map((item) => item.location)))
      setUnits(["All", ...uniqueUnits]) // Add "All" option for filtering all units
    } catch (error) {
      console.error("Failed to fetch data:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [startDate, endDate])

  // Function to refresh data
  const handleReloadData = () => {
    fetchData()
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleUnitChange = (event: SelectChangeEvent<string>) => {
    setSelectedUnit(event.target.value)
    setPage(0) // Reset to first page when unit filter is applied
  }

  const filteredData = selectedUnit === "All"
    ? loadingData
    : loadingData.filter((row) => row.location === selectedUnit)

  // Calculate total count for the selected unit
  const totalCount = filteredData.reduce((total, row) => total + (row.current_date_count || 0), 0)

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(loadingData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, "LoadingData")
    XLSX.writeFile(workbook, "LoadingData.xlsx")
  }

  return (
    <Box
      component="span"
      m={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ bgcolor: "background.paper", padding: 3, borderRadius: 2 }}
    >
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ width: "100%", marginBottom: 2, }}
      >

        {/* Dropdown for Unit Filtering */}

        <FormControl
          sx={{
            minWidth: 120,
            margin: "0 10px",
            "& .MuiInputBase-root": {
              height: 40,
            },

          }}
        >
          <InputLabel shrink={true}>Unit</InputLabel>
          <Select value={selectedUnit} onChange={handleUnitChange} variant="outlined">
            {units.map((unit, index) => (
              <MenuItem key={index} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DatePicker
          onChange={handleDateRangeChange}
          defaultDateRange={(value) => setDateRange(value)}
          direction={"horizontal"}
        />
        {/* Display Total Count Beside Unit Selection */}
        <Typography variant="h6" sx={{ marginLeft: 2 }}>
          Total: {totalCount}
        </Typography>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          // sx={{ marginTop: 2 }}
          sx={{ marginLeft: 1, marginRight: 1 }}
        />
        <FlatButton label="DOWNLOAD" onClick={downloadExcel} disableControlsStatus={false} />
        {/* Reload Button */}
        {/* <FlatButton label="RELOAD" onClick={handleReloadData} disableControlsStatus={false}  /> */}

        {/* Reload Icon Button */}
        <IconButton
          onClick={handleReloadData}
          sx={{
            backgroundColor: "alpha.light",
            borderRadius: 2,
            color: "white",
            width: 50,
            height: 40,
            marginLeft: 1,
          }}
        >
          <RefreshIcon />
        </IconButton>


      </Box>

      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 9999,
          }}
        >
          <LinearProgress sx={{ width: '50%' }} />
        </Box>
      ) : filteredData.length === 0 ? (
        <Alert severity="info" sx={{ width: "100%" }}>
          <AlertTitle>No Data Available</AlertTitle>
          No records found for the selected date range. Please adjust the date range or unit filter and try again.
        </Alert>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ maxWidth: "100%", overflowX: "auto" }}>
            <Table>
              <TableHead sx={{ bgcolor: "grey.100" }}>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Serial No</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Unit</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Date</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Batch Number</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>OC Number</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Colour Code</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Colour Description</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Size</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Buyer PO Number</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Buyer PO Country</TableCell>
                  <TableCell align="center" sx={{ fontWeight: "bold" }}>Today's Count</TableCell>
                  {/* <TableCell align="center" sx={{ fontWeight: "bold" }}>Total Count</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell align="center">{row.location}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="center">{row.batch_number}</TableCell>
                      <TableCell align="center">{row.oc_number}</TableCell>
                      <TableCell align="center">{row.colour_code}</TableCell>
                      <TableCell align="center">{row.colour_description}</TableCell>
                      <TableCell align="center">{row.size}</TableCell>
                      <TableCell align="center">{row.buyer_po_number}</TableCell>
                      <TableCell align="center">{row.buyer_po_country}</TableCell>
                      <TableCell align="center">{row.current_date_count}</TableCell>
                      {/* <TableCell align="center">{row.total_count}</TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  )
}

export default LoadingDetails
