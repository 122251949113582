import React, { useState, useEffect } from 'react'
import { TextField, Typography, Box, TableContainer, Paper, Table, TableCell, TableHead, TableBody, TableRow, IconButton } from '@mui/material'
import { PartsDetails, PartsListWithId } from '../types'
import { nanoid } from 'nanoid'
import {AddButton, FlatButton} from '../../UI/Components'
import DeleteIcon from '@mui/icons-material/Delete'

interface PartsProps {
  partsDetails: PartsDetails
  submitParts : (parts: string[]) => void
}

function Parts(props: PartsProps) {
  const {partsDetails, submitParts} = props

  const [partsList, setPartsList] = useState<PartsListWithId[]>([])

  useEffect(() => {
    if (partsDetails !== null && partsDetails !== undefined){
      const partsWithId =  partsDetails.parts.map((object) => {
        const {part} = object
        return ({
          part,
          id: nanoid(4)
        })
      })
      setPartsList(partsWithId)
    }
  }, [partsDetails])

  /**This function is responsible to update part
   * @param {string}part The part to update
   * @param {string}indexToChange The index value to effect
   */
  const handlePartChange = (part: string, indexToChange: string) => {
    const createPartsListWithChanges = [...partsList]
    const partIndex = createPartsListWithChanges.findIndex((obj => obj.id === indexToChange))
    createPartsListWithChanges[partIndex].part = part
    
    setPartsList(createPartsListWithChanges)
  }

  /**This function is responsible to remove row from entry
   * @param {string}indexToRemove The index value to remove row
   */
  const removePartQuantityRow = (indexToRemove: string) => {
    if (partsList.length === 1) {
      return
    }
    const createPartsListWithChanges = [...partsList]
    const partIndex = createPartsListWithChanges.findIndex((obj => obj.id === indexToRemove))
    createPartsListWithChanges.splice(partIndex, 1)

    setPartsList(createPartsListWithChanges)
  }

  /**This function is responsible to add row in entry */
  const addPartsRow = () => {
    setPartsList([...partsList, { part: '', id: nanoid(4) }])
  }

  /**This function is responsible to submit parts entry */
  const submitPartsList = () => {
    const partsListWithIdRemoved = partsList.map(({ part }) => {
      return part
    })
    submitParts(partsListWithIdRemoved)
  }

  return (
    <React.Fragment>
      <Box sx={{
        paddingTop: 2
      }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: 'alpha.lightest'
                }}
              >
                <TableCell><Typography variant='subtitle1'>Part Name</Typography></TableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                partsList.map((object) => {
                  return (
                    <TableRow key={object.id}>
                      <TableCell>
                        <TextField
                          type='string'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          size="small"
                          value={object.part}
                          sx={{width: '80%'}}
                          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handlePartChange(event.target.value, object.id)}
                        /> 
                      </TableCell>
                      <TableCell>
                        <IconButton aria-label="delete" size="large" onClick={() => removePartQuantityRow(object.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display="flex"
        justifyContent="left"
        alignItems="center" sx={{ paddingTop: 3 }}>
        <AddButton label='ADD ROW' onClick={addPartsRow}/>
      </Box>

      <Box display="flex"
        justifyContent="center"
        alignItems="center" sx={{ paddingTop: 4 }}>
        <FlatButton label='SUBMIT' onClick={submitPartsList} disableControlsStatus={false}/>
      </Box>
    </React.Fragment>
  )
}

export default Parts